export enum FilterEnum {
    IS_NOT_EQUAL = 0,
    IS_EQUAL = 1,
    IS_LIKE = 2,
    IS_NOT_LIKE = 3,
    IS_IN = 4,
    IS_NOT_IN = 5
};
export enum ManagerStatus {
    SUSPENDED = 6
};
export enum StatusConditions {
    APPROVED = 5,
    SUSPENDED = 6,
    PENDING = 2,
    DECLINED = 8, 
    FRAUD = 7
};
export enum PaymentMethod {
    PAYPAL = 0,
    CHEQUE = 1,
    MONEYBOOKERS = 2,
    BANKTRANSFER = 3
};
export enum Affiliate {
    TOP_AFFILIATE = 0,
    SUB_AFFILIATE = 1,
    CALL_AFFILIATE = 2,
}
export enum CustomField {
    ID = 0, 
    NAME = 1,
    TYPE = 2,
    MANAGER = 3,
    STATUS = 4,
    DATE_JOINED = 5,
    PARRENT_AFFILIATE = 6,
    WEBSITE_URL = 7
};

export enum Clicks {
    SALE = 'sale',
    LEAD = 'lead'
}


