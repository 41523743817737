import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { SharedPipesModule } from '@pipes/index';
import { NgSelectModule } from '@ng-select/ng-select';
import { MyDatePickerModule } from 'mydatepicker';
import { AffiliatesInCampaignPage } from './index';
import { AffiliatesInCampaignPageRoutingModule } from './affiliates-in-campaign-page-routing.module';
import { AffiliatesInCampaignFilterComponent } from './affiliates-in-campaign-filter/affiliates-in-campaign-filter.component';
import { SharedDirectivesModule } from '@directives/index';

@NgModule({
  declarations: [
    AffiliatesInCampaignPage,
    AffiliatesInCampaignFilterComponent
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AffiliatesInCampaignPageRoutingModule,
    SharedPipesModule,
    NgSelectModule,
    MyDatePickerModule,
    SharedDirectivesModule
  ] 
})
export class AffiliatesInCampaignPageModule { }
