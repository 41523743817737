import {Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewChildren} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

import {
  UserService,
  HelperService,
  AffiliateService,
  PageService,
  PaymentService,
  PaymentMethodsService,
  AffiliateManagersService} from "@services/index";

import { IState } from '@models/index';
import * as ENUMS from '@enums/index';
import { IModalvalue } from '@shared-models/index';
import { IFormOptions } from '@interfaces/index';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'aff-pay-affiliates-filter',
  templateUrl: './pay-affiliates-filter.component.html',
  styleUrls: ['./pay-affiliates-filter.component.scss']
})
export class PayAffiliatesFilterComponent implements OnInit, OnDestroy {

  status = ENUMS.StatusConditions;

  filters$: Observable<any>;
  selectedFilter$: Observable<any>;
  filterNamePopup: boolean = false;

  savedFilterList: any = {};
  filterSelectedValue: string | number = '';
  filterSelectedId: number;
  isFilterEmpty: boolean = true;

  filterOptions = {
    balance_from: '0',
    keyword: '',
    paymentMethods: [],
    status: [],
    created_from: '',
    created_to: '',
    affiliates: [],
    managers: [],
    affTypes: [],
    reachMinAmount: false
  };

  @ViewChild('createdDatePicker', {static: false}) createdDatePicker: ElementRef;
  datePickerOptions$: Observable<any>
  dateOption: any = {
    created_from: '',
    created_to: ''
  }

  showFiterComponent: boolean = false;

  selectedValues: any = {
    affiliates: [],
    managers: [],
    paymentMethods: [],
    affTypes: []
  };

  lists: any = {
    affiliates: [],
    managers: [],
    paymentMethods: []
  };

  searchValues: any = {
    affiliates: new FormControl(),
    managers: new FormControl(),
    keyword: new FormControl()
  };

  affiliates$: Observable<IState>;
  managers$: Observable<IState>;
  paymentMethods$: Observable<IState>;
  affTypes$: Observable<any[]>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private paymentService: PaymentService,
    private userService: UserService,
    private affiliateManagersService: AffiliateManagersService,
    private affiliateService: AffiliateService,
    private paymentMethodsService: PaymentMethodsService,
    private pageService: PageService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    ) {
    // get datepicker options
    this.datePickerOptions$ = pageService.getDatePickerOptionsSelector();

    this.filters$ = this.userService.filtersSelector();
    this.selectedFilter$ = this.userService.filterSelector();

    // get affiliates
    this.affiliates$ = this.affiliateService.getAffiliatesSelector();

    // get affiliate managers
    this.managers$ = this.affiliateManagersService.getAffiliateManagersSelector();
    this.affiliateManagersService.fetchAffiliateManagers(1, {});
    // get payment methods
    this.paymentMethods$ = this.paymentMethodsService.getPaymentMethodsSelector();
    this.paymentMethodsService.fetchPaymentMethods(1, {});
    // get affiliate types
    this.affTypes$ = this.pageService.getUserTypeListSelector();
    this.pageService.fetchUserTypes();
  }

  ngOnInit() {
    // listen value change of keyword
    this.searchValues.keyword.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(newValue => {
        this.filterOptions['keyword'] = newValue;
        this.affiliateService.fetchAffiliates(1, this.filterOptions);
      });

    // Get filters
    this.userService.fetchFilters('payment');
    this.filters$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded && state.data && state.data.length) {
          this.savedFilterList = {};
          state.data.forEach(filter => {
            this.savedFilterList = {
              ...this.savedFilterList,
              [filter.id]: filter.name
            }
          });
        }
      });

    // Get selected filter
    this.selectedFilter$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(filter => {
        if (filter.type !== 'payment') return;
        const filterOpt = filter.filter_fields;
        if (filterOpt) {
          this.onClearFilter();
          HelperService.fillFilterOption(filterOpt, this.filterOptions);
          this.filterSelectedId = filter.id;
          Object.keys(this.dateOption).forEach(key => {
            this.dateOption[key] = filterOpt.find(opt => opt.key === key)
              ? { formatted: filterOpt.find(opt => opt.key === key)['value'] } : ''
          });
          this.paymentService.fetchPayments(1, this.filterOptions);
          this.isFilterEmpty = this.checkEmptyFilter(this.filterOptions);
          this.showFiterComponent = true;
        }
      });

    // affiliate managers search
    this.searchValues.managers.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.affiliateManagersService.fetchAffiliateManagers(1, { keyword }));

    // get affiliate managers list
    this.getManagers();

    // affiliates search
    this.searchValues.affiliates.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.affiliateService.fetchAffiliates(1, { keyword }));

    // get affiliate
    this.getAffiliates();

    // get payments
    this.getPaymentMethods();
  }

  getType(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  }

  getAffiliates(): void {
    this.affiliates$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.lists.affiliates = state.data;
        }
      });
  }

  getManagers(): void {
    this.managers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.lists.managers = state.data;
        }
      });
  }

  getPaymentMethods() {
    this.paymentMethods$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      if (state.isLoaded) {
        this.lists.paymentMethods = state.data;
      }
    });
  }

  getSearchValue(options, name: string): void {
    // search value in managers
    this.searchValues[name].patchValue(options.term);
  }

  onSelectedValue(values: any[], name: string): void {
    let selectedValues: number[] = [];
    for (const value of values) {
      selectedValues.push(value.id);
    }
    this.filterOptions[name] = selectedValues;
    this.isFilterEmpty = this.checkEmptyFilter(this.filterOptions);
    this.affiliateService.fetchAffiliates(1, this.filterOptions);
  }

  setFilterNamePopup(option: IModalvalue): void {
    if (option.confirm) {
      if (!this.userService.checkFilterName(option.inputValue, this.savedFilterList, this.filterSelectedId)) return;
      // delete keyword property
      delete this.filterOptions.keyword;

      this.filterSelectedId
        ? this.userService.updateFilter({
            id: this.filterSelectedId,
            name: option.inputValue,
            type: 'payment',
            additional_fields: this.filterOptions
          })
        :  this.userService.addFilter({
            name: option.inputValue,
            type: 'payment',
            additional_fields: this.filterOptions
          });
    }
    this.filterNamePopup = false;
  }


  onDateChanged(event, name: string) {
    this.filterOptions[name] = event.formatted;
    this.isFilterEmpty = this.checkEmptyFilter(this.filterOptions);
    this.affiliateService.fetchAffiliates(1, this.filterOptions);
  }

  onCalendarToggle(e: Event, name: string): void {
    this.pageService.disableDate({
      name,
      date: name === 'created_from' ? this.filterOptions.created_to : this.filterOptions.created_from
    });
  }

  onToggleFilter(): void {
    this.showFiterComponent = !this.showFiterComponent;
  }

  getSelectedFilter(id): void {
    this.filterSelectedValue = this.savedFilterList[id];
    this.userService.fetchFilter(id);
  }

  onDeleteFilter(id): void {
    this.userService.deleteFilter(id);
  }

  onClearFilter() {
    this.filterOptions = {
      balance_from: '0',
      keyword: '',
      status: [],
      created_from: '',
      created_to: '',
      affiliates: [],
      managers: [],
      paymentMethods: [],
      affTypes: [],
      reachMinAmount: false
    };

    this.dateOption = {
      joined_from: '',
      created_to: ''
    };

    this.selectedValues = {
      affiliates: [],
      managers: [],
      paymentMethods: [],
      affTypes: []
    };
  }

  onChangeFormValue(options: IFormOptions) {
    switch (options.name) {
      case 'clearFilter':
        this.onClearFilter();
        this.filterSelectedValue = '';
        this.isFilterEmpty = this.checkEmptyFilter(this.filterOptions);
        this.affiliateService.fetchAffiliates(1, this.filterOptions);
        break;

      case 'saveFilter':
        if (this.isFilterEmpty) return;
        this.filterNamePopup = true;
        break;

      case 'reachMinAmount':
        this.filterOptions[options.name] = options.checked;
        this.isFilterEmpty = this.checkEmptyFilter(this.filterOptions);
        this.affiliateService.fetchAffiliates(1, this.filterOptions);
        break;

      case 'status':
        if (options.checked) {
          this.filterOptions['status'].push(options.value)
        } else {
          // find unchecked value and remove from status array
          const findIndex = this.filterOptions['status'].findIndex(val => val === options.value);
          this.filterOptions['status'].splice(findIndex, 1)
        }
        this.isFilterEmpty = this.checkEmptyFilter(this.filterOptions);
        this.affiliateService.fetchAffiliates(1, this.filterOptions);
        break;

      case 'keyword':
        this.searchValues.keyword.patchValue(options.value)
        break;

      default:
        this.filterOptions[options.name] = options.value;
        this.isFilterEmpty = this.checkEmptyFilter(this.filterOptions);
        this.affiliateService.fetchAffiliates(1, this.filterOptions);
    }
  }

  checkEmptyFilter(filters) {
    // _todo: fix check
    const filterObj = JSON.parse(JSON.stringify(filters));

    if (filters.balance_from === '0') {
      filterObj.balance_from = '';
    }

    if (!filters.reachMinAmount) {
      filterObj.reachMinAmount = '';
    }

    if (filters.page) {
      filters.page = '';
    }

    return HelperService.checkEmptyFilter(filterObj);
  }

  ngOnDestroy(): void {
    this.userService.clearSelectedFilter();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
