import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardPage } from '@pages/dashboard-page/dashboard-page.component';

const routes: Routes = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    {
      path: 'dashboard',
      component: DashboardPage,
      data: {
        breadcrumb: 'Dashboard'
      }
    },
    {
      path: 'campaigns',
      loadChildren: () => import('@pages/campaign-page/campaign-page.module').then(m => m.CampaignPageModule)
    },
    { 
      path: 'promotion-tools',
      loadChildren: () => import('@pages/banners-page/banners-page.module').then(m => m.BannersPageModule)
    },
    {
      path: 'affiliates',
      loadChildren: () => import('@pages/affiliates-page/affiliates-page.module').then(m => m.AffiliatesPageModule)
    },
    {
      path: 'affiliates-in-campaign',
      loadChildren: () => import('@pages/affiliates-in-campaign-page/affiliates-in-campaign-page.module').then(m => m.AffiliatesInCampaignPageModule)
    },
    {
      path: 'generated-commissions',
      loadChildren: () => import('@pages/generated-commissions-page/generated-commissions.module').then(m => m.GeneratedCommissionsModule)
    },
    {
      path: 'affiliate-payments',
      loadChildren: () => import('@pages/affiliate-payments-page/affiliate-payments-page.module').then(m => m.AffiliatePaymentsPageModule)
    },
    {
      path: 'affiliate-managers',
      loadChildren: () => import('@pages/affiliate-managers-page/affiliate-managers-page.module').then(m => m.AffiliateManagersPageModule)
    },
    {
      path: 'configuration',
      loadChildren: () => import('@pages/configuration-page/configuration-page.module').then(m => m.ConfigurationPageModule)
    },
    {
      path: 'messages',
      loadChildren: () => import('@pages/messages-page/messages-page.module').then(m => m.MessagesPageModule)
    },
    {
      path: 'reports',
      loadChildren: () => import('@pages/reports-page/reports-page.module').then(m => m.ReportsPageModule)
    },
    {
      path: 'tracking',
      loadChildren: () => import('@pages/tracking-page/tracking-page.module').then(m => m.TrackingPageModule)
    }
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthorizedPagesRoutingModule { }