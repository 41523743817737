import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PermissionGuard } from '@guards/index';
import { 
    EventTypesPage,
    EventCategoriesPage
} from './index';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'event-types',
        pathMatch: 'full'
    },
    {
        path: 'event-types',
        canActivate: [PermissionGuard],
        component: EventTypesPage, 
        data: {
            breadcrumb: 'Event Types'
        },
    },
    {
      path: 'event-categories',
      canActivate: [PermissionGuard],
      component: EventCategoriesPage, 
      data: {
          breadcrumb: 'Event Categories'
      },
  },
   
];

@NgModule({
  imports: [
    RouterModule.forChild(
        routes
    )
  ],
  exports: [
    RouterModule
  ]
})
export class TrackingRoutingModule {}
