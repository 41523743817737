import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {Store} from "@ngrx/store";

import {SharedService} from '@services/shared/shared.service';
import {
  addCampaignUrl, campaignURLsEventSelector,
  campaignURLsSelectorStore,
  deleteCampaignUrl,
  editCampaignUrl,
  fetchCampaignURLs
} from "@store/campaign-urls";
import {Observable, of} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class CampaignUrlStore {
  backendUrl = environment.backendUrl;

  constructor(
    private sharedService: SharedService,
    private store: Store<{ campaignUrl: any }>
  ) {
  }

  selectCampaignUrls(): Observable<any> {
    return this.store.select(campaignURLsSelectorStore)
  }

  selectCampaignUrlsEvents(): Observable<any> {
    return this.store.select(campaignURLsEventSelector)
  }

  fetchUrls(page: number, campaignID: number) {
    return this.store.dispatch(fetchCampaignURLs({page: page, payload: campaignID}))
  }

  addUrl(params: any) {
    this.store.dispatch(addCampaignUrl({params}))
  }

  deleteUrl(id: number, params: any = {}) {
    return this.store.dispatch(deleteCampaignUrl({id, params}))
  }

  editUrl(id: number, params: any = {}) {
    return this.store.dispatch(editCampaignUrl({id, params}))
  }

}
