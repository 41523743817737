import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ReportService, PageService } from '@services/index';
import { IPage } from '@interfaces/index';
import { takeUntil } from 'rxjs/operators';
import { IModalvalue } from '@shared-models/index';
import { IState } from "@models/index";

@Component({
  selector: 'aff-top-promotion-tools',
  templateUrl: './top-promotion-tools.component.html',
  styleUrls: ['./top-promotion-tools.component.scss']
})
export class TopPromotionToolsPage implements OnInit, OnDestroy {

  loadingIndicator: boolean = true;
  selectedRows = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  rows: any[] = [];

  topPromotions$: Observable<IState>;
  unsubscribe$ = new Subject<void>()

  constructor(
    private reportService: ReportService,
    private pageService: PageService
  ) { 

    this.rows = [];
    this.topPromotions$ = this.reportService.getReportsSelector();

    this.pageService.changePageInfo({ breadcrum: ['Reports', 'Top Promotion Tools']});
  }

  ngOnInit() {
    // get top promotions list
    this.reportService.fetchTopPromotions(1, {});
    
    this.topPromotions$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.rows = [];

          for (const topPromotion of state.data) {
            
            if(!topPromotion.lsum) {
              topPromotion.lsum = 0;
            }
            if(!topPromotion.ssum) {
              topPromotion.ssum = 0;
            }
            
            const row = {
              id: topPromotion.banner_id, 
              promotionToolName: topPromotion.banner_name,
              promotionToolType: topPromotion.banner_type ? topPromotion.banner_type.replace('_', ' ').toUpperCase() : '',
              hidden: topPromotion.hidden_banner === 1 ? 'true' : 'false',
              destinationUrl: topPromotion.destination_url,
              campaignName: topPromotion.campaign_name,
              affiliateManager: topPromotion.email ,
              // commission: campaign.commissionOverview, 
              leadsRatio: '',
              leadsCountSum: topPromotion.lcount + '/$' + topPromotion.lsum/100 , 
              salesCountSum: topPromotion.scount + '/$' + topPromotion.ssum/100 , 
              returnsCountSum: '',
              total: '$' + topPromotion.total/100
            };
            this.rows.push(row);
          }
        }
        this.rows = [...this.rows];
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
    });
}

  setPage(e): void {
    this.reportService.fetchTopPromotions(e.offset + 1, {});
  }

  onSelect(e): void {
    this.selectedRows = e.selected;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
