import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

import { 
  CampaignService, 
  HelperService, 
  UserService, 
  AffiliateManagersService,
  PageService,
  SharedService,
  ReportService } from "@services/index";

import * as ENUMS from '@enums/index';
import { IModalvalue } from '@shared-models/index';
import { IState } from '@models/index';
import { IFormOptions } from '@interfaces/iFormOptions';
import { IMyDateModel } from 'mydatepicker';


@Component({
  selector: 'aff-top-campaign-filter',
  templateUrl: './top-campaign-filter.component.html',
  styleUrls: ['./top-campaign-filter.component.scss']
})
export class TopCampaignFilterComponent implements OnInit, OnDestroy {

  @ViewChild('parentSale',{static: false}) parentSale: ElementRef;
  @ViewChild('parentLead',{static: false}) parentLead: ElementRef;

 // enums
 status = ENUMS.Status
 managerStatus = ENUMS.ManagerStatus;
 camType = ENUMS.CampaignType;
 commissionType = ENUMS.FixedCostsCurrency;

 datePickerOptions$: Observable<any>;
 dateOption: any = {
    report_from: '',
    report_to: '',
    created_from: '',
    created_to: '',
    valid_from: '',
    valid_to: ''
 };

 showFiterComponent: boolean = false;

 affiliateManagers$: Observable<IState>;
 selectedManagers: any = [];
 managersList: any = [];
 managersSearch = new FormControl();

 filters$: Observable<any>;
 selectedFilter$: Observable<any>;
 filterNamePopup: boolean = false;
 isFilterEmpty: boolean = true;

 savedFilterList: any = {};
 filterSelectedValue: string | number = '';
 filterSelectedId: number;

 keyword = new FormControl();
 filterOptions = {
   keyword: '',
   status: [],
   camType: [],
   manager_status: [],
   report_from: '',
   report_to: '',
   created_from: '',
   created_to: '',
   valid_from: '',
   valid_to: '',
   commissionType: [],
   sale_commission_from: '',
   sale_commission_to: '',
   lead_commission_from: '',
   lead_commission_to: '',
   limit_cookies_days_from: '',
   limit_cookies_days_to: '',
   managers: []
 };

 private unsubscribe$ = new Subject<void>();

 constructor(private campaingService: CampaignService,
             private pageService: PageService,
             private userService: UserService,
             private sharedService: SharedService,
             private affiliateManagersService: AffiliateManagersService,
             private resportService: ReportService) { 
               // get datepicker options
               this.datePickerOptions$ = pageService.getDatePickerOptionsSelector();

               this.filters$ = this.userService.filtersSelector();
               this.selectedFilter$ = this.userService.filterSelector();
               this.affiliateManagers$ = this.affiliateManagersService.getAffiliateManagersSelector();

               // get affiliate managers
               this.affiliateManagersService.fetchAffiliateManagers(1, {});
             }

 ngOnInit() {
   // listen value change of keyword
   this.keyword.valueChanges
     .pipe(
       takeUntil(this.unsubscribe$),
         debounceTime(500),
         distinctUntilChanged()
     )
     .subscribe(newValue => {

       this.filterOptions['keyword'] = newValue;
       this.resportService.fetchTopCampaigns(1, this.filterOptions);
     });

   // Get filters
   this.userService.fetchFilters('campaign');
   this.filters$
     .pipe(takeUntil(this.unsubscribe$))
     .subscribe(state => {
       if (state.isLoaded && state.data && state.data.length) {
         this.savedFilterList = {};
         state.data.forEach(filter => {
           this.savedFilterList = {
             ...this.savedFilterList,
             [filter.id] : filter.name
           }
         });
       }
      });

   // Get selected filter
   this.selectedFilter$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(filter => {
       if (filter.type !== 'campaign') return;
        const filterOpt = filter.filter_fields;
        if (filterOpt) {
           this.onClearFilter();
           HelperService.fillFilterOption(filterOpt, this.filterOptions);
           this.selectedManagers = this.filterOptions.managers;
           this.filterSelectedId = filter.id;
           
           Object.keys(this.dateOption).forEach(key => {
             this.dateOption[key] = filterOpt.find(opt => opt.key === key) 
                                   ? { formatted: filterOpt.find(opt => opt.key === key)['value'] } : ''
           });
           this.resportService.fetchTopCampaigns(1, this.filterOptions);
           this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
           this.showFiterComponent = true;
        }
      });

   // owner search
   this.managersSearch.valueChanges
     .pipe(
       takeUntil(this.unsubscribe$),
       debounceTime(500),
       distinctUntilChanged()
     )
     .subscribe(keyword => this.affiliateManagersService.fetchAffiliateManagers(1, { keyword }));

   // get affiliate managers
   this.getAffiliateManagers();
 }

 onCalendarToggle(e: Event, name: string) {
   switch (name) {
     case 'created_from':
       this.pageService.disableDate({
         name, 
         date: this.filterOptions.created_to
       });
       break;
       
     case 'created_to':
       this.pageService.disableDate({
         name, 
         date: this.filterOptions.created_from
       });
       break;
      case 'report_from':
      this.pageService.disableDate({
        name, 
        date: this.filterOptions.report_to
      });
      break;
      
    case 'report_to':
      this.pageService.disableDate({
        name, 
        date: this.filterOptions.report_from
      });
      break;

     case 'valid_from':
       this.pageService.disableDate({
         name, 
         date: this.filterOptions.valid_to
       });
       break;
   
     default:
       this.pageService.disableDate({
         name, 
         date: this.filterOptions.valid_from
       });
       break;
   }
 }

 onDateChanged(event: IMyDateModel, name: string): void {
   this.filterOptions[name] = event.formatted;
   this.resportService.fetchTopCampaigns(1, this.filterOptions);
   this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
 }

 getAffiliateManagers(): void {
   this.affiliateManagers$
     .pipe(takeUntil(this.unsubscribe$))
     .subscribe(state => {
       if (state.isLoaded) {
         this.managersList = state.data;
       }
   });
 }

 getSearchValue(options): void {
   // search value in managers
   this.managersSearch.patchValue(options.term);
 }

 onChangeManagersSelection(managers: any[]): void {
   let affiliateManagers: number[] = [];
   for (const affiliate of managers) {
     affiliateManagers.push(affiliate.id);
   }
   this.filterOptions['managers'] = affiliateManagers;
   this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);

   this.resportService.fetchTopCampaigns(1, this.filterOptions);
 }

 onToggleFilter(): void {
   this.showFiterComponent = !this.showFiterComponent;
 }

 getSelectedFilter(id): void {
   this.filterSelectedValue = this.savedFilterList[id];
   this.userService.fetchFilter(id);
 }

 onDeleteFilter(id): void {
   this.userService.deleteFilter(id);
 }

 setFilterNamePopup(option: IModalvalue): void {
   if (option.confirm) {
     if (!this.userService.checkFilterName(option.inputValue, this.savedFilterList, this.filterSelectedId)) return;

     // delete keyword property
     delete this.filterOptions.keyword;

     this.filterSelectedId
       ? this.userService.updateFilter({
           id: this.filterSelectedId,
           name: option.inputValue, 
           type: 'campaign', 
           additional_fields: this.filterOptions
         })
       :  this.userService.addFilter({
           name: option.inputValue, 
           type: 'campaign', 
           additional_fields: this.filterOptions
         });
     this.filterSelectedValue = option.inputValue;
   }
   this.filterNamePopup = false;
 }

 onClearFilter(): void {
   this.dateOption = {
      report_from: '',
      report_to: '',
      created_from: '',
      created_to: '',
      valid_from: '',
      valid_to: ''
   };
   this.selectedManagers = [];
   this.filterOptions = {
     keyword: '',
     status: [],
     camType: [],
     manager_status: [],
     report_from: '',
     report_to: '',
     created_from: '',
     created_to: '',
     valid_from: '',
     valid_to: '',
     commissionType: [],
     sale_commission_from: '',
     sale_commission_to: '',
     lead_commission_from: '',
     lead_commission_to: '',
     limit_cookies_days_from: '',
     limit_cookies_days_to: '',
     managers: this.selectedManagers
   };
    this.parentSale.nativeElement.querySelectorAll('input').forEach(element => {
      element.value = '';
  });
    this.parentLead.nativeElement.querySelectorAll('input').forEach(element => {
      element.value = '';
  });

   this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
   
 }

 onChangeFormValue(options: IFormOptions): void {
   
   switch(options.name) {
     case 'clearFilter' :
       this.onClearFilter();
       // reset selected value
       this.filterSelectedValue = '';
       this.resportService.fetchTopCampaigns(1, this.filterOptions);
       break;

     case 'saveFilter' :
       if (this.isFilterEmpty) return;
       this.filterNamePopup = true;
       break;

     case 'status' :
     case 'manager_status' :
     case 'camType' :
     case 'commissionType' :
       if (options.checked) {
         this.filterOptions[options.name].push(options.value);
       } else {
         // find unchecked value and remove from status array
         const findIndex = this.filterOptions[options.name].findIndex(val => val === options.value);
         this.filterOptions[options.name].splice(findIndex, 1);
       }
        this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
        this.resportService.fetchTopCampaigns(1, this.filterOptions);
       break;
      
     case 'keyword':
       this.keyword.patchValue(options.value)
       break;

     default :
      this.filterOptions[options.name] = options.value;
      this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
      this.resportService.fetchTopCampaigns(1, this.filterOptions);
   }
 }

 ngOnDestroy(): void {
   this.userService.clearSelectedFilter();
   this.unsubscribe$.next();
   this.unsubscribe$.complete();
 }


}
