import { createReducer, on } from "@ngrx/store";
import {
    fetchEventCategories,
    fetchEventCategoriesSuccess,
    fetchEventCategoriesFailure,
    getEventCategory,
    getEventCategorySuccess,
    getEventCategoryFailure,
    addEventCategory,
    addEventCategorySuccess,
    addEventCategoryFailure,
    updateEventCategory,
    updateEventCategorySuccess,
    updateEventCategoryFailure,
    deleteEventCategory,
    deleteEventCategorySuccess,
    deleteEventCategoryFailure
} from "./event-category.action";

import { IState } from "@models/index";

const initialState: IState = {
  isLoaded: false,
  data: [],
  selectedItem: {},
  error: null,
  total: 0,
  maxPages: 0,
  perPage: 0,
  currentPage: 0
};

const _eventCategoryReducer = createReducer(
  initialState,
  on(fetchEventCategories, (state) => {
        return state;
  }),
  on(fetchEventCategoriesSuccess, (state, { eventCategory }) => {
    const payload = {
      isLoaded: true,
      data: eventCategory.data,
      total: eventCategory.total,
      maxPages: eventCategory.maxPages,
      perPage: eventCategory.length,
      currentPage: eventCategory.currentPage,
      selectedItem: {}
    };    
    return { ...state, ...payload };
  }),
  on(fetchEventCategoriesFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),


  on(getEventCategory, (state, { id }) => {
    const payload = {
      isLoaded: false
    };
    return { ...state, ...payload };
  }),
  on(getEventCategorySuccess, (state, { eventCategory }) => {
    const payload = {
      isLoaded: true,
      selectedItem: eventCategory
    };
    return { ...state, ...payload };
  }),
  on(getEventCategoryFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(updateEventCategory, (state, { eventCategory }) => {
    const payload = {
      isLoaded: false,
    }
    return { ...state, ...payload };
  }),
  on(updateEventCategorySuccess, (state, { eventCategory }) => {
    const index = state.data.findIndex(cat => cat.id === eventCategory.id); 
    state[index] = eventCategory;
    const payload = {
      isLoaded: true,
      selectedItem: eventCategory
    }
    return { ...state, ...payload };
  }),
  on(updateEventCategoryFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(addEventCategory, (state, { eventCategory }) => {
    const payload = {
      isLoaded: false
    };
    return { ...state, ...payload };
  }),
  on(addEventCategorySuccess, (state, { eventCategory }) => {
    const payload = {
      isLoaded: true,
    };
    return { ...state, ...payload };
  }),
  on(addEventCategoryFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
 
  on(deleteEventCategory, (state, { id }) => {
    const payload = {
      isLoaded: false
    };
    return { ...state, ...payload };
  }),
  on(deleteEventCategorySuccess, (state, { id }) => {
    const payload = {
      isLoaded: true,
      // data: state.data.filter(item => item.id !== id)
    };
    return { ...state, ...payload };
  }),
  on(deleteEventCategoryFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  })

);

export function eventCategoryReducer(state, action) {
  return _eventCategoryReducer(state, action);
}
