import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { FormControl } from '@angular/forms';

import { AffiliateService, HelperService } from "@services/index";
import { IState } from '@models/index';
import { IPage, IFormOptions } from '@interfaces/index';

@Component({
  selector: 'aff-affiliates-downlines-page',
  templateUrl: './affiliates-downlines-page.component.html',
  styleUrls: ['./affiliates-downlines-page.component.scss']
})
export class AffiliatesDownlinesPage implements OnInit, OnDestroy {

  affiliateID: string = '';

  rows = [];
  loadingIndicator: boolean = false;
  selectedRows = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  editMode: boolean = false

  filterOptions: any = {
    user_id: ''
  };

  searchValue = new FormControl();

  downlines$: Observable<IState>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private affiliateService: AffiliateService,
    private route: ActivatedRoute ) {

    this.downlines$ = this.affiliateService.getAffiliatesSelector();
  }

  ngOnInit(): void {

      // get affiliate id
      this.affiliateID = this.route.snapshot.params.id;

      if (this.affiliateID) {
        this.affiliateService.fetchAffiliateDownlines(1, this.affiliateID);
      }
   
    this.searchValue.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => {
        this.affiliateService.fetchAffiliateDownlines(1, this.affiliateID, { keyword });
      });

    this.downlines$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.rows = [];
          for (const data of state.data) {
            const row = {
              affiliateName: data.profile ? data.profile.first_name : '',
              affiliateType: data.affType ? data.affType.name : '',
              affiliateStatus: data.status ? data.status.name : '', 
              dateJoined: data.created_at,
            };
            this.rows.push(row);
          }

          this.rows = [...this.rows];

          // get pagination options
          this.page.currentPage = state.currentPage - 1;
          this.page.perPage = state.perPage;
          this.page.maxPages = state.maxPages;
          this.page.total = state.total;
        }

        // close loader
        this.loadingIndicator = false;
      });
  }

  onSearch(options: IFormOptions): void {
    this.searchValue.patchValue(options.value);
  }

  setPage(e): void {
    this.selectedRows = [];
    this.affiliateService.fetchAffiliateDownlines(e.offset + 1, this.affiliateID);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
