import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {Observable, of} from 'rxjs';
import { environment } from "../../../environments/environment";
import { Store } from "@ngrx/store";
import {
  fetchCampaign,
  deleteCampaign,
  addCampaign,
  updateCampaign,
  getCampaign,
  fetchCampaignsByAffiliate,
  unarchiveCampaign
} from "../../store/campaign/campaign.actions";
import { getCampaignsSelector, getCampaignSelector } from '@store/campaign/campaign.selectors';
import { IState } from '@models/index';

import { HelperService } from '../helper/helper.service';
import { SharedService } from '@services/shared/shared.service';

@Injectable({
  providedIn: "root"
})
export class CampaignService {
  backendUrl = environment.backendUrl;

  constructor(
    private sharedService: SharedService,
    private http: HttpClient,
    private store: Store<{ campaign: string }>
  ) {}

  getCampaignsSelector(): Observable<IState> {
    return this.store.select(getCampaignsSelector);
  }

  getCampaignSelector(): Observable<any> {
    return this.store.select(getCampaignSelector)
  }

  fetchCampaigns(page, filterOptions) {
    this.store.dispatch(fetchCampaign({ page, filterOptions }));
  }

  fetchCampaign(id) {
    this.store.dispatch(getCampaign({ id }))
  }

  fetchCampaignsByAffiliate(page: number, userID: string) {
    this.store.dispatch(fetchCampaignsByAffiliate({ page, userID }));
  }

  deleteCampaign(id) {
    this.store.dispatch(deleteCampaign({ id }));
  }

  addCampaign(campaign) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(addCampaign({ campaign }));
  }

  updateCampaign(campaign) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(updateCampaign({ campaign }));
  }

  unarchiveCampaign(id, archivedCampaign) {
    this.store.dispatch(unarchiveCampaign({ id, archivedCampaign }))
  }

  getCampaigns(page: number, filterOptions: any = { }) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);

    return this.http.get(`${this.backendUrl}/campaigns?${queryString}`);
  }

  getCampaign(id: string) {
    return this.http.get(`${this.backendUrl}/campaigns/campaign/${id}`);
  }

  getCampaignsByAffiliate(page: number, userID: string) {
    return this.http.get(`${this.backendUrl}/campaigns/user/${userID}/campaigns?page=${page}`);
  }

  removeCampaign(id) {
    return this.http.delete(`${this.backendUrl}/campaigns/campaign/${id}`);
  }

  insertCampaign(campaign) {
    return this.http.post(`${this.backendUrl}/campaigns/campaign`, campaign);
  }

  renewCampaign(campaign) {
    return this.http.patch(`${this.backendUrl}/campaigns/campaign/${campaign.id}`, campaign)
  }

  getCampaignUrls(page, campaignId: any) {
    const params: any = {};
    if (!!page && page > 1) params.page = page;
    return this.http.get(`${this.backendUrl}/campaigns/campaign/${campaignId}/urls`, {
      params
    })
  }

  addCampaignUrls(params?) {
    return this.http.post(`${this.backendUrl}/campaigns/campaign/url`, params);
  }

  deleteCampaignUrls(campaignId: any, params = {}) {
    return this.http.delete(`${this.backendUrl}/campaigns/campaign/url/${campaignId}`, params);
  }

  editCampaignUrls(campaignId: any, params?) {
    return this.http.patch(`${this.backendUrl}/campaigns/campaign/url/${campaignId}`, params);
  }

  unarchivedCampaign(id, params?) {
    return this.http.patch(`${this.backendUrl}/campaigns/campaign/${id}/unarchive`, params);
  }

}
