import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable, of, Subject, timer} from 'rxjs';
import {AffiliateManagersService, CampaignService, PageService, UserService} from '@services/index';
import {IState} from "@models/iState";
import {
  debounce,
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
  tap
} from "rxjs/operators";
import {DashboardService} from "@services/dashboard/dashboard.service";
import {UserRole} from "@enums/EUserRole";
import {FormControl} from "@angular/forms";
import * as ENUMS from "@enums/index";

@Component({
  selector: 'aff-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit {

  userRoles = UserRole;
  public userRoleId: number = null;

  datePickerOptions$: Observable<any>;

  dateOption: any = {
    created_from: '',
    created_to: ''
  };
  private campaigns$: Observable<IState>;
  public campaigns: any = [];
  selectedCampaign: any;
  selectedAffManagers: any;
  affManagers: any;

  filterOptions: any = {
    keyword: '',
    campaigns: '',
    created_from: '',
    created_to: '',
  };

  private subs$: Subject<any> = new Subject<any>();

  private filterOptions$ = new BehaviorSubject<any>({
    isFiltering: false,
    filters: {}
  });
  private managers$: Observable<any>;
  public managersList: any = [];
  private user$: Observable<any>;
  private affManagerSearch: FormControl = new FormControl();
  private campaignsSearch: FormControl = new FormControl();
  private initManagersList: any[] = [];
  private initCampaigns: any[] = [];

  constructor(
    private pageService: PageService,
    private campaignService: CampaignService,
    private userService: UserService,
    private dashboardService: DashboardService,
    private affiliateManagersService: AffiliateManagersService,
  ) {
    // get datepicker options
    this.datePickerOptions$ = this.pageService.getDatePickerOptionsSelector();
    this.campaigns$ = this.campaignService.getCampaignsSelector();
    this.user$ = this.userService.getProfileSelector();
  }

  ngOnInit() {
    // this.datePickerOptions$.subscribe(resp => {
    //   console.log('res', resp);
    // })

    this.getCampaigns()
    this.listenFilter()
    this.getDetails()
    this.listenSearch()
    this.listenAffManagers()
  }

  listenFilter() {
    this.filterOptions$
      .pipe(
        takeUntil(this.subs$),
        map(e => ({filters: e.filters, isFiltering: !!Object.keys(e.filters).length}))
      )
      .subscribe(params => {
        this.dashboardService.fetchCommissions(params.filters);

        if (params.isFiltering) { //
        }
      });
  }

  addFilterOption(obj) {
    const currOptions = this.filterOptions$.getValue();

    for (const field in obj) {
      if (obj.hasOwnProperty(field)) {
        if (obj[field] && obj[field].length) {
          currOptions.filters[field] = obj[field];
        } else {
          delete currOptions.filters[field];
        }
      }
    }

    this.filterOptions$.next(currOptions);
  }

  getCampaigns() {
    this.campaignService.fetchCampaigns(1, {});
    this.campaigns$
      .pipe(takeUntil(this.subs$),)
      .subscribe(state => {
        if (state.isLoaded) {
          this.campaigns = state.data;
        }
      });
  }

  getDetails() {
    this.dashboardService.fetchGroupDetails();
  }

  onChangeCampaignSelection(e: any) {
    this.addFilterOption({campaigns: e.map(i => i.id).toString()});
  }

  getSearchValue(e: { term: string; items?: any[] }) {
    // console.log('getSearchValue', e);
    this.campaignsSearch.patchValue(e.term);
  }

  getSearchAffManagersValue(e: { term: string; items: any[] }) {
    this.affManagerSearch.patchValue(e.term);
  }

  onChangeAffManagersSelection(e: any) {
    this.addFilterOption({managers: e.map(i => i.id).toString()});
  }

  onDateChanged(event, name: string) {
    this.addFilterOption({[name]: event.formatted});
  }

  onCalendarToggle(e: Event, name: string): void {
    this.pageService.disableDate({
      name,
      date: name === 'created_from' ? this.filterOptions$.getValue().filters.created_to : this.filterOptions$.getValue().filters.created_from
    });
  }

  private listenAffManagers() {
    this.user$
      .pipe(
        takeUntil(this.subs$),
        tap((e) => {
          if (e.roles && e.roles[0].role_id === ENUMS.UserRole.SUPERADMIN) {
            this.affiliateManagersService.fetchAffiliateManagers(1, {});
          }
        }),
        switchMap((user: any) => {
          if (user.roles && user.roles.length) {
            const roleId = user.roles[0].role_id;

            if (roleId === ENUMS.UserRole.MANAGER) {
              return of({
                role: roleId,
                data: user
              })
            } else if (roleId === ENUMS.UserRole.SUPERADMIN) {
              return this.affiliateManagersService.getAffiliateManagersSelector()
                .pipe(map(data => {
                  return {
                    role: roleId,
                    data
                  }
                }))
            }
          }

          return of(null);
        })
      )
      .subscribe(resp => {
        if (resp) {
          this.userRoleId = resp.role;

          if (resp.role === ENUMS.UserRole.MANAGER) {
            this.managersList = [{
              email: resp.data.email,
              id: resp.data.id
            }];

            this.selectedAffManagers = this.managersList;

          } else if (resp.role === ENUMS.UserRole.SUPERADMIN) {
            if (resp.data.isLoaded) {
              this.managersList = resp.data.data.map(i => {
                const {email, profile: {id, user_id}} = i;
                return {
                  id, user_id, email
                }
              });
            }
          }
        }
      })
  }

  private listenSearch() {
    this.affManagerSearch.valueChanges
      .pipe(
        takeUntil(this.subs$),
        debounce(ev => !!ev ? timer(600) : EMPTY),
        distinctUntilChanged()
      )
      .subscribe(keyword => {
        if (keyword.length > 2) {
          this.affiliateManagersService.fetchAffiliateManagers(1, {keyword});
        } else {
          this.resetAffManagers();
        }
      })

    this.campaignsSearch.valueChanges
      .pipe(
        takeUntil(this.subs$),
        debounce(ev => !!ev ? timer(600) : EMPTY),
        distinctUntilChanged()
      )
      .subscribe(keyword => {
        if (keyword.length > 2) {
          this.campaignService.fetchCampaigns(1, {keyword});
        } else {
          this.resetCampaignsSearch();
        }
      })
  }

  resetAffManagers() {
    this.affiliateManagersService.fetchAffiliateManagers(1, {});
  }

  resetCampaignsSearch() {
    this.campaignService.fetchCampaigns(1, {});
  }
}
