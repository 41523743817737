import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { HelperService } from '@services/index';
import { CommissionModel, CommissionGroupClickApproval } from "@enums/index";
import { IFormOptions } from '@interfaces/index';

@Component({
  selector: 'aff-group-setting-click',
  templateUrl: './group-setting-click.component.html',
  styleUrls: ['./group-setting-click.component.scss']
})
export class GroupSettingClickComponent implements OnInit {
  @Output() getObjValues = new EventEmitter<any>();
  @Input() set commissionSettingsForm(options) {
    if (options.click_commission_active) {
      HelperService.fillFormValues(options, this.clickCommissionForm)
    } else {
      this.clickCommissionForm.reset();
    }
  }
  @Input() campaignEditMode: boolean = false;
  @Input() clickSaved$: Observable<boolean>;
  
  public clickCommissionForm: FormGroup;

  // enums
  commissionModel = CommissionModel;
  commissionGroupClickApproval = CommissionGroupClickApproval;

  errorObj: any = {};

  private unsubscribe$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder) { 
    this.clickCommissionForm = this.formBuilder.group({
      click_commission_active: new FormControl(false, [Validators.required]),
      click_commission_model: new FormControl(this.commissionModel.CPA, [Validators.required]),
      click_extra_bonus: new FormControl(false, [Validators.required]),
      click_approval: new FormControl(this.commissionGroupClickApproval.AUTO, [Validators.required]),
      click_commission: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      click_second_tier: new FormControl(false, [Validators.required]),
      click_tier_commission: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      click_unique_clicks: new FormControl(false, [Validators.required])
    });
  }

  ngOnInit() {
    this.clickSaved$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(() => {
      if (this.clickCommissionForm.value.click_commission_active) {
        // check validations
        this.errorObj = HelperService.checkValidation(this.clickCommissionForm);
        this.checkDisableValueValidation();
      }
    });
  }

  checkDisableValueValidation(): void {
    const formValues = this.clickCommissionForm.value;
    if (!formValues.click_second_tier) {
      delete this.errorObj['click_tier_commission'];
    }
  }

  onChangeFormValue(options: IFormOptions): void {
    switch(options.name) {
      case 'click_commission_active' :
      case 'click_extra_bonus' :
      case 'click_second_tier' :
      case 'click_unique_clicks' :
        this.clickCommissionForm.patchValue({ [options.name]: options.checked });
        this.getObjValues.emit({ [options.name]: options.checked });
        break;

      case 'click_commission' :
      case 'click_tier_commission' :
        this.clickCommissionForm.patchValue({ [options.name]: parseInt(options.value)});
        this.getObjValues.emit({ [options.name]: parseInt(options.value) });
        break;
      
      default :
      this.clickCommissionForm.patchValue({ [options.name]: options.value });
      this.getObjValues.emit({ [options.name]: options.value });
    }
  }

}
