import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import {
  fetchCampaignAffiliates,
  fetchCampaignAffiliatesSuccess,
  fetchCampaignAffiliatesFailure,
  getCampaignAffiliate,
  getCampaignAffiliateSuccess,
  getCampaignAffiliateFailure,
  deleteCampaignAffiliate,
  deleteCampaignAffiliateSuccess,
  deleteCampaignAffiliateFailure,
  addCampaignAffiliate,
  addCampaignAffiliateSuccess,
  addCampaignAffiliateFailure,
  updateCampaignAffiliate,
  updateCampaignAffiliateSuccess,
  updateCampaignAffiliateFailure,
  fetchCampaignAffiliatesByComGroup,
  fetchCampaignAffiliatesByComGroupSuccess,
  fetchCampaignAffiliatesByComGroupFailure,
  fetchAffiliatesInCampaign,
  fetchAffiliatesInCampaignSuccess,
  fetchAffiliatesInCampaignFailure,
  fetchAffiliateInCampaignById,
  fetchAffiliateInCampaignByIdSuccess,
  fetchAffiliateInCampaignByIdFailure,
  updateAffiliateInCampaign,
  updateAffiliateInCampaignSuccess,
  updateAffiliateInCampaignFailure,
  updateCamAffiliatesComGroup,
  updateCamAffiliatesComGroupSuccess,
  changeMassStatus,
  changeMassStatusSuccess,
  changeMassStatusFailure
} from "./campaign-affiliates.action";
import { EMPTY, of } from "rxjs";
import { map, mergeMap, catchError, tap } from "rxjs/operators";
import { CampaignAffiliatesService } from "@services/campaign-affiliates/campaign-affiliates.service";
import { SharedService } from '@services/shared/shared.service';

@Injectable()
export class CampaignAffiliatesEffects {
  loadCampaignAffiliates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCampaignAffiliates),
      mergeMap((action) =>
        this.campaignAffiliatesService.getCampaignAffiliates(action.page, action.campaignID, action.filterOptions).pipe(
          map(res => {
            return fetchCampaignAffiliatesSuccess({ campaignAffiliates: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchCampaignAffiliatesFailure({ error }));
          })
        )
      )
    )
  );

  loadCampaignAffiliatesByComGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCampaignAffiliatesByComGroup),
      mergeMap((action) =>
        this.campaignAffiliatesService.getCampaignAffiliatesByComGroup(action.page, action.campaignID, action.commissionGroupID).pipe(
          map(res => {
            return fetchCampaignAffiliatesByComGroupSuccess({ campaignAffiliates: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchCampaignAffiliatesByComGroupFailure({ error }));
          })
        )
      )
    )
  );

  getCampaignAffiliate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCampaignAffiliate),
      mergeMap(action => 
        this.campaignAffiliatesService.getCampaignAffiliate(action.id).pipe(
          map(res => {
            return getCampaignAffiliateSuccess({ campaignAffiliate: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(getCampaignAffiliateFailure({ error }));
          })
        )
      )
    )
  );

  deleteCampaignAffiliate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCampaignAffiliate),
      mergeMap(action =>
        this.campaignAffiliatesService.removeCampaignAffiliate(action.id).pipe(
          map(() => {
            return deleteCampaignAffiliateSuccess({ id: action.id });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(deleteCampaignAffiliateFailure({ error }));
          })
        )
      )
    )
  );

  addCampaignAffiliate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCampaignAffiliate),
      mergeMap(action =>
        this.campaignAffiliatesService.insertCampaignAffiliate(action.campaignAffiliate).pipe(
          map(campaignAffiliate => {
            return addCampaignAffiliateSuccess({ campaignAffiliate });
          }),
          tap(res => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(addCampaignAffiliateFailure({ error }));
          })
        )
      )
    )
  );

  updateCampaignAffiliate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCampaignAffiliate),
      mergeMap(action =>
        this.campaignAffiliatesService.renewCampaignAffiliate(action.campaignAffiliate).pipe(
          map(campaignAffiliate => {
            return updateCampaignAffiliateSuccess({ campaignAffiliate });
          }),
          tap(res => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(updateCampaignAffiliateFailure({ error }));
          })
        )
      )
    )
  );


  loadAffiliatesInCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAffiliatesInCampaign),
      mergeMap(action =>
        this.campaignAffiliatesService.getAffiliatesinCampaign(action.page, action.filterOptions).pipe(
          map(res => {
            return fetchAffiliatesInCampaignSuccess({ affiliatesInCampaign: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchAffiliatesInCampaignFailure({ error }));
          })
        )
      )
    )
  );

  getAffiliatesInCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAffiliateInCampaignById),
      mergeMap(action => 
        this.campaignAffiliatesService.getAffiliateInCampaign(action.id).pipe(
          map(res => {
            return fetchAffiliateInCampaignByIdSuccess({ affiliatesInCampaign: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchAffiliateInCampaignByIdFailure({ error }));
          })
        )
      )
    )
  );


  updateAffiliatesInCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAffiliateInCampaign),
      mergeMap(action =>
        this.campaignAffiliatesService.renewAffiliateInCampaign(action.affStatus).pipe(
          map(affStatus => {
            return updateAffiliateInCampaignSuccess({ affStatus });
          }),
          tap(res => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(updateAffiliateInCampaignFailure({ error }));
          })
        )
      )
    )
  );

  updateMassStatus$ = createEffect(() =>
  this.actions$.pipe(
    ofType(changeMassStatus),
    mergeMap(action =>
      this.campaignAffiliatesService.updateMassStatus(action.massStatus).pipe(
        map(massStatus => {
          return changeMassStatusSuccess({ massStatus: action.massStatus });
        }),
        tap(res => { 
          this.campaignAffiliatesService.fetchAffiliatesInCampaign(1, {});
          this.sharedService.onLoaderToggle(false);
          this.sharedService.onSaveToggle(true);
        }),
        catchError(error => {
          this.sharedService.onLoaderToggle(false);
          return of(changeMassStatusFailure({ error }));
        })
      )
    )
  )
);

  updateCamAffiliatesComGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCamAffiliatesComGroup),
      mergeMap(action =>
        this.campaignAffiliatesService.updateCamAffiliatesComGroup(action.comGroup, action.camAffiliatesIds).pipe(
          map(res => {
            return updateCamAffiliatesComGroupSuccess({ comGroup: action.comGroup, camAffiliatesIds: action.camAffiliatesIds });
          }),
          tap(res => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true, true, 'Something Went Wrong!');
            return of(updateAffiliateInCampaignFailure({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private campaignAffiliatesService: CampaignAffiliatesService,
    private sharedService: SharedService
  ) {}
}
