import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '@shared/shared.module';
import { SharedPipesModule } from '@pipes/index';
import { SharedDirectivesModule } from '@directives/index';
import { TrackingRoutingModule } from './tracking-routing.module';

import { 
  EventTypesPage,
  EventCategoriesPage
} from './index';



@NgModule({
  declarations: [EventTypesPage, EventCategoriesPage],
  imports: [
    CommonModule,
    TrackingRoutingModule,
    NgxDatatableModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgSelectModule,
    SharedPipesModule,
    SharedDirectivesModule
  ]
})
export class TrackingPageModule { }
