import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { IPage } from '@interfaces/index';

@Component({
  selector: 'aff-affiliates-tracking-page',
  templateUrl: './affiliates-tracking-page.component.html',
  styleUrls: ['./affiliates-tracking-page.component.scss']
})
export class AffiliatesTrackingPage implements OnInit {

  affiliateTracking: FormGroup;

  rows = [];
  loadingIndicator: boolean = true;
  selectedRows = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  constructor(private formBuilder: FormBuilder ) { 
    this.affiliateTracking = this.formBuilder.group({
      overwrite_cookies: new FormControl(false, [Validators.required])
    });
  }

  ngOnInit() {
  }

  onChangeFormValue($event) {
    //values
  }

}
