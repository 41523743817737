import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { CommissionGroupClickApproval, CommissionModel } from "@enums/index";
import { HelperService } from '@services/index';
import { IFormOptions } from '@interfaces/index';

@Component({
  selector: 'aff-group-setting-lead',
  templateUrl: './group-setting-lead.component.html',
  styleUrls: ['./group-setting-lead.component.scss']
})
export class GroupSettingLeadComponent implements OnInit {
  @Output() getObjValues = new EventEmitter<any>();
  @Input() set commissionSettingsForm(options) {
    if (options.lead_commission_active) {
      HelperService.fillFormValues(options, this.leadCommissionForm)
    } else {
      this.leadCommissionForm.reset();
    }
  }
  @Input() campaignEditMode: boolean = false;
  @Input() clickSaved$: Observable<boolean>;

  leadCommissionForm: FormGroup;

  // enums
  commissionGroupClickApproval = CommissionGroupClickApproval;
  commissionModel = CommissionModel;

  errorObj: any = {};

  private unsubscribe$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder) { 
    this.leadCommissionForm = this.formBuilder.group({
      lead_commission_active: new FormControl(false, [Validators.required]),
      lead_commission_model: new FormControl(this.commissionModel.CPA, [Validators.required]),
      lead_extra_bonus: new FormControl(false, [Validators.required]),
      lead_extra_bonus_value: new FormControl(null),
      lead_approval: new FormControl(this.commissionGroupClickApproval.MANUAL, [Validators.required]),
      lead_commission: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      lead_second_tier: new FormControl(false, [Validators.required]),
      lead_tier_commission: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")])
    });
  }

  ngOnInit() {
    this.clickSaved$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(() => {
      if (this.leadCommissionForm.value.lead_commission_active) {
        // check validations
        this.errorObj = HelperService.checkValidation(this.leadCommissionForm);
        this.checkDisableValueValidation();
      }
    });
  }

  checkDisableValueValidation(): void {
    const formValues = this.leadCommissionForm.value;
    if (!formValues.lead_second_tier) {
      delete this.errorObj['lead_tier_commission'];
    }
  }

  onChangeFormValue(options: IFormOptions): void {
    switch(options.name) {
      case 'lead_commission_active':
      case 'lead_extra_bonus':
      case 'lead_second_tier':
        this.leadCommissionForm.patchValue({ [options.name]: options.checked });
        this.getObjValues.emit({ [options.name]: options.checked });
        break;

      case 'lead_commission' : 
      case 'lead_tier_commission' : 
      case 'lead_commission_model':
        this.leadCommissionForm.patchValue({ [options.name]: parseInt(options.value)});
        this.getObjValues.emit({ [options.name]: parseInt(options.value) });
        break;
      
      default :
      this.leadCommissionForm.patchValue({ [options.name]: options.value });
      this.getObjValues.emit({ [options.name]: options.value });
    }

  }

}
