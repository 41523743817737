import { Component, OnInit, OnDestroy } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { PageService } from '@services/index';
import { IPage } from '@interfaces/index';
import { IState } from "@models/index";
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IModalvalue } from '@shared-models/index';
import { MessageService, UserService, HelperService } from '@services/index';

@Component({
  selector: 'aff-messages-list-page',
  templateUrl: './messages-list-page.component.html',
  styleUrls: ['./messages-list-page.component.scss']
})
export class MessagesListPage implements OnInit, OnDestroy {

  addPopup: boolean = false;
  viewPopup: boolean = false;
  messageID: string = '';

  confirmationModal: boolean = false;
  clicked;
  userId;

  rows = [];
  loadingIndicator: boolean = true;
  selectedRows = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  profile$: Observable<any>;
  message$: Observable<IState>;
  private unsubscribe$ = new Subject<void>();


  constructor(
    private router: Router, private pageService: PageService,
    private messageService: MessageService,
    private userService: UserService,
    private activeRoute: ActivatedRoute,
    private helperService: HelperService
  ) {
    pageService.changePageInfo({ breadcrum: ['Messages'] });
    this.profile$ = this.userService.getProfileSelector();
    this.message$ = this.messageService.getMessagesSelector();
  }


  ngOnInit() {

    this.activeRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        if (this.viewPopup && !Object.keys(resp)) {
          this.viewPopup = false;
        }

        if (!!HelperService.strParse(resp.popup) && resp.id) {
          this.onAction('view', resp);
        }
      })

    this.profile$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(user => {
      if (HelperService.isObjectEmpty(user)) return;

      this.userId = user.userId;
        if(this.userId) {
          this.messageService.fetchMessages(1, {}, {} );
        }
    })

    this.message$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.rows = [];
          for (const message of state.data) {
            const row = {
              id: message["id"],
              subject: message["subject"],
              from: message.sent.email,
              to: message.received.email,
              message: message["text"],
              sentDate: message["created_at"]
            };
            this.rows.push(row);
          }
        }
        this.rows = [...this.rows];
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
      })

  }

  onCompose() {
    this.addPopup = true;
  }

  closePopup(value: any):void {
    this.addPopup = value;
  }

  closeViewPopup(value: any):void {
    if (!value) {
      this.router.navigate([], {
        relativeTo: this.activeRoute,
        queryParams: {},
      });
    }

    this.viewPopup = value;
  }

  onAction(action: string, row: any) {
    switch (action) {
      case "delete":
        this.selectedRows= [{ id: row.id }];
        this.confirmationModal = true;
        break;

      case "view":
        this.messageID = row.id;
        this.viewPopup = true;
        this.router.navigate([], {
          relativeTo: this.activeRoute,
          queryParams: {
            popup: 1,
            id: row.id
          },
        });
        break;

      default:
        break;
    }
  }

  setPage(e): void {
    this.messageService.fetchMessages(e.offset + 1, {}, {} );
  }

  onDeleteConfirm(options: IModalvalue): void {
    if (options.confirm) {
      for (const selectedRow of this.selectedRows) {
        this.messageService.deleteMessage(selectedRow.id);
      }
      this.selectedRows = [];
    }
    this.confirmationModal = false;
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
