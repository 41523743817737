import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { MyDatePickerModule } from 'mydatepicker';

import { AffiliateManagersPageRoutingModule } from './affiliate-managers-page-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '@shared/shared.module';
import { SharedPipesModule } from '@pipes/index';

import { 
  AffiliateManagersAddPage,
  AffiliateManagersListPage,
  AffiliatesManagerListFilterComponent,
  AffiliateManagersAddForm,
  AssignedAffiliatesComponent } from './index';
import { SharedDirectivesModule } from '@directives/index';

@NgModule({
  declarations: [
    AffiliateManagersAddPage,
    AffiliateManagersListPage,
    AffiliatesManagerListFilterComponent,
    AffiliateManagersAddForm,
    AssignedAffiliatesComponent
  ],
  imports: [
    CommonModule,
    AffiliateManagersPageRoutingModule,
    NgxDatatableModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgSelectModule,
    SharedPipesModule,
    MyDatePickerModule,
    SharedDirectivesModule,
  ]
})
export class AffiliateManagersPageModule { }
