import { Injectable } from '@angular/core';
import { 
  ActivatedRouteSnapshot, 
  CanActivate, 
  Router, 
  RouterStateSnapshot, 
  UrlTree 
} from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

import { UserService } from '@services/index';

@Injectable({
  providedIn: 'root'
})
export class SecureInnerPagesGuard implements CanActivate {
  isUserAuthenticated$: Observable<boolean>;
  private unsubscribe$ = new Subject<void>();
  
  constructor(
    private router: Router,
    private userService: UserService
  ) {
    this.isUserAuthenticated$ = userService.isAuthorized();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.isUserAuthenticated$
        .pipe(
          takeUntil(this.unsubscribe$),
          map(isAuthorized => {
            if (isAuthorized) {
              this.router.navigateByUrl('/dashboard')
            }
            return true;
          })
        );
  }

}