import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil, map } from 'rxjs/operators';

import { IState } from "@models/index";
import { IPage, IFormOptions } from '@interfaces/index';

import { 
  CampaignAffiliatesService, 
  CommissionGroupService, 
  PageService, 
  HelperService, 
  CampaignService,
  UserService} from "@services/index";

import * as ENUMS from '@enums/index';
import { IModalvalue } from '@shared-models/index';
import { CampaignAffiliateStatus, CampaignType, UserRole } from '@enums/index';

@Component({
  selector: 'aff-campaign-affiliates-campaign-tab',
  templateUrl: './campaign-affiliates-campaign-tab.component.html',
  styleUrls: ['./campaign-affiliates-campaign-tab.component.scss']
})
export class CampaignAffiliatesCampaignTabComponent implements OnInit, OnDestroy {
  @Input() campaignID: string = '';
  
  // get enums
  status = ENUMS.CampaignAffiliateStatus;

  rows = [];
  loadingIndicator: boolean = false;
  selectedRows = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  confirmationModal: boolean = false;
  allowInvitation: boolean = false;
  deleteValues: string = '';

  affiliateID: string = '';

  addAffiliatePopup: boolean = false;
  changeComGroupPopup: boolean = false;
  showFiterComponent: boolean = false;

  keyword = new FormControl();
  filterOptions = {
    keyword: '',
    status: [],
    created_from: '',
    created_to: '',
    comGroups: []
  };

  datePickerOptions$: Observable<any>;
  dateOption: any = {
    created_from: '',
    created_to: ''
  }

  comGroups$: Observable<IState>;
  selectedComGroups: any = [];
  comGroupsList: any = [];
  comGroupsSearch = new FormControl();
  currentCampaign$: Observable<any>;
  currentCampaign: {};
  currentUser$: Observable<any>;
  currentUser: {};

  campaignAffiliates$: Observable<IState>;
  private unsubscribe$ = new Subject<void>();

  constructor(private campaignAffiliatesService: CampaignAffiliatesService,
              private pageService: PageService,
              private commissionGroupService: CommissionGroupService,
              private campaignService: CampaignService,
              private userService: UserService) {
                // get datepicker options
                this.datePickerOptions$ = pageService.getDatePickerOptionsSelector();

                this.campaignAffiliates$ = this.campaignAffiliatesService.getCampaignAffiliatesSelector();
                this.comGroups$ = this.commissionGroupService.getCommissionGroupsSelector();
                this.currentCampaign$ = this.campaignService.getCampaignSelector()
                this.currentUser$ = this.userService.getProfileSelector()
               }
   
  ngOnInit(): void {
    // listen value change of keyword
    this.keyword.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
          debounceTime(500),
          distinctUntilChanged()
      )
      .subscribe(newValue => {
        this.filterOptions['keyword'] = newValue;
        this.campaignAffiliatesService.fetchCampaignAffiliates(1, this.campaignID, this.filterOptions);
      });

    // check if edit mode
    if (!this.campaignID) return;
    // get campaign affiliates list
    this.campaignAffiliatesService.fetchCampaignAffiliates(1, this.campaignID, this.filterOptions);

    // listen campaign affiliates list
    this.campaignAffiliates$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {

        if (state.isSaved) {
          this.addAffiliatePopup = false;
          this.changeComGroupPopup = false;
          this.selectedRows = [];
        }
        if (state.isLoaded) {
          this.rows = [];
          for (const campaignAffiliate of state.data) {
            const row = {
              id: campaignAffiliate.id,
              userId: campaignAffiliate.user.id,
              affiliateName: campaignAffiliate.user.profile.first_name + ' ' 
                              + campaignAffiliate.user.profile.last_name,
              affiliateEmail: campaignAffiliate.user.email,
              involvementStatus: HelperService.getEnumValue(CampaignAffiliateStatus, campaignAffiliate.status),
              dateJoined: campaignAffiliate.created_at,
              affiliateNote: campaignAffiliate.affiliate_note,
              merchantNote: campaignAffiliate.manager_note,
              commissionGroup: campaignAffiliate.commission_group ? 
                                campaignAffiliate.commission_group.com_group_name : '',
              affiliateManagerNote: campaignAffiliate.manager_note
            };
            this.rows.push(row);
          }
        }
        this.rows = [...this.rows];
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
    });

    // dispatch commission gorups
    this.commissionGroupService.fetchFilteredCommissionGroups(1, this.campaignID, {});

    // affiliates search
    this.comGroupsSearch.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.commissionGroupService.fetchFilteredCommissionGroups(1, this.campaignID, { keyword }));

    // get affiliate managers
    this.getComGroups();

    // get current campaign
    this.getCurrentCampaign()    
  }

  getComGroups(): void {
    this.comGroups$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.comGroupsList = state.filteredDate;
        }
    });
  }

  getCurrentCampaign(): void {
    combineLatest(this.currentCampaign$, this.currentUser$)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      ([camp, user]) => {
        this.allowInvitation = camp.campaign_type === CampaignType.INVITED || user.roles[0].role_id === UserRole.SUPERADMIN;
      }
    )
  }
  getcomGroupsSearchValue(options): void {
    // search value in campaigns
    this.comGroupsSearch.patchValue(options.term);
  }

  onChangecomGroupsSelection(comGroups: any[]): void {
    let camGroupsIds: number[] = [];
    for (const comGroup of comGroups) {
      camGroupsIds.push(comGroup.id);
    }
    this.filterOptions['com_groups'] = camGroupsIds;

    this.commissionGroupService.fetchFilteredCommissionGroups(1, this.campaignID, this.filterOptions);
  }

  onCalendarToggle(e: Event, name: string) {
    this.pageService.disableDate({
      name, 
      date: this.filterOptions[name === 'created_from' ? 'created_to' : 'created_from']
    });
  }

  onDateChanged(event, name: string): void {
    this.filterOptions[name] = event.formatted;
    this.campaignAffiliatesService.fetchCampaignAffiliates(1, this.campaignID, this.filterOptions);
  }

  addAffiliatePopupToggle(value: boolean): void {
    this.addAffiliatePopup  = value;
  }

  onToggleFilter(): void {
    this.showFiterComponent = !this.showFiterComponent;
  }

  onDeleteConfirm(options: IModalvalue): void {
    if (options.confirm) {
      for (const selectedRow of this.selectedRows) {
        this.campaignAffiliatesService.deleteCampaignAffiliate(selectedRow.id);
      }
      this.selectedRows = [];
    }
    this.confirmationModal = false;
  }

  onAction(action: string, row: any): void {
    switch (action) {
      case "delete":
        this.deleteValues = `Affiliate: <br><br> ${row.affiliateEmail}`;
        this.selectedRows = [{ id: row.id }];
        this.confirmationModal = true;
        break;

      case "edit":
        this.affiliateID = row.id;
        this.addAffiliatePopupToggle(true);
        break;

      default:
        HelperService.goToLink(`/affiliates/affiliate/${row.userId}`);
        break
    }
  }

  setPage(e): void {
    this.selectedRows = [];
    this.campaignAffiliatesService.fetchCampaignAffiliates(e.offset + 1, this.campaignID, this.filterOptions);
  }

  onSelect(e): void {
    this.selectedRows = e.selected;
  }

  deleteAffiliate(): void {
    if (this.selectedRows.length) {
      this.deleteValues = '';
      this.confirmationModal = true;
    }
  }

  changeComGroup(): void {
    if (!this.selectedRows.length) return;
    this.changeComGroupPopup = true;
  }
   
  onChangeFormValue(options: IFormOptions): void {
    switch(options.name) {
      case 'inviteAffiliate' :
        this.affiliateID = '';
        this.addAffiliatePopupToggle(true);
        break;

      case 'deleteAffiliate' :
        this.deleteAffiliate();
        break;
      
        case 'changeComGroup' :
        this.changeComGroup();
        break;

      case 'clearFilter' :
        this.onClearFilter();

      case 'status' :
        if (options.checked) {
          this.filterOptions['status'].push(options.value);
        } else {
          // find unchecked value and remove from status array
          const findIndex = this.filterOptions['status'].findIndex(val => val === options.value);
          this.filterOptions['status'].splice(findIndex, 1);
        }
        this.campaignAffiliatesService.fetchCampaignAffiliates(1, this.campaignID, this.filterOptions);
        break;
      
      case 'keyword':
        this.keyword.patchValue(options.value);
        break;

      default:
        this.filterOptions[options.name] = options.value;
        this.campaignAffiliatesService.fetchCampaignAffiliates(1, this.campaignID, this.filterOptions);
    }
  }

  onClearFilter(): void {
    this.filterOptions = {
      keyword: '',
      status: [],
      created_from: '',
      created_to: '',
      comGroups: []
    };

    this.dateOption = {
      created_from: '',
      created_to: ''
    };

    this.selectedComGroups = [];
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
