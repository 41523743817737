import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ReportService, PageService } from '@services/index';
import { IPage } from '@interfaces/index';
import { takeUntil } from 'rxjs/operators';
import { IModalvalue } from '@shared-models/index';
import { IState } from "@models/index";

@Component({
  selector: 'aff-top-referring-urls',
  templateUrl: './top-referring-urls.component.html',
  styleUrls: ['./top-referring-urls.component.scss']
})
export class TopReferringUrlsPage implements OnInit, OnDestroy {

  loadingIndicator: boolean = true;
  selectedRows = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  rows: any[] = [];

  topReferringUrls$: Observable<IState>;
  unsubscribe$ = new Subject<void>();

  constructor(
    private reportService: ReportService,
    private pageService: PageService
  ) { 

    this.rows = [];
    this.topReferringUrls$ = this.reportService.getReportsSelector();

    this.pageService.changePageInfo({ breadcrum: ['Reports', 'Top Referring URLs']});
  }

  ngOnInit() {
    // get top urls list
    this.reportService.fetchTopreferringUrls(1, {});

    this.topReferringUrls$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {        
        if (state.isLoaded) {
          this.rows = [];
          for (const topRefUrls of state.data) {
            if(!topRefUrls.lsum) {
              topRefUrls.lsum = 0;
            }
            if(!topRefUrls.ssum) {
              topRefUrls.ssum = 0;
            }
            
            const row = {
              id: topRefUrls.banner_id, 
              referringUrl: topRefUrls.refferrer ? topRefUrls.refferrer : '',
              // affiliate: topRefUrls.affiliate_email,
              promotionToolType: topRefUrls.banner_type ? topRefUrls.banner_type.replace('_', ' ').toUpperCase() : '',
              // campaignName: topRefUrls.campaign_name,
              // affiliateManager: topRefUrls.manager_email,
              leadsRatio: '',
              leadsCountSum: topRefUrls.lcount + '/$' + topRefUrls.lsum/100 , 
              salesCountSum: topRefUrls.scount + '/$' + topRefUrls.ssum/100 , 
              returnsCountSum: '',
              total: '$' + topRefUrls.total/100
            };
            this.rows.push(row);
          }
        }
        this.rows = [...this.rows];
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
    });
}

  setPage(e): void {
    this.reportService.fetchTopreferringUrls(e.offset + 1, {});
  }

  onSelect(e): void {
    this.selectedRows = e.selected;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
