import { Component, OnInit, OnDestroy, Input, HostListener, ElementRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { PageService } from '@services/index';

@Component({
  selector: 'aff-help-question-mark',
  templateUrl: './help-question-mark.component.html',
  styleUrls: ['./help-question-mark.component.scss']
})
export class HelpQuestionMarkComponent implements OnInit, OnDestroy {
  @Input() key: string;
  @Input() leftDirection: false;
  show: boolean = false;
  help: any = {};

  help$: Observable<any>;
  private unsubscribe$ = new Subject<void>();

  constructor(private pageService: PageService,
              private eRef: ElementRef) {
    this.help$ = pageService.getHelpSelector();
   }

  ngOnInit(): void {
    this.help$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(help => {
        if (!this.show) return;
        this.help = help;
      });
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.show = false;
    }
  }

  onGetHelp(): void {
    if (!this.show && !this.help.hasOwnProperty(this.key)) {
      this.pageService.dispatchHelp(this.key);
    }
    this.show = !this.show;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
