import { createAction, props } from "@ngrx/store";

export const fetchMessages = createAction(
  "[Message] Fetch Messages",
  props<{ page, filterOptions, user_id }>()
);

export const fetchMessagesSuccess = createAction(
  "[Message] Fetch Messages Success",
  props<{ message }>()
);

export const fetchMessagesFailure = createAction(
  "[Message] Fetch Messages Faild",
  props<{ error }>()
);

export const getMessage = createAction(
  "[Message] Get Message",
  props<{ id }>()
);

export const getMessageSuccess = createAction(
  "[Message] Get Message Success",
  props<{ message }>()
);

export const getMessageFailure = createAction(
  "[Message] Get Message Faild",
  props<{ error }>()
);

export const addMessage = createAction(
  "[Message] Add Message",
  props<{ message }>()
);

export const addMessageSuccess = createAction(
  "[Message] Add Message Success",
  props<{ message }>()
);

export const addMessageFailure = createAction(
  "[Message] Add Message Failure",
  props<{ error }>()
);

export const deleteMessage = createAction(
  "[Message] Delete Message",
  props<{ id }>()
);

export const deleteMessageSuccess = createAction(
  "[Message] Delete Message Success",
  props<{ id }>()
);

export const deleteMessageFailure = createAction(
  "[Message] Delete Message Failure",
  props<{ error }>()
);
