import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import * as ENUMS from '@enums/index';
import { UserService, HelperService, CampaignService, AffiliateManagersService, PageService, AffiliateService, CampaignAffiliatesService } from "@services/index";
import { IModalvalue } from '@shared-models/index';
import { Subject, Observable } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { IState } from "@models/index";
import { IMyDateModel } from 'mydatepicker';
import {SuspendedManager} from '@enums/index';
import { ActivatedRoute, Router } from "@angular/router";


@Component({
  selector: 'aff-affiliates-in-campaign-filter',
  templateUrl: './affiliates-in-campaign-filter.component.html',
  styleUrls: ['./affiliates-in-campaign-filter.component.scss']
})
export class AffiliatesInCampaignFilterComponent implements OnInit, OnDestroy {

  @Output() selectionOption = new EventEmitter<boolean>();

  //enums
  camp_status = ENUMS.Status
  camType = ENUMS.CampaignType;
  status = ENUMS.StatusConditions;

  filters$: Observable<any>;
  selectedFilter$: Observable<any>;

  savedFilterList: any = {};
  filterSelectedValue: string | number = '';
  filterSelectedId: number;
  isFilterEmpty: boolean = true;

  suspended = SuspendedManager;

  datePickerOptions$: Observable<any>;
  dateOption: any = {
    created_from: '',
    created_to: '',
    valid_from: '',
    valid_to: '',
    joined_from: '',
    joined_to: '',
  };

  keyword = new FormControl();

  campaing$: Observable<IState>;
  affTypes$: Observable<any[]>;
  affiliateManagers$: Observable<any>;
  affiliates$: Observable<IState>;



  selectedValues: any = {
    campaigns: [],
    affTypes: [],
    managers: [],
    affiliates: []
  };

  filterOptions = {
    keyword: '',
    camp_status: [],
    camp_type: [],
    created_from: '',
    created_to: '',
    valid_from: '',
    valid_to: '',
    campaigns: [],
    hide_campaign: false,

    aff_status: [],
    aff_inv_status: [],
    affTypes: [],
    joined_from: '',
    joined_to: '',
    suspended: [],
  };

  filterNamePopup: boolean = false;
  showFiterComponent: boolean = false;

  campaigns: any = [];
  campaignsSearch = new FormControl();
  selectedCampaign: any;


  managersList: any = [];
  managersSearch = new FormControl();
  selectedManagers: any = [];


  affiliatesList: any = [];
  affiliatesSearch = new FormControl();

  private unsubscribe$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private campaignService: CampaignService,
    private pageService: PageService,
    private affiliateManagersService: AffiliateManagersService,
    private affiliateService: AffiliateService,
    private campaignAffiliatesService: CampaignAffiliatesService,
    private activeRoute: ActivatedRoute,
    private route: Router,
    private helpService: HelperService
    ) {
    this.filters$ = this.userService.filtersSelector();
    this.selectedFilter$ = this.userService.filterSelector();

    this.campaing$ = this.campaignService.getCampaignsSelector();
    this.affTypes$ = this.pageService.getUserTypeListSelector();
    this.affiliateManagers$ = this.affiliateManagersService.getAffiliateManagersSelector();
    this.affiliates$ = this.affiliateService.getAffiliatesSelector();


    this.affiliateManagersService.fetchAffiliateManagers(1, {});
    this.pageService.fetchUserTypes();
    this.affiliateService.fetchAffiliates(1, {});

    // get datepicker options
    this.datePickerOptions$ = pageService.getDatePickerOptionsSelector();
  }

  ngOnInit() {
    // listen value change of keyword
    this.keyword.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(newValue => {
        this.filterOptions['keyword'] = newValue;
        this.campaignAffiliatesService.fetchAffiliatesInCampaign(1, this.filterOptions);
      });

    // Get filters
    this.userService.fetchFilters('affiliates_in_campaign');
    this.filters$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded && state.data && state.data.length) {
          this.savedFilterList = {};
          state.data.forEach(filter => {
            this.savedFilterList = {
              ...this.savedFilterList,
              [filter.id]: filter.name
            }
          });
        }
      });

    // Get selected filter
    this.selectedFilter$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(filter => {
        if (filter.type !== 'affiliates_in_campaign') return;
        const filterOpt = filter.filter_fields;
        if (filterOpt) {
          this.onClearFilter();
          HelperService.fillFilterOption(filterOpt, this.filterOptions);
          this.filterSelectedId = filter.id;

          Object.keys(this.dateOption).forEach(key => {
            this.dateOption[key] = filterOpt.find(opt => opt.key === key)
              ? { formatted: filterOpt.find(opt => opt.key === key)['value'] } : ''
          });

          this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
          this.campaignAffiliatesService.fetchAffiliatesInCampaign(1, this.filterOptions);
          this.showFiterComponent = true;
        }
      });

    this.campaignsSearch.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.campaignService.fetchCampaigns(1, { keyword }));

    this.managersSearch.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.affiliateManagersService.fetchAffiliateManagers(1, { keyword }));


    // affiliates search
    this.affiliatesSearch.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.affiliateService.fetchAffiliates(1, { keyword }));

    this.getAffiliateManagers();
    this.getCampaigns();
    this.getAffiliates();
  }

  getCampaigns() {
    this.campaignService.fetchCampaigns(1, {});
    // listen campaign
    this.campaing$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.campaigns = state.data
      })
  }

  getSearchValue(options): void {
    // search value in managers
    this.campaignsSearch.patchValue(options.term);
  }

  onSelectedValue(values: any[], name: string): void {
    let selectedValues: number[] = [];
    for (const value of values) {
      selectedValues.push(value.id);
    }
    this.filterOptions[name] = selectedValues;
    this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
    this.checkSelection();
    this.campaignAffiliatesService.fetchAffiliatesInCampaign(1, this.filterOptions);
  }

  getAffiliatesSearchValue(options): void {
    // search value in affiliates
    this.affiliatesSearch.patchValue(options.term);
  }

  getAffiliateManagers(): void {
    this.affiliateManagers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.managersList = state.data;
      });
  }

  getManagerSearchValue(options: any): void {
    this.managersSearch.patchValue(options.term);
  }

  getAffiliates() {
    this.activeRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
      if (Object.keys(resp).length) {
        const queryFilter = HelperService.queryParamsToObj(resp, this.filterOptions);
        // if (!this.activeRoute.snapshot.queryParams['page'] || !this.activeRoute.snapshot.queryParams['page'].length) {
        //   queryFilter['page'] = 1;
        // } else {
        //   queryFilter['page'] = this.activeRoute.snapshot.queryParams['page'];
        // }

        this.filterOptions = { ...queryFilter};
        this.campaignAffiliatesService.fetchAffiliatesInCampaign(1, this.filterOptions);
        this.isFilterEmpty = this.checkEmptyFilter(queryFilter);

        if (!this.showFiterComponent && !this.checkEmptyFilter(this.filterOptions)) {
          this.showFiterComponent = true;
        }
      }
    });


    this.affiliateService.fetchAffiliates(1, {})
    // listen affiliates
    this.affiliates$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.affiliatesList = state.data
      })
  }

  checkEmptyFilter(filters) {
    // _todo: fix check
    const filterObj = JSON.parse(JSON.stringify(filters));

    if (!filters.hide_campaign) {
      filterObj.hide_campaign = '';
    }

    if (filters.page) {
      filterObj.page = '';
    }

    return HelperService.checkEmptyFilter(filterObj);
  }

  setFilterNamePopup(option: IModalvalue) {
    if (option.confirm) {
      if (!this.userService.checkFilterName(option.inputValue, this.savedFilterList, this.filterSelectedId)) return;
      delete this.filterOptions.keyword;

      this.filterSelectedId
        ? this.userService.updateFilter({
          id: this.filterSelectedId,
          name: option.inputValue,
          type: 'affiliates_in_campaign',
          additional_fields: this.filterOptions
        })
        : this.userService.addFilter({
          name: option.inputValue,
          type: 'affiliates_in_campaign',
          additional_fields: this.filterOptions
        });
    }

    this.filterNamePopup = false;
  }

  onToggleFilter(): void {
    this.showFiterComponent = !this.showFiterComponent;
  }

  getSelectedFilter(id): void {
    this.filterSelectedValue = this.savedFilterList[id];
    this.userService.fetchFilter(id);
  }

  onDeleteFilter(id): void {
    this.userService.deleteFilter(id);
  }

  checkSelection(): void {
    this.selectionOption.emit(this.selectedValues.campaigns.length === 1);
  }

  onClearFilter() {

    this.dateOption = {
      created_from: '',
      created_to: '',
      valid_from: '',
      valid_to: '',
      joined_from: '',
      joined_to: ''
    };

    this.filterOptions = {
      keyword: '',
      camp_status: [],
      camp_type: [],
      created_from: '',
      created_to: '',
      valid_from: '',
      valid_to: '',
      campaigns: [],
      hide_campaign: false,

      aff_status: [],
      aff_inv_status: [],
      affTypes: [],
      joined_from: '',
      joined_to: '',
      suspended: [],
    };
  }

  onCalendarToggle(e: Event, name: string) {
    switch (name) {
      case 'created_from':
        this.pageService.disableDate({
          name,
          date: this.filterOptions.created_to
        });
        break;

      case 'created_to':
        this.pageService.disableDate({
          name,
          date: this.filterOptions.created_from
        });
        break;

      case 'valid_from':
        this.pageService.disableDate({
          name,
          date: this.filterOptions.valid_to
        });
        break;

      case 'valid_to':
        this.pageService.disableDate({
          name,
          date: this.filterOptions.valid_from
        });
        break;

      case 'joined_from':
        this.pageService.disableDate({
          name,
          date: this.filterOptions.joined_to
        });
        break;

      case 'joined_to':
        this.pageService.disableDate({
          name,
          date: this.filterOptions.joined_from
        });
        break;

      default:
        // this.pageService.disableDate({
        //   name,
        //   date: this.filterOptions.valid_from
        // });
        break;
    }
  }


  onDateChanged(event: IMyDateModel, name: string): void {
    this.filterOptions[name] = event.formatted;

    this.campaignAffiliatesService.fetchAffiliatesInCampaign(1, this.filterOptions);
    this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);

    this.updateQueryParams();
  }

  onChangeFormValue(options, simpleE = false) {
    switch (options.name) {
      case 'clearFilter':
        this.onClearFilter();
        this.filterSelectedValue = '';
        this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
        break;

      case 'saveFilter':
        if (this.isFilterEmpty) return;
        this.filterNamePopup = true;
        break;

      case 'camp_status':
      case 'camp_type':
      case 'aff_status':
      case 'aff_inv_status':
      case 'suspended':
        if (options.checked) {
          this.filterOptions[options.name].push(options.value);
        } else {
          // find unchecked value and remove from status array
          const findIndex = this.filterOptions[options.name].findIndex(val => val === options.value);
          this.filterOptions[options.name].splice(findIndex, 1);
        }
        this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
        break;

      case 'hide_campaign':
        this.filterOptions[options.name] = options.checked;
        break;

      case 'keyword':
        this.keyword.patchValue(options.value)
        break;

      default:
        this.filterOptions[options.name] = options.value;
        this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
    }

    this.updateQueryParams();
  }

  ngOnDestroy(): void {
    this.userService.clearSelectedFilter();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private updateQueryParams() {
    const mergedOptions = {...this.filterOptions, ...{page: '1'}};
    const optionsPass = HelperService.generateQueryParamsObj(mergedOptions);
    this.route.navigate([], {
      relativeTo: this.activeRoute,
      queryParams: optionsPass,
    });
  }
}
