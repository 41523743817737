import { createReducer, on } from "@ngrx/store";
import {
  fetchLanguage,
  fetchLanguageSuccess,
  fetchLanguageFailure
} from "./language.actions";

import { LanguageState } from "@models/index";

const initialState: LanguageState = {
  isLoaded: false,
  data: [],
  selectedItem: {},
  error: null
};

const _languageReducer = createReducer(

  initialState,

  on(fetchLanguage, (state) => {
    return state;
  }),
  on(fetchLanguageSuccess, (state, { language }) => {
    const payload = {
      isLoaded: true,
      data: language,
      selectedItem: {}
    };

    return { ...state, ...payload };
  }),
  on(fetchLanguageFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  
);

export function languageReducer(state, action) {
  return _languageReducer(state, action);
}
