import { Component, OnInit, Input, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { IModalvalue } from '@shared-models/index';

@Component({
  selector: 'aff-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() question: string = '';
  @Input() details: string = '';
  @Input() placeholder: string = 'Value';
  @Input() confirmLabel: string = 'OK';
  @Input() cancelLabel: string = 'Cancel';
  @Input() set inputValue(value: any) {
    this.value.inputValue = value;
  };
  @Input() type: string = 'Confirmation' || 'InputConfirmation';

  @Output() outPutValue = new EventEmitter<IModalvalue>();
  value: IModalvalue = {
    cancel: false,
    confirm: false,
    inputValue: ''
  }

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.document.body.classList.add('modal-open');
  }

  ngOnInit() {
  }

  onChangeFormValue(options: any): void {
    this.value.inputValue = options.value;
  }

  onClick(btn: string): void {
    this.value.confirm = false;
    this.value.cancel = false;
    this.value[btn] = true;
    
    this.outPutValue.emit(this.value);
  }

  onClose(e: Event) {
    e.stopPropagation();
    if (e.target['className'] === 'modal') {
      this.value['cancel'] = true;
    
      this.outPutValue.emit(this.value);
    }
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove('modal-open');
  }

}
