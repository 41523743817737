import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import {
  fetchPaymentMethod,
  getPaymentMethod,
  updatePaymentMethod,
  deletePaymentMethod,
  addPaymentMethod,
  updateUserPayout
} from "../../store/payment-method/payment-method.action";
import { getPaymentMethodSelector, getPaymentMethodsSelector } from '@store/payment-method/payment-method.selectors';

import { HelperService } from '../helper/helper.service';
import { SharedService } from '@services/shared/shared.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class PaymentMethodsService {
  backendUrl = environment.backendUrl;

  private _clickSaved = new Subject<boolean>();
  clickSaved$ = this._clickSaved.asObservable();

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private store: Store<{ authToken: string }>
  ) {}


  onClickSavedToggle(clicked: boolean) {
    this._clickSaved.next(clicked);
  }

  getPaymentMethodSelector() {
    return this.store.select(getPaymentMethodSelector); 
  }

  getPaymentMethodsSelector() {
    return this.store.select(getPaymentMethodsSelector); 
  }

  fetchPaymentMethods(page, filterOptions) {
    this.store.dispatch(fetchPaymentMethod({page, filterOptions}));
  }

  fetchPaymentMethod(id) {
    this.store.dispatch(getPaymentMethod({ id }))
  }

  addPaymentMethod(method) {
    this.store.dispatch(addPaymentMethod({ method }))
  }

  updatePaymentMethod(method) {
    this.store.dispatch(updatePaymentMethod({ method }));
  }

  deletePaymentMethod(id) {
    this.store.dispatch(deletePaymentMethod({ id }));
  }

  updateUserPayout(payMethod) {
    this.store.dispatch(updateUserPayout({ payMethod }));
  }

  getPaymentMethods(page: number, filterOptions: any = { }) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(`${this.backendUrl}/payments/methods?${queryString}`);
  }

  getPaymentMethod(id: string) {
    return this.http.get(`${this.backendUrl}/payments/methods/${id}`);
  }
  
  insertPaymentMethod(method) {
    return this.http.post(`${this.backendUrl}/payments/methods`, method);
  }

  renewMethod(method) {
    return this.http.patch(`${this.backendUrl}/payments/methods/${method.id}`, method)
  }

  removeMethod(id) {
    return this.http.delete(`${this.backendUrl}/payments/methods/${id}`);
  }

  renewUserPayment(payMethod) {
    console.log(payMethod);
    
    return this.http.patch(`${this.backendUrl}/payments/requisites`, payMethod)
  }

}
