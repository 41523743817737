import { createReducer, on } from "@ngrx/store";
import {
    fetchBanner,
    fetchBannerSuccess,
    fetchBannerFailure,
    getBanner,
    getBannerSuccess,
    getBannerFailure,
    deleteBanner,
    deleteBannerSuccess,
    deleteBannerFailure,
    addBanner,
    addBannerSuccess,
    addBannerFailure,
    updateBanner,
    updateBannerSuccess,
    updateBannerFailure
} from "./banner.actions";

import { IState } from "@models/index";

const initialState: IState = {
  isLoaded: false,
  data: [],
  selectedItem: {},
  error: null,
  total: 0,
  maxPages: 0,
  perPage: 0,
  currentPage: 0
};

const _bannerReducer = createReducer(
  initialState,
  on(fetchBanner, (state, { page, filterOptions }) => {
    return state;
  }),
  on(fetchBannerSuccess, (state, { banner }) => {
    const payload = {
      isLoaded: true,
      data: banner.data,
      total: banner.total,
      maxPages: banner.maxPages,
      perPage: banner.perPage,
      currentPage: banner.currentPage,
      selectedItem: {},
      error: null
    };

    return { ...state, ...payload };
  }),
  on(fetchBannerFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(getBanner, (state, { id }) => {
    const payload = {
      isLoaded: false
    };
    return { ...state, ...payload };
  }),
  on(getBannerSuccess, (state, { banner }) => {
    const payload = {
      isLoaded: true,
      selectedItem: banner
    };
    return { ...state, ...payload };
  }),
  on(getBannerFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(deleteBanner, (state, { id }) => {
    const payload = {
      isLoaded: false
    };
    return { ...state, ...payload };
  }),
  on(deleteBannerSuccess, (state, { id }) => {
    const payload = {
      isLoaded: true,
      data: state.data.filter(item => item.id !== id)
    };
    return { ...state, ...payload };
  }),
  on(deleteBannerFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(addBanner, (state, { banner }) => {
    const payload = {
      isLoaded: false
    };
    return { ...state, ...payload };
  }),
  on(addBannerSuccess, (state, { banner }) => {
    state.data.splice(state.data.length - 1, 1);
    const payload = {
      isLoaded: true,
      data: [...state.data, { ...banner }],
      error: null
    };
    return { ...state, ...payload };
  }),
  on(addBannerFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(updateBanner, (state, { banner }) => {
    const payload = {
      isLoaded: false,
    }
    return { ...state, ...payload };
  }),
  on(updateBannerSuccess, (state, { banner }) => {
    const index = state.data.findIndex(bann => bann.id === banner.id);
    state[index] = banner;
    const payload = {
      isLoaded: true,
      selectedItem: banner
    }
    return { ...state, ...payload };
  }),
  on(updateBannerFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
);

export function bannerReducer(state, action) {
  return _bannerReducer(state, action);
}
