import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-manage-sources-add-page',
  templateUrl: './manage-sources-add-page.component.html',
  styleUrls: ['./manage-sources-add-page.component.scss']
})
export class ManageSourcesAddPage implements OnInit {
  addSourceForm: FormGroup;

  availableManagers: string[] = ['Rafi B', 'Itamar G'];
  assignedManagers: string[] = [];
  selectedSources: string[] = [];

  constructor(private formBuilder: FormBuilder) { 
    this.addSourceForm = this.formBuilder.group({
      source: new FormControl(null, [Validators.required]),
      assignedManagers: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit() {
  }

  onChangeFormValue(options) {
    switch(options.name) {
      case 'cancel' :
        this.onCancel()
        break;

      case 'submit' :
        this.addNewSource();
        break;
      case 'selectAvailable' :
      case 'selectAssigned' :
        this.selectedSources = options.value;
        break;

      case 'moveToAssigned' :
        // move selected source to assigned sources
        this.assignedManagers = this.assignedManagers.concat(this.selectedSources);
        // remove elements from availabe sources
        this.selectedSources.forEach(source => {
          this.availableManagers = this.availableManagers.filter(element => element !== source)
        });
        this.addSourceForm.patchValue({ affiliates: this.assignedManagers });
        this.selectedSources = [];
        break;

      case 'moveToAvailable' :
        // move selected source to assigned sources
        this.availableManagers = this.availableManagers.concat(this.selectedSources);
        // remove elements from availabe sources
        this.selectedSources.forEach(source => {
          this.assignedManagers = this.assignedManagers.filter(element => element !== source)
        });
        this.selectedSources = [];
        break;

      default :
      this.addSourceForm.patchValue({ [options.name]: options.value });
    }
  }

  onCancel() {
    // cancel
  }

  addNewSource() {
    // add
  }

}
