import { createAction, props } from "@ngrx/store";

export const fetchPayment = createAction(
  "[Payment] Fetch Payment",
  props<{ page, filterOptions }>()
);

export const fetchPaymentSuccess = createAction(
  "[Payment] Fetch Payment Success",
  props<{ payment }>()
);

export const fetchPaymentFailure = createAction(
  "[Payment] Fetch Payment Faild",
  props<{ error }>()
);

export const getPayment = createAction(
  "[Payment] Get Payment",
  props<{ id }>()
);

export const getPaymentSuccess = createAction(
  "[Payment] Get Payment Success",
  props<{ payment }>()
);

export const getPaymentFailure = createAction(
  "[Payment] Get Payment Failure",
  props<{ error }>()
)

export const addPayment = createAction(
  "[Payment] Add Payment",
  props<{ payment }>()
);

export const addPaymentSuccess = createAction(
  "[Payment] Add Payment Success",
  props<{ payment }>()
);

export const addPaymentFailure = createAction(
  "[Payment] Add Payment Failure",
  props<{ error }>()
);