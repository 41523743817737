import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import {
  fetchEventCategories,
  getEventCategory,
  addEventCategory,
  updateEventCategory,
  deleteEventCategory
} from "../../store/event-category/event-category.action";
import { getEventCategorySelector, getEventCategoriesSelector } from '@store/event-category/event-category.selectors';

import { HelperService } from '../helper/helper.service';
import { SharedService } from '@services/shared/shared.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventCategoryService {

  backendUrl = environment.backendUrl;

  private _clickSaved = new Subject<boolean>();
  clickSaved$ = this._clickSaved.asObservable();

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private store: Store<{ authToken: string }>
  ) {}


  onClickSavedToggle(clicked: boolean) {
    this._clickSaved.next(clicked);
  }

  getEventCategorySelector() {
    return this.store.select(getEventCategorySelector); 
  }

  getEventCategoriesSelector() {
    return this.store.select(getEventCategoriesSelector); 
  }

  fetchEventCategories(page, filterOptions) {
    this.store.dispatch(fetchEventCategories({page, filterOptions}));
  }

  fetchEventCategory(id) {
    this.store.dispatch(getEventCategory({ id }))
  }

  addEventCategory(eventCategory) {
    this.store.dispatch(addEventCategory({ eventCategory }))
  }

  updateEventCategory(eventCategory) {
    this.store.dispatch(updateEventCategory({ eventCategory }));
  }

  deleteEventCategory(id) {
    this.store.dispatch(deleteEventCategory({ id }));
  }

  getEventCategories(page: number, filterOptions: any = { }) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(`${this.backendUrl}/event-categories?${queryString}`);
  }

  getEventCategory(id: string) {
    return this.http.get(`${this.backendUrl}/event-categories/event-category/${id}`);
  }
  
  insertEventCategory(eventCategory) {
    return this.http.post(`${this.backendUrl}/event-categories/event-category`, eventCategory);
  }

  renewEventCategory(eventCategory) {
    return this.http.patch(`${this.backendUrl}/event-categories/event-category/${eventCategory.id}`, eventCategory)
  }

  removeEventCategory(id) {
    return this.http.delete(`${this.backendUrl}/event-categories/event-category/${id}`);
  }
}
