import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import {
  fetchAffiliate,
  fetchAffiliateSuccess,
  fetchAffiliateFailure,
  fetchFilteredAffiliates,
  fetchFilteredAffiliatesFailure,
  fetchFilteredAffiliatesSuccess,
  getAffiliate,
  getAffiliateSuccess,
  getAffiliateFailure,
  deleteAffiliate,
  deleteAffiliateSuccess,
  deleteAffiliateFailure,
  addAffiliate,
  addAffiliateSuccess,
  addAffiliateFailure,
  updateAffiliate,
  updateAffiliateSuccess,
  updateAffiliateFailure,
  fetchAffiliateByAffManager,
  fetchAffiliateByAffManagerSuccess,
  fetchAffiliateByAffManagerFailure,
  fetchAffiliateDownlines,
  fetchAffiliateDownlinesSuccess,
  fetchAffiliateDownlinesFailure
} from "./affiliate.actions";
import {EMPTY, Observable, of} from "rxjs";
import {map, mergeMap, catchError, tap, exhaustMap, takeUntil, withLatestFrom, finalize} from "rxjs/operators";
import { AffiliateService } from "@services/affiliate/affiliate.service";
import { SharedService } from '@services/shared/shared.service';
import {Store} from "@ngrx/store";
import {getAffiliatesSelector} from "@store/affiliate/affiliate.selectors";

@Injectable()
export class AffiliateEffects {
  loadAffiliate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAffiliate),
      withLatestFrom(this.store$.select(getAffiliatesSelector)),
      finalize(() => {
        console.log('[API FINALIZE] --------- ');
      }),
      mergeMap(([action, storeState]) =>
        {
          // console.log('action', action);
          // console.log('storeState', storeState);
          return this.affiliateService.getAffiliates(action.page, storeState['_filterOptions']).pipe(
            map(res => {
              return fetchAffiliateSuccess({ affiliate: res });
            }),
            catchError(error => {
              this.sharedService.onLoaderToggle(false);
              return of(fetchAffiliateFailure({ error }));
            })
          )
        }
      )
    )
  );

  loadFilteredAffiliate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchFilteredAffiliates),
      mergeMap(action =>
        this.affiliateService.getAffiliates(action.page, action.filterOptions).pipe(
          map(res => {
            return fetchFilteredAffiliatesSuccess({ affiliates: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchFilteredAffiliatesFailure({ error }));
          })
        )
      )
    )
  );

  loadAffiliatesByAffManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAffiliateByAffManager),
      mergeMap(action =>
        this.affiliateService.getAffiliatesByAffManager(action.affManagerId, action.page, action.filterOptions).pipe(
          map(res => {
            return fetchAffiliateByAffManagerSuccess({ affiliates: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchAffiliateByAffManagerFailure({ error }));
          })
        )
      )
    )
  );

  getAffiliate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAffiliate),
      mergeMap(action =>
        this.affiliateService.getAffiliate(action.id).pipe(
          map(res => {
            return getAffiliateSuccess({ affiliate: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(getAffiliateFailure({ error }));
          })
        )
      )
    )
  );

  updateAffiliate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAffiliate),
      mergeMap(action =>
        this.affiliateService.renewAffiliate(action.affiliate).pipe(
          map(affiliate => {
            return updateAffiliateSuccess({ affiliate });
          }),
          tap(res => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(updateAffiliateFailure({ error }));
          })
        )
      )
    )
  );

  deleteAffiliate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAffiliate),
      mergeMap(action =>
        this.affiliateService.removeAffiliate(action.id).pipe(
          map(() => {
            return deleteAffiliateSuccess({ id: action.id });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(deleteAffiliateFailure({ error }));
          })
        )
      )
    )
  );


  addAffiliate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addAffiliate),
      mergeMap(action =>
        this.affiliateService.insertAffiliate(action.affiliate).pipe(
          map(affiliate => {
            return addAffiliateSuccess({ affiliate });
          }),
          tap(res => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
            setTimeout(() => {
              this.router.navigate([`/affiliates/affiliate/${res.affiliate["id"]}`]);
            }, 2000);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(addAffiliateFailure({ error }));
          })
        )
      )
    )
  );

  loadDownlinesByAffiliate$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fetchAffiliateDownlines),
    mergeMap((action) =>
      this.affiliateService.getDownlinesByAffiliate(action.page, action.affiliateID, action.filterOptions).pipe(
        map(res => {
          return fetchAffiliateDownlinesSuccess({ downlines: res });
        }),
        catchError(error => {
          this.sharedService.onLoaderToggle(false);
          return of(fetchAffiliateDownlinesFailure({ error }));
        })
      )
    )
  )
);


  constructor(
    private actions$: Actions,
    private affiliateService: AffiliateService,
    private sharedService: SharedService,
    private router: Router,
    private store$: Store<any>
  ) {}
}
