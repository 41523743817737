export { DashboardPageModule } from './dashboard-page/dashboard-page.module';
export { AffiliatesPageModule } from './affiliates-page/affiliates-page.module';
export { ReportsPageModule } from './reports-page/reports-page.module';
export { ConfigurationPageModule } from './configuration-page/configuration-page.module';
export { AffiliatePaymentsPageModule } from './affiliate-payments-page/affiliate-payments-page.module';
export { AffiliateManagersPageModule } from './affiliate-managers-page/affiliate-managers-page.module';
export { MessagesPageModule } from './messages-page/messages-page.module';
export { CampaignPageModule } from './campaign-page/campaign-page.module';
export { BannersPageModule } from './banners-page/banners-page.module';
export { UserPageModule } from './user-page/user-page.module';
export { GeneratedCommissionsModule } from './generated-commissions-page/generated-commissions.module';
export { AffiliatesInCampaignPageModule } from './affiliates-in-campaign-page/affiliates-in-campaign-page.module';
export { TrackingPageModule } from './tracking-page/tracking-page.module';