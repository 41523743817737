export { AffiliatesListPage } from './affiliates-list-page/affiliates-list-page.component';
export { AffiliatesAddPage } from './affiliates-add-page/affiliates-add-page.component';
export { ManageSourcesPage } from './manage-sources-page/manage-sources-page.component';
export { ManageProductsDiscountsPage } from './manage-products-discounts-page/manage-products-discounts-page.component';
export { ManageProductsDiscountsListPage } from './manage-products-discounts-page/manage-products-discounts-list-page/manage-products-discounts-list-page.component';
export { ManageProductsDiscountsAddPage } from './manage-products-discounts-page/manage-products-discounts-add-page/manage-products-discounts-add-page.component';
export { ManageSourcesListPage } from './manage-sources-page/manage-sources-list-page/manage-sources-list-page.component';
export { ManageSourcesAddPage } from './manage-sources-page/manage-sources-add-page/manage-sources-add-page.component';
export { CommissionGroupsPage } from './commission-groups-page/commission-groups-page.component';
export { AffiliatesDetailsPage } from './affiliates-details-page/affiliates-details-page.component';
export { AffiliatesCampaignsPage } from './affiliates-campaigns-page/affiliates-campaigns-page.component';
export { AffiliatesMailsPage } from './affiliates-mails-page/affiliates-mails-page.component';
export { AffiliatesPayoutsPage } from './affiliates-payouts-page/affiliates-payouts-page.component';
export { AffiliatesTrackingPage } from './affiliates-tracking-page/affiliates-tracking-page.component';
export { LoginsHistoryPage } from './logins-history-page/logins-history-page.component';
export { AffiliatesDownlinesPage } from './affiliates-downlines-page/affiliates-downlines-page.component';
export { AffiliatesListFilterComponent } from './affiliates-list-filter/affiliates-list-filter.component';
export { AffiliatesAddTabsComponent } from './affiliates-add-tabs/affiliates-add-tabs.component';