import { Component, OnInit, Input } from '@angular/core';
import { INotification } from '@models/index';

@Component({
  selector: 'aff-notification-alert',
  templateUrl: './notification-alert.component.html',
  styleUrls: ['./notification-alert.component.scss']
})
export class NotificationAlertComponent implements OnInit {
  @Input() notification: INotification = {
                          isShow: false,
                          isError: false,
                          message: ''
                        };

  constructor() { }

  ngOnInit() {
  }

}
