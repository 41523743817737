import { createAction, props } from '@ngrx/store';

export const fetchNavigation = createAction('[Navigation] Fetch');

export const fetchNavigationSuccess = createAction(
    '[Navigation] Fetch Success',
    props<{navigation}>()
);

export const fetchNavigationFailure = createAction(
    '[Navigation] Fetch Faild',
    props<{error}>()
);

export const setActivePage = createAction(
    '[Navigation] Set Active Page',
    props<{pageSlug: string}>()
);