import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'aff-manage-products-discounts-add-page',
  templateUrl: './manage-products-discounts-add-page.component.html',
  styleUrls: ['./manage-products-discounts-add-page.component.scss']
})
export class ManageProductsDiscountsAddPage implements OnInit {
  addPromotionForm: FormGroup;

  availableAffiliates: string[] = ['5002 - RANKCOM(internal)', '505 - WallCann Trust MC'];
  availableStores: string[] = ['5002 - pillstore', '505 - 4RX'];
  availableProduct: string[] = ['Acai', 'Pure'];
  assignedAffiliates: string[] = [];
  selectAssigned: string[] = [];
  assignedProduct: string[] = [];
  selectedSources: string[] = [];
  assignedStores: string[] = [];

  constructor(private formBuilder: FormBuilder) { 
    this.addPromotionForm = this.formBuilder.group({
      promotionName: new FormControl(null, [Validators.required]),
      fromDate: new FormControl(null, [Validators.required]),
      toDate: new FormControl(null, [Validators.required]),
      affiliates: new FormControl([], [Validators.required]),
      stores: new FormControl([], [Validators.required]),
      products: new FormControl([], [Validators.required]),
      discountBrand: new FormControl(null, [Validators.required]),
      discountGeneric: new FormControl(null, [Validators.required]),
      isActive: new FormControl(true, [Validators.required]),
      returningCustomerDiscount: new FormControl(true, [Validators.required]),
      enableUpsaleDiscount: new FormControl(true, [Validators.required]),
    })
  }

  ngOnInit() {
  }

  onChangeFormValue(options) {
    switch(options.name) {
      case 'cancel' :
        this.onCancel()
        break;

      case 'submit' :
        this.addNewPromotion();
        break;
      case 'selectAvailable' :
      case 'selectAssigned' :
        this.selectedSources = options.value;
        break;

      case 'moveToAffiliatesAssigned' :
        // move selected Affiliates to assigned Affiliates
        this.assignedAffiliates = this.assignedAffiliates.concat(this.selectedSources);
        // remove elements from availabe Affiliates
        this.selectedSources.forEach(source => {
          this.availableAffiliates = this.availableAffiliates.filter(element => element !== source)
        });
        this.addPromotionForm.patchValue({ affiliates: this.assignedAffiliates });
        this.selectedSources = [];
        break;

      case 'moveToAffiliatesAvailable' :
        // move selected Affiliates to availabe Affiliates
        this.availableAffiliates = this.availableAffiliates.concat(this.selectedSources);
        // remove elements from assigned Affiliates
        this.selectedSources.forEach(source => {
          this.assignedAffiliates = this.assignedAffiliates.filter(element => element !== source)
        });
        this.selectedSources = [];
        break;

      case 'moveToStoresAssigned' :
        // move selected Stores to assigned Stores
        this.assignedStores = this.assignedStores.concat(this.selectedSources);
        // remove elements from availabe Stores
        this.selectedSources.forEach(source => {
          this.availableStores = this.availableStores.filter(element => element !== source)
        });
        this.addPromotionForm.patchValue({ affiliates: this.assignedStores });
        this.selectedSources = [];
        break;

      case 'moveToStoresAvailable' :
        // move selected Stores to availabe Stores
        this.availableStores = this.availableStores.concat(this.selectedSources);
        // remove elements from assigned Stores
        this.selectedSources.forEach(source => {
          this.assignedStores = this.assignedStores.filter(element => element !== source)
        });
        this.selectedSources = [];
        break;

      case 'moveToProductsAssigned' :
        // move selected Products to assigned Products
        this.assignedProduct = this.assignedProduct.concat(this.selectedSources);
        // remove elements from availabe Products
        this.selectedSources.forEach(source => {
          this.availableProduct = this.availableProduct.filter(element => element !== source)
        });
        this.addPromotionForm.patchValue({ affiliates: this.assignedProduct });
        this.selectedSources = [];
        break;

      case 'moveToProductsAvailable' :
        // move selected Products to availabe Products
        this.availableProduct = this.availableProduct.concat(this.selectedSources);
        // remove elements from assigned Products
        this.selectedSources.forEach(source => {
          this.assignedProduct = this.assignedProduct.filter(element => element !== source)
        });
        this.selectedSources = [];
        break;
      
      default :
      this.addPromotionForm.patchValue({ [options.name]: options.value });
    }
  }

  addNewPromotion() {
    // add new promotion
  }

  onCancel() {
    // cancel
  }

}
