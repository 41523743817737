import { createReducer, on } from "@ngrx/store";
import {
  fetchMessages,
  fetchMessagesSuccess,
  fetchMessagesFailure,
  getMessage,
  getMessageSuccess,
  getMessageFailure,
  addMessage,
  addMessageSuccess,
  addMessageFailure,
  deleteMessage,
  deleteMessageSuccess,
  deleteMessageFailure
} from "./message.actions";

import { IState } from "@models/index";

const initialState: IState = {
  isLoaded: false,
  data: [],
  selectedItem: {},
  error: null,
  total: 0,
  maxPages: 0,
  perPage: 0,
  currentPage: 0
};

const _messageReducer = createReducer(
  initialState,
  on(fetchMessages, (state, { page, filterOptions, user_id }) => {
    return state;
  }),
  on(fetchMessagesSuccess, (state, { message }) => {
    const payload = {
      isLoaded: true,
      data: message.data,
      total: message.total,
      maxPages: message.maxPages,
      perPage: message.perPage,
      currentPage: message.currentPage,
    };

    return { ...state, ...payload };
  }),
  on(fetchMessagesFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(getMessage, (state, { id }) => {
    const payload = {
      isSaved: false,
      isUpdated: false,
    };
    return { ...state, ...payload };
  }),
  on(getMessageSuccess, (state, { message }) => {
    const payload = {
      error: null,
      selectedItem: message
    };
    return { ...state, ...payload };
  }),
  on(getMessageFailure, (state, { error }) => {
    const payload = {
      error
    };
    return { ...state, ...payload };
  }),
  on(addMessage, (state, { message }) => {
    const payload = {
      isLoaded: false
    };
    return { ...state, ...payload };
  }),
  on(addMessageSuccess, (state, { message }) => {

    const payload = {
      isLoaded: true,
    };

    console.log({ ...state, ...payload });

    return { ...state, ...payload };
  }),
  on(addMessageFailure, (state, { error }) => {
    console.log(state);

    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(deleteMessage, (state, { id }) => {
    const payload = {
      isLoaded: false
    };
    return { ...state, ...payload };
  }),
  on(deleteMessageSuccess, (state, { id }) => {
    const payload = {
      isLoaded: true,
    };
    return { ...state, ...payload };
  }),
  on(deleteMessageFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  })


);

export function messageReducer(state, action) {
  return _messageReducer(state, action);
}

