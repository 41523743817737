export enum WrapperForBanner {
  IFRAME = 'iframe',
  JAVASCRIPTDINAMIC = 'javascript_dynamic',
  PLAIN = 'plain',
  POPUNDERONCLICK = 'pop_under_on_click',
  POPUPONCLICK = 'pop_up_on_click',
  POPUP = 'pop_up',
  POPUNDER = 'pop_under',
}

export enum BannerSizeOption {
  UNDEFINED = 'undefined',
  OWNSIZE = 'own_size',
  PREDEFINEDSIZE = 'predefined_size'
}

export enum BannerPredefineSize {
  SIZE300X250 = '300x250',
  SIZE250X250 = '250x250',
  SIZE240X400 = '240x400',
  SIZE336X280 = '336x280',
  SIZE180X150 = '180x150',
  SIZE300X100 = '300x100',
  SIZE720X300 = '720x300',
  SIZE468X60 = '468x60',
  SIZE234X60 = '234x60',
  SIZE120X90 = '120x90',
  SIZE120X60 = '120x60',
  SIZE120X240 = '120x240',
  SIZE125X125 = '125X125',
  SIZE728X90 = '728x90',
  SIZE160X600 = '160X600',
  SIZE120X600 = '120X600',
  SIZE300X600 = '300x600',
}

export enum ObjectFit {
  CONTAIN = 'contain',
  COVER = 'cover'
}

export enum BannerType {
  LINK = 'link',
  TEXTLINK = 'text_link',
  IMAGEBANNER = 'image_banner',
  FLASHBANNER = 'flash_banner',
  HTMLBANNER = 'html_banner',
  PROMOEMAIL = 'promo_email',
  SIMPLEPDF = 'simple_pdf',
}

export enum TargetAttribut {
  BLANK = 'blank',
  SELF = 'self',
  TOP = 'top',
  PARENT = 'parent',
}

export enum WindowMode {
  WINDOW = 'window',
  OPAQUE = 'opaque',
  TRANSPARENT = 'transparent',
}