import { createReducer, on } from "@ngrx/store";
import {
  fetchCountry,
  fetchCountrySuccess,
  fetchCountryFailure
} from "./country.actions";

import { CountryState } from "@models/index";

const initialState: CountryState = {
  isLoaded: false,
  data: [],
  selectedItem: {},
  error: null
};

const _countryReducer = createReducer(

  initialState,

  on(fetchCountry, (state) => {
    return state;
  }),
  on(fetchCountrySuccess, (state, { country }) => {
    const payload = {
      isLoaded: true,
      data: country,
      selectedItem: {}
    };

    return { ...state, ...payload };
  }),
  on(fetchCountryFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  
);

export function countryReducer(state, action) {
  return _countryReducer(state, action);
}
