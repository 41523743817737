import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Status } from '@enums/index';
import { OrderService, HelperService, GeneratedCommissionService } from '@services/index';
import { GeneratedCommissionStatus, GeneratedCommissionCurrency } from '@enums/index';
import { IFormOptions } from '@interfaces/index';

@Component({
  selector: 'aff-generated-commissions-approve-popup',
  templateUrl: './generated-commissions-approve-popup.component.html',
  styleUrls: ['./generated-commissions-approve-popup.component.scss']
})
export class GeneratedCommissionsApprovePopup implements OnInit, OnDestroy {
  @Output() actionType = new EventEmitter<GeneratedCommissionStatus>();
  @Input() orderId: string = '';
  @Input() viewMode: boolean;
  @Input() selectedCommission: {};
  genCommStatus = GeneratedCommissionStatus;

  order: any = {};
  genCommission$: Observable<any>;
  order$: Observable<any>;
  noteForManager: string;
  campaignStatus: string;
  noteForAff: string;
  currentCommission$: Observable<any>;
  currentCommission: {};
  private unsubscribe$ = new Subject<void>();

  constructor(private orderService: OrderService, 
              private generatedCommissionService: GeneratedCommissionService) { 
    this.order$ = orderService.getOrderSelector();
    this.genCommission$ = generatedCommissionService.getGeneratedCommissionSelector();
    this.currentCommission$ = generatedCommissionService.getGeneratedCommissionSelector();
  }

  ngOnInit() {
    if(this.viewMode){
      this.currentCommission$.pipe(takeUntil(this.unsubscribe$))
      .subscribe(commission => {
        this.currentCommission = commission;
      })
    }
    if (this.orderId) {
      this.orderService.fetchOrder(this.orderId);
    }

    this.order$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(order => {
        if (HelperService.isObjectEmpty(order)) return;
        // get order first object element
        const ordetDetails = order[Object.keys(order)[0]].commission;
        // check if generated commissions is one or more
        const isSingleGenComm = Object.keys(order).length === 1;
        let commission: number = 0;
        let saleOrderAmount: number = 0;
        
        this.campaignStatus = ordetDetails.campaign ? Status[ordetDetails.campaign.status] : '';

        // Calculate sum of commission and sale order amount
        if (!isSingleGenComm) {
          Object.keys(order).forEach(key => {
            commission += order[key].commission.commission;
            saleOrderAmount += order[key].commission.sale_order_amount;
          });
        } else {
          saleOrderAmount = ordetDetails.sale_order_amount;
          commission = ordetDetails.commission;
        }

        this.order = {
          ...ordetDetails,          
          orderId: this.orderId,
          sale_order_amount: '$' + saleOrderAmount,
          commission: '$' + commission,
          isSingleGenComm,
          ...this.createDetailsOption(ordetDetails)
          
        }        
        
      });
      
      this.genCommission$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(genCommission => {        
        if (HelperService.isObjectEmpty(genCommission)) return;
        this.getGenCommission(genCommission);
      });
  }

  getGenCommission(genCommission: any): void {
    this.order = {
      campaign_status: genCommission.campaignStatus,
      ...genCommission,
      isSingleGenComm: true,
      ...this.createDetailsOption(genCommission)
    }
  }

  createDetailsOption(genCommission: any): any {
    return {
      date: HelperService.dateFormater(genCommission.created_at),
      affiliate: {
        name: genCommission.user.profile.first_name + ' ' + genCommission.user.profile.last_name,
        url: `affiliates/affiliate/${genCommission.user.id}`
      },
      campaign: {
        name: genCommission.campaign.name,
        url: `/campaigns/campaign/${genCommission.campaign.id}`,
        status:  Status[genCommission.campaign.status]
      },
      promotionTool: {
        name: genCommission.banner.name,
        url: `/banners/banner/${genCommission.banner.id}`
      }
    }
  }

  goToLink(link: string): void {
    window.open(link, "_blank");
  }

  onChangeFormValue(options: IFormOptions): void {
    switch (options.name) {
      case "approve":
        this.order.status = GeneratedCommissionStatus.Approved;
        this.actionType.emit(GeneratedCommissionStatus.Approved);
        this.orderService.updateOrder(this.order, options.name);
        break;
      
      case "reject":
        this.order.status = GeneratedCommissionStatus.Rejected;
        this.actionType.emit(GeneratedCommissionStatus.Rejected);
        this.orderService.updateOrder(this.order, options.name);
        break;

      case 'send_notification':
        this.order[options.name] = options.checked;
        break;
      
      default:
        this.order[options.name] = options.value.trim();
    }
  }

  ngOnDestroy(): void {
    this.generatedCommissionService.clearSelectedGenCommision();
    this.orderService.clearOrderState();
    this.unsubscribe$.next()
    this.unsubscribe$.complete();
  }

}
