import { createReducer, on } from "@ngrx/store";
import {
  fetchOrders,
  fetchOrdersFailure,
  fetchOrdersSuccess,
  getOrder,
  getOrderSuccess,
  getOrderFailure,
  createOrder,
  createOrderFailure,
  createOrderSuccess,
  updateOrder,
  updateOrderSuccess,
  updateOrderFailure,
  clearOrderState
} from "./order.action";

import { IOrderState } from "@models/index";

const initialState: IOrderState = {
  isLoaded: false,
  data: [],
  filteredDate: [],
  isSaved: false,
  isUpdated: false,
  createdItem: {},
  selectedItem: {},
  error: null,
  total: 0,
  maxPages: 0,
  perPage: 0,
  currentPage: 0
};

const _orderReducer = createReducer(
  initialState,
  on(fetchOrders, (state, { page, filterOptions }) => {
    return state;
  }),
  on(fetchOrdersSuccess, (state, { orders }) => {
    const payload = {
      isLoaded: true,
      isSaved: false,
      isUpdated: false,
      createdItem: {},
      data: orders.data,
      total: orders.total,
      maxPages: orders.maxPages,
      perPage: orders.perPage,
      currentPage: orders.currentPage,
      error: null,
      selectedItem: {}
    };

    return { ...state, ...payload };
  }),
  on(fetchOrdersFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };

    return { ...state, ...payload };
  }),
  on(getOrder, (state, { id }) => {
    const payload = {
      isSaved: false,
      isUpdated: false,
    };

    return { ...state, ...payload };
  }),
  on(getOrderSuccess, (state, { order }) => {
    const payload = {
      error: null,
      selectedItem: order
    };

    return { ...state, ...payload };
  }),
  on(getOrderFailure, (state, { error }) => {
    const payload = {
      error
    };

    return { ...state, ...payload };
  }),
  on(createOrder, (state, { order }) => {
    const payload = {
      isSaved: false,
      isUpdated: false,
      createdItem: {}
    };

    return { ...state, ...payload };
  }),
  on(createOrderSuccess, (state, { order }) => {
    let total: number = state.total;
    
    if (state.total < state.perPage) {
      total += 1;
    } else {
      state.data.splice(state.data.length - 1, 1);
    }

    const payload = {
      isSaved: true,
      createdItem: order,
      data: [order, ...state.data]
    };
    
    return { ...state, ...payload };
  }),
  on(createOrderFailure, (state, { error }) => {
    const payload = {
      error
    };

    return { ...state, ...payload };
  }),
  on(updateOrder, (state, { order, option }) => {
    const payload = {
      isSaved: false,
      isUpdated: false
    };

    return { ...state, ...payload };
  }),
  on(updateOrderSuccess, (state, { order }) => {
    const payload = {
      isUpdated: true,
      selectedItem: {}
    };
    
    return { ...state, ...payload };
  }),
  on(updateOrderFailure, (state, { error }) => {
    const payload = {
      error
    };

    return { ...state, ...payload };
  }),
  on(clearOrderState, (state) => {
    state = {
      isLoaded: false,
      data: [],
      filteredDate: [],
      isSaved: false,
      isUpdated: false,
      createdItem: {},
      selectedItem: {},
      error: null,
      total: 0,
      maxPages: 0,
      perPage: 0,
      currentPage: 0
    };

    return state;
  })
);

export function orderReducer(state, action) {
  return _orderReducer(state, action);
}
