import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '@services/index';

@Component({
  selector: 'aff-activation-page',
  templateUrl: './activation-page.component.html',
  styleUrls: ['./activation-page.component.scss']
})
export class ActivationPage implements OnInit {

  code: string;
  email: string

  constructor(private router: Router,
              private route: ActivatedRoute,
              private userService: UserService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.code = params.hash;
      this.email = params.email;
    });
  }

  onLogin() {
    this.userService.dispatchSendActivation(this.code, this.email);
    this.router.navigate(['/login']);
  }
}
