import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';

import { AffiliateManagersService, PageService } from "@services/index";
import { IState } from "@models/index";
import { IPage } from '@interfaces/index';
import { IModalvalue } from '@shared-models/index';

/**
 * Affiliate Managers Page
 */
@Component({
  selector: 'aff-affiliate-managers-list-page',
  templateUrl: './affiliate-managers-list-page.component.html',
  styleUrls: ['./affiliate-managers-list-page.component.scss']
})
export class AffiliateManagersListPage implements OnInit, OnDestroy {
  rows = [];
  loadingIndicator: boolean = true;
  selectedRows = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  confirmationModal: boolean = false;
  deleteValues: string = '';

  affiliateManagers$: Observable<IState>;
  private unsubscribe$ = new Subject<void>();

  /**
   * Affiliate Managers List Constructor
   * @param router
   */
  constructor(private router: Router,
              private pageService: PageService,
              private affiliateManagersService: AffiliateManagersService) { 
                //set breadcrum
                pageService.changePageInfo({ breadcrum: ['Affiliate Managers']});
                // get affiliate managers list
                this.affiliateManagersService.fetchAffiliateManagers(1, {});
                this.affiliateManagers$ = this.affiliateManagersService.getAffiliateManagersSelector();
              }

  /**
   * @ignore
   */
  ngOnInit() {
    // listen affiliate managers
    this.affiliateManagers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.rows = [];
          for (const affManager of state.data) {
            const row = {
              id: affManager["id"],
              managerName: affManager["profile"]["first_name"] + ' ' + affManager["profile"]["last_name"],
              email: affManager["email"],
              dateCreated: affManager["created_at"],
              lastlogIn: affManager["lat_login"]
            };
            this.rows.push(row);
          }
        }
        this.rows = [...this.rows];
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
    });
  }

  onAction(action: string, row: any): void {
    switch (action) {
      case "delete":
        this.deleteValues = `Affiliate Manager: <br><br> ${row.managerName}`;
        this.selectedRows= [{ id: row.id }];
        this.confirmationModal = true;
        break;

      case "edit":
        this.router.navigate([`/affiliate-managers/affiliate-manager/${row.id}`])
        break;

      default:
      // default
    }
  }

  /**
   * Handle Router Change
   */
  onAddManager(): void {
    this.router.navigate(['/affiliate-managers/affiliate-manager'])
  }

  onDeleteConfirm(options: IModalvalue): void {
    if (options.confirm) {
      for (const selectedRow of this.selectedRows) {
        this.affiliateManagersService.deleteAffiliateManager(selectedRow.id);
      }
      this.selectedRows = [];
    }
    this.confirmationModal = false;
  }

  setPage(e): void {
    this.selectedRows = [];
    this.affiliateManagersService.fetchAffiliateManagers(e.offset + 1, {});
  }

  onSelect(e): void {
    this.selectedRows = e.selected;
  }

  onDeleteManager(): void {
    if (this.selectedRows.length) {
      this.deleteValues = '';
      this.confirmationModal = true;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
