import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageService } from '@services/page/page.service';
import { IPage, IFormOptions } from '@interfaces/index';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HelperService } from '@services/helper/helper.service';
import { IModalvalue } from '@shared-models/index';
import { PaymentMethodsService } from '@services/payment-methods/payment-methods.service'
import { ConfigService } from '@services/config/config.service';

@Component({
  selector: 'aff-payout-methods-page',
  templateUrl: './payout-methods-page.component.html',
  styleUrls: ['./payout-methods-page.component.scss']
})
export class PayoutMethodsPage implements OnInit, OnDestroy {

  addPayMethodForm: FormGroup;
  editMode: boolean = false;

  treshPopup: boolean = false;

  maxOrder: number;
  typeId: string;
  userType;
  confirmationModal: boolean = false;
  deleteValues: string = '';

  showFiterComponent: boolean = false;
  popupForm: boolean = false;
  loadingIndicator: boolean = true;

  selectedRows = [];
  rows: any[] = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  errorObj: any = {};

  payMethods$: Observable<any>;
  payMethod$: Observable<any>;

  treshold$: Observable<any>;
  treshold:any = [];

  unsubscribe$ = new Subject<void>();

  constructor(
    private paymentMethodsService: PaymentMethodsService,
    private configService: ConfigService,
    private pageService: PageService,
    private formBuilder: FormBuilder) {
    this.addPayMethodForm = this.formBuilder.group({
      name: new FormControl(null, [Validators.required]),
      treshold_name: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      // display_order: new FormControl(null),
      // is_default: new FormControl(false)
    });
    this.pageService.changePageInfo({ breadcrum: ['Configuration', 'Payout Methods'] });

    this.payMethods$ = this.paymentMethodsService.getPaymentMethodsSelector();

    this.payMethod$ = this.paymentMethodsService.getPaymentMethodSelector();

    this.treshold$ = this.configService.getconfigsSelector();
  
    this.paymentMethodsService.fetchPaymentMethods(1, {});

    this.configService.fetchTreshold();

  }

  ngOnInit(): void {

    this.treshold$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      for (const treshold of state.data) {
        this.treshold = treshold;
      }
      console.log(this.treshold);
    });

    this.payMethods$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
      console.log(state);
      
        if(!HelperService.isObjectEmpty(state.selectedItem)) {
            HelperService.fillFormValues(state.selectedItem, this.addPayMethodForm);
        }
        if (!state.isLoaded) return;
        // this.maxOrder = state.data.length + 1;
        this.rows = [];
        for (const method of state.data) {
          const row = {
            payoutMethodId: method.id,
            payoutMethodName: method.name
          };
          this.rows.push(row);
        }

        this.rows = [...this.rows];
        console.log(this.rows);
        
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
      });
    

  }

  onAddPayMethod(): void {
    this.addPayMethodForm.reset();
    this.editMode = false;
    this.popupForm = true;
  }

  closePopup(value: boolean): void {
    this.popupForm = value;
    this.treshPopup = value;
  }

  onAction(action: string, row: any): void {
     switch (action) {
      case "delete":
        this.deleteValues = `Payout Method: <br><br> ${row.payoutMethodName}`;
        this.selectedRows= [{ id: row.payoutMethodId }];
        this.confirmationModal = true;
        break;

      case "edit":
        this.popupForm = true;
        this.editMode = true;
        this.typeId = row.payoutMethodId;
        this.paymentMethodsService.fetchPaymentMethod(row.payoutMethodId);
        break;
    }
  }

  onDeleteConfirm(options: IModalvalue): void {
    if (options.confirm) {
      for (const selectedRow of this.selectedRows) {
        this.paymentMethodsService.deletePaymentMethod(selectedRow.id);
      }
      this.selectedRows = [];
    }
    this.confirmationModal = false;
  }

  onSelect(e): void {
    this.selectedRows = e.selected;
  }
  tresholdPopup() {
    this.treshPopup = true;

  }
  savePayMethod(): void {
    this.errorObj = HelperService.checkValidation(this.addPayMethodForm);
    delete this.errorObj['treshold_name'];
    if (HelperService.isObjectEmpty(this.errorObj)) {
      if(!this.addPayMethodForm.value.is_default) {
        this.addPayMethodForm.value.is_default = false;
      }
      this.paymentMethodsService.addPaymentMethod(this.addPayMethodForm.value);
    }
  }

  updatePaymentMethod() {
    this.errorObj = HelperService.checkValidation(this.addPayMethodForm);
    delete this.errorObj['treshold_name'];
    if(this.addPayMethodForm.value.type_name != '') {
      delete this.errorObj['type_name'];
    }
    if (HelperService.isObjectEmpty(this.errorObj)) {
      this.paymentMethodsService.updatePaymentMethod({ id: this.typeId, ...this.addPayMethodForm.value });
    }
  }

  onChangeFormValue(options: IFormOptions): void {
    
    switch(options.name) {
      case 'submit' :
        // if(this.editMode) {
        //   this.addPayMethodForm.value.display_order = this.addPayMethodForm.value.display_order 
        //   ? this.addPayMethodForm.value.display_order : this.userType.display_order;
        // } else {
        //   this.addPayMethodForm.value.display_order = this.addPayMethodForm.value.display_order 
        //   ? this.addPayMethodForm.value.display_order : this.maxOrder;
        // }
        this.editMode ? this.updatePaymentMethod() : this.savePayMethod();
        if (HelperService.isObjectEmpty(this.errorObj)) {
        this.popupForm = false;
        }
        break;

      case 'change':
        this.errorObj = HelperService.checkValidation(this.addPayMethodForm);
        delete this.errorObj['name'];
        if (HelperService.isObjectEmpty(this.errorObj)) {
          this.configService.updateTreshold({ id: this.treshold.id , value: this.addPayMethodForm.value.treshold_name });
          this.treshPopup = false;
          }
     
        break;
      
      // case 'is_default': 
      //   this.addPayMethodForm.patchValue({ [options.name]: options.checked });
      //   break;

      default :
        this.addPayMethodForm.patchValue({ [options.name]: options.value });
        break;
    }
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
