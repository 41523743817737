import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '@shared/shared.module';

import { MessagesPage } from './messages-page.component';
import { MessagesListPage } from './messages-list-page/messages-list-page.component';
import { MessagesReplayPage } from './messages-replay-page/messages-replay-page.component';
import { MessagesPageRoutingModule } from './messages-page-routing.module';
import { MessageAddPopup } from './message-add-popup/message-add-popup.component';
import { MessageViewPopup } from './message-view-popup/message-view-popup.component'


@NgModule({
  declarations: [
    MessagesPage,
    MessagesListPage,
    MessagesReplayPage,
    MessageAddPopup,
    MessageViewPopup
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MessagesPageRoutingModule,
    NgSelectModule
  ]
})
export class MessagesPageModule { }
