import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import {
  fetchCampaign,
  fetchCampaignSuccess,
  fetchCampaignFailure,
  getCampaign,
  getCampaignSuccess,
  getCampaignFailure,
  deleteCampaign,
  deleteCampaignSuccess,
  deleteCampaignFailure,
  addCampaign,
  addCampaignSuccess,
  addCampaignFailure,
  updateCampaign,
  updateCampaignSuccess,
  updateCampaignFailure,
  fetchCampaignsByAffiliate,
  fetchCampaignsByAffiliateSuccess,
  fetchCampaignsByAffiliateFailure,
  unarchiveCampaign,
  unarchiveCampaignSuccess,
  unarchiveCampaignFailure
} from "./campaign.actions";
import { EMPTY, of } from "rxjs";
import { map, mergeMap, catchError, tap } from "rxjs/operators";
import { CampaignService } from "@services/campaign/campaign.service";
import { SharedService } from '@services/shared/shared.service';

@Injectable()
export class CampaignEffects {
  loadCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCampaign),
      mergeMap(action =>
        this.campaignService.getCampaigns(action.page, action.filterOptions).pipe(
          map(res => {
            return fetchCampaignSuccess({ campaign: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchCampaignFailure({ error }));
          })
        )
      )
    )
  );

  loadCampaignsByAffiliate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCampaignsByAffiliate),
      mergeMap(action =>
        this.campaignService.getCampaignsByAffiliate(action.page, action.userID).pipe(
          map(res => {
            return fetchCampaignsByAffiliateSuccess({ campaigns: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchCampaignsByAffiliateFailure({ error }));
          })
        )
      )
    )
  );

  getCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCampaign),
      mergeMap(action => 
        this.campaignService.getCampaign(action.id).pipe(
          map(res => {
            return getCampaignSuccess({ campaign: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(getCampaignFailure({ error }));
          })
        )
      )
    )
  );

  deleteCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCampaign),
      mergeMap(action =>
        this.campaignService.removeCampaign(action.id).pipe(
          map(() => {
            return deleteCampaignSuccess({ id: action.id });
          }),
          tap(res => {
            this.campaignService.fetchCampaigns(1, {});
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(deleteCampaignFailure({ error }));
          })
        )
      )
    )
  );

  addCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCampaign),
      mergeMap(action =>
        this.campaignService.insertCampaign(action.campaign).pipe(
          map(campaign => {
            return addCampaignSuccess({ campaign });
          }),
          tap(res => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
            setTimeout(() => {
              this.router.navigate([`/campaigns/campaign/${res.campaign["id"]}`]);
            }, 2000);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(addCampaignFailure({ error }));
          })
        )
      )
    )
  );

  updateCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCampaign),
      mergeMap(action =>
        this.campaignService.renewCampaign(action.campaign).pipe(
          map(campaign => {
            return updateCampaignSuccess({ campaign });
          }),
          tap(res => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(updateCampaignFailure({ error }));
          })
        )
      )
    )
  );

  unarchiveCampaign$ = createEffect(() =>
  this.actions$.pipe(
    ofType(unarchiveCampaign),
    mergeMap(action =>
      this.campaignService.unarchivedCampaign(action.id).pipe(
        map(() => {
          return unarchiveCampaignSuccess({ id: action.id , archivedCampaign: action.archivedCampaign});
        }),
        tap(res => {
          this.campaignService.fetchCampaigns(1, res.archivedCampaign);
        }),
        catchError(error => {
          this.sharedService.onLoaderToggle(false);
          return of(unarchiveCampaignFailure({ error }));
        })
      )
    )
  )
);

  constructor(
    private actions$: Actions,
    private campaignService: CampaignService,
    private sharedService: SharedService,
    private router: Router
  ) {}
}
