import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { PageService, UserService, HelperService } from '@services/index';

import { UserState } from '@models/index';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPage implements OnInit, OnDestroy {

  loginForm: FormGroup;

  resetPasswordPopup: boolean = false;
  
  tabs: any = {
    login: true,
    registartion: false
  };

  icons: any = {
    email: '/assets/images/icons/email-grey.svg',
    password: '/assets/images/icons/password-grey.svg'
  };

  error: any = {
    errorMessage: '',
    email: '',
    password: ''
  };

  user$: Observable<UserState>;
  unsubscribe$ = new Subject<void>();

  constructor(private userService: UserService,
              private pageService: PageService,
              private formBuilder: FormBuilder) { 
    //set title
    pageService.changePageInfo({ breadcrum: ['Log in']});

    this.user$ = userService.getUserSelector();

    this.loginForm = this.formBuilder.group({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      remember: new FormControl(false, [Validators.required])
    });
  }

  ngOnInit() {
    this.loginForm.valueChanges.subscribe(option => {
      if (option.email && !this.error.email) {
        this.changeIcons('email', 'green');
      }

      if (option.password && !this.error.password) {
        this.changeIcons('password', 'green');
      }
    })
  }

  changeIcons(option: string, color: string): void {
    this.icons[option] = `/assets/images/icons/${option}-${color}.svg`;
  }

  onChangeTab(tabName: string): void {
    this.userService.clearUSerState();
    // reset tabs
    Object.keys(this.tabs).forEach(key => this.tabs[key] = false);

    this.tabs[tabName] = true;
  }

  getUserState(): void {
    this.user$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(userState => {
        if (userState.error) {
          this.error.errorMessage = 'Email or Password is invalid!'
        } else {
          this.error.errorMessage = '';
        }
      });
  }

  onLogin(): void {
    this.userService.clearUSerState();
    this.error = HelperService.checkValidation(this.loginForm);
    if (this.error.email) {
      this.changeIcons('email', 'red');
    }

    if (this.error.password) {
      this.changeIcons('password', 'red');
    }

    if (this.loginForm.valid) {
      this.userService.dispatchLogin(this.loginForm.value.email, this.loginForm.value.password);
      this.getUserState();
    }
  }

  onChangeFormValue(e: any, name: string) {
    this.loginForm.patchValue({ [name]: e.target.value.trim() });
  }

  onForgotPassword(value) {
    this.resetPasswordPopup = value;
    return false;
  }

  forgotPopupToggle(value) {
    this.resetPasswordPopup = value;
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
