import { createAction , props } from '@ngrx/store';

export const changePageInfo = createAction(
  '[Page] Change Page Info',
  props<{page}>()
);

export const changeDateOption = createAction(
  '[Page] Change Date Option',
  props<{option}>()
);

export const resetOption = createAction(
  '[Page] Delete Date Option',
  props<{optionName}>()
);

export const fetchResources = createAction(
  '[Page] Fetch Resources'
);

export const fetchResourcesSuccess = createAction(
  '[Page] Fetch Resources Success',
  props<{resources}>()
);

export const fetchResourcesFaild = createAction(
  '[Page] Fetch Resources Faild',
  props<{error}>()
);

export const fetchRoles = createAction(
  '[Page] Fetch Roles'
);

export const fetchRolesSuccess = createAction(
  '[Page] Fetch Roles Success',
  props<{roles}>()
);

export const fetchRolesFaild = createAction(
  '[Page] Fetch Roles Faild',
  props<{error}>()
);

export const fetchRoleByToken = createAction(
  '[Page] Fetch Role By Token'
);

export const fetchRoleByTokenSuccess = createAction(
  '[Page] Fetch Roles By Token Success',
  props<{role}>()
);

export const fetchRoleByTokenFaild = createAction(
  '[Page] Fetch Roles By Token Faild',
  props<{error}>()
);

export const fetchRole = createAction(
  '[Page] Fetch Role',
  props<{id}>()
);

export const fetchRoleSuccess = createAction(
  '[Page] Fetch Role Success',
  props<{role}>()
);

export const fetchRoleFaild = createAction(
  '[Page] Fetch Role Faild',
  props<{error}>()
);

export const updatePermissions = createAction(
  '[Page] Update Permission',
  props<{permissions}>()
);

export const updatePermissionsSuccess = createAction(
  '[Page] Update Permission Success',
  props<{role}>()
);

export const updatePermissionsFaild = createAction(
  '[Page] Update Permission Faild',
  props<{error}>()
);

export const fetchUserStatus = createAction(
  '[Page] Fetch User Status',
  props<{filterOptions}>()
);

export const fetchUserStatusSuccess = createAction(
  '[Page] Fetch User Status Success',
  props<{userStatus}>()
);

export const fetchUserStatusFaild = createAction(
  '[Page] Fetch User Status Faild',
  props<{error}>()
);

export const fetchUserType = createAction(
  '[Page] Fetch User Type',
  props<{id}>()
);

export const fetchUserTypeSuccess = createAction(
  '[Page] Fetch User Type Success',
  props<{userType}>()
);

export const fetchUserTypeFaild = createAction(
  '[Page] Fetch User Type Faild',
  props<{error}>()
);

export const fetchUserTypes = createAction(
  '[Page] Fetch User Types'
);

export const fetchUserTypesSuccess = createAction(
  '[Page] Fetch User Types Success',
  props<{userTypes}>()
);

export const fetchUserTypesFaild = createAction(
  '[Page] Fetch User Types Faild',
  props<{error}>()
);

export const addUserType = createAction(
  "[Page] Add User Type",
  props<{ userType }>()
);

export const addUserTypeSuccess = createAction(
  "[Page] Add User Type Success",
  props<{ userType }>()
);

export const addUserTypeFailure = createAction(
  "[Page] Add User Type Failure",
  props<{ error }>()
);

export const updateUserType = createAction(
  "[Page] Update User Type",
  props<{ userType }>()
);

export const updateUserTypeSuccess = createAction(
  "[Page] Update User Type Success",
  props<{ userType }>()
);

export const updateUserTypeFailure = createAction(
  "[Page] Update User Type Failure",
  props<{ error }>()
);

export const deleteUserType = createAction(
  "[Page] Delete User Type",
  props<{ id }>()
);

export const deleteUserTypeSuccess = createAction(
  "[Page] Delete User Type Success",
  props<{ id }>()
);

export const deleteUserTypeFailure = createAction(
  "[Page] Delete User Type Failure",
  props<{ error }>()
);

export const getEnums = createAction(
  "[Page] Get Enums",
  props<{ types }>()
);

export const getEnumsSuccess = createAction(
  "[Page] Get Enums Success",
  props<{ enums }>()
);

export const getEnumsFailure = createAction(
  "[Page] Get Enums Failure",
  props<{ error }>()
);

export const getHelp = createAction(
  '[Page] Get Help',
  props<{ key: string }>()
);

export const getHelpSuccess = createAction(
  '[Page] Get Help Success',
  props<{ help: any }>()
);

export const getHelpFailure = createAction(
  '[Page] Get Help Failure',
  props<{ error: any }>()
);


