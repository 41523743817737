import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Store } from "@ngrx/store";
import {
  fetchPayment,
  addPayment,
  getPayment
} from "../../store/payment/payment.actions";
import { getPaymentSelector } from '@store/payment/payment.selectors';

import { HelperService } from '../helper/helper.service';
import { SharedService } from '@services/shared/shared.service';
import { IState } from '@models/index';

@Injectable({
  providedIn: "root"
})
export class PaymentService {
  backendUrl = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private store: Store<{ payment: IState }>
  ) {}

  getPaymentsSelector(): Observable<IState> {
    return this.store.select(getPaymentSelector); 
  }

  fetchPayments(page, filterOptions): void {
    this.store.dispatch(fetchPayment({ page, filterOptions }));
  }

  fetchPayment(id: string): void {
    this.store.dispatch(getPayment({ id }))
  }

  addPayment(payment: any): void {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(addPayment({ payment }));
  }

  getPayments(page: number, filterOptions: any = { }): Observable<any> {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(`${this.backendUrl}/payments?${queryString}`);
  }

  getPayment(id: string): Observable<any> {
    return this.http.get(`${this.backendUrl}/payments/payment/${id}`);
  }

  insertPayment(payment): Observable<any> {
    return this.http.post(`${this.backendUrl}/payments/payout`, payment);
  }
}
