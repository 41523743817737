import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Store } from "@ngrx/store";
import { environment } from "../../../environments/environment";
import { HelperService } from '../helper/helper.service';
import {
  fetchGeneratedCommissions,
  getGeneratedCommission,
  clearSelectedGenCommision,
  updateGeneratedCommissions
} from "../../store/generated-commission/generated-commission.action";
import { 
  getGeneratedCommissionSelector,
  getGeneratedCommissionsSelector } from '@store/generated-commission/generated-commission.selectors';
import { IState } from '@models/index';

@Injectable({
  providedIn: "root"
})
export class GeneratedCommissionService {
  backendUrl = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private store: Store<{ generatedCommission: IState }>
  ) {}

  getGeneratedCommissionsSelector(): Observable<IState> {
    return this.store.select(getGeneratedCommissionsSelector);
  }

  getGeneratedCommissionSelector(): Observable<any> {
    return this.store.select(getGeneratedCommissionSelector);
  }

  fetchGeneratedCommissions(page: number, filterOptions: any): void {
    this.store.dispatch(fetchGeneratedCommissions({ page, filterOptions }));
  }

  fetchGeneratedCommission(id: string): void {
    this.store.dispatch(getGeneratedCommission({ id }))
  }

  updateGeneratedCommissions(generatedCommissions: any[]): void {
    this.store.dispatch(updateGeneratedCommissions({ generatedCommissions }));
  }

  clearSelectedGenCommision(): void {
    this.store.dispatch(clearSelectedGenCommision());
  }

  getGeneratedCommissions(page: number, filterOptions: any = { }) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);

    return this.http.get(`${this.backendUrl}/commissions?${queryString}`);
  }

  getGeneratedCommission(id: string) {
    return this.http.get(`${this.backendUrl}/commissions/transaction/${id}`);
  }
}
