import { createAction, props } from "@ngrx/store";

export const fetchCampaign = createAction(
  "[Campaign] Fetch",
  props<{ page, filterOptions }>()
);

export const fetchCampaignSuccess = createAction(
  "[Campaign] Fetch Success",
  props<{ campaign }>()
);

export const fetchCampaignFailure = createAction(
  "[Campaign] Fetch Faild",
  props<{ error }>()
);

export const getCampaign = createAction(
  "[Campaign] Get",
  props<{ id }>()
);

export const getCampaignSuccess = createAction(
  "[Campaign] Get Success",
  props<{ campaign }>()
);

export const getCampaignFailure = createAction(
  "[Campaign] Get Failure",
  props<{ error }>()
);

export const fetchCampaignsByAffiliate = createAction(
  "[Campaign] Fetch By Affiliate",
  props<{ page, userID }>()
);

export const fetchCampaignsByAffiliateSuccess = createAction(
  "[Campaign] Fetch By Affiliate Success",
  props<{ campaigns }>()
);

export const fetchCampaignsByAffiliateFailure = createAction(
  "[Campaign] Fetch By Affiliate Faild",
  props<{ error }>()
);

export const deleteCampaign = createAction(
  "[Campaign] Delete",
  props<{ id }>()
);

export const deleteCampaignSuccess = createAction(
  "[Campaign] Delete Success",
  props<{ id }>()
);

export const deleteCampaignFailure = createAction(
  "[Campaign] Delete Failure",
  props<{ error }>()
);

export const addCampaign = createAction(
  "[Campaign] Add",
  props<{ campaign }>()
);

export const addCampaignSuccess = createAction(
  "[Campaign] Add Success",
  props<{ campaign }>()
);

export const addCampaignFailure = createAction(
  "[Campaign] Add Failure",
  props<{ error }>()
);

export const updateCampaign = createAction(
  "[Campaign] Update",
  props<{ campaign }>()
);

export const updateCampaignSuccess = createAction(
  "[Campaign] Update Success",
  props<{ campaign }>()
);

export const updateCampaignFailure = createAction(
  "[Campaign] Update Failure",
  props<{ error }>()
);

export const unarchiveCampaign = createAction(
  "[Campaign] Unarchive Campaign",
  props<{ id, archivedCampaign}>()
);

export const unarchiveCampaignSuccess = createAction(
  "[Campaign] Unarchive Campaign Success",
  props<{ id, archivedCampaign }>()
);

export const unarchiveCampaignFailure = createAction(
  "[Campaign] Unarchive Campaign Failure",
  props<{ error }>()
);
