import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import {
    fetchMessages,
    fetchMessagesSuccess,
    fetchMessagesFailure,
    getMessage,
    getMessageSuccess,
    getMessageFailure,
    addMessage,
    addMessageSuccess,
    addMessageFailure,
    deleteMessage,
    deleteMessageSuccess,
    deleteMessageFailure
} from "./message.actions";
import { of } from "rxjs";
import { map, mergeMap, catchError, tap } from "rxjs/operators";
import { MessageService } from "@services/message/message.service";
import { SharedService } from '@services/shared/shared.service';

@Injectable()
export class MessageEffects {

  loadMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchMessages),
      mergeMap(action =>
        this.messageService.getMessages(action.page, action.filterOptions, action.user_id).pipe(
          map(res => {
            return fetchMessagesSuccess({ message: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchMessagesFailure({ error }));
          })
        )
      )
    )
  );

  getMessage$ = createEffect(() =>
  this.actions$.pipe(
    ofType(getMessage),
    mergeMap(action => 
      this.messageService.getMessage(action.id).pipe(
        map(res => {
          return getMessageSuccess({ message: res });
        }),
        catchError(error => {
          this.sharedService.onLoaderToggle(false);
          return of(getMessageFailure({ error }));
        })
      )
    )
  )
);

  addMessage$ = createEffect(() =>
  this.actions$.pipe(
    ofType(addMessage),
    mergeMap(action =>
      this.messageService.insertMessage(action.message).pipe(
        map( message => {
          return addMessageSuccess({ message });
        }),
        tap(res => {
          // this.messageService.fetchMessages(1, {});
          this.sharedService.onLoaderToggle(false);
          this.sharedService.onSaveToggle(true);
        }),
        catchError(error => {
          this.sharedService.onLoaderToggle(false);
          return of(addMessageFailure({ error }));
        })
      )
    )
  )
);

deleteMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMessage),
      mergeMap(action =>
        this.messageService.removeMessage(action.id).pipe(
          map(() => {
            return deleteMessageSuccess({ id: action.id });
          }),
          tap(res => {
            this.messageService.fetchMessages(1, {}, {});
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(deleteMessageFailure({ error }));
          })
        )
      )
    )
  );


  constructor(
    private actions$: Actions,
    private messageService: MessageService,
    private sharedService: SharedService,
    private router: Router
  ) {}

}
