import {Injectable, OnDestroy} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { environment } from "../../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {DashboardGroupDetails, IDashboardState, TopBannersDataState} from "@models/iDashboardState";
import {
  dashboardCommissionsSelector, dashboardGroupDetailsSelector,
  dashboardsSelector,
  dashboardTopAffiliatesSelector, dashboardTopBannersSelector,
} from "@store/dashboard/dashboard.selectors";
import {
  fetchCommissions,
  fetchGroupDetails,
  fetchTopAffiliate,
  fetchTopBanners
} from "@store/dashboard/dashboard.actions";
import {StoreModel} from "@interfaces/IStoreModel";

@Injectable({
  providedIn: "root"
})
export class DashboardService {
  backendUrl = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private store: Store<any>
  ) {}

  selectDashboardStore(): Observable<IDashboardState> {
    return this.store.select(dashboardsSelector);
  }

  selectTopAffiliates(): Observable<any> {
    return this.store.select(dashboardTopAffiliatesSelector);
  }

  fetchTopAffiliates(field?, params: any = {}): void {
    this.store.dispatch(fetchTopAffiliate({payload: {field, params}}));
  }

  getTopAffiliates(params: any = {}) {
    return this.http.get(`${this.backendUrl}/reports/top-affiliates`, {params});
  }

  selectTopBanners(): Observable<StoreModel<TopBannersDataState>> {
    return this.store.select(dashboardTopBannersSelector);
  }

  fetchTopBanners(field?, params: any = {}): void {
    this.store.dispatch(fetchTopBanners({payload: {field, params}}));
  }

  getTopBanners(params: any = {}) {
    return this.http.get(`${this.backendUrl}/reports/top-banners`, {params});
  }

  selectGroupDetails(): Observable<StoreModel<DashboardGroupDetails>> {
    return this.store.select(dashboardGroupDetailsSelector);
  }

  fetchGroupDetails(): void {
    this.store.dispatch(fetchGroupDetails());
  }

  getGroupDetails() {
    return this.http.get(`${this.backendUrl}/reports/group`);
  }

  selectCommissions(): Observable<any> {
    return this.store.select(dashboardCommissionsSelector);
  }

  fetchCommissions(params?: {}): void {
    this.store.dispatch(fetchCommissions({payload: params}));
  }

  getCommissions(params?: {}) {
    return this.http.get(`${this.backendUrl}/reports/commissions`, {params});
  }
}

