import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserService } from '@services/index';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    currentUser$: Observable<string>;

    constructor(private userService: UserService) { 
        this.currentUser$ = userService.getUserAuthToken();
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        this.currentUser$.subscribe(token => {
            if (token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`,
                        'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                        'Pragma': 'no-cache',
                        'Expires': '0'
                    }
                });
            }
        });
        
        return next.handle(request);
    }
}