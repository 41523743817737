import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { IFormOptions } from '@interfaces/index';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserService, HelperService } from '@services/index';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { UserState } from '@models/index';


@Component({
  selector: 'aff-user-forgot-password',
  templateUrl: './user-forgot-password.component.html',
  styleUrls: ['./user-forgot-password.component.scss']
})
export class UserForgotPasswordPage implements OnInit, OnDestroy {

  forgotPassForm: FormGroup;
  disableBtn: boolean = false;

  @Output() closePopup = new EventEmitter<any>();

  success:boolean = false;

  error: any = {
    forgot_email: '',
    errorMessage: ''
  };

  icons: any = {
    email: '/assets/images/icons/email-grey.svg'
  };

  user$: Observable<UserState>;
  unsubscribe$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder) {
    this.forgotPassForm = this.formBuilder.group({
      forgot_email: new FormControl(null, [Validators.required, Validators.email])
    });

    this.user$ = userService.getUserSelector();
  }

  ngOnInit() {
    this.forgotPassForm.valueChanges.subscribe(option => {
      if (option.forgot_email && !this.error.forgot_email) {
        this.changeIcons('email', 'blue');
      }
    })
  }

  changeIcons(option: string, color: string): void {
    this.icons[option] = `/assets/images/icons/${option}-${color}.svg`;
  }

  closePopupBox(value: boolean, e): void {
    e.stopPropagation(); 
    if (e.target['className'] === 'popup_container' || e.target['className'] === 'close') {
      this.closePopup.emit(value);
    }
  }

  onsendVerification() {
    this.error = HelperService.checkValidation(this.forgotPassForm);
    if (this.error.forgot_email) {
      this.changeIcons('email', 'red');
    }
    if (this.forgotPassForm.valid) {
      this.userService.dispatchSendVerification(this.forgotPassForm.value.forgot_email);
      this.getUserState();
    }
  }

  getUserState(): void {
    this.user$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(userState => {
        console.log(userState);
        
        if (userState.error) {
          console.log(userState.error);
          
          this.success = false;
          switch (userState.error) {
            case "invalid request":
              this.error.errorMessage = "The email verification has already sent, try again in 3 minutes later!";
              this.disableBtn = true;
              break;
            default:
              this.error.errorMessage = "This Email Doesn't exist!";
              this.disableBtn = false;
          }
        } else {
            this.error.errorMessage='';
            this.success = true;
            this.disableBtn = true;
            setTimeout(() => {
              this.disableBtn = false;
             }, 180000);
        }
      });

  }

  onChangeFormValue(e: any, name: string) {
    this.forgotPassForm.patchValue({ [name]: e.target.value.trim() });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
