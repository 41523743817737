import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

import {
  UserService,
  PageService,
  HelperService,
  AffiliateService,
  CampaignService,
  BannerService,
  GeneratedCommissionService } from '@services/index';

import {
  GeneratedCommissionComType,
  GeneratedCommissionStatus,
  StatusConditions,
  GeneratedCommissionReturnSales} from '@enums/index';

import { IModalvalue } from '@shared-models/index';
import { IState } from '@models/index';
import { IMyDateModel } from 'mydatepicker';
import { IFormOptions } from '@interfaces/index';

@Component({
  selector: 'aff-commissions-history-filter',
  templateUrl: './commissions-history-filter.component.html',
  styleUrls: ['./commissions-history-filter.component.scss']
})
export class CommissionsHistoryFilterPage implements OnInit, OnDestroy {
  @Output() selectionOption = new EventEmitter<boolean>();
  filters$: Observable<any>;
  selectedFilter$: Observable<any>;
  filterNamePopup: boolean = false;

  savedFilterList: any = {};
  filterSelectedValue: string | number = '';
  filterSelectedId: number;
  isFilterEmpty: boolean = true;

  affiliateManagers$: Observable<IState>;
  affiliates$: Observable<IState>;
  campaigns$: Observable<IState>;
  banners$: Observable<IState>;
  affTypes$: Observable<any[]>;

  selectedValues: any = {
    affTypes: [],
    affiliates: [],
    campaigns: [],
    banners: []
  };

  lists: any = {
    affiliates: [],
    campaigns: [],
    banners: []
  };

  searchValues: any = {
    affiliates: new FormControl(),
    campaigns: new FormControl(),
    banners: new FormControl(),
    keyword: new FormControl()
  };

  filterOptions: any = {
    keyword: '',
    returnSale: GeneratedCommissionReturnSales.Include,
    status: 'approved,rejected',
    statusOption: 'all',
    comType: [],
    affStatus: [],
    affTypes: [],
    managers: [],
    affiliates: [],
    campaigns: [],
    banners: [],
    created_from: '',
    created_to: '',
    action_created_from: '',
    action_created_to: ''
  }

  datePickerOptions$: Observable<any>;
  dateOption: any = {
    created_from: '',
    created_to: '',
    action_created_from: '',
    action_created_to: ''
  };

  showFiterComponent: boolean = false;

  //enums
  returnSale = GeneratedCommissionReturnSales;
  status = GeneratedCommissionStatus;
  comType = GeneratedCommissionComType;
  affStatus = StatusConditions;

  private unsubscribe$ = new Subject<void>();

  constructor(private pageService: PageService,
              private userService: UserService,
              private generatedCommissionService: GeneratedCommissionService,
              private bannerService: BannerService,
              private campaignService: CampaignService,
              private affiliateService: AffiliateService) {
    this.filters$ = this.userService.filtersSelector();
    this.selectedFilter$ = this.userService.filterSelector();
    // get datepicker options
    this.datePickerOptions$ = pageService.getDatePickerOptionsSelector();
    // get affiliates
    this.affiliates$ = this.affiliateService.getAffiliatesSelector();
    this.affiliateService.fetchFilteredAffiliates(1, {});
    // get campaigns
    this.campaigns$ = this.campaignService.getCampaignsSelector();
    this.campaignService.fetchCampaigns(1, {});
    // get promotions
    this.banners$ = this.bannerService.getBannersSelector();
    this.bannerService.fetchBanners(1, {});
    // get aff types
    this.affTypes$ = this.pageService.getUserTypeListSelector();
    this.pageService.fetchUserTypes();
  }

  ngOnInit() {
    // listen value change of keyword
    this.searchValues.keyword.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
          debounceTime(500),
          distinctUntilChanged()
      )
      .subscribe(newValue => {
        this.filterOptions['keyword'] = newValue;
        this.generatedCommissionService.fetchGeneratedCommissions(1, this.filterOptions);
      });

    // get filters
    this.userService.fetchFilters('commissions-history');
    this.filters$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      if (state.isLoaded && state.data.length) {
        this.savedFilterList = {};
        state.data.forEach(filter => {
          this.savedFilterList = {
            ...this.savedFilterList,
            [filter.id] : filter.name
          }
        });
      }
    });

    // Get selected filter
    this.selectedFilter$
       .pipe(takeUntil(this.unsubscribe$))
       .subscribe(filter => {
         if (filter.type !== 'generated-commission') return;
         const filterOpt = filter.filter_fields;
         if (filterOpt) {
            this.onClearFilter();
            HelperService.fillFilterOption(filterOpt, this.filterOptions);
            this.filterSelectedId = filter.id;

            Object.keys(this.dateOption).forEach(key => {
              this.dateOption[key] = filterOpt.find(opt => opt.key === key)
                                   ? { formatted: filterOpt.find(opt => opt.key === key)['value'] }
                                   : ''
            });
            this.isFilterEmpty = this.checkEmptyFilter(this.filterOptions);
            this.generatedCommissionService.fetchGeneratedCommissions(1, this.filterOptions);
            this.showFiterComponent = true;
         }
       });

    // affiliates search
    this.searchValues.affiliates.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.affiliateService.fetchFilteredAffiliates(1, { keyword }));

    // get affiliate managers
    this.getAffiliates();

    // campaign search
    this.searchValues.campaigns.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.campaignService.fetchCampaigns(1, { keyword }));

    // get campaigns managers
    this.getCampaigns();

    // promotional tool search
    this.searchValues.banners.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.bannerService.fetchBanners(1, { keyword }));

    // get promotional tool
    this.getBanners();
  }

  getAffiliates(): void {
    this.affiliates$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.lists.affiliates = state.filteredDate;
        }
    });
  }

  getCampaigns(): void {
    this.campaigns$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.lists.campaigns = state.data;
        }
      });
  }

  getBanners(): void {
    this.banners$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.lists.banners = state.data;
        }
    });
  }

  getSelectedFilter(id): void {
    this.filterSelectedValue = this.savedFilterList[id];
    this.userService.fetchFilter(id);
  }

  onDeleteFilter(id): void {
    this.userService.deleteFilter(id);
  }

  setFilterNamePopup(option: IModalvalue): void {
    if (option.confirm) {
      if (!this.userService.checkFilterName(option.inputValue, this.savedFilterList, this.filterSelectedId)) return;
      // delete keyword property
      delete this.filterOptions.keyword;

      this.filterSelectedId
        ? this.userService.updateFilter({
            id: this.filterSelectedId,
            name: option.inputValue,
            type: 'commissions-history',
            additional_fields: this.filterOptions
          })
        :  this.userService.addFilter({
            name: option.inputValue,
            type: 'commissions-history',
            additional_fields: this.filterOptions
          });
    }
    this.filterNamePopup = false;
  }

  onCalendarToggle(e: Event, name: string): void {
    switch (name) {
      case 'created_from':
        this.pageService.disableDate({
          name,
          date: this.filterOptions.created_to
        });
        break;

      case 'created_to':
        this.pageService.disableDate({
          name,
          date: this.filterOptions.created_from
        });
        break;

      case 'action_created_from':
        this.pageService.disableDate({
          name,
          date: this.filterOptions.action_created_to
        });
        break;

      default:
        this.pageService.disableDate({
          name,
          date: this.filterOptions.action_created_from
        });
        break;
    }
  }

  onDateChanged(event: IMyDateModel, name: string): void {
    this.filterOptions[name] = event.formatted;
    this.isFilterEmpty = this.checkEmptyFilter(this.filterOptions);
    this.generatedCommissionService.fetchGeneratedCommissions(1, this.filterOptions);
  }

  getSearchValue(options, name: string): void {
    // search value in managers
    this.searchValues[name].patchValue(options.term);
  }

  onSelectedValue(values: any[], name: string): void {
    let selectedValues: number[] = [];
    for (const value of values) {
      selectedValues.push(value.id);
    }
    this.filterOptions[name] = selectedValues;
    this.isFilterEmpty = this.checkEmptyFilter(this.filterOptions);
    this.generatedCommissionService.fetchGeneratedCommissions(1, this.filterOptions);
  }

  onClearFilter(): void {
    this.filterOptions = {
      returnSale: GeneratedCommissionReturnSales.Include,
      status: 'approved,rejected',
      statusOption: 'all',
      comType: [],
      affStatus: [],
      affTypes: [],
      managers: [],
      affiliates: [],
      campaigns: [],
      banners: [],
      created_from: '',
      created_to: '',
      action_created_from: '',
      action_created_to: ''
    }

    this.dateOption = {
      created_from: '',
      created_to: '',
      action_created_from: '',
      action_created_to: ''
    };

    this.selectedValues = {
      affTypes: [],
      affiliates: [],
      campaigns: [],
      banners: []
    };
  }

  onChangeFormValue(options: IFormOptions): void {
    switch(options.name) {
      case 'clearFilter' :
        this.onClearFilter();
        // reset selected value
        this.filterSelectedValue = '';
        this.isFilterEmpty = this.checkEmptyFilter(this.filterOptions);
        this.generatedCommissionService.fetchGeneratedCommissions(1, this.filterOptions);
        break;

      case 'saveFilter' :
        if (this.isFilterEmpty) return;
        this.filterNamePopup = true;
        break;

      case 'returnSale' :
        this.filterOptions[options.name] = options.value;
        this.isFilterEmpty = this.checkEmptyFilter(this.filterOptions);
        this.generatedCommissionService.fetchGeneratedCommissions(1, this.filterOptions);
        break;
      case 'affStatus' :
      case 'comType' :
        if (options.checked) {
          this.filterOptions[options.name].push(options.value);
        } else {
          // find unchecked value and remove from status array
          const findIndex = this.filterOptions[options.name].findIndex(val => val === options.value);
          this.filterOptions[options.name].splice(findIndex, 1);
        }
        this.isFilterEmpty = this.checkEmptyFilter(this.filterOptions);
        this.generatedCommissionService.fetchGeneratedCommissions(1, this.filterOptions);
        break;

      case 'statusOption' :
        options.value === 'all' ? this.filterOptions.status = 'approved,rejected'
                                : this.filterOptions.status = options.value;
        this.isFilterEmpty = this.checkEmptyFilter(this.filterOptions);
        this.generatedCommissionService.fetchGeneratedCommissions(1, this.filterOptions);
        break;

      case 'keyword' :
        this.searchValues.keyword.patchValue(options.value);
        break;

      default :
        this.filterOptions[options.name] = options.value;
        this.isFilterEmpty = this.checkEmptyFilter(this.filterOptions);
        this.generatedCommissionService.fetchGeneratedCommissions(1, this.filterOptions);
    }
  }

  checkEmptyFilter(filters) {
    // _todo: fix check
    const filterObj = JSON.parse(JSON.stringify(filters));

    if (filters.status.split(',').length > 1) {
      filterObj.status = '';
    }

    if (filters.returnSale === GeneratedCommissionReturnSales.Include) {
      filterObj.returnSale = '';
    }

    const {statusOption, ...newFilter} = filterObj;

    return HelperService.checkEmptyFilter(newFilter);
  }

  ngOnDestroy(): void {
    this.userService.clearSelectedFilter();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
