import { createAction, props } from "@ngrx/store";

export const fetchAffiliate = createAction(
  "[Affiliate] Fetch",
  props<{ page, filterOptions, reducerOptions }>()
);

export const fetchAffiliateSuccess = createAction(
  "[Affiliate] Fetch Success",
  props<{ affiliate }>()
);

export const fetchAffiliateFailure = createAction(
  "[Affiliate] Fetch Faild",
  props<{ error }>()
);

export const fetchFilteredAffiliates = createAction(
  "[Affiliate] Fetch Filtered",
  props<{ page, filterOptions }>()
);

export const fetchFilteredAffiliatesSuccess = createAction(
  "[Affiliate] Fetch Filtered Success",
  props<{ affiliates }>()
);

export const fetchFilteredAffiliatesFailure = createAction(
  "[Affiliate] Fetch Filtered Faild",
  props<{ error }>()
);

export const getAffiliate = createAction(
  "[Affiliate] Get",
  props<{ id }>()
);

export const getAffiliateSuccess = createAction(
  "[Affiliate] Get Success",
  props<{ affiliate }>()
);

export const getAffiliateFailure = createAction(
  "[Affiliate] Get Failure",
  props<{ error }>()
);

export const fetchAffiliateByAffManager = createAction(
  "[Affiliate] Fetch Affiliates By Aff Manager",
  props<{ affManagerId, page, filterOptions }>()
);

export const fetchAffiliateByAffManagerSuccess = createAction(
  "[Affiliate] Fetch Affiliates By Aff Manager Success",
  props<{ affiliates }>()
);

export const fetchAffiliateByAffManagerFailure = createAction(
  "[Affiliate] Fetch Affiliates By Aff Manager Failure",
  props<{ error }>()
);

export const deleteAffiliate = createAction(
  "[Affiliate] Delete",
  props<{ id }>()
);

export const deleteAffiliateSuccess = createAction(
  "[Affiliate] Delete Success",
  props<{ id }>()
);

export const deleteAffiliateFailure = createAction(
  "[Affiliate] Delete Failure",
  props<{ error }>()
);

export const addAffiliate = createAction(
  "[Affiliate] Add",
  props<{ affiliate }>()
);

export const addAffiliateSuccess = createAction(
  "[Affiliate] Add Success",
  props<{ affiliate }>()
);

export const addAffiliateFailure = createAction(
  "[Affiliate] Add Failure",
  props<{ error }>()
);

export const updateAffiliate = createAction(
  "[Affiliate] Update",
  props<{ affiliate }>()
);

export const updateAffiliateSuccess = createAction(
  "[Affiliate] Update Success",
  props<{ affiliate }>()
);

export const updateAffiliateFailure = createAction(
  "[Affiliate] Update Failure",
  props<{ error }>()
);

export const fetchAffiliateDownlines = createAction(
  "[Affiliate] Fetch Downlines By Affiliate",
  props<{ page, affiliateID, filterOptions }>()
);

export const fetchAffiliateDownlinesSuccess = createAction(
  "[Affiliate] Fetch Downlines By Affiliate Success",
  props<{ downlines }>()
);

export const fetchAffiliateDownlinesFailure = createAction(
  "[Affiliate] Fetch Downlines By Affiliate Faild",
  props<{ error }>()
);

export const clearAffFilter = createAction(
  "[CLEAR] clearAffFilter",
  props<{ any? }>()
);
