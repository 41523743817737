import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PermissionGuard } from '@guards/index';
import { 
    AffiliateManagersListPage, AffiliateManagersAddPage } from './index';

const routes: Routes = [
    {
        path: '',
        canActivate: [PermissionGuard],
        component: AffiliateManagersListPage,
        data: {
            breadcrumb: 'Affiliate Managers'
        },
    },
    { 
        path: 'affiliate-manager', 
        // canActivate: [PermissionGuard],
        children: [
            { path: '', component: AffiliateManagersAddPage, data: { breadcrumb: 'Add Affiliate Manager' } },
            { path: ':id', component: AffiliateManagersAddPage, data: { breadcrumb: 'Edit Affiliate Manager' } }
        ] 
    }
];

@NgModule({
  imports: [
    RouterModule.forChild(
        routes
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AffiliateManagersPageRoutingModule {}
