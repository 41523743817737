import { Component, OnInit } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { IState } from '@models/index';
import { takeUntil } from 'rxjs/operators';
import { IPage } from '@interfaces/index';
import { PageService, PaymentService, HelperService } from '@services/index';

@Component({
  selector: 'aff-payout-history-page',
  templateUrl: './payout-history-page.component.html',
  styleUrls: ['./payout-history-page.component.scss']
})
export class PayoutHistoryPage implements OnInit {
  payments$: Observable<IState>;
  
  loadingIndicator: boolean = true;
  rows: any[] = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  approvePopup: boolean = false;
  selectedAffiliate: string = '';

  unsubscribe$ = new Subject<void>();

  constructor(
    private pageService: PageService,
    private paymentService: PaymentService
    ) { 
      pageService.changePageInfo({ breadcrum: ['Payout History']});

      this.payments$ = paymentService.getPaymentsSelector();
      paymentService.fetchPayments(1, {});
  }
  
  ngOnInit(): void {
    this.payments$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.rows = [];
          for (const payout of state.data) {
            const row = {
              payoutId: payout.payment_id,
              affiliateName: payout.profile_first_name + ' ' + payout.profile_last_name,
              email: payout.u_email,
              paidAmount: '$' + payout.payment_amount / 100, 
              payoutType: payout.t_resource_type,
              datePaid: HelperService.dateFormater(payout.payment_created_at),
              payoutMethod: payout.m_name,
              note_for_affiliate: payout.payment_note_for_affiliate,
              note_for_manager: payout.payment_note_for_manager,
              send_notification: payout.payment_send_notification,
              extra_bonus: payout.extra_bonus,
            };
            this.rows.push(row);
          }
        }
        this.rows = [...this.rows];
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
    });
  }
  
  setPage(e): void {
    this.paymentService.fetchPayments(e.offset + 1, {});
  }
  
  onView(row: any): void {
      this.selectedAffiliate = row;
      this.approvePopup = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}