export enum NotificationType {
  CAMPAIGN_INVITATION = 'campaign_invitation',
  MESSAGE = 'message',
  INVOLVEMENT_REQUEST= 'involvement_request',
  INVOLVEMENT_APPROVED = 'involvement_approved',
  INVOLVEMENT_DECLINED = 'involvement_declined',
  INVOLVEMENT_SUSPENDED = 'involvement_suspended',
  AFFILIATE_STATUS_APPROVED = 'affiliate_status_approved',
  AFFILIATE_STATUS_SUSPENDED = 'affiliate_status_suspended',
  AFFILIATE_STATUS_DECLINED = 'affiliate_status_declined',
  AFFILIATE_STATUS_FRAUD = 'affiliate_status_fraud',
  COMMISSION_GROUP_CHANGED = 'commission_group_changed',
  ADDED_DOWNLINE = 'added_downline',
}
