import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { Store } from "@ngrx/store";
import { getAffiliateManagerSelector } from "@store/index";
import { IState } from "@models/index";

import { AffiliateManagersService, HelperService, PageService } from '@services/index';

/**
 * Affiliate Managers Adding Page
 */
@Component({
  selector: 'aff-affiliate-managers-add-page',
  templateUrl: './affiliate-managers-add-page.component.html',
  styleUrls: ['./affiliate-managers-add-page.component.scss']
})
export class AffiliateManagersAddPage implements OnInit, OnDestroy {
  affManagerID: string = '';
  editMode: boolean = false;
  public tabName: string = 'addForm';

  affManager$: Observable<any>;
  private unsubscribe$ = new Subject<void>();

  constructor(private store: Store<{ affiliateManagers: IState }>,
              private pageService: PageService,
              private affiliateManagersService: AffiliateManagersService,
              private route: ActivatedRoute,) {
      //set breadcrum
      pageService.changePageInfo({ breadcrum: ['Affiliate Managers', 'Add New Manager']});
      // select affiliate manager
      this.affManager$ = this.affiliateManagersService.getAffiliateManagerSelector();
   }

  ngOnInit() {
    // get affiliate manager id
    this.affManagerID = this.route.snapshot.params.id;

    if (this.affManagerID) {
      this.affiliateManagersService.fetchAffiliateManager(this.affManagerID);
    }

    this.affManager$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(affManager => {
        
        // check mode
        if (HelperService.isObjectEmpty(affManager)) {
          return this.editMode = false;
        }

        this.editMode = true;
        //set breadcrum
  
        this.pageService.changePageInfo({ breadcrum: ['Affiliate Managers', affManager.email]});
      });
  }

  onChangeTab(tabName: string) {
    if (!this.editMode && tabName === 'assignedAffiliates') { return } 
    this.tabName = tabName;
  }

  ngOnDestroy(): void {
    this.affiliateManagersService.clearaffiliateManager();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
