import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { HelperService, PageService, ReportService } from "@services/index";
import { IState } from "@models/index";
import { IPage } from '@interfaces/index';

@Component({
  selector: 'aff-affiliate-activity',
  templateUrl: './affiliate-activity.component.html',
  styleUrls: ['./affiliate-activity.component.scss']
})
export class AffiliateActivityPage implements OnInit, OnDestroy {

  rows = [];
  loadingIndicator: boolean = true;
  selectedRows = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  affiliateActivity$: Observable<IState>;
  unsubscribe$ = new Subject<void>();

  constructor(
    private reportService: ReportService,
    private pageService: PageService
  ) { 

    this.rows = [];
    this.affiliateActivity$ = this.reportService.getReportsSelector();

    pageService.changePageInfo({ breadcrum: ['Reports', 'Affiliate Activity']});
  }

  ngOnInit() {
  
    this.reportService.fetchAffiliateActivity(1, {});
    
    this.affiliateActivity$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.rows = [];

          for (const affActivity of state.data) {
            if(affActivity.commission) {
              affActivity.commission = affActivity.commission/100 
            } else {
              affActivity.commission = 0
            }
            const row = {
              id: affActivity.id, 
              affiliate: affActivity.email,
              affiliateStatus: affActivity.status ?  affActivity.status : '',
              affiliateType: affActivity.type ? affActivity.type : '',
              affiliateJoinedDate: affActivity.join_date,
              lastLogin: affActivity.last_login_date,
              lastSaleLead: '$' + affActivity.commission
            };
            this.rows.push(row);
          }
        }
        this.rows = [...this.rows];
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
    });
}

  setPage(e): void {
    this.reportService.fetchAffiliateActivity(e.offset + 1, {}); 
  }

  onSelect(e): void {
    this.selectedRows = e.selected;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
