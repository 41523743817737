export enum GeneratedCommissionStatus {
    Approved = 'approved',
    Pending = 'pending',
    Rejected = 'rejected'
};

export enum GeneratedCommissionComType {
    Sale = 'sale',
    Lead = 'lead',
    SecondTier = 'second_tier',
};

export enum GeneratedCommissionCurrency {
    MONEY = 'money',
    PERCENT = 'percent',
}

export enum GeneratedCommissionReturnSales {
    Include = 'include_in_the_list',
    Exclude = 'exclude_from_the_list',
    Returns = 'show_only_returns'
}