import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CommissionGroupService, SharedService } from '@services/index';

import { IState } from "@models/index";
import { IPage } from '@interfaces/index';
import { IModalvalue } from '@shared-models/index';
import { AutoCurrency } from '@enums/index';

@Component({
  selector: 'aff-commission-group-listing',
  templateUrl: './commission-group-listing.component.html',
  styleUrls: ['./commission-group-listing.component.scss']
})
export class CampaignCommissionGroupListing implements OnInit, OnDestroy {
  @Output() showCommSettingForm = new EventEmitter<boolean>();
  @Input() campaignID: string = '';
  commissionGroupID: string = '';

  comissionGroupRows: any[] = [];
  loadingIndicator: boolean = true;
  selectedRows = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  affiliateLoadingIndicator: boolean = true;
  affiliatePage: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  autoCurrency = AutoCurrency;

  confirmationModal: boolean = false;
  deleteValues: string = '';

  popup: boolean = false;
  changeComGroupPopup: boolean = false;

  commissionGroup$: Observable<IState>;
  private unsubscribe$ = new Subject<void>();

  constructor(private commissionGroupService: CommissionGroupService, 
              private sharedService: SharedService) { 
                this.commissionGroup$ = this.commissionGroupService.getCommissionGroupsSelector();
              }
  
  ngOnInit() {
    // check if edit mode
    if (!this.campaignID) return this.loadingIndicator = false;

    // get campaign list
    this.commissionGroupService.fetchCommissionGroups(1, this.campaignID, {});

    // listen campaign list
    this.commissionGroup$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.comissionGroupRows = [];
          for (const comGroup of state.data) {
            const row = {
              id: comGroup.id,
              name: comGroup.com_group_name,
              commissionOverview: comGroup.commissionOverview,
              countOfAffiliates: comGroup.affCount,
              default: comGroup.is_default,
            };
            this.comissionGroupRows.push(row);
          }
        }
        this.comissionGroupRows = [...this.comissionGroupRows];
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
    });
  }

  onAddCommissionGroup() {
    this.showCommSettingForm.emit(true);
  }

  onAction(action: string, row: any): void {
    switch (action) {
      case "delete":
        if (this.comissionGroupRows.length < 2 || row.default) {
          this.sharedService.onSaveToggle(true, true, 'You cant delete default group');
        }
        this.deleteValues = `Commission Group: <br><br> ${row.name}`;
        this.selectedRows = [{ id: row.id, affCount: row.countOfAffiliates }];
        row.countOfAffiliates === 0 ? this.confirmationModal = true : this.changeComGroupPopup = true;
        break;

      case "edit":
        this.commissionGroupID = row.id;
        this.commissionGroupService.fetchCommissionGroup(this.commissionGroupID);
        break;

      case "default":
        if (row.default) return;
        this.commissionGroupService.fetchMakeDefaultComGroup(+row.id, +this.campaignID);
        break;

      default:
      // default
    }
  }

  onDeleteConfirm(options: IModalvalue): void {
    if (options.confirm) {
      for (const selectedRow of this.selectedRows) {
        this.commissionGroupService.deleteCommissionGroup(
          selectedRow.id, 
          +options.inputValue, 
          +selectedRow.affCount
        );
      }
      this.selectedRows = [];
    }
    this.confirmationModal = false;
    this.changeComGroupPopup = false;
  }

  setPage(e): void {
    this.selectedRows = [];
    this.commissionGroupService.fetchCommissionGroups(e.offset + 1, this.campaignID, {});
  }

  onSelect(e): void {
    this.selectedRows = e.selected;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
