import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import {
    fetchPaymentMethod,
    fetchPaymentMethodSuccess,
    fetchPaymentMethodFailure,
    getPaymentMethod,
    getPaymentMethodSuccess,
    getPaymentMethodFailure,
    updatePaymentMethod,
    updatePaymentMethodSuccess,
    updatePaymentMethodFailure,
    deletePaymentMethod,
    deletePaymentMethodSuccess,
    deletePaymentMethodFailure,
    addPaymentMethod,
    addPaymentMethodSuccess,
    addPaymentMethodFailure,
    updateUserPayout,
    updateUserPayoutSuccess,
    updateUserPayoutFailure
} from "./payment-method.action";
import { EMPTY, of } from "rxjs";
import { map, mergeMap, catchError, tap } from "rxjs/operators";
import { PaymentMethodsService } from "../../services/payment-methods/payment-methods.service";
import { SharedService } from '@services/shared/shared.service';


@Injectable()
export class PaymentMethodEffects {
  loadPayments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPaymentMethod),
      mergeMap(action =>
        this.paymentMethodsService.getPaymentMethods(action.page, action.filterOptions).pipe( 
          map(res => {
            return fetchPaymentMethodSuccess({ method: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchPaymentMethodFailure({ error }));
          })
        )
      )
    )
  );

  getPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPaymentMethod),
      mergeMap(action => 
        this.paymentMethodsService.getPaymentMethod(action.id).pipe(
          map(res => {
            return getPaymentMethodSuccess({ method: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(getPaymentMethodFailure({ error }));
          })
        )
      )
    )
  );

  addMethod$ = createEffect(() =>
  this.actions$.pipe(
    ofType(addPaymentMethod),
    mergeMap(action =>
      this.paymentMethodsService.insertPaymentMethod(action.method).pipe(
        map(method => {
          return addPaymentMethodSuccess({ method });
        }),
        tap(res => {
          this.paymentMethodsService.fetchPaymentMethods(1, {}); 
          this.sharedService.onLoaderToggle(false);
          this.sharedService.onSaveToggle(true);
        }),
        catchError(error => {
          this.sharedService.onLoaderToggle(false);
          return of(addPaymentMethodFailure({ error }));
        })
      )
    )
  )
);

  updateMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePaymentMethod),
      mergeMap(action =>
        this.paymentMethodsService.renewMethod(action.method).pipe(
          map(method => {
            return updatePaymentMethodSuccess({ method });
          }),
          tap(res => {
            this.paymentMethodsService.fetchPaymentMethods(1, {}); 
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(updatePaymentMethodFailure({ error }));
          })
        )
      )
    )
  );

  deleteMethod$ = createEffect(() =>
  this.actions$.pipe(
    ofType(deletePaymentMethod),
    mergeMap(action =>
      this.paymentMethodsService.removeMethod(action.id).pipe(
        map(() => {
          return deletePaymentMethodSuccess({ id: action.id });
        }),
        tap(res => {
          this.paymentMethodsService.fetchPaymentMethods(1, {});
        }),
        catchError(error => {
          this.sharedService.onLoaderToggle(false);
          return of(deletePaymentMethodFailure({ error }));
        })
      )
    )
  )
);


updateUserPayment$ = createEffect(() =>
  this.actions$.pipe(
    ofType(updateUserPayout),
    mergeMap(action =>
      this.paymentMethodsService.renewUserPayment(action.payMethod).pipe(
        map(payMethod => {
          return updateUserPayoutSuccess({ payMethod });
        }),
        tap(res => {
          // this.paymentMethodsService.fetchPaymentMethods(); 
          this.sharedService.onLoaderToggle(false);
          this.sharedService.onSaveToggle(true);
        }),
        catchError(error => {
          this.sharedService.onLoaderToggle(false);
          return of(updateUserPayoutFailure({ error }));
        })
      )
    )
  )
);

  
  constructor(
    private actions$: Actions,
    private paymentMethodsService: PaymentMethodsService,
    private sharedService: SharedService,
    private router: Router
  ) {}
}
