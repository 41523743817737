import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

import { 
  UserService, 
  HelperService, 
  AffiliateService, 
  PageService, 
  PaymentService, 
  PaymentMethodsService } from "@services/index";

import { IState } from '@models/index';
import * as ENUMS from '@enums/index';
import { IModalvalue } from '@shared-models/index';
import { IFormOptions } from '@interfaces/index';

@Component({
  selector: 'aff-payout-history-filter',
  templateUrl: './payout-history-filter.component.html',
  styleUrls: ['./payout-history-filter.component.scss']
})
export class PayoutHistoryFilterComponent implements OnInit, OnDestroy {

  status = ENUMS.StatusConditions;

  filters$: Observable<any>;
  selectedFilter$: Observable<any>;
  filterNamePopup: boolean = false;

  savedFilterList: any = {};
  filterSelectedValue: string | number = '';
  filterSelectedId: number;
  isFilterEmpty: boolean = true;

  filterOptions: any = {
    balance_from: '',
    keyword: '',
    pay_method: [],
    status: [],
    paid_date_from: '',
    paid_date_to: '',
    amount_from: '',
    amount_to: '',
    affiliates: [],
    affTypes: [],
    extra_bonus: false
  };

  datePickerOptions$: Observable<any>
  dateOption: any = {
    paid_date_from: '',
    paid_date_to: ''
  }

  showFiterComponent: boolean = false;

  selectedValues: any = {
    affiliates: [],
    paymentMethods: [],
    affTypes: []
  };

  lists: any = {
    affiliates: [],
    paymentMethods: []
  };

  searchValues: any = {
    affiliates: new FormControl(),
    keyword: new FormControl()
  };

  affiliates$: Observable<IState>;
  paymentMethods$: Observable<IState>;
  affTypes$: Observable<any[]>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private paymentService: PaymentService,
    private userService: UserService,
    private affiliateService: AffiliateService,
    private paymentMethodsService: PaymentMethodsService,
    private pageService: PageService) {
    // get datepicker options
    this.datePickerOptions$ = pageService.getDatePickerOptionsSelector();

    this.filters$ = this.userService.filtersSelector();
    this.selectedFilter$ = this.userService.filterSelector();

    // get affiliates
    this.affiliates$ = this.affiliateService.getAffiliatesSelector();
    this.affiliateService.fetchAffiliates(1, {});
    
    // get payment methods
    this.paymentMethods$ = this.paymentMethodsService.getPaymentMethodsSelector();
    this.paymentMethodsService.fetchPaymentMethods(1, {});
    // get affiliate types
    this.affTypes$ = this.pageService.getUserTypeListSelector();
    this.pageService.fetchUserTypes();
  }

  ngOnInit() {
    // listen value change of keyword
    this.searchValues.keyword.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(newValue => {
        this.filterOptions['keyword'] = newValue;
        this.paymentService.fetchPayments(1, this.filterOptions);
      });

    // Get filters
    this.userService.fetchFilters('payout');
    this.filters$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded && state.data && state.data.length) {
          this.savedFilterList = {};
          state.data.forEach(filter => {
            this.savedFilterList = {
              ...this.savedFilterList,
              [filter.id]: filter.name
            }
          });
        }
      });

    // Get selected filter
    this.selectedFilter$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(filter => {
        if (filter.type !== 'payout') return;
        const filterOpt = filter.filter_fields;
        if (filterOpt) {
          this.onClearFilter();
          HelperService.fillFilterOption(filterOpt, this.filterOptions);
          this.filterSelectedId = filter.id;
          Object.keys(this.dateOption).forEach(key => {
            this.dateOption[key] = filterOpt.find(opt => opt.key === key)
              ? { formatted: filterOpt.find(opt => opt.key === key)['value'] } : ''
          });
          this.paymentService.fetchPayments(1, this.filterOptions);
          this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
          this.showFiterComponent = true;
        }
      });

    // affiliates search
    this.searchValues.affiliates.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.paymentService.fetchPayments(1, this.filterOptions));

    // get affiliate 
    this.getAffiliates();

    // get payments 
    this.getPaymentMethods();
  }

  getAffiliates(): void {
    this.affiliates$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.lists.affiliates = state.data;
        }
      });
  }

  getPaymentMethods() {
    this.paymentMethods$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      if (state.isLoaded) {
        this.lists.paymentMethods = state.data;
      }
    });
  }

  getSearchValue(options, name: string): void {
    // search value in managers
    this.searchValues[name].patchValue(options.term);
  }

  onSelectedValue(values: any[], name: string): void {
    let selectedValues: number[] = [];
    for (const value of values) {
      selectedValues.push(value.id);
    }
    this.filterOptions[name] = selectedValues;
    this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
    this.paymentService.fetchPayments(1, this.filterOptions);
  }

  setFilterNamePopup(option: IModalvalue): void {
    if (option.confirm) {
      if (!this.userService.checkFilterName(option.inputValue, this.savedFilterList, this.filterSelectedId)) return;
      // delete keyword property
      delete this.filterOptions.keyword;

      this.filterSelectedId
        ? this.userService.updateFilter({
            id: this.filterSelectedId,
            name: option.inputValue, 
            type: 'payout', 
            additional_fields: this.filterOptions
          })
        :  this.userService.addFilter({
            name: option.inputValue, 
            type: 'payout', 
            additional_fields: this.filterOptions
          });
    }
    this.filterNamePopup = false;
  }

  onDateChanged(event, name: string) {
    this.filterOptions[name] = event.formatted;
    this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
    this.paymentService.fetchPayments(1, this.filterOptions);
  }

  onCalendarToggle(e: Event, name: string): void {
    this.pageService.disableDate({
      name, 
      date: name === 'paid_date_from' ? this.filterOptions.paid_date_to : this.filterOptions.paid_date_from 
    });
  }

  onToggleFilter(): void {
    this.showFiterComponent = !this.showFiterComponent;
  }

  getSelectedFilter(id): void {
    this.filterSelectedValue = this.savedFilterList[id];
    this.userService.fetchFilter(id);
  }

  onDeleteFilter(id): void {
    this.userService.deleteFilter(id);
  }

  onClearFilter() {
    this.filterOptions = {
      balance_from: '',
      keyword: '',
      status: [],
      paid_date_from: '',
      paid_date_to: '',
      amount_from: '',
      amount_to: '',
      affiliates: [],
      pay_method: [],
      affTypes: [],
      extra_bonus: false
    };

    this.dateOption = {
      paid_date_from: '',
      paid_date_to: ''
    };

    this.selectedValues = {
      affiliates: [],
      paymentMethods: [],
      affTypes: []
    };
  }

  onChangeFormValue(options: IFormOptions) {
    console.log(options);
    
    switch (options.name) {
      case 'clearFilter':
        this.onClearFilter();
        this.filterSelectedValue = '';
        this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
        this.paymentService.fetchPayments(1, this.filterOptions);
        break;

      case 'saveFilter':
        if (this.isFilterEmpty) return;
        this.filterNamePopup = true;
        break;

      case 'extra_bonus':
        this.filterOptions[options.name] = options.checked;
        this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
        this.paymentService.fetchPayments(1, this.filterOptions);
        break;

      case 'status':
        if (options.checked) {
          this.filterOptions['status'].push(options.value)
        } else {
          // find unchecked value and remove from status array
          const findIndex = this.filterOptions['status'].findIndex(val => val === options.value);
          this.filterOptions['status'].splice(findIndex, 1)
        }
        this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
        this.paymentService.fetchPayments(1, this.filterOptions);
        break;

      case 'amount_from':
      case 'amount_to':
        this.filterOptions[options.name] = +options.value * 100;
        this.paymentService.fetchPayments(1, this.filterOptions);
        break;

      case 'keyword':
        this.searchValues.keyword.patchValue(options.value)
        break;

      default:
        this.filterOptions[options.name] = options.value;
        this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
        this.paymentService.fetchPayments(1, this.filterOptions);
    }
  }

  ngOnDestroy(): void {
    this.userService.clearSelectedFilter();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
