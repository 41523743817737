import { createAction, props } from "@ngrx/store";

export const fetchCampaignURLs = createAction(
  "[Campaign URLs] Fetch",
  props<{ page, payload }>()
);

export const fetchCampaignURLsSuccess = createAction(
  "[Campaign URLs] Fetch Success",
  props<{ payload }>()
);

export const fetchCampaignURLsFailure = createAction(
  "[Campaign URLs] Fetch Failed",
  props<{ error }>()
);

export const addCampaignUrl = createAction(
  "[Campaign URLs Add] Fetch",
  props<{ params }>()
);

export const addCampaignUrlSuccess = createAction(
  "[Campaign URLs Add] Fetch Success",
  props<{ payload }>()
);

export const addCampaignUrlFailure = createAction(
  "[Campaign URLs Add] Fetch Failed",
  props<{ error }>()
);

export const deleteCampaignUrl = createAction(
  "[Campaign URLs Delete] Fetch",
  props<{ id, params }>()
);

export const deleteCampaignUrlSuccess = createAction(
  "[Campaign URLs Delete] Fetch Success",
  props<{ id }>()
);

export const deleteCampaignUrlFailure = createAction(
  "[Campaign URLs Delete] Fetch Failed",
  props<{ error }>()
);

export const editCampaignUrl = createAction(
  "[Campaign URLs Edit] Fetch",
  props<{ id, params }>()
);

export const editCampaignUrlSuccess = createAction(
  "[Campaign URLs Edit] Fetch Success",
  props<{ payload }>()
);

export const editCampaignUrlFailure = createAction(
  "[Campaign URLs Edit] Fetch Failed",
  props<{ error }>()
);
