import { Component, OnInit, OnDestroy } from '@angular/core';

import { PageService, SharedService, HelperService } from '@services/index';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Resources, Permissions } from '@models/iPage';
import { IModalvalue } from '@shared-models/index';

@Component({
  selector: 'aff-permision-page',
  templateUrl: './permission-page.component.html',
  styleUrls: ['./permission-page.component.scss']
})
export class PermissionPage implements OnInit, OnDestroy {
  roleID: string = '';
  changedPermissionsList: any[] = [];
  errorMessage: string = '';
  confirmationModal: boolean = false;

  role: any = {};
  permissions$: Observable<Permissions>;
  role$: Observable<any>;
  resources: any[] = [];
  resources$: Observable<Resources>;
  private unsubscribe$ = new Subject<void>();

  constructor(private pageService: PageService,
              private sharedService: SharedService,
              private router: Router,
              private route: ActivatedRoute) {

    this.resources$ = pageService.getRecoursesSelector();
    pageService.fetchResources();

    this.role$ = pageService.getRoleSelector();
    this.permissions$ = pageService.getPermissionsSelector();
   }

  ngOnInit() {
    this.roleID = this.route.snapshot.params.id;

    if (!this.roleID) {
      return this.router.navigate(['configuration', 'permissions']);
    }

    this.pageService.fetchRole(this.roleID);

    this.permissions$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(perm => {
        if (perm.error) {
          return this.errorMessage = perm.error;
        }
        
        if (perm.isUpdated) {
          this.changedPermissionsList = [];
        }
      });

      combineLatest(this.resources$, this.role$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([res, role]) => {
        if(!res.isLoaded || HelperService.isObjectEmpty(role)) return;
          // set page breadcrum
          this.pageService.changePageInfo({ breadcrum: ['Configuration', 'Permissions', role.name] });
        
          this.resources = res.data;
          this.role = role;
          this.addDropDownOption(res.data, role.permissions);
       });
  }

  addDropDownOption(resources: any[], permissions: any[]): void {
    for (const resource of resources) {
      // add properties
      resource.check = false;
      resource.show = true;

      // check this role permissions
      const permission = this.findPermission(permissions, resource.id);
      if (permission) {
        resource.read = permission.read;
        resource.create = permission.create;
        resource.update = permission.update;
        resource.delete = permission.delete;
        resource.firstValue = {
          read: permission.read,
          create: permission.create,
          update: permission.update,
          delete: permission.delete,
        }
        // check or not parent
        if (permission.read || permission.create || permission.update || permission.delete) {
          resource.check = true;
        }
      }

      if (resource.children) {
        this.addDropDownOption(resource.children, permissions);
      }
    }
  }

  findPermission(perrmissions: any[], resourceId: number): any {
    const permission = perrmissions.find(res => res.resource_id === resourceId);
    if (!permission) return null;
    return permission;
  }

  changedPermissions(resource: any): void {
    const permission = this.role.permissions.find(perm => perm.resource_id === resource.id);
    if (!permission) return;

    // find if this change object is in list
    const findChangedPerm = this.changedPermissionsList.find(perm => perm.id === permission.id);
    // if is not add to list
    // if there is not changes delete this permission from list
    // else change only changed option
    if (!findChangedPerm) {
      permission[resource.optionName] = resource[resource.optionName];
      this.changedPermissionsList.push(permission);
    } else if (resource.firstValue.read === resource.read && 
               resource.firstValue.create === resource.create &&
               resource.firstValue.update === resource.update &&
               resource.firstValue.delete === resource.delete) {
                this.changedPermissionsList = this.changedPermissionsList.filter(perm => perm.id !== permission.id);
    } else {
      findChangedPerm[resource.optionName] = resource[resource.optionName];
    }
  }

  onUpdate(): string {
    if (!this.changedPermissionsList.length) {
      return this.errorMessage = 'There are no changes to save!'
    }
    this.confirmationModal = true;
  }

  onConfirmSave(value: IModalvalue): void {
    if (value.confirm) {
      this.errorMessage = '';
      this.sharedService.onLoaderToggle(false);
      this.pageService.updatePermissions(this.changedPermissionsList);
    }

    this.confirmationModal = false;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
