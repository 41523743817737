import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'aff-select-multy',
  templateUrl: './select-multy.component.html',
  styleUrls: ['./select-multy.component.scss']
})
export class SelectMultyComponent implements OnInit {
  @Input() title: string = '';
  @Input() size: number = 4;
  @Input() name: string = '';
  @Input() options: any[] = [];
  @Input() values: any[] = [];
  @Output() onSelectedOptions = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onSelectOptions(e) {
    const options = e.target.options;
    let selectedVaues: any = [];
    for (const opt of options) {
      if (opt.selected) {
        selectedVaues.push(opt.value)
      }
    }

    const option = {
      value: selectedVaues,
      name: this.name
    }
    this.onSelectedOptions.emit(option);
  }

  findOption(option) {
    for (let i = 0; i < this.values.length; i++) {
      if (this.values[i] === option) {
        return true;
      }
      if (i === (this.values.length - 1)) return false;
    }
  }

}
