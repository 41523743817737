import { Component, OnInit, OnDestroy } from '@angular/core';
import * as ENUMS from '@enums/index';
import { ReportService, BannerService, UserService, HelperService, CampaignService, PageService } from "@services/index";
import { IModalvalue } from '@shared-models/index';
import { Subject, Observable } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { IState } from "@models/index";
import { IMyDateModel } from 'mydatepicker';

@Component({
  selector: 'aff-click-filter',
  templateUrl: './click-filter.component.html',
  styleUrls: ['./click-filter.component.scss']
})
export class ClickFilterComponent implements OnInit, OnDestroy {

  click = ENUMS.Clicks;
  filters$: Observable<any>;
  selectedFilter$: Observable<any>;

  managerStatus = ENUMS.ManagerStatus;

  savedFilterList: any = {};
  filterSelectedValue: string | number = '';
  filterSelectedId: number;
  isFilterEmpty: boolean = true;

  keyword = new FormControl();

  datePickerOptions$: Observable<any>;
  dateOption: any = {
    valid_from: '',
    valid_to: ''
  };

  filterOptions = {
    keyword: '',
    valid_from: '',
    valid_to: '',
    type: [],
    with_sale_lead: [],
    campaign: '',
    show_hidden: false
  };

  filterNamePopup: boolean = false;
  showFiterComponent: boolean = false;

  campaing$: Observable<IState>;
  campaigns: any = [];
  campaignsSearch = new FormControl();
  selectedCampaign: any;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private bannerService: BannerService,
    private userService: UserService,
    private campaignService: CampaignService,
    private helpService: HelperService,
    private pageService: PageService,
    private reportService: ReportService
    ) {

    // get datepicker options
    this.datePickerOptions$ = pageService.getDatePickerOptionsSelector();

    this.filters$ = this.userService.filtersSelector();
    this.selectedFilter$ = this.userService.filterSelector();

    this.campaing$ = this.campaignService.getCampaignsSelector();
  }

  ngOnInit() {
    // listen value change of keyword
    this.keyword.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
          debounceTime(500),
          distinctUntilChanged()
      )
      .subscribe(newValue => {

        this.filterOptions['keyword'] = newValue;
        this.reportService.fetchTopPromotions(1, this.filterOptions);
        this.reportService.fetchClicks(1, this.filterOptions);
      });

    // Get filters
    this.userService.fetchFilters('banner');
    this.filters$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded && state.data && state.data.length) {
          this.savedFilterList = {};
          state.data.forEach(filter => {
            this.savedFilterList = {
              ...this.savedFilterList,
              [filter.id]: filter.name
            }
          });
        }
      });

    // Get selected filter
    this.selectedFilter$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(filter => {
        if (filter.type !== 'banner') return;
        const filterOpt = filter.filter_fields;
        if (filterOpt) {
          this.onClearFilter();
          filterOpt.forEach(field => {
            if (field.key === 'show_hidden') {
              field.value = this.helpService.numberToBool(field.value);
            }
          });

          HelperService.fillFilterOption(filterOpt, this.filterOptions);
          this.filterSelectedId = filter.id;
          this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
          this.reportService.fetchTopPromotions(1, this.filterOptions);
          this.showFiterComponent = true;

        }
      });

    // owner search
    this.campaignsSearch.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.campaignService.fetchCampaigns(1, { keyword }));

    // get campaign list
    this.getCampaigns();

  }

  getCampaigns() {
    this.campaignService.fetchCampaigns(1, {});
    // listen campaign
    this.campaing$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.campaigns = state.data
      })
  }

  getSearchValue(options): void {
    // search value in managers
    this.campaignsSearch.patchValue(options.term);
  }

  onChangeCampaignSelection(campaigns): void {
    let selectedCamp: number[] = [];
    for (const campaign of campaigns) {
      selectedCamp.push(campaign.id);
    }
    this.filterOptions['campaigns'] = selectedCamp;
    this.selectedCampaign = campaigns;
    this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
    this.reportService.fetchClicks(1, this.filterOptions);
  }

  setFilterNamePopup(option: IModalvalue) {
    if (option.confirm) {
      if (!this.userService.checkFilterName(option.inputValue, this.savedFilterList, this.filterSelectedId)) return;
      delete this.filterOptions.keyword;
      let filters = this.filterOptions;

      this.filterSelectedId
        ? this.userService.updateFilter({
            id: this.filterSelectedId,
            name: option.inputValue,
            type: 'banner',
            additional_fields: filters
          })
        :  this.userService.addFilter({
            name: option.inputValue,
            type: 'banner',
            additional_fields: filters
          });
    }

    this.filterNamePopup = false;
  }

  onToggleFilter(): void {
    this.showFiterComponent = !this.showFiterComponent;
  }

  getSelectedFilter(id): void {
    this.filterSelectedValue = this.savedFilterList[id];
    this.userService.fetchFilter(id);
  }

  onDeleteFilter(id): void {
    this.userService.deleteFilter(id);
  }

  onCalendarToggle(e: Event, name: string) {
    switch (name) {
      case 'valid_from':
        this.pageService.disableDate({
          name, 
          date: this.filterOptions.valid_to
        });
        break;
    
      default:
        this.pageService.disableDate({
          name, 
          date: this.filterOptions.valid_from
        });
        break;
    }
  }
 
  onDateChanged(event: IMyDateModel, name: string): void {
    this.filterOptions[name] = event.formatted;
    
    this.reportService.fetchClicks(1, this.filterOptions);
    this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
  }

  onClearFilter() {
    this.selectedCampaign = [];

    this.dateOption = {
      valid_from: '',
      valid_to: ''
    };

    this.filterOptions = {
      keyword: '',
      valid_from: '',
      valid_to: '',
      type: [],
      with_sale_lead: [],
      campaign: this.selectedCampaign,
      show_hidden: false,
    };
  }

  onChangeFormValue(options) {
    switch (options.name) {
      case 'clearFilter':
        this.onClearFilter();
        this.filterSelectedValue = '';
        this.filterSelectedId = null;
        this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
        this.reportService.fetchClicks(1, this.filterOptions);
        break;

      case 'saveFilter':
        if (this.isFilterEmpty) return;
        this.filterNamePopup = true;
        break;

      case 'type':
        if (options.checked) {
          this.filterOptions['type'].push(options.value)
        } else {
          // find unchecked value and remove from type array
          const findIndex = this.filterOptions['type'].findIndex(val => val === options.value);
          this.filterOptions['type'].splice(findIndex, 1)
        }
        this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
          this.reportService.fetchClicks(1, this.filterOptions);
        break;

      case 'keyword':
        this.keyword.patchValue(options.value)
        break;

      case 'show_hidden':
          this.filterOptions.show_hidden = !this.helpService.numberToBool(this.filterOptions.show_hidden);
          this.reportService.fetchClicks(1, this.filterOptions);
          this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
        break;
      
      case 'with_sale_lead':
        if (options.checked) {
          this.filterOptions['with_sale_lead'].push(options.value)
        } else {
          // find unchecked value and remove from with_sale_lead array
          const findIndex = this.filterOptions['with_sale_lead'].findIndex(val => val === options.value);
          this.filterOptions['with_sale_lead'].splice(findIndex, 1)
        }
          this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
          this.reportService.fetchClicks(1, this.filterOptions);
        break;

      default:
          this.filterOptions[options.name] = options.value;
          this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
          this.reportService.fetchClicks(1, this.filterOptions);
      }
  }

  ngOnDestroy(): void {
    this.userService.clearSelectedFilter();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
