import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PermissionGuard } from '@guards/index';

import { 
  CampaignListPage, CampaignAddPage } from './index';

const routes: Routes = [
    {
        path: '',
        component: CampaignListPage,
        canActivate: [PermissionGuard],
        data: {
            breadcrumb: 'Campaigns'
        },
    },
    { 
        path: 'campaign', 
        canActivate: [PermissionGuard],
        children: [
            { path: '', component: CampaignAddPage, data: { breadcrumb: 'Add Campaign' } },
            { path: ':id', component: CampaignAddPage, data: { breadcrumb: 'Edit Campaign' } }
        ] 
    }
];

@NgModule({
  imports: [
    RouterModule.forChild(
        routes
    )
  ],
  exports: [
    RouterModule
  ]
})
export class CampaignPageRoutingModule {}
