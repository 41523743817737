import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {map, mergeMap, catchError, switchMap} from 'rxjs/operators';
import {DashboardService} from '@services/dashboard/dashboard.service';
import {
  fetchCommissions, fetchCommissionsFailure, fetchCommissionsSuccess,
  fetchGroupDetails, fetchGroupDetailsFailure, fetchGroupDetailsSuccess,
  fetchTopAffiliate,
  fetchTopAffiliateFailure,
  fetchTopAffiliateSuccess, fetchTopBanners, fetchTopBannersFailure, fetchTopBannersSuccess
} from "@store/dashboard/dashboard.actions";
import {Store} from "@ngrx/store";


@Injectable()
export class DashboardEffects {
  loadTopAffiliates = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchTopAffiliate),
      mergeMap(action =>
        this.dashboardService.getTopAffiliates(action.payload.params).pipe(
          map(res => {
            return fetchTopAffiliateSuccess({payload: res, field: action.payload.field});
          }),
          catchError(error => {
            return of(fetchTopAffiliateFailure({error, field: action.payload.field}));
          })
        )
      )
    )
  );

  loadTopBanners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchTopBanners),
      switchMap((action) => {
          return this.dashboardService.getTopBanners(action.payload.params).pipe(
            map(res => {
              return fetchTopBannersSuccess({payload: res, field: action.payload.field});
            }),
            catchError(error => {
              return of(fetchTopBannersFailure({error, field: action.payload.field}));
            })
          )
        }
      )
    )
  );

  loadGroupDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchGroupDetails),
      mergeMap(action =>
        this.dashboardService.getGroupDetails().pipe(
          map(res => {
            return fetchGroupDetailsSuccess({payload: res});
          }),
          catchError(error => {
            return of(fetchGroupDetailsFailure({error}));
          })
        )
      )
    )
  );

  fetchCommissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCommissions),
      mergeMap((action) => {
        return this.dashboardService.getCommissions(action.payload).pipe(
          map(res => {
            return fetchCommissionsSuccess({payload: res});
          }),
          catchError(error => {
            return of(fetchCommissionsFailure({error}));
          })
        )
      })
    )
  );

  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
    private store: Store<any>
  ) {
  }
}
