import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { IPage } from '@interfaces/index';
import { IState } from "@models/index";
import { Status } from '@enums/index';
import { PageService, HelperService, ReportService} from "@services/index";

@Component({
  selector: 'aff-click',
  templateUrl: './click.component.html',
  styleUrls: ['./click.component.scss']
})
export class ClickPage implements OnInit, OnDestroy {
  
  eventPopup: boolean = false;
  selectedClick: string = '';

  rows = [];
  loadingIndicator: boolean = true;
  selectedRows = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  clicks$: Observable<IState>;
  unsubscribe$ = new Subject<void>();

  constructor(
    private reportService: ReportService,
    private pageService: PageService
  ) { 

    this.rows = [];
    this.clicks$ = this.reportService.getReportsSelector();

    pageService.changePageInfo({ breadcrum: ['Reports', 'Clicks']});
  }

  ngOnInit() {
    this.reportService.fetchClicks(1, {});
    
    this.clicks$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
           this.rows = [];
          for (const click of state.data) {            
            if(click.hidden_banner === 1 ) {
              click.hidden_banner = true;
            } else {
              click.hidden_banner = false;
            }
            const row = {
              clickId: click.id, 
              clickDate: click.click_date, 
              promotionToolName: click.banner_name,
              promotionToolType: click.banner_type,
              hidden: click.hidden_banner,
              referringUrl: '',
              destinationUrl: click.destination_url,
              os: click.os,
              browser: click.browser,
              device: click.device,
              campaign: click.campaign_name,
              campaignStatus: click.campaign_status ? Status[click.campaign_status] : '',
              affiliateManager: click.manager_first_name + ' ' + click.manager_last_name,
              leadId: click.lead_ids,
              saleId: click.sale_ids
            };
            this.rows.push(row);
          }
        }
        this.rows = [...this.rows];
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
    });
}
  onAction(row: any): void {
    this.selectedClick = row.clickId;
    this.eventPopup = true;
}

  setPage(e): void {
    this.reportService.fetchClicks(e.offset + 1, {}); 
  }

  onSelect(e): void {
    this.selectedRows = e.selected;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


}
