import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PermissionGuard } from '@guards/index';
import { 
    MessagesListPage, MessagesReplayPage } from './index';

const routes: Routes = [
    {
        path: '',
        component: MessagesListPage,
        canActivate: [PermissionGuard],
        data: {
            breadcrumb: 'Affiliates'
        },
    },
    { 
        path: 'message', 
        canActivate: [PermissionGuard],
        children: [
            { path: '', component: MessagesReplayPage, data: { breadcrumb: 'Compose' } },
            { path: ':id', component: MessagesReplayPage, data: { breadcrumb: 'Compose' } }
        ] 
    }
];

@NgModule({
  imports: [
    RouterModule.forChild(
        routes
    )
  ],
  exports: [
    RouterModule
  ]
})
export class MessagesPageRoutingModule {}
