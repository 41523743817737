import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CampaignService, HelperService } from "@services/index";

import { IPage } from '@interfaces/index';
import { Status } from '@enums/index';

@Component({
  selector: 'aff-affiliates-campaigns-page',
  templateUrl: './affiliates-campaigns-page.component.html',
  styleUrls: ['./affiliates-campaigns-page.component.scss']
})
export class AffiliatesCampaignsPage implements OnInit, OnDestroy {
  affiliateID: string = '';

  rows = [];
  loadingIndicator: boolean = false;
  selectedRows = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  campaign: Observable<any>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private campaingService: CampaignService) {

    this.campaign = this.campaingService.getCampaignsSelector();
  }

  ngOnInit() {
    // get affiliate id
    this.affiliateID = this.route.snapshot.params.id;

    if (this.affiliateID) {
      this.campaingService.fetchCampaignsByAffiliate(1, this.affiliateID);
    }

    this.campaign
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (!state.isLoaded) return;
        this.rows = [];
        for (const camp of state.data) {
            const row = {
              name: camp.name,
              type: camp.campaign_type ? camp.campaign_type.toUpperCase() : '',
              manager: camp.owner,
              dateJoined: HelperService.dateFormater(camp.created_at),
              status: Status[camp.status],
            };
            this.rows.push(row);
        }
        this.rows = [...this.rows];
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
      });
  }

  setPage(e): void {
    this.selectedRows = [];
    this.campaingService.fetchCampaignsByAffiliate(e.offset + 1, this.affiliateID);
  }

  onSelect(e): void {
    this.selectedRows = e.selected;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
