import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PermissionGuard } from '@guards/index';
import { 
    AffiliateTypesPage, PermissionListingPage, PermissionPage, PayoutMethodsPage } from './index';

const routes: Routes = [
    {
        path: 'payout-methods',
        component: PayoutMethodsPage, 
        canActivate: [PermissionGuard],
        data: {
            breadcrumb: 'Payout Methods'
        },
    },
    {
        path: 'affiliate-types',
        component: AffiliateTypesPage,
        canActivate: [PermissionGuard],
        data: {
            breadcrumb: 'Affiliate Types'
        },
    },
    { 
        path: 'permissions', 
        canActivate: [PermissionGuard],
        children: [
            { path: '', component: PermissionListingPage },
            { path: ':id', component: PermissionPage }
        ] 
    }
];

@NgModule({
  imports: [
    RouterModule.forChild(
        routes
    )
  ],
  exports: [
    RouterModule
  ]
})
export class ConfigurationPageRoutingModule {}
