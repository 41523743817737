import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, FormControl} from '@angular/forms';
import * as Chart from 'chart.js';
import {DashboardService} from "@services/dashboard/dashboard.service";
import {Observable, Subject} from "rxjs";
import {HelperService} from "@services/helper/helper.service";
import {ChartLabelTypes, ChartThemingColors} from "@enums/ECharts";
import {takeUntil} from "rxjs/operators";
import {StoreModel} from "@interfaces/IStoreModel";
import {DashboardGroupDetails} from "@models/iDashboardState";

@Component({
  selector: 'aff-dashboard-charts',
  templateUrl: './dashboard-charts.component.html',
  styleUrls: ['./dashboard-charts.component.scss']
})
export class DashboardChartsComponent implements OnInit, OnDestroy {

  chartForm: FormGroup;

  chart: any = [];
  pie: any = [];

  statuses = [
    {name: 'Pending', amount: 0},
    {name: 'Approved', amount: 0},
    {name: 'Suspended', amount: 0},
    {name: 'Fraud', amount: 0},
    {name: 'Declined', amount: 0}
  ];

  private subs$ = new Subject();

  @ViewChild('chartEl', {static: true}) chartEl: ElementRef;
  @ViewChild('pieChartEl', {static: true}) pieChartEl: ElementRef;

  private chartBar: Chart | any;
  private chartPie: Chart | any;

  // @ViewChild('myCanvas', {static: true}) myCanvas;
  // el = this.myCanvas.nativeElement

  // el = <HTMLCanvasElement> document.getElementById('chart-area');
  // ctx = this.el.getContext('2d');


  // canvas = <HTMLCanvasElement> document.getElementById("chart-area");
  // ctx: CanvasRenderingContext2D = this.canvas.getContext("2d");

  private commissions$: Observable<any>;
  private groupDetails$: Observable<StoreModel<DashboardGroupDetails>>;
  public groupInfo: DashboardGroupDetails = {
    commissionsToApprove: 0,
    payAffiliates: 0,
    pendingAffiliates: 0
  };
  public commissionsState: any = {};
  public chartFilterLabels: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService
  ) {
    this.chartForm = this.formBuilder.group({
      campaign: new FormControl('all')
    });

    this.commissions$ = this.dashboardService.selectCommissions();
    this.groupDetails$ = this.dashboardService.selectGroupDetails();
  }

  ngOnInit() {
    this.createChartBar();
    this.createChartPie();
    this.initCharts();
    this.getGroupDetails();
  }

  ngOnDestroy() {
    this.subs$.next(null);
    this.subs$.unsubscribe();
  }

  initCharts() {
    // this.dashboardService.fetchCommissions();

    this.commissions$
      .pipe(takeUntil(this.subs$))
      .subscribe(resp => {
        if (resp.isLoaded && !resp.isLoading) {
          this.createLabels(Object.keys(resp.data));
          this.initChartBar(resp.data);
          this.initChartPie(resp.data);

          for (const field in resp.data) {
            if (resp.data.hasOwnProperty(field)) {
              this.commissionsState[field] = {};
              this.commissionsState[field].total = Math.round(HelperService.calcAmount(resp.data[field]) / 100);
              this.commissionsState[field].data = resp.data[field].filter(i => parseFloat(i.sum) > 0).map(i => i.sum);
            }
          }
        }
      })
  }

  onChangeFormValue(option) {
    this.legendFilter(option.value, this.chartBar);
    this.onPieFilter(option.value, this.chartPie);
    this.chartForm.get('campaign').patchValue(option.value);
  }

  createChartBar() {
    this.chartBar = new Chart(this.chartEl.nativeElement, {
      type: 'bar',
      data: {
        labels: [],
        datasets: []
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              bounds: 'ticks',
              type: 'time',
              time: {
                unit: 'day',
                displayFormats: {
                  quarter: 'll'
                }
              },
              gridLines: {
                display: false
              },
              ticks: {
                beginAtZero: true,
                autoSkip: true,
                autoSkipPadding: 10,
                source: 'labels'
              },
              stacked: false,
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true
              },
              ticks: {
                beginAtZero: true
              },
            }
          ],
        },
        responsive: true,
        tooltips: {
          callbacks: {
            label: (tooltipItem, chart) => {
              return `${chart.datasets[tooltipItem.datasetIndex].label} - ${tooltipItem.value} $`;
            }
          }
        },
        legend: {
          display: true,
          labels: {},
          onClick: (e, chart) => {
            this.onLegendClick(chart.text, this.chartBar);
          },
        },
        datasets: {
          bar: {categoryPercentage: 0.5, barPercentage: 0.5},
          horizontalBar: {categoryPercentage: 0.51, barPercentage: 0.75},
          scatter: {showLine: false},
          maxBarThickness: 100
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 25
          }
        }
      }
    });
  }

  createChartPie() {
    this.chartPie = new Chart(this.pieChartEl.nativeElement, {
      type: 'doughnut',
      data: {
        labels: [],
        datasets: []
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (tooltipItem, chart) => {
              return `${chart.labels[tooltipItem.index]} - ${chart.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] / 100} $`;
            }
          }
        },
        legend: {
          onClick: (e, chart) => {
            this.onLegendClick(chart.text, this.chartPie);
          }
        },
      }
    });

  }

  reverseData(data) {
    const returnData = [];
    for (const type in data) {
      if (data.hasOwnProperty(type)) {
        data[type].forEach(item => {
          returnData.push({
            ...item,
            type
          });
        });
      }
    }

    return returnData;
  }

  initChartBar(data?) {
    const correctData = this.reverseData({...data});
    let dateLabels: any[] = HelperService.removeDups(correctData.map(i => i.date)).map(i => new Date(i).getTime());
    const min = new Date(new Date(new Date(Math.min(...dateLabels))).setDate(new Date(Math.min(...dateLabels)).getDate() - 1)).getTime();
    const max = new Date(new Date(new Date(Math.max(...dateLabels))).setDate(new Date(Math.max(...dateLabels)).getDate() + 1)).getTime();

    dateLabels = dateLabels.map(i => new Date(i));

    const colorGen = (e) => {
      return e.chart.config.data.labels.map(i => ChartThemingColors[HelperService.getKeyOf(ChartLabelTypes, e.dataset.label)]);
    };

    this.chartBar.data = {
      labels: dateLabels,
      datasets: []
    };

    const t = {...data};

    for (const el in t) {
      if (t.hasOwnProperty(el)) {
        const chartDataSet = {
          label: ChartLabelTypes[el.toLowerCase()],
          data: t[el].map(i => {
            return {x: new Date(i.date), y: parseFloat(i.sum) / 100};
          }),
          backgroundColor: colorGen,
        }
        this.chartBar.data.datasets.push(chartDataSet);
      }
    }

    this.chartBar.options.scales.xAxes[0].ticks.min = min;
    this.chartBar.options.scales.xAxes[0].ticks.max = max;

    this.chartBar.update();
  }

  initChartPie(data?) {
    const someData: any = {};

    for (const field in data) {
      if (data.hasOwnProperty(field)) {
        someData[field] = [];
        someData[field].push(HelperService.calcAmount(data[field]));
      }
    }


    const colorGen = (e) => {
      return e.chart.config.data.labels.map(i => ChartThemingColors[HelperService.getKeyOf(ChartLabelTypes, i)]);
    };


    this.chartPie.data = {
      labels: Object.keys(someData).map(label => ChartLabelTypes[label.toLowerCase()]),
      datasets: [
        {
          label: 'roll_1',
          data: Object.values(someData),
          backgroundColor: colorGen,
        }
      ]
    };
    this.chartPie.update();
  }

  onPieFilter(label: string, chart, toggle?) {
    this.legendFilter(label, chart, toggle);
    return;

    const meta = chart.getDatasetMeta(0);

    chart.legend.legendItems.forEach(legend => {
      if (label === 'clear' || label === 'all') {
        meta.data[legend.index].hidden = false;
        return;
      }

      if (legend.text.toLowerCase() === label.toLowerCase()) {
        meta.data[legend.index].hidden = toggle ? !meta.data[legend.index].hidden : false;
      } else if (legend.text.toLowerCase() !== label.toLowerCase()) {
        meta.data[legend.index].hidden = toggle ? meta.data[legend.index].hidden : true;
      }

    });

    chart.update();

  }

  legendFilter(label: string, chart, toggle?) {
    // console.log('legendFilter', chart);
    const {config} = chart;

    chart.legend.legendItems.forEach(legend => {
      let meta;

      if (config.type === 'bar') {
        meta = chart.getDatasetMeta(legend.datasetIndex);
      } else {
        meta = chart.getDatasetMeta(0).data[legend.index];
      }

      if (label === 'clear' || label === 'all') {
        meta.hidden = false;
        return;
      } else if (label === 'showAll') {
        meta.hidden = false;
        return;
      }

      if (!toggle) {
        meta.hidden = legend.text.toLowerCase() !== label.toLowerCase();
      } else if (legend.text.toLowerCase() == label.toLowerCase()) {
        meta.hidden = !meta.hidden;
      }

    });

    chart.update();
  }

  setStatus(data) {
    data.forEach((i, key) => {
      const item = this.statuses.find(s => s.name.toLowerCase() === i.status.toLowerCase());
      if (item) {
        this.statuses[this.statuses.indexOf(item)].amount = i.count;
      }
    })
  }

  getGroupDetails() {
    this.groupDetails$
      .pipe(takeUntil(this.subs$))
      .subscribe(resp => {
        if (resp.isLoaded && !resp.error) {
          this.groupInfo = resp.data;
          this.setStatus(resp.data.existingAffiliates);
        }
      });
  }

  private createLabels(data: any) {
    this.chartFilterLabels = data.map(el => ChartLabelTypes[el.toLowerCase()]);
  }

  private onLegendClick(text: any, chart: any) {
    this.legendFilter(text, chart, true);
    this.chartForm.get('campaign').patchValue('all');

    // const {config} = chart;
    //
    // if (config.type === 'bar') {
    //   this.legendFilter('showAll', this.chartPie);
    // } else {
    //   this.legendFilter('showAll', this.chartBar);
    // }

  }
}
