import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject, Observable } from 'rxjs';
import {on, Store} from "@ngrx/store";
import { environment } from "../../../environments/environment";
import {
  fetchAffiliate,
  fetchFilteredAffiliates,
  deleteAffiliate,
  addAffiliate,
  updateAffiliate,
  getAffiliate,
  fetchAffiliateByAffManager,
  fetchAffiliateDownlines, clearAffFilter
} from "../../store/affiliate/affiliate.actions";
import { IState } from '@models/index';
import { getAffiliatesSelector, getAffiliateSelector } from '@store/affiliate/affiliate.selectors';

import { HelperService } from '../helper/helper.service';
import { SharedService } from '@services/shared/shared.service';
import {finalize, map, tap} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class AffiliateService {
  backendUrl = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private store: Store<{ affiliate: IState | any}>
  ) {}

  getAffiliatesSelector(): Observable<IState | any> {
    return this.store.select(getAffiliatesSelector).pipe(
      finalize(() => {
        console.log('--------- [finalize]');
        this.store.dispatch(clearAffFilter({any: 'affiliate'}));
      })
    );
  }

  getAffiliateSelector(): Observable<any> {
    return this.store.select(getAffiliateSelector);
  }

  fetchAffiliates(page, filterOptions, reducerOptions?: any) {
    this.store.dispatch(fetchAffiliate({ page, filterOptions, reducerOptions }));
  }

  fetchFilteredAffiliates(page, filterOptions) {
    this.store.dispatch(fetchFilteredAffiliates({ page, filterOptions }));
  }

  fetchAffiliate(id) {
    this.store.dispatch(getAffiliate({ id }))
  }

  fetchAffiliateByAffManager(affManagerId: string | number, page: number, filterOptions: any) {
    this.store.dispatch(fetchAffiliateByAffManager({ affManagerId, page, filterOptions }))
  }

  deleteAffiliate(id) {
    this.store.dispatch(deleteAffiliate({ id }));
  }

  addAffiliate(affiliate) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(addAffiliate({ affiliate }));
  }

  fetchAffiliateDownlines(page: number, affiliateID: string, filterOptions: any = {}) {
    this.store.dispatch(fetchAffiliateDownlines({ page, affiliateID, filterOptions }));
  }

  updateAffiliate(affiliate) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(updateAffiliate({ affiliate }));
  }

  getAffiliates(page: number, filterOptions: any = { }) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(`${this.backendUrl}/users/affiliates?${queryString}`);
  }

  getAffiliatesByAffManager(affManagerId: string | number, page: number, filterOptions: any = { }) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);

    return this.http.get(`${this.backendUrl}/users/user/${affManagerId}/asigned-affiliates?${queryString}`);
  }

  getAffiliate(id: string) {
    return this.http.get(`${this.backendUrl}/users/user/${id}`);
  }

  removeAffiliate(id) {
    return this.http.delete(`${this.backendUrl}/users/user/${id}`);
  }

  insertAffiliate(affiliate) {
    return this.http.post(`${this.backendUrl}/users/user`, affiliate);
  }

  renewAffiliate(affiliate) {
    return this.http.patch(`${this.backendUrl}/users/user/${affiliate.id}`, affiliate)
  }

  getDownlinesByAffiliate(page: number, ID: string, filterOptions: any) {
    const queryparams = { ...{ page }, ...filterOptions, ...{user_id: ID} };
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(`${this.backendUrl}/users/user/downlines?${queryString}`);
  }

}
