import { Component, Input, OnInit } from '@angular/core';

/**
 * Dynamic Card Component for rendering small part of application
 */
@Component({
  selector: 'aff-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {

  /**
   * Getting information from parent
   */
  @Input() data: any;

  /**
   * @ignore
   */
  ngOnInit() {
  }

}
