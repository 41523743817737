import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Router} from "@angular/router";
import {catchError, delay, map, mergeMap, switchMap, take, takeWhile, tap} from "rxjs/operators";
import {
  fetchNotifications, fetchNotificationsEndPoll,
  fetchNotificationsFailed,
  fetchNotificationsPoll,
  fetchNotificationsPollFailed,
  fetchNotificationsPollSuccess,
  fetchNotificationsStartPoll,
  fetchNotificationsSuccess,
  setNotificationAsRead,
  setNotificationAsReadFailed,
  setNotificationAsReadSuccess
} from "./notifications.actions";
import {interval, of} from "rxjs";
import {NotificationsService} from "@services/notifications/notifications.service";

@Injectable()
export class NotificationsEffects {

  private isPollingActive = false;

  fetchNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchNotifications),
      mergeMap(() =>
        this.notificationsService.getNotifications()
          .pipe(
            map(res => {
              return fetchNotificationsSuccess({payload: res});
            }),
            catchError(e => {
              return of(fetchNotificationsFailed({error: e}));
            })
          )
      )
    )
  );

  setNotificationAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setNotificationAsRead),
      mergeMap((e) => {
          return this.notificationsService.setNotificationAsSeen(e.id)
            .pipe(
              map(res => {
                return setNotificationAsReadSuccess({payload: res});
              }),
              catchError(e => {
                return of(setNotificationAsReadFailed({error: e}));
              })
            )
        }
      )
    )
  );

  fetchNotificationsPollStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchNotificationsStartPoll),
      takeWhile(() => this.isPollingActive),
      map(() => {
        this.isPollingActive = true;
      }),
      switchMap(() => {
        console.log('switch');
        return of(fetchNotificationsPoll({params: ''}))
      })
    )
  );

  stopPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchNotificationsEndPoll),
      take(1),
      tap(() => {
        console.log('STOP');
        this.isPollingActive = false;
      })
    )
  );

  notificationsPoolRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchNotificationsPoll),
      takeWhile(() => this.isPollingActive),
      tap(() => {
        console.log('hittt');
      }),
      mergeMap(() =>
        this.notificationsService.getNotifications()
          .pipe(
            delay(5000),
            map(res => {
              return fetchNotificationsPollSuccess({payload: res});
            }),
            catchError(e => {
              return of(fetchNotificationsPollFailed({error: e}));
            })
          )
      )
    )
  );


  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
    private router: Router
  ) {
  }
}
