import { createReducer, on } from "@ngrx/store";
import {
    fetchPaymentMethod,
    fetchPaymentMethodSuccess,
    fetchPaymentMethodFailure,
    getPaymentMethod,
    getPaymentMethodSuccess,
    getPaymentMethodFailure,
    updatePaymentMethod,
    updatePaymentMethodSuccess,
    updatePaymentMethodFailure,
    deletePaymentMethod,
    deletePaymentMethodSuccess,
    deletePaymentMethodFailure,
    addPaymentMethod,
    addPaymentMethodSuccess,
    addPaymentMethodFailure,
    updateUserPayout,
    updateUserPayoutSuccess,
    updateUserPayoutFailure
} from "./payment-method.action";

import { IState } from "@models/index";

const initialState: IState = {
  isLoaded: false,
  data: [],
  selectedItem: {},
  error: null,
  total: 0,
  maxPages: 0,
  perPage: 0,
  currentPage: 0
};

const _paymentMethodReducer = createReducer(
  initialState,
  on(fetchPaymentMethod, (state) => {
        return state;
  }),
  on(fetchPaymentMethodSuccess, (state, { method }) => {
    const payload = {
      isLoaded: true,
      data: method.data,
      total: method.total,
      maxPages: method.maxPages,
      perPage: method.length,
      currentPage: method.currentPage,
      selectedItem: {}
    };    
    return { ...state, ...payload };
  }),
  on(fetchPaymentMethodFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),


  on(getPaymentMethod, (state, { id }) => {
    const payload = {
      isLoaded: false
    };
    return { ...state, ...payload };
  }),
  on(getPaymentMethodSuccess, (state, { method }) => {
    const payload = {
      isLoaded: true,
      selectedItem: method
    };
    return { ...state, ...payload };
  }),
  on(getPaymentMethodFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(updatePaymentMethod, (state, { method }) => {
    const payload = {
      isLoaded: false,
    }
    return { ...state, ...payload };
  }),
  on(updatePaymentMethodSuccess, (state, { method }) => {
    const index = state.data.findIndex(meth => meth.id === method.id);
    state[index] = method;
    const payload = {
      isLoaded: true,
      selectedItem: method
    }
    return { ...state, ...payload };
  }),
  on(addPaymentMethod, (state, { method }) => {
    const payload = {
      isLoaded: false
    };
    return { ...state, ...payload };
  }),
  on(addPaymentMethodSuccess, (state, { method }) => {
    const payload = {
      isLoaded: true,
    };
    return { ...state, ...payload };
  }),
  on(addPaymentMethodFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(updatePaymentMethodFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(deletePaymentMethod, (state, { id }) => {
    const payload = {
      isLoaded: false
    };
    return { ...state, ...payload };
  }),
  on(deletePaymentMethodSuccess, (state, { id }) => {
    const payload = {
      isLoaded: true,
      // data: state.data.filter(item => item.id !== id)
    };
    return { ...state, ...payload };
  }),
  on(deletePaymentMethodFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(updateUserPayout, (state, { payMethod }) => {
    const payload = {
      isLoaded: false,
    }
    return { ...state, ...payload };
  }),
  on(updateUserPayoutSuccess, (state, { payMethod }) => { 
    const index = state.data.findIndex(method => method.id === payMethod.id);
    state.data[index] = payMethod;

    const payload = {
      isLoaded: true,
    }
    return { ...state, ...payload };
  }),
  on(updateUserPayoutFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),

);

export function paymentMethodReducer(state, action) {
  return _paymentMethodReducer(state, action);
}
