import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { IState } from '@models/index';

import { 
  AffiliateService,
  PageService,
  HelperService, 
  CampaignService} from "@services/index";

import * as ENUMS from '@enums/index';
import { IPage, IFormOptions } from '@interfaces/index';

@Component({
  selector: 'aff-assigned-affiliates',
  templateUrl: './assigned-affiliates.component.html',
  styleUrls: ['./assigned-affiliates.component.scss']
})
export class AssignedAffiliatesComponent implements OnInit, OnDestroy {
  @Input() affManagerId: string = '';

  // enums
  status = ENUMS.StatusConditions;
  campaignStatus = ENUMS.Status;

  loadingIndicator: boolean = true;
  rows: any[] = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  showFiterComponent: boolean = false;
  filterOptions = {
    keyword: '',
    status: [],
    campaigns: [],
    campaignStatus: [],
    created_from: '',
    created_to: ''
  };

  datePickerOptions$: Observable<any>;
  dateOption: any = {
    created_from: '',
    created_to: ''
  }

  selectedValues: any = {
    campaigns: [],
    affTypes: []
  };

  lists: any = {
    campaigns: []
  };

  searchValues: any = {
    campaigns: new FormControl(),
    keyword: new FormControl()
  };

  campaigns$: Observable<IState>;
  affTypes$: Observable<any[]>;
  affiliates$: Observable<IState>;
  private unsubscribe$ = new Subject<void>();

  constructor(private affiliateService: AffiliateService, 
              private pageService: PageService,
              private campaignService: CampaignService) {
                // get datepicker options
                this.datePickerOptions$ = pageService.getDatePickerOptionsSelector();

                // get affiliates
                this.affiliates$ = this.affiliateService.getAffiliatesSelector();
                // get campaigns
                this.campaigns$ = this.campaignService.getCampaignsSelector();
                this.campaignService.fetchCampaigns(1, {});
                // get affiliate types
                this.affTypes$ = this.pageService.getUserTypeListSelector();
                this.pageService.fetchUserTypes();
    }

  ngOnInit() {
    // get affiliate list
    this.affiliateService.fetchAffiliateByAffManager(this.affManagerId, 1, this.filterOptions);

    // listen value change of keyword
    this.searchValues.keyword.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
          debounceTime(500),
          distinctUntilChanged()
      )
      .subscribe(newValue => {
        this.filterOptions['keyword'] = newValue;
        this.affiliateService.fetchAffiliateByAffManager(this.affManagerId, 1, this.filterOptions);
      });

    // listen affiliate list
    this.affiliates$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        console.log(state);
        if (!state.isLoaded) return;
        this.rows = [];
        for (const affiliate of state.assignedAffiliates) {
          const row = { 
            id: affiliate.user.id,
            affiliateName: affiliate.user.profile.first_name + ' ' + affiliate.user.profile.last_name,
            affiliateType: affiliate["type"],
            affiliateStatus: HelperService.getEnumValue(this.status, affiliate.user.status_id),
            dateJoined: HelperService.dateFormater(affiliate.created_at),
            campaignName: affiliate.campaign.name,
            campaignStatus: HelperService.getEnumValue(this.campaignStatus, affiliate.campaign.status),
          };
          this.rows.push(row);
        }
        this.rows = [...this.rows];
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
    });

    // campaigns search
    this.searchValues.campaigns.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.campaignService.fetchCampaigns(1, { keyword }));

    // get campaign list
    this.getCampaigns();
  }

  getCampaigns(): void {
    this.campaigns$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.lists.campaigns = state.data;
        }
      });
  }

  onClearFilter() {
    this.filterOptions = {
      keyword: '',
      status: [],
      campaigns: [],
      campaignStatus: [],
      created_from: '',
      created_to: ''
    };

    this.dateOption = {
      created_from: '',
      created_to: ''
    };

    this.selectedValues = {
      campaigns: [],
      affTypes: []
    };
  }

  getSearchValue(options, name: string): void {
    // search value in managers
    this.searchValues[name].patchValue(options.term);
  }

  onSelectedValue(values: any[], name: string): void {
    let selectedValues: number[] = [];
    for (const value of values) {
      selectedValues.push(value.id);
    }
    this.filterOptions[name] = selectedValues;
    this.affiliateService.fetchAffiliateByAffManager(this.affManagerId, 1, this.filterOptions);
  }

  onDateChanged(event, name: string): void {
    this.filterOptions[name] = event.formatted;
    this.affiliateService.fetchAffiliateByAffManager(this.affManagerId, 1, this.filterOptions);
  }

  onCalendarToggle(e: Event, name: string): void {
    this.pageService.disableDate({
      name, 
      date: name === 'created_from' ? this.filterOptions.created_to : this.filterOptions.created_from 
    });
  }

  goToLink(id: string): void {
    HelperService.goToLink(`/affiliates/affiliate/${id}`);
  }

  onChangeFormValue(options: IFormOptions) {
    switch(options.name) {
      case 'clearFilter' :
        this.onClearFilter();
        // reset selected value
        this.affiliateService.fetchAffiliateByAffManager(this.affManagerId, 1, this.filterOptions);
        break;

      case 'keyword':
        this.searchValues.keyword.patchValue(options.value)
        break;

      case 'status' :
      case 'campaignStatus' :
        if (options.checked) {
          this.filterOptions[options.name].push(options.value);
        } else {
          // find unchecked value and remove from status array
          const findIndex = this.filterOptions[options.name].findIndex(val => val === options.value);
          this.filterOptions[options.name].splice(findIndex, 1);
        }
        this.affiliateService.fetchAffiliateByAffManager(this.affManagerId, 1, this.filterOptions);
        break;

      default :
      this.filterOptions[options.name] = options.value;
      this.affiliateService.fetchAffiliateByAffManager(this.affManagerId, 1, this.filterOptions);
    }
  }

  setPage(e): void {
    this.affiliateService.fetchAffiliates(e.offset + 1, this.filterOptions);
  }

  onToggleFilter(): void {
    this.showFiterComponent = !this.showFiterComponent;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
