import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import {
  fetchAffiliateManagers,
  fetchAffiliateManagersSuccess,
  fetchAffiliateManagersFailure,
  fetchFilteredAffiliateManagers,
  fetchFilteredAffiliateManagersSuccess,
  fetchFilteredAffiliateManagersFailure,
  getAffiliateManager,
  getAffiliateManagerSuccess,
  getAffiliateManagerFailure,
  deleteAffiliateManager,
  deleteAffiliateManagerSuccess,
  deleteAffiliateManagerFailure,
  addAffiliateManager,
  addAffiliateManagerSuccess,
  addAffiliateManagerFailure,
  updateAffiliateManager,
  updateAffiliateManagerSuccess,
  updateAffiliateManagerFailure
} from "./affiliate-managers.action";
import { of } from "rxjs";
import { map, mergeMap, catchError, tap } from "rxjs/operators";
import { AffiliateManagersService } from "@services/affiliate-managers/affiliate-managers.service";
import { SharedService } from '@services/shared/shared.service';

@Injectable()
export class AffiliateManagerssEffects {
  loadAffiliateManagers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAffiliateManagers),
      mergeMap((action) =>
        this.affiliateManagersService.getAffiliateManagers(action.page, action.filterOptions).pipe(
          map(res => {
            return fetchAffiliateManagersSuccess({ affiliateManagers: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchAffiliateManagersFailure({ error }));
          })
        )
      )
    )
  );

  loadFilteredAffiliateManagers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchFilteredAffiliateManagers),
      mergeMap((action) =>
        this.affiliateManagersService.getAffiliateManagers(action.page, action.filterOptions).pipe(
          map(res => {
            return fetchFilteredAffiliateManagersSuccess({ affiliateManagers: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchFilteredAffiliateManagersFailure({ error }));
          })
        )
      )
    )
  );

  getAffiliateManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAffiliateManager),
      mergeMap(action => 
        this.affiliateManagersService.getAffiliateManager(action.id).pipe(
          map(res => {
            return getAffiliateManagerSuccess({ affiliateManager: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(getAffiliateManagerFailure({ error }));
          })
        )
      )
    )
  );

  deleteAffiliateManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAffiliateManager),
      mergeMap(action =>
        this.affiliateManagersService.removeAffiliateManager(action.id).pipe(
          map(() => {
            return deleteAffiliateManagerSuccess({ id: action.id });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(deleteAffiliateManagerFailure({ error }));
          })
        )
      )
    )
  );

  addAffiliateManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addAffiliateManager),
      mergeMap(action =>
        this.affiliateManagersService.insertAffiliateManager(action.affiliateManager).pipe(
          map(affiliateManager => {
            return addAffiliateManagerSuccess({ affiliateManager });
          }),
          tap(res => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
            setTimeout(() => {
              this.router.navigate([`affiliate-managers/affiliate-manager/${res.affiliateManager["id"]}`]);
            }, 2000);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(addAffiliateManagerFailure({ error }));
          })
        )
      )
    )
  );

  updateAffiliateManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAffiliateManager),
      mergeMap(action =>
        this.affiliateManagersService.renewAffiliateManager(action.affiliateManager).pipe(
          map(affiliateManager => {
            return updateAffiliateManagerSuccess({ affiliateManager });
          }),
          tap(res => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(updateAffiliateManagerFailure({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private affiliateManagersService: AffiliateManagersService,
    private sharedService: SharedService,
  ) {}
}
