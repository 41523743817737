export enum CommissionGroupClickApproval {
    AUTO = 'auto',
    MANUAL = 'manual',
}
  
export enum CampaignType {
  PUBLIC = 'public',
  APPROVAL = 'approval',
  INVITED = 'invited',
}

export enum ShowCampaignTypeValue {
  public = 'Public',
  approval = 'Public with approval',
  invited = 'Private'
}

export enum SalesApproval {
  AUTO = 'auto',
  MANUAL = 'manual',
}

export enum StatusType {
  MANUAL = 'manual',
  AUTO = 'auto',
}

export enum Status {
  ACTIVE = 1,
  PAUSED = 0,
  STOPPED = -1,
}

export enum AutoCurrency {
  MONEY = 'money',
  PERCENT = 'percent',
}

export enum FixedCostsCurrency {
  MONEY = 'money',
  PERCENT = 'percent',
}

export enum LinkingMethod {
  DEFAULT = 'default',
  ANCHOR = 'anchor',
  NEWSTYLE = 'new_style',
  MODREWRITE = 'mod_rewrite',
  DIRECT = 'direct',
  REDIRECT = 'redirect'
}

export enum StatusCondition {
  INVOLVED = 1,
  INVITED = -1, 
  SUSPENDED = 0
}

export enum CampaignAffiliateStatus {
  PENDING = 'pending',
  SUSPENDED = 'suspended',
  DECLINED = 'declined',
  INVITED = 'invited',
  INVOLVED = 'involved',
}

export enum ArchivedCampaign {
  ARCHIVED = 'true'
}