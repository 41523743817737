import { createReducer, on } from "@ngrx/store";
import {
    fetchPayment,
    fetchPaymentSuccess,
    fetchPaymentFailure,
    getPayment,
    getPaymentSuccess,
    getPaymentFailure,
    addPayment,
    addPaymentSuccess,
    addPaymentFailure,
} from "./payment.actions";

import { IState } from "@models/index";

const initialState: IState = {
  isLoaded: false,
  isSaved: false,
  data: [],
  selectedItem: {},
  error: null,
  total: 0,
  maxPages: 0,
  perPage: 0,
  currentPage: 0
};

const _paymentReducer = createReducer(
  initialState,
  on(fetchPayment, (state, { page, filterOptions }) => {
    return state;
  }),
  on(fetchPaymentSuccess, (state, { payment }) => {
    const payload = {
      isLoaded: true,
      isSaved: false,
      data: payment.data,
      total: payment.total,
      maxPages: payment.maxPages,
      perPage: payment.perPage,
      currentPage: payment.currentPage,
      selectedItem: {}
    };

    return { ...state, ...payload };
  }),
  on(fetchPaymentFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(getPayment, (state, { id }) => {
    return state;
  }),
  on(getPaymentSuccess, (state, { payment }) => {
    const payload = {
      selectedItem: payment
    };
    return { ...state, ...payload };
  }),
  on(getPaymentFailure, (state, { error }) => {
    const payload = {
      error
    };
    return { ...state, ...payload };
  }),
  on(addPayment, (state, { payment }) => {
    const payload = {
      isSaved: false,
      isLoaded: false
    };
    return { ...state, ...payload };
  }),
  on(addPaymentSuccess, (state, { payment }) => {
    const payload = {
      isSaved: true,
      data: [...state.data, { ...payment }]
    };
    return { ...state, ...payload };
  }),
  on(addPaymentFailure, (state, { error }) => {
    const payload = {
      error
    };
    return { ...state, ...payload };
  })
);

export function paymentReducer(state, action) {
  return _paymentReducer(state, action);
}
