import { Component, OnInit, OnDestroy } from '@angular/core';
import { IPage } from '@interfaces/index';
import { PageService, HelperService, ReportService } from "@services/index";
import { Observable, Subject } from 'rxjs';
import { IState } from "@models/index";
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'aff-top-affiliates',
  templateUrl: './top-affiliates.component.html',
  styleUrls: ['./top-affiliates.component.scss']
})
export class TopAffiliatesPage implements OnInit, OnDestroy {

 
  loadingIndicator: boolean = true;
  selectedRows = [];
  rows = [];

  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };


  topAffiliates$: Observable<IState>;
  unsubscribe$ = new Subject<void>();
  
  constructor(
    private reportService: ReportService,
    private pageService: PageService
  ) { 

    this.rows = [];
    this.topAffiliates$ = this.reportService.getReportsSelector();

    pageService.changePageInfo({ breadcrum: ['Reports', 'Top Affiliates']});
  }

  ngOnInit() {
    // get top affiliate list
    this.reportService.fetchTopAffiliates(1, {});

    // listen campaign list
    this.topAffiliates$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.rows = [];

          for (const topAffiliate of state.data) {
            const row = {
              id: topAffiliate.id, 
              affiliateName: topAffiliate.first_name + ' ' + topAffiliate.last_name,
              affiliateStatus: topAffiliate.affiliate_status,
              affiliateType: topAffiliate.affiliate_type,
              affiliateJoinedDate: topAffiliate.join_date ,
              // affiliateManager: topAffiliate.manager_email, 
              leadsRatio: '',
              leadsCountSum: topAffiliate.lcount + '/$' + topAffiliate.lsum/100 , 
              salesCountSum: topAffiliate.scount + '/$' + topAffiliate.ssum/100 , 
              returnsCountSum: '',
              secondTierCommissionSum: '',
              total: '$' + topAffiliate.total/100
            };
            this.rows.push(row);
          }
        }
        this.rows = [...this.rows];
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
    });
}
  
  setPage(e): void {
    this.reportService.fetchTopAffiliates(1, {});
  }

  onSelect(e): void {
    this.selectedRows = e.selected;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
