import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import {
  fetchCommissionGroup,
  fetchCommissionGroupSuccess,
  fetchCommissionGroupFailure,
  fetchFilteredCommissionGroups,
  fetchFilteredCommissionGroupsSuccess,
  fetchFilteredCommissionGroupsFailure,
  getCommissionGroup,
  getCommissionGroupSuccess,
  getCommissionGroupFailure,
  deleteCommissionGroup,
  deleteCommissionGroupSuccess,
  deleteCommissionGroupFailure,
  addCommissionGroup,
  addCommissionGroupSuccess,
  addCommissionGroupFailure,
  updateCommissionGroup,
  updateCommissionGroupSuccess,
  updateCommissionGroupFailure,
  fetchCommissionGroupsByAffiliate,
  fetchCommissionGroupsByAffiliateSuccess,
  fetchCommissionGroupsByAffiliateFailure,
  makeDefaultComGroup,
  makeDefaultComGroupSuccess,
  makeDefaultComGroupFailure
} from "./commission-groups.actions";
import { EMPTY, of } from "rxjs";
import { map, mergeMap, catchError, tap } from "rxjs/operators";
import { CommissionGroupService } from "@services/commission-groups/commission-group.service";
import { SharedService } from '@services/shared/shared.service';

@Injectable()
export class CommissionGroupEffects {
  loadCommissionGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCommissionGroup),
      mergeMap((action) =>
        this.commissionGroupService.getCommissionGroups(action.page, action.campaignID, action.filterOptions).pipe(
          map(res => {
            return fetchCommissionGroupSuccess({ commissionGroup: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchCommissionGroupFailure({ error }));
          })
        )
      )
    )
  );

  loadFilteredCommissionGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchFilteredCommissionGroups),
      mergeMap((action) =>
        this.commissionGroupService.getCommissionGroups(action.page, action.campaignID, action.filterOptions).pipe(
          map(res => {
            return fetchFilteredCommissionGroupsSuccess({ commissionGroups: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchFilteredCommissionGroupsFailure({ error }));
          })
        )
      )
    )
  );

  loadCommissionGroupsByAffiliate$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fetchCommissionGroupsByAffiliate),
    mergeMap((action) =>
      this.commissionGroupService.getCommissionGroupsByAffiliate(action.page, action.affiliateID, action.filterOptions).pipe(
        map(res => {
          return fetchCommissionGroupsByAffiliateSuccess({ commissionGroup: res });
        }),
        catchError(error => {
          this.sharedService.onLoaderToggle(false);
          return of(fetchCommissionGroupsByAffiliateFailure({ error }));
        })
      )
    )
  )
);

  getCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCommissionGroup),
      mergeMap(action => 
        this.commissionGroupService.getCommissionGroup(action.id).pipe(
          map(res => {
            return getCommissionGroupSuccess({ commissionGroup: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(getCommissionGroupFailure({ error }));
          })
        )
      )
    )
  );

  deleteCommissionGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCommissionGroup),
      mergeMap(action =>
        this.commissionGroupService.removeCommissionGroup(action.id, action.moveAffToGroupId).pipe(
          map(() => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
            return deleteCommissionGroupSuccess({ 
              id: action.id, 
              moveAffToGroupId: action.moveAffToGroupId, 
              affCount: action.affCount 
            });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(deleteCommissionGroupFailure({ error }));
          })
        )
      )
    )
  );

  addCommissionGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCommissionGroup),
      mergeMap(action =>
        this.commissionGroupService.insertCommissionGroup(action.commissionGroup).pipe(
          map(commissionGroup => {
            return addCommissionGroupSuccess({ commissionGroup });
          }),
          tap(res => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(addCommissionGroupFailure({ error }));
          })
        )
      )
    )
  );

  updateCommissionGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCommissionGroup),
      mergeMap(action =>
        this.commissionGroupService.renewCommissionGroup(action.commissionGroup).pipe(
          map(commissionGroup => {
            return updateCommissionGroupSuccess({ commissionGroup });
          }),
          tap(res => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(updateCommissionGroupFailure({ error }));
          })
        )
      )
    )
  );

  makeDefaultComGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(makeDefaultComGroup),
      mergeMap(action =>
        this.commissionGroupService.renewDefaultComGroup(action.id, action.campaignId).pipe(
          map(comGroup => {
            return makeDefaultComGroupSuccess({ id: action.id, comGroup });
          }),
          tap(res => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(makeDefaultComGroupFailure({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private commissionGroupService: CommissionGroupService,
    private sharedService: SharedService,
    private router: Router
  ) {}
}
