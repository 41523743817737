import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TableColumn} from "@swimlane/ngx-datatable";
import {Observable, Subject} from "rxjs";
import {CampaignUrlStore} from "@services/campaign/campaign-url.store";
import {StoreModel} from "@interfaces/IStoreModel";
import {ICampaignUrlsState} from "@models/iCampaignUrlsState";
import {IModalvalue} from "@shared-models/iModalValue";
import {IPage} from "@interfaces/iPage";
import {HelperService} from "@services/helper/helper.service";
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {ConfirmationModal} from "@models/index";

@Component({
  selector: 'aff-campaign-tab-urls',
  templateUrl: './campaign-tab-urls.component.html',
  styleUrls: ['./campaign-tab-urls.component.scss']
})
export class CampaignTabUrlsComponent implements OnInit, OnDestroy {
  @Input('campaignID') campaignID: any;
  loadingIndicator: any;
  rows: any = [];
  columns: TableColumn[] = [];
  page: any;
  selectedRows: any;
  selected: any;

  private subs$: Subject<any> = new Subject();
  private campaignUrls$: Observable<any>;

  public modalEl: ConfirmationModal = {
    opened: false,
    value: '',
    event: '',
    type: 'InputConfirmation',
    question: 'test',
    details: 'details',
    label: ''
  };

  public popUpEl: any = {
    opened: false,
    title: '',
    label: '',
    type: '',
  }

  public selectedRow: any;

  public pageData: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  public urlForm: FormGroup;
  private campaignUrlsEvents$: Observable<any>;

  constructor(
    private campaignUrlStore: CampaignUrlStore,
    private fb: FormBuilder,
  ) {
    this.campaignUrls$ = this.campaignUrlStore.selectCampaignUrls();
    this.campaignUrlsEvents$ = this.campaignUrlStore.selectCampaignUrlsEvents();
  }

  ngOnInit() {
    this.initTable();
    this.initForm();
    this.columns = [
      {name: "ID", flexGrow: 1},
      {name: "URL", flexGrow: 1},
      {name: "Action", flexGrow: .35}
    ];
  }

  ngOnDestroy() {
    this.subs$.next();
    this.subs$.complete();
  }

  initForm() {
    this.urlForm = this.fb.group({
      url: ['', [Validators.required]]
    }, {
      validator: HelperService.urlValidator('url')
    });
  }

  fillModal(obj: ConfirmationModal) {
    for (const field in obj) {
      if (obj.hasOwnProperty(field)) this.modalEl[field] = obj[field];
    }
  }

  initTable(data?) {
    this.campaignUrlStore.fetchUrls(1, this.campaignID);

    this.campaignUrls$.subscribe(resp => {
      // console.log('[campaignUrls$]', resp);

      if (resp.isLoaded) {
        this.rows = resp.data.map((i) => ({id: i.id, url: i.url}));
        if (resp.perPage) {
          const {data, action, selectedItem, error,...respPage} = resp;

          this.pageData = respPage;
        }

        if (resp && !!!resp.error) {
          this.fillModal({opened: false});
          this.onClosePopup(false);
        }
      }
    })
  }


  setPage(e: any) {
    this.campaignUrlStore.fetchUrls(e.offset + 1, this.campaignID);
  }

  onSelect(e: any) {
    console.log('setPage', e);
  }

  onAddUrl(e: any) {
    this.popUpEl = Object.assign(this.popUpEl, {
      opened: true,
      title: 'Add URL',
      label: 'Add URL',
      type: 'add'
    });
  }

  editUrl(e) {
    this.selectedRow = e;
    this.popUpEl = Object.assign(this.popUpEl, {
      opened: true,
      title: 'Edit',
      label: 'Edit',
      type: 'edit'
    });
    this.urlForm.get('url').patchValue(e.url);
    this.urlForm.markAsTouched();
  }

  deleteUrl(e: any) {
    this.selectedRow = e;
    this.fillModal({
      opened: true,
      label: 'Delete',
      event: 'delete',
      question: 'Want to delete?',
      details: this.selectedRow.url,
      type: 'Confirmation',
    })
  }

  onPopUpEvent(e: IModalvalue) {
    if (e.cancel) {
      this.modalEl.opened = false;
      if (this.modalEl.value) {
        this.modalEl.value = '';
      }
    } else if (e.confirm) {
      switch (this.modalEl.event) {
        case 'delete':
          this.campaignUrlStore.deleteUrl(this.selectedRow.id, {
            campaign_id: parseFloat(this.campaignID)
          });
          break;
        default:
          break;
      }
    }
  }

  onChangeFormValue(e: any) {
    this.urlForm.controls[e.name].patchValue(e.value);
    if (this.urlForm.controls[e.name].touched || !e.value) { // bad case for get dirtyAndClean
      this.urlForm.controls[e.name].markAsUntouched();
    }
  }

  onSubmit() {
    const titleValue = this.urlForm.get('url');

    if (titleValue.errors && Object.values(titleValue.errors)) {
      this.urlForm.markAsTouched();
      return false;
    }

    const sendData = {
      campaign_id: parseFloat(this.campaignID),
      url: titleValue.value
    };

    if (this.popUpEl.type === 'add') {
      this.campaignUrlStore.addUrl(sendData);
    } else if (this.popUpEl.type === 'edit') {
      this.campaignUrlStore.editUrl(this.selectedRow.id, sendData);
    }
  }

  onCloseModal(e: boolean) {
    this.fillModal({
      opened: e,
    })
  }

  onClosePopup(e: boolean) {
    if (!e) {
      this.popUpEl.opened = e;
      this.popUpEl.label = '';
      this.urlForm.reset({
        url: '',
      });
      return;
    }

    this.popUpEl.opened = e;
  }

  errorView(errors: ValidationErrors) {
    const errorFields = Object.keys(errors);
    const initError = errorFields[0];
    if (initError === 'UrlFormatIncorrect') {
      return `field must be starts format ('https://')`;
    }

    return Object.values(errors) ? Object.keys(errors)[0] : 'error';
  }
}
