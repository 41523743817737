import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '@shared/shared.module';
import { MyDatePickerModule } from 'mydatepicker';
import { DashboardPage } from './dashboard-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AffiliateActivityComponent } from '@components/index';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { DashboardChartsComponent } from './dashboard-charts/dashboard-charts.component';
import { DashboardTablesComponent } from './dashboard-tables/dashboard-tables.component';
import { SharedDirectivesModule } from '@directives/index';
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [
    DashboardPage,
    AffiliateActivityComponent,
    DashboardHeaderComponent,
    DashboardChartsComponent,
    DashboardTablesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    SharedModule,
    MyDatePickerModule,
    NgSelectModule,
    SharedDirectivesModule,
    RouterModule
  ]
})
export class DashboardPageModule { }
