import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'aff-manage-sources-list-page',
  templateUrl: './manage-sources-list-page.component.html',
  styleUrls: ['./manage-sources-list-page.component.scss']
})
export class ManageSourcesListPage implements OnInit {
  public data = {
    rows : [
      { sourceName: 'gabi test comm', affiliateCount: 'Daniel Brown', lastRegistered: '28%'},
      { sourceName: 'gabi test comm', affiliateCount: 'Daniel Brown', lastRegistered: '28%'},
      { sourceName: 'gabi test comm', affiliateCount: 'Daniel Brown', lastRegistered: '28%'},
      { sourceName: 'gabi test comm', affiliateCount: 'Daniel Brown', lastRegistered: '28%'},
      { sourceName: 'gabi test comm', affiliateCount: 'Daniel Brown', lastRegistered: '28%'},
    ],
    columns : [
      { name: 'Source name' },
      { name: 'Affiliate count' },
      { name: 'Last registered' },
    ]
  };

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onAddPromotion() {
    this.router.navigate(['/manage_affiliates/manage_sources/add']);
  }

}
