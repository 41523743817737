import { Component, OnInit, OnDestroy } from '@angular/core';
import * as ENUMS from '@enums/index';
import { BannerService, UserService, HelperService, CampaignService, AffiliateManagersService } from "@services/index";
import { IModalvalue } from '@shared-models/index';
import { Subject, Observable } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { IState } from "@models/index";
import {UserStatus} from "@enums/index";

@Component({
  selector: 'aff-banners-list-filter',
  templateUrl: './banners-list-filter.component.html',
  styleUrls: ['./banners-list-filter.component.scss']
})
export class BannersListFilterComponent implements OnInit, OnDestroy {
  filters$: Observable<any>;
  selectedFilter$: Observable<any>;

  savedFilterList: any = {};
  filterSelectedValue: string | number = '';
  filterSelectedId: number;
  isFilterEmpty: boolean = true;

  keyword = new FormControl();

  filterOptions = {
    keyword: '',
    managers: [],
    type: [],
    bannerSize: '',
    campaign: '',
    width: '',
    height: '',
    hidden_from: [],
    show_hidden: false
  };

  filterNamePopup: boolean = false;
  showFiterComponent: boolean = false;

  campaing$: Observable<IState>;
  campaigns: any = [];
  campaignsSearch = new FormControl();
  selectedCampaign: any;

  affiliateManagers$: Observable<any>;
  managersList: any = [];
  managersSearch = new FormControl();
  selectedManagers: any = [];

  private unsubscribe$ = new Subject<void>();

  public userStatus = UserStatus;

  constructor(
    private bannerService: BannerService,
    private userService: UserService,
    private campaignService: CampaignService,
    private affiliateManagersService: AffiliateManagersService,
    private helpService: HelperService
    ) {
    this.filters$ = this.userService.filtersSelector();
    this.selectedFilter$ = this.userService.filterSelector();

    this.campaing$ = this.campaignService.getCampaignsSelector();
    // get affiliate managers
    this.affiliateManagers$ = this.affiliateManagersService.getAffiliateManagersSelector();
    this.affiliateManagersService.fetchAffiliateManagers(1, {});
  }

  ngOnInit() {
    // listen value change of keyword
    this.keyword.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
          debounceTime(500),
          distinctUntilChanged()
      )
      .subscribe(newValue => {

        this.filterOptions['keyword'] = newValue;
        this.bannerService.fetchBanners(1, this.filterOptions);
      });

    // Get filters
    this.userService.fetchFilters('banner');
    this.filters$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded && state.data && state.data.length) {
          this.savedFilterList = {};
          state.data.forEach(filter => {
            this.savedFilterList = {
              ...this.savedFilterList,
              [filter.id]: filter.name
            }
          });
        }
      });

    // Get selected filter
    this.selectedFilter$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(filter => {
        if (filter.type !== 'banner') return;
        const filterOpt = filter.filter_fields;
        if (filterOpt) {
          this.onClearFilter();
          filterOpt.forEach(field => {
            if (field.key === 'show_hidden') {
              field.value = this.helpService.numberToBool(field.value);
            }
          });

          HelperService.fillFilterOption(filterOpt, this.filterOptions);
          this.filterSelectedId = filter.id;
          this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
          this.bannerService.fetchBanners(1, this.filterOptions);
          this.showFiterComponent = true;



          // console.log('filters', this.filterOptions);
          // console.log('filterOpt', filterOpt);
        }
      });

    // owner search
    this.campaignsSearch.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.campaignService.fetchCampaigns(1, { keyword }));

    // get campaign list
    this.getCampaigns();

    // owner search
    this.managersSearch.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.affiliateManagersService.fetchAffiliateManagers(1, { keyword }));

    // get affiliate managers
    this.getAffiliateManagers();
  }

  getCampaigns() {
    this.campaignService.fetchCampaigns(1, {});
    // listen campaign
    this.campaing$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.campaigns = state.data
      })
  }

  getSearchValue(options): void {
    // search value in managers
    this.campaignsSearch.patchValue(options.term);
  }

  onChangeCampaignSelection(campaigns): void {
    let selectedCamp: number[] = [];
    for (const campaign of campaigns) {
      selectedCamp.push(campaign.id);
    }
    this.filterOptions['campaigns'] = selectedCamp;
    this.selectedCampaign = campaigns;
    this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
    this.bannerService.fetchBanners(1, this.filterOptions);
  }

  getAffiliateManagers(): void {
    this.affiliateManagers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.managersList = state.data;
      });
  }

  getManagerSearchValue(options: any): void {
    // search value in managers
    this.managersSearch.patchValue(options.term);
  }

  onChangeManagersSelection(managers: any[]): void {
    let affiliateManagers: number[] = [];
    for (const affiliate of managers) {
      affiliateManagers.push(affiliate.id);
    }
    this.filterOptions['managers'] = affiliateManagers;
    this.selectedManagers = managers;
    this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
    this.bannerService.fetchBanners(1, this.filterOptions);
  }

  setFilterNamePopup(option: IModalvalue) {
    if (option.confirm) {
      if (!this.userService.checkFilterName(option.inputValue, this.savedFilterList, this.filterSelectedId)) return;
      delete this.filterOptions.keyword;
      let filters = this.filterOptions;

      this.filterSelectedId
        ? this.userService.updateFilter({
            id: this.filterSelectedId,
            name: option.inputValue,
            type: 'banner',
            additional_fields: filters
          })
        :  this.userService.addFilter({
            name: option.inputValue,
            type: 'banner',
            additional_fields: filters
          });
    }

    this.filterNamePopup = false;
  }

  onToggleFilter(): void {
    this.showFiterComponent = !this.showFiterComponent;
  }

  getSelectedFilter(id): void {
    this.filterSelectedValue = this.savedFilterList[id];
    this.userService.fetchFilter(id);
  }

  onDeleteFilter(id): void {
    this.userService.deleteFilter(id);
  }

  onClearFilter() {
    this.filterOptions = {
      keyword: '',
      managers: [],
      type: [],
      bannerSize: '',
      campaign: '',
      width: '',
      height: '',
      show_hidden: false,
      hidden_from: []
    };
  }

  onChangeFormValue(options) {
    switch (options.name) {
      case 'clearFilter':
        this.onClearFilter();
        this.filterSelectedValue = '';
        this.filterSelectedId = null;
        this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
        this.bannerService.fetchBanners(1, this.filterOptions);
        break;

      case 'saveFilter':
        if (this.isFilterEmpty) return;
        this.filterNamePopup = true;
        break;

      case 'type':
        if (options.checked) {
          this.filterOptions['type'].push(options.value)
        } else {
          // find unchecked value and remove from type array
          const findIndex = this.filterOptions['type'].findIndex(val => val === options.value);
          this.filterOptions['type'].splice(findIndex, 1)
        }
        this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
        this.bannerService.fetchBanners(1, this.filterOptions);
        break;

      case 'keyword':
        this.keyword.patchValue(options.value)
        break;

      case 'show_hidden':
        this.filterOptions.show_hidden = !this.helpService.numberToBool(this.filterOptions.show_hidden);
        this.bannerService.fetchBanners(1, this.filterOptions);
        this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
        break;

      case 'hidden_from':
        if (options.checked) {
          this.filterOptions[options.name].push(options.value)
        } else {
          // find unchecked value and remove from type array
          const findIndex = this.filterOptions[options.name].findIndex(val => val === options.value);
          this.filterOptions[options.name].splice(findIndex, 1)
        }

        this.bannerService.fetchBanners(1, this.filterOptions);
        this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
        console.log('type', this.filterOptions);
        break;

      default:
        this.filterOptions[options.name] = options.value;
        this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
        this.bannerService.fetchBanners(1, this.filterOptions);
    }
  }

  ngOnDestroy(): void {
    this.userService.clearSelectedFilter();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
