import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';

import { Store } from "@ngrx/store";
import { getAffiliateManagerSelector, getAffiliateManagersSelector } from "@store/index";
import { IState } from "@models/index";

import { 
  AffiliateManagersService, 
  HelperService, 
  SharedService, 
  LanguageService, 
  PageService} from '@services/index';
import * as ENUMS from "@enums/index";
import { IFormOptions } from '@interfaces/index';
import { UserStatus } from '@models/iPage';
import { UserRole } from '@enums/index';

@Component({
  selector: 'aff-affiliate-managers-add-form',
  templateUrl: './affiliate-managers-add-form.component.html',
  styleUrls: ['./affiliate-managers-add-form.component.scss']
})
export class AffiliateManagersAddForm implements OnInit, OnDestroy {
  affManagersAddForm: FormGroup;
  errorObj: any = {};

  languages$: Observable<any>;
  selectedLanguage: any = null;

  status$: Observable<UserStatus>;
  selectedStatus: any = null;

  affManagerID: string = '';
  imageURL: string = '';
  editMode: boolean = false;

  affManager$: Observable<any>;
  affManagers$: Observable<IState>;
  private unsubscribe$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder,
              private affiliateManagersService: AffiliateManagersService,
              private sharedService: SharedService,
              private languageService: LanguageService,
              private route: ActivatedRoute,
              private pageService: PageService) { 
                
      this.affManagersAddForm = this.formBuilder.group({
        file_id: new FormControl(null),
        password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
        confirm_password: new FormControl(null, [Validators.required]),
        first_name: new FormControl(null, [Validators.required, Validators.pattern("^[a-zA-Z]*$")]),
        last_name: new FormControl(null, [Validators.required, Validators.pattern("^[a-zA-Z]*$")]),
        id: new FormControl(null, [Validators.pattern("^[0-9]*$")]),
        email: new FormControl(null, [Validators.required, Validators.email]),
        phone: new FormControl(null, [Validators.pattern("^[0-9]*$")]),
        status_id: new FormControl(null, [Validators.required]),
        language_id: new FormControl(null),
        affiliate_token: new FormControl(null),
        send_notification: new FormControl(false, [Validators.required]),
        note: new FormControl(null),
        role: new FormControl(2, [Validators.required])
      }, {
        validator: HelperService.passwordMatchValidator
      });

      // select statuses
      this.status$ = this.pageService.getUserStatusSelector();
      this.pageService.fetchUserStatuses({ roleId: UserRole.MANAGER });

      // select affiliate manager
      this.affManager$ = this.affiliateManagersService.getAffiliateManagerSelector();

      this.affManagers$ = this.affiliateManagersService.getAffiliateManagersSelector();

      // get languages
      this.languages$ = this.languageService.getLanguageListSelector();
      this.languageService.fetchLanguages();
    }

  ngOnInit(): void {
    // get affiliate manager id
    this.affManagerID = this.route.snapshot.params.id;

    this.affManagers$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      if (!state.isLoaded) return;
      if (state.error === 'duplicate email address') {
        this.errorObj['email'] = 'This email already exist!';
      } else { 
        delete this.errorObj['email']
      }
    });

    this.affManager$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(affManager => {
        // check mode
        if (HelperService.isObjectEmpty(affManager)) {
          return this.editMode = false;
        }

        this.editMode = true;
        //set breadcrum
        // this.pageService.changePageInfo({ breadcrum: ['Affiliate Managers', affManager.email] });

        // fill form values
        HelperService.fillFormValues(affManager, this.affManagersAddForm);
        this.affManagersAddForm.patchValue({ language_id: affManager.language ? affManager.language.id : null, })
        this.imageURL = affManager.image_url;
        this.selectedStatus = affManager.status;
        this.selectedLanguage = affManager.language;
        this.affManagersAddForm.value.password = null;
        this.affManagersAddForm.value.confirm_password = null;
      });
  }

  saveAffManager(): void {
    // check validations
    this.errorObj = HelperService.checkValidation(this.affManagersAddForm);
    
    // add new affiliate manager
    if (!this.editMode && this.affManagersAddForm.valid) {
      this.affiliateManagersService.addAffiliateManager(this.affManagersAddForm.value);
    } 

    // update affiliate manager
    if (this.editMode) {
      if (!this.affManagersAddForm.value.password && !this.affManagersAddForm.value.confirm_password) {
        delete this.errorObj['password'];
        delete this.errorObj['confirm_password'];
      }

      if (HelperService.isObjectEmpty(this.errorObj)) {
        this.affiliateManagersService.updateAffiliateManager({ id: this.affManagerID, ...this.affManagersAddForm.value });
      }
    }
  }

  onChangeLanguage(language: any): void {
    this.affManagersAddForm.patchValue({ language_id: language ? language.id : null });
  }

  onChangeStatus(status: any): void {
    this.affManagersAddForm.patchValue({ status_id: status ? status.id : null });
  }

  uploadImage(files): void {
    this.sharedService.onLoaderToggle(true);
    const imageFile: FormData = HelperService.createFormData(files); 
    
    this.sharedService.uploadImage(imageFile, 'user')
      .then(res => {
        this.imageURL = res['url'];
        this.affManagersAddForm.patchValue({ file_id: res['id'] });
        this.sharedService.onLoaderToggle(false);
      })
      .catch(err => {
        console.log(err);
        this.sharedService.onLoaderToggle(false);
      });
  }

  deleteImage(e: Event): void {
    e.stopPropagation();
    this.imageURL = '';
    this.affManagersAddForm.patchValue({ file_id: null });
  }

  onChangeFormValue(options: IFormOptions): void {
    switch (options.name) {
      case "submit":
        this.saveAffManager();
        break;
      
      case "image":
        this.uploadImage(options.value);
        break;

      case 'send_notification':
        this.affManagersAddForm.patchValue({ [options.name]: options.checked });
        break;
      
      default:
        this.affManagersAddForm.patchValue({ [options.name]: options.value.trim() });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
