import { createAction, props } from "@ngrx/store";

export const fetchOrders = createAction(
  "[Orders] Fetch",
  props<{ page, filterOptions }>()
);

export const fetchOrdersSuccess = createAction(
  "[Orders] Fetch Success",
  props<{ orders }>()
);

export const fetchOrdersFailure = createAction(
  "[Orders] Fetch Faild",
  props<{ error }>()
);

export const getOrder = createAction(
  "[Orders] Get",
  props<{ id }>()
);

export const getOrderSuccess = createAction(
  "[Orders] Get Success",
  props<{ order }>()
);

export const getOrderFailure = createAction(
  "[Orders] Get Failure",
  props<{ error }>()
);

export const updateOrder = createAction(
  "[Orders] Update",
  props<{ order, option }>()
);

export const updateOrderSuccess = createAction(
  "[Orders] Update Success",
  props<{ order }>()
);

export const updateOrderFailure = createAction(
  "[Orders] Update Failure",
  props<{ error }>()
);

export const createOrder = createAction(
  "[Orders] Create",
  props<{ order }>()
);

export const createOrderSuccess = createAction(
  "[Orders] Create Success",
  props<{ order }>()
);

export const createOrderFailure = createAction(
  "[Orders] Create Failure",
  props<{ error }>()
);

export const clearOrderState = createAction(
  "[Orders] Clear State"
);
