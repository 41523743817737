import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { map, mergeMap, catchError } from "rxjs/operators";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
    fetchGeneratedCommissions,
    fetchGeneratedCommissionsSuccess,
    fetchGeneratedCommissionsFailure,
    getGeneratedCommission,
    getGeneratedCommissionSuccess,
    getGeneratedCommissionFailure,
} from "./generated-commission.action";

import { SharedService } from '@services/shared/shared.service';
import { GeneratedCommissionService } from '@services/generated-commission/generated-commissions.service';

@Injectable()
export class GeneratedCommissionEffects {
  loadGeneratedCommission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchGeneratedCommissions),
      mergeMap((action) =>
        this.generatedCommissionService.getGeneratedCommissions(action.page, action.filterOptions).pipe(
          map(res => {
            return fetchGeneratedCommissionsSuccess({ generatedCommissions: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchGeneratedCommissionsFailure({ error }));
          })
        )
      )
    )
  );

  getGeneratedCommission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGeneratedCommission),
      mergeMap(action => 
        this.generatedCommissionService.getGeneratedCommission(action.id).pipe(
          map(res => {
            return getGeneratedCommissionSuccess({ generatedCommission: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(getGeneratedCommissionFailure({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private generatedCommissionService: GeneratedCommissionService,
    private sharedService: SharedService
  ) {}
}
