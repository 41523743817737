import { createReducer, on } from '@ngrx/store';
import {
  openLoader,
  closeLoader,
  openSaving,
  closeSaving } from './components.actions';

import { IComponentState } from '@models/index';

const initialState: IComponentState = {
  notification: {
    isShow: false,
    isError: false,
    message: '',
  },
  loader: false
};

const _componentsReducer = createReducer(initialState,
  on(openSaving, (state, { isError, message }) => {
    const payload = {
      notification: {
        isShow: true,
        isError,
        message
      }
    };

    return { ...state, ...payload };
  }),
  on(closeSaving, state => {
    const payload = {
      notification: {
        isShow: false,
        isError: false,
        message: ''
      }
    };

    return { ...state, ...payload };
  }),
  on(openLoader, state => {
    const payload = {
      loader: true
    };

    return { ...state, ...payload };
  }),
  on(closeLoader, state => {
    const payload = {
      loader: false
    };

    return { ...state, ...payload };
  }),
);

export function componentsReducer(state, action) {
  return _componentsReducer(state, action);
}
