import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';

import { PageService, SharedService } from '@services/index';

/**
 * App Component
 */
@Component({
  selector: 'aff-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  loader: boolean = false;

  pageTitle$: Observable<string>;
  loader$: Observable<boolean>;

  constructor(private sharedService: SharedService,
              private titleService: Title,
              private router: Router,
              private pageService: PageService) {
    this.loader$ = sharedService.getLoaderSelector();
    // select page title
    this.pageTitle$ = pageService.getPageTitleSelector();
    
    // set page url
    pageService.changePageInfo({ pageURL: this.router.url });
  }

  ngOnInit() {
    this.loader$
      .subscribe((val => {
        this.loader = val;
    }));
  
    this.pageTitle$
      .subscribe(title => this.setTitle(title));
  }

  setTitle( newTitle: string): void {
    this.titleService.setTitle( newTitle + ' - Affiliate Manager' );
  }
}
