import { Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { SharedService, HelperService, BannerService } from '@services/index';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'aff-banner-simple-pdf',
  templateUrl: './banner-simple-pdf.component.html',
  styleUrls: ['./banner-simple-pdf.component.scss']
})
export class BannerSimplePdfComponent implements OnInit {
  public bannerPdfForm: FormGroup;
  @Input() htmlcode;
  @Output() private getObjValues = new EventEmitter<any>();
  @Output() private previewClicked = new EventEmitter<any>();

  // set values from parent component
  @Input() set bannersAddForm(options) {
    HelperService.fillFormValues(options, this.bannerPdfForm);
  }

  errorObj: any = {};

  variables: string[] = [''];
  affiliates: string[] = [''];
  showIframe: boolean = false;
  url: string = '';
    
  public imageGenerator: boolean = false;
  generatorValues;  

  constructor(private sharedService: SharedService,  
              private formBuilder: FormBuilder,
              private bannerService: BannerService,
              private route: ActivatedRoute, ) { 
    this.bannerPdfForm = this.formBuilder.group({
      html_code: new FormControl(null, [Validators.required]),
      pdf_name: new FormControl(null, [Validators.required, Validators.pattern("^[0-9a-zA-Z]+$"), Validators.maxLength(50)]),
      upload_file: new FormControl(null)
    });
  }

  ngOnInit() {
      // check click saved button
      this.bannerService.clickSaved$
      .subscribe(clicked => {
        if (clicked) {
          // check validations
          this.errorObj = HelperService.checkValidation(this.bannerPdfForm);
        }
      })
      this.url = this.bannerService.getUrl(this.route.snapshot.params['id']);
  }

  onChangeFormValue(options) {
    switch(options.name) {
      case 'upload_file':
        if(options.value.length) {
          this.uploadImage(options.value);
          this.getObjValues.emit({ [options.name]: options.value });
        }
        break;

      default :
        this.bannerPdfForm.patchValue({ [options.name]: options.value });
        this.getObjValues.emit({ [options.name]: options.value });
    }
  }

  uploadImage(files) {
    this.sharedService.onLoaderToggle(true);
    let imageFile = HelperService.createFormData(files); 
    
    this.sharedService.uploadImage(imageFile, 'promotion-tool')
      .then(res => {
        this.bannerPdfForm.patchValue({ upload_file: res['id'] });
        this.sharedService.onLoaderToggle(false);
        this.getObjValues.emit({ upload_file: res['id'] });
        this.imageGenerator = true;
        
        this.generatorValues = res;
      })
      .catch(err => {
        this.sharedService.onLoaderToggle(false);
        this.imageGenerator = false;
      })
  }

  popupToggle(value) {
    this.imageGenerator = value;
  }

  iframeToggle(value: boolean) {
    console.log(this.url);
    this.showIframe = value; 
  }

  deleteImage() {
    this.bannerPdfForm.patchValue({ upload_file: null });
    this.getObjValues.emit({ upload_file: null });
  }
}
