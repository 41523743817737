import { createAction, props } from "@ngrx/store";

export const fetchAffiliateManagers = createAction(
  "[Campaign Affiliate Managers] Fetch",
  props<{ page, filterOptions }>()
);

export const fetchAffiliateManagersSuccess = createAction(
  "[Campaign Affiliate Managers] Fetch Success",
  props<{ affiliateManagers }>()
);

export const fetchAffiliateManagersFailure = createAction(
  "[Campaign Affiliate Managers] Fetch Faild",
  props<{ error }>()
);

export const fetchFilteredAffiliateManagers = createAction(
  "[Campaign Affiliate Managers] Fetch Filtered",
  props<{ page, filterOptions }>()
);

export const fetchFilteredAffiliateManagersSuccess = createAction(
  "[Campaign Affiliate Managers] Fetch Filtered Success",
  props<{ affiliateManagers }>()
);

export const fetchFilteredAffiliateManagersFailure = createAction(
  "[Campaign Affiliate Managers] Fetch Filtered Faild",
  props<{ error }>()
);

export const getAffiliateManager = createAction(
  "[Campaign Affiliate Managers] Get",
  props<{ id }>()
);

export const getAffiliateManagerSuccess = createAction(
  "[Campaign Affiliate Managers] Get Success",
  props<{ affiliateManager }>()
);

export const getAffiliateManagerFailure = createAction(
  "[Campaign Affiliate Managers] Get Failure",
  props<{ error }>()
);

export const clearSelectedManager = createAction("[Campaign Affiliate Managers] Clear Selected Manager");

export const deleteAffiliateManager = createAction(
  "[Campaign Affiliate Managers] Delete",
  props<{ id }>()
);

export const deleteAffiliateManagerSuccess = createAction(
  "[Campaign Affiliate Managers] Delete Success",
  props<{ id }>()
);

export const deleteAffiliateManagerFailure = createAction(
  "[Campaign Affiliate Managers] Delete Failure",
  props<{ error }>()
);

export const addAffiliateManager = createAction(
  "[Campaign Affiliate Managers] Add",
  props<{ affiliateManager }>()
);

export const addAffiliateManagerSuccess = createAction(
  "[Campaign Affiliate Managers] Add Success",
  props<{ affiliateManager }>()
);

export const addAffiliateManagerFailure = createAction(
  "[Campaign Affiliate Managers] Add Failure",
  props<{ error }>()
);

export const updateAffiliateManager = createAction(
  "[Campaign Affiliate Managers] Update",
  props<{ affiliateManager }>()
);

export const updateAffiliateManagerSuccess = createAction(
  "[Campaign Affiliate Managers] Update Success",
  props<{ affiliateManager }>()
);

export const updateAffiliateManagerFailure = createAction(
  "[Campaign Affiliate Managers] Update Failure",
  props<{ error }>()
);
