import { createAction, props } from "@ngrx/store";

export const fetchGeneratedCommissions = createAction(
  "[GeneratedCommissions] Fetch",
  props<{ page, filterOptions }>()
);

export const fetchGeneratedCommissionsSuccess = createAction(
  "[GeneratedCommissions] Fetch Success",
  props<{ generatedCommissions }>()
);

export const fetchGeneratedCommissionsFailure = createAction(
  "[GeneratedCommissions] Fetch Faild",
  props<{ error }>()
);

export const getGeneratedCommission = createAction(
  "[GeneratedCommissions] Get",
  props<{ id }>()
);

export const getGeneratedCommissionSuccess = createAction(
  "[GeneratedCommissions] Get Success",
  props<{ generatedCommission }>()
);

export const getGeneratedCommissionFailure = createAction(
  "[GeneratedCommissions] Get Failure",
  props<{ error }>()
);

export const updateGeneratedCommissions = createAction(
  "[GeneratedCommissions] Update",
  props<{ generatedCommissions }>()
);

export const clearSelectedGenCommision = createAction(
  "[GeneratedCommissions] Clear Selected Item"
);