import { createAction, props } from "@ngrx/store";


export const fetchEventCategories = createAction(
  "[Event Category] Fetch Event Categories",
  props<{ page, filterOptions }>()
);

export const fetchEventCategoriesSuccess = createAction(
  "[Event Category] Fetch Event Categories Success",
  props<{ eventCategory }>()
);

export const fetchEventCategoriesFailure = createAction(
  "[Event Category] Fetch Event Categories Faild",
  props<{ error }>()
);

export const getEventCategory = createAction(
  "[Event Category] Get Event Category",
  props<{ id }>()
);

export const getEventCategorySuccess = createAction(
  "[Event Category] Get Event Category Success",
  props<{ eventCategory }>()
);

export const getEventCategoryFailure = createAction(
  "[Event Category] Get Event Category Failure",
  props<{ error }>()
)

export const addEventCategory = createAction(
  "[Event Category] Add Event Category",
  props<{ eventCategory }>()
);

export const addEventCategorySuccess = createAction(
  "[Event Category] Add Event Category Success",
  props<{ eventCategory }>()
);

export const addEventCategoryFailure = createAction(
  "[Event Category] Add Event Category Failure",
  props<{ error }>()
);

export const updateEventCategory = createAction(
  "[Event Category] Update Event Category",
  props<{ eventCategory }>()
);

export const updateEventCategorySuccess = createAction(
  "[Event Category] Update Event Category Success",
  props<{ eventCategory }>()
);

export const updateEventCategoryFailure = createAction(
  "[Event Category] Update Event Category Failure",
  props<{ error }>()
);

export const deleteEventCategory = createAction(
  "[Event Category] Delete Event Category",
  props<{ id }>()
);

export const deleteEventCategorySuccess = createAction(
  "[Event Category] Delete Event Category Success",
  props<{ id }>()
);

export const deleteEventCategoryFailure = createAction(
  "[Event Category] Delete Event Category Failure",
  props<{ error }>()
);




