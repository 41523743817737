import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { SharedPipesModule } from '@pipes/index';
import { SharedDirectivesModule } from '@directives/index';

import { AffiliatesRoutingModule } from './affiliates-page.routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '@shared/shared.module';
import { MyDatePickerModule } from 'mydatepicker';

import { 
  AffiliatesListPage,
  AffiliatesAddPage,
  ManageProductsDiscountsPage,
  ManageProductsDiscountsListPage,
  ManageProductsDiscountsAddPage,
  ManageSourcesPage,
  ManageSourcesListPage,
  ManageSourcesAddPage,
  CommissionGroupsPage,
  AffiliatesPayoutsPage,
  AffiliatesTrackingPage,
  AffiliatesMailsPage,
  AffiliatesCampaignsPage,
  LoginsHistoryPage,
  AffiliatesListFilterComponent,
  AffiliatesAddTabsComponent,
  AffiliatesDetailsPage ,
  AffiliatesDownlinesPage} from './index';

@NgModule({
  declarations: [
    AffiliatesListPage,
    AffiliatesAddPage,
    ManageProductsDiscountsPage,
    ManageProductsDiscountsListPage,
    ManageProductsDiscountsAddPage,
    ManageSourcesPage,
    ManageSourcesListPage,
    ManageSourcesAddPage,
    CommissionGroupsPage,
    AffiliatesDetailsPage,
    AffiliatesPayoutsPage,
    AffiliatesTrackingPage,
    AffiliatesMailsPage,
    AffiliatesCampaignsPage,
    LoginsHistoryPage,
    AffiliatesListFilterComponent,
    AffiliatesAddTabsComponent,
    AffiliatesDownlinesPage
  ],
  imports: [
    CommonModule,
    AffiliatesRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    SharedModule,
    NgSelectModule,
    MyDatePickerModule,
    SharedPipesModule,
    SharedDirectivesModule
  ],
  
})
export class AffiliatesPageModule { }
