import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {BehaviorSubject, Observable} from 'rxjs';
import { environment } from "../../../environments/environment";
import { Store } from "@ngrx/store";
import {
  fetchCampaignAffiliates,
  deleteCampaignAffiliate,
  addCampaignAffiliate,
  updateCampaignAffiliate,
  getCampaignAffiliate,
  fetchCampaignAffiliatesByComGroup,
  fetchAffiliatesInCampaign,
  fetchAffiliateInCampaignById,
  updateAffiliateInCampaign,
  updateCamAffiliatesComGroup,
  changeMassStatus
} from "../../store/campaign-affiliate/campaign-affiliates.action";
import { getcampaignAffiliatesSelector, getcampaignAffiliateSelector } from '@store/campaign-affiliate/campaign-affiliates.selectors';
import { IState } from '@models/index';

import { HelperService } from '../helper/helper.service';
import { SharedService } from '@services/shared/shared.service';

@Injectable({
  providedIn: "root"
})
export class CampaignAffiliatesService {
  backendUrl = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private store: Store<{ campaignAffiliates: IState }>
  ) {}

  getCampaignAffiliatesSelector(): Observable<IState> {
    return this.store.select(getcampaignAffiliatesSelector)
  }

  getCampaignAffiliateSelector(): Observable<IState> {
    return this.store.select(getcampaignAffiliateSelector)
  }

  fetchCampaignAffiliates(page: number, campaignID: string, filterOptions: any) {
    this.store.dispatch(fetchCampaignAffiliates({ page, campaignID, filterOptions }));
  }

  fetchCampaignAffiliatesByComGroup(page: number, campaignID: string, commissionGroupID: string = '') {
    this.store.dispatch(fetchCampaignAffiliatesByComGroup({ page, campaignID, commissionGroupID }));
  }

  fetchCampaignAffiliate(id: string) {
    this.store.dispatch(getCampaignAffiliate({ id }))
  }

  deleteCampaignAffiliate(id: string) {
    this.store.dispatch(deleteCampaignAffiliate({ id }));
  }

  addCampaignAffiliate(campaignAffiliate: any) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(addCampaignAffiliate({ campaignAffiliate }));
  }

  updateCampaignAffiliate(campaignAffiliate: any) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(updateCampaignAffiliate({ campaignAffiliate }));
  }

  fetchAffiliatesInCampaign(page, filterOptions) {
    this.store.dispatch(fetchAffiliatesInCampaign({ page, filterOptions }));
  }

  fetchAffiliateInCampaignById(id: string) {
    this.store.dispatch(fetchAffiliateInCampaignById({ id }))
  }

  updateAffiliateInCampaign(affStatus) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(updateAffiliateInCampaign({affStatus}));
  }

  dispatchupdateCamAffiliatesComGroup(comGroup: any, camAffiliatesIds: number[]): any {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(updateCamAffiliatesComGroup({ comGroup, camAffiliatesIds }));
  }

  changeMassStatus(massStatus) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(changeMassStatus({massStatus}));
  }

  updateMassStatus(massStatus) {
    return this.http.patch(`${this.backendUrl}/campaigns/campaign/campaign-affiliates/mass-status`, massStatus);
  }

  updateCamAffiliatesComGroup(comGroup: any, camAffiliatesIds: number[]) {
    return this.http.patch(`${this.backendUrl}/campaigns/affiliates/update-com-group`, { comGroupId: comGroup.id, camAffiliatesIds });
  }

  getAffiliatesinCampaign(page: number, filterOptions: any = { }) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);

    return this.http.get(`${this.backendUrl}/campaigns/campaign/campaign-affiliates?${queryString}`);
  }

  getAffiliateInCampaign(id: string) {
    return this.http.get(`${this.backendUrl}/campaigns/campaign/campaign-affiliates/campaign-affiliate/${id}`);
  }

  renewAffiliateInCampaign(affStatus: any) {
    const status = {status: affStatus.status};
    return this.http.patch(`${this.backendUrl}/campaigns/campaign/campaign-affiliates/campaign-affiliate/${affStatus.id}`, status)
  }

  getCampaignAffiliates(page: number, campaignID: string, filterOptions: any = { }) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);

    return this.http.get(`${this.backendUrl}/campaigns/campaign/${campaignID}/affiliates?${queryString}`);
  }

  getCampaignAffiliatesByComGroup(page: number, campaignID: string, commissionGroupID: string) {
    return this.http.get(`${this.backendUrl}/campaigns/campaign/${campaignID}/commission/${commissionGroupID}/affiliates?page=${page}`);
  }

  getCampaignAffiliate(id: string) {
    return this.http.get(`${this.backendUrl}/campaigns/campaign/affiliate/${id}`);
  }

  removeCampaignAffiliate(id: string) {
    return this.http.delete(`${this.backendUrl}/campaigns/campaign/affiliate/${id}`);
  }

  insertCampaignAffiliate(campaignAffiliate: any) {
    return this.http.post(`${this.backendUrl}/campaigns/campaign/affiliate/invite`, campaignAffiliate);
  }

  renewCampaignAffiliate(campaignAffiliate: any) {
    return this.http.patch(`${this.backendUrl}/campaigns/campaign/affiliate/${campaignAffiliate.id}`, campaignAffiliate)
  }
}
