import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { map, mergeMap, catchError, tap } from "rxjs/operators";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
    fetchOrders,
    fetchOrdersFailure,
    fetchOrdersSuccess,
    getOrder,
    getOrderSuccess,
    getOrderFailure,
    createOrder,
    createOrderFailure,
    createOrderSuccess,
    updateOrder,
    updateOrderSuccess,
    updateOrderFailure
} from "./order.action";

import { SharedService } from '@services/shared/shared.service';
import { OrderService } from '@services/order/order.service';

@Injectable()
export class OrderEffects {
  loadOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchOrders),
      mergeMap((action) =>
        this.orderService.getOrders(action.page, action.filterOptions).pipe(
          map(res => {
            return fetchOrdersSuccess({ orders: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchOrdersFailure({ error }));
          })
        )
      )
    )
  );

  getOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOrder),
      mergeMap(action => 
        this.orderService.getOrder(action.id).pipe(
          map(res => {
            return getOrderSuccess({ order: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(getOrderFailure({ error }));
          })
        )
      )
    )
  );

  createOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createOrder),
      mergeMap(action =>
        this.orderService.insertOrder(action.order).pipe(
          map(order => {
            return createOrderSuccess({ order: order });
          }),
          tap(res => {
            this.sharedService.onLoaderToggle(false);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(createOrderFailure({ error }));
          })
        )
      )
    )
  );

  updateOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrder),
      mergeMap(action =>
        this.orderService.renewOrder(action.order, action.option).pipe(
          map(order => {
            return updateOrderSuccess({ order: order });
          }),
          tap(res => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(updateOrderFailure({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private orderService: OrderService,
    private sharedService: SharedService
  ) {}
}
