import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';

import { HelperService, PageService, ReportService } from "@services/index";
import { IState } from "@models/index";
import { IPage } from '@interfaces/index';
import { Status } from '@enums/index';

@Component({
  selector: 'aff-top-campaigns',
  templateUrl: './top-campaigns.component.html',
  styleUrls: ['./top-campaigns.component.scss']
})
export class TopCampaignsPage implements OnInit, OnDestroy {

  rows = [];
  loadingIndicator: boolean = true;
  selectedRows = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  campaignTypes = {
    public : 'Public',
    invited: 'Private',
    approval: 'Approval'
  };

  campaignStatus = {
    1 : 'Active',
    0: 'Paused',
    '-1': 'Stopped'
  };

  topCampaings$: Observable<IState>;
  unsubscribe$ = new Subject<void>();

  constructor(
    private reportService: ReportService,
    private pageService: PageService
  ) {

      this.rows = [];
      this.topCampaings$ = this.reportService.getReportsSelector();
      
      // set page breadcrum
      this.pageService.changePageInfo({ breadcrum: ['Reports', 'Top Campaigns']});
   }

   ngOnInit() {
    // get top campaign list
    this.reportService.fetchTopCampaigns(1, {});
    // listen campaign list
    this.topCampaings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.rows = [];

          for (const topCampaign of state.data) {
            if(!topCampaign.lsum) {
              topCampaign.lsum = 0;
            }
            if(!topCampaign.ssum) {
              topCampaign.ssum = 0;
            }
            
            const row = {
              id: topCampaign.campaign_id, 
              campaignName: topCampaign.name,
              campaignStatus: this.campaignStatus.hasOwnProperty(topCampaign.status) ?  this.campaignStatus[topCampaign.status] : topCampaign.status,
              campaignType: this.campaignTypes.hasOwnProperty(topCampaign.type) ?  this.campaignTypes[topCampaign.type] : topCampaign.type,
              // createDate: topCampaign.created_at,
              validFrom: topCampaign.valid_from,
              validTo: topCampaign.valid_to,
              // commission: campaign.commissionOverview, 
              leadsRatio: '',
              leadsCountSum: topCampaign.lcount + '/$' + topCampaign.lsum/100 , 
              salesCountSum: topCampaign.scount + '/$' + topCampaign.ssum/100 , 
              returnsCountSum: '',
              total: '$' + topCampaign.total/100
            };
            this.rows.push(row);
          }
        }
        this.rows = [...this.rows];
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
    });
}

  setPage(e): void {
    this.reportService.fetchTopCampaigns(e.offset + 1, {}); 
  }

  onSelect(e): void {
    this.selectedRows = e.selected;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
