import {Component, OnInit, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PageService, AffiliateService, PaymentService} from '@services/index';
import {IPage} from '@interfaces/index';
import {IState} from "@models/index";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'aff-pay-affiliates',
  templateUrl: './pay-affiliates-page.component.html',
  styleUrls: ['./pay-affiliates-page.component.scss']
})
export class PayAffiliatesPage implements OnInit, OnDestroy {
  affiliates$: Observable<IState>;
  payments$: Observable<IState>;

  loadingIndicator: boolean = true;
  rows: any[] = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  approvePopup: boolean = false;
  selectedAffiliate: string = '';

  unsubscribe$ = new Subject<void>();

  constructor(
    private affiliateService: AffiliateService,
    private pageService: PageService,
    private paymentService: PaymentService,
    private activeRoute: ActivatedRoute
  ) {
    pageService.changePageInfo({breadcrum: ['Pay Affiliates']});

    this.affiliates$ = this.affiliateService.getAffiliatesSelector();
    affiliateService.fetchAffiliates(1, {balance_from: '0'});

    this.payments$ = paymentService.getPaymentsSelector();
  }

  ngOnInit(): void {
    this.affiliates$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {

          if (state.isLoaded) {
            this.rows = [];
            for (const affiliate of state.data) {
              const row = {
                id: affiliate.id,
                affiliateName: affiliate.profile.first_name + ' ' + affiliate.profile.last_name,
                email: affiliate.email,
                toPay: '$' + affiliate.balance / 100,
                payoutMethod: affiliate.payment_method ? affiliate.payment_method.name : '',
                payoutDate: affiliate.affiliate_manager,
                extra_bonus: affiliate.extra_bonus,
              };
              this.rows.push(row);
            }
          }

          this.rows = [...this.rows];
          // get pagination options
          this.page.currentPage = state.currentPage - 1;
          this.page.perPage = state.perPage;
          this.page.maxPages = state.maxPages;
          this.page.total = state.total;
          // close loader
          this.loadingIndicator = false;
        }
      );

    this.payments$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isSaved) {
          this.affiliateService.fetchAffiliates(1, {balance_from: '0'});
          return this.approvePopup = false;
        }
      });
  }

  setPage(e): void {
    this.affiliateService.fetchAffiliates(e.offset + 1, {balance_from: '0'}, {merge: true});
  }

  onAction(row: any): void {
    this.selectedAffiliate = row;
    this.approvePopup = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
