import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subject} from "rxjs";
import {NotificationsService} from "@services/notifications/notifications.service";
import {Router} from "@angular/router";
import {NotificationType} from "@enums/ENotificationTypes";
import {HelperService} from "@services/helper/helper.service";
import {UserService} from "@services/user/user.service";
import {switchMap, takeUntil} from "rxjs/operators";

@Component({
  selector: 'aff-notification-dropdown',
  templateUrl: './notification-dropdown.component.html',
  styleUrls: ['./notification-dropdown.component.scss']
})
export class NotificationDropdownComponent implements OnInit, OnDestroy {
  @Input('events') events$: any;
  @Input('dropState') dropState$: any;
  private notifications$: Observable<any>;
  public notifications: any[] = [];
  public notificationState: { isLoading: any, isRefreshing: any };

  private subs$: Subject<any> = new Subject<any>();

  constructor(
    private notificationsService: NotificationsService,
    private router: Router,
    private helperService: HelperService,
    private user$: UserService,
  ) {
    this.notifications$ = this.notificationsService.selectNotifications()
  }

  ngOnDestroy() {
    this.subs$.next();
    this.subs$.unsubscribe();
  }

  ngOnInit() {
    this.listenNotifications();
    this.user$.getUserSelector()
      .pipe(
        takeUntil(this.subs$)
      ).subscribe(resp => {
        if (resp.user && resp.user.id) {
          // this.notificationsService.startPolling();
        } else {
          // console.log('NO USER');
          // this.notificationsService.stopPolling();
        }
      })

    this.notificationsService.selectNotificationsLoadState()
      .pipe(takeUntil(this.subs$))
      .subscribe(resp => {
        if (!this.notifications.length && !resp) {
        }
      })

    this.dropState$.events.pipe(takeUntil(this.subs$)).subscribe(e => {
      if (!e.isOpen) {
        // push notifications
      } else {
        this.notificationsService.fetchNotifications();

        if (!this.notifications.length) {
          // this.notificationsService.fetchNotifications();
        }
      }

    });
  }

  private listenNotifications() {
    this.notifications$.pipe(takeUntil(this.subs$)).subscribe(resp => {
      // console.log('[notifications$]', resp.isRefreshing);
      this.notificationState = {
        isLoading: resp.isLoading,
        isRefreshing: resp.isRefreshing
      };
      if (resp.isLoaded && !resp.isLoading) {
        this.notifications = resp.data;
      }
    })
  }

  openNotificationURL(params) {
    const {type, resource_id} = params;
    let redirectURL = '';
    let queryParams = {popup: 1, id: resource_id};

    switch (type) {
      case NotificationType.MESSAGE:
        redirectURL = 'messages';
        break;
      case NotificationType.INVOLVEMENT_APPROVED
      || NotificationType.INVOLVEMENT_REQUEST
      || NotificationType.INVOLVEMENT_DECLINED
      || NotificationType.INVOLVEMENT_SUSPENDED
      :
        redirectURL = 'campaigns';
        break;
      case NotificationType.AFFILIATE_STATUS_APPROVED
      || NotificationType.AFFILIATE_STATUS_DECLINED
      || NotificationType.AFFILIATE_STATUS_FRAUD
      || NotificationType.AFFILIATE_STATUS_SUSPENDED
      :
        redirectURL = 'affiliates';
        break;
      case NotificationType.CAMPAIGN_INVITATION:
        redirectURL = 'campaigns';
        break;
      case NotificationType.COMMISSION_GROUP_CHANGED:
        redirectURL = 'commissions';
        break;
      case NotificationType.ADDED_DOWNLINE:
        redirectURL = 'downlines';
        break;
      default:
        break;
    }

    this.dropState$.isOpen = false;
    this.router.navigate([redirectURL],
      {
        queryParams: {...queryParams},
      }
    );
  }

  onNotificationClick(notification: any) {
    const {seen, type, id, resource_id} = notification;
    this.openNotificationURL({pupup: 1, type, resource_id});
    if (!seen) {
      this.notificationsService.setNotificationAsRead(id);
    }
  }
}
