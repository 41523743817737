import { Component, OnInit } from '@angular/core';

/**
 * Affiliate Activity Component
 */
@Component({
  selector: 'aff-affiliate-activity',
  templateUrl: './affiliate-activity.component.html',
  styleUrls: ['./affiliate-activity.component.scss']
})
export class AffiliateActivityComponent implements OnInit {

  /**
   * FAKE DATA
   */
  cardTitle: String = 'Showing 10/17/2019';

  /**
   * FAKE DATA
   */
  cardList: Array<string> = ['Orders Sum: $13,437.99', 'Orders: 24', 'Approved Sum: $3,572.00', 'Approved Orders: 6', 'Clicks: 1,046', 'Unique: 198'];

  /**
   * FAKE DATA
   */
  rows = [
    {
      affiliate: '31975 http://top-pills.net/',
      affiliateManager: 'Eyal B.',
      ordersSum: '$4,620.00 / $0.00',
      orders: '1 / 0',
      clicks: '0',
      unique: '0'
    },
    {
      affiliate: '31975 http://top-pills.net/',
      affiliateManager: 'Eyal B.',
      ordersSum: '$4,620.00 / $0.00',
      orders: '1 / 0',
      clicks: '0',
      unique: '0'
    },
    {
      affiliate: '31975 http://top-pills.net/',
      affiliateManager: 'Eyal B.',
      ordersSum: '$4,620.00 / $0.00',
      orders: '1 / 0',
      clicks: '0',
      unique: '0'
    },
    {
      affiliate: '31975 http://top-pills.net/',
      affiliateManager: 'Eyal B.',
      ordersSum: '$4,620.00 / $0.00',
      orders: '1 / 0',
      clicks: '0',
      unique: '0'
    },
    {
      affiliate: '31975 http://top-pills.net/',
      affiliateManager: 'Eyal B.',
      ordersSum: '$4,620.00 / $0.00',
      orders: '1 / 0',
      clicks: '0',
      unique: '0'
    },
  ];

  /**
   * FAKE DATA
   */
  columns = [
    {name: 'Affiliate', width: 300},
    {name: 'Affiliate Manager'},
    {name: 'Orders Sum*'},
    {name: 'Orders*'},
    {name: 'Clicks'},
    {name: 'Unique'}
  ];

  constructor() {
  }

  /**
   * @ignore
   */
  ngOnInit() {
  }

  /**
   * @ignore
   */
  onChangeFormValue($event) {
  
  }
}
