import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import {
    fetchPayment,
    fetchPaymentSuccess,
    fetchPaymentFailure,
    getPayment,
    getPaymentSuccess,
    getPaymentFailure,
    addPayment,
    addPaymentSuccess,
    addPaymentFailure
} from "./payment.actions";
import { of } from "rxjs";
import { map, mergeMap, catchError, tap } from "rxjs/operators";
import { PaymentService } from "../../services/payments/payments.service";
import { SharedService } from '@services/shared/shared.service';

@Injectable()
export class PaymentEffects {
  loadPayments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPayment),
      mergeMap(action =>
        this.paymentService.getPayments(action.page, action.filterOptions).pipe(
          map(res => {
            return fetchPaymentSuccess({ payment: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchPaymentFailure({ error }));
          })
        )
      )
    )
  );

  getPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPayment),
      mergeMap(action => 
        this.paymentService.getPayment(action.id).pipe(
          map(res => {
            return getPaymentSuccess({ payment: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(getPaymentFailure({ error }));
          })
        )
      )
    )
  );

  addPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addPayment),
      mergeMap(action =>
        this.paymentService.insertPayment(action.payment).pipe(
          map(payment => {
            return addPaymentSuccess({ payment });
          }),
          tap(res => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(addPaymentFailure({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private paymentService: PaymentService,
    private sharedService: SharedService,
    private router: Router
  ) {}
}
