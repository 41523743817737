import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { IFormOptions } from '@interfaces/index';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserService, HelperService } from '@services/index';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { UserState } from '@models/index';
import { Router, ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-user-reset-password',
  templateUrl: './user-reset-password.component.html',
  styleUrls: ['./user-reset-password.component.scss']
})
export class UserResetPasswordPage implements OnInit, OnDestroy {

  resetPassForm: FormGroup;
  hash: string;

  @Output() closePopup = new EventEmitter<any>();
  successReset:boolean = false;

  error: any = {
    password: '',
    confirm_password: '',
    errorMessage: '',
    successMessage: "Your password changed successfully"
  };

  user$: Observable<UserState>;
  unsubscribe$ = new Subject<void>();
  
  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute ) { 
        this.resetPassForm = this.formBuilder.group({
          password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
          confirm_password: new FormControl(null, [Validators.required, Validators.minLength(6)])
        }, {
          validator: HelperService.passwordMatchValidator
        });

        this.user$ = userService.getUserSelector();
    }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.hash = params.hash;
    });
  }


  closePopupBox(value: boolean): void {
      this.closePopup.emit(value);
  }


  onResetPassword() {
    this.error = HelperService.checkValidation(this.resetPassForm);
    if (this.resetPassForm.valid) {
      this.userService.dispatchResetPassword(this.resetPassForm.value.password, this.hash);
      this.getUserState();
      this.successReset = true;
    }

  }

  onLoginPage() {
    this.router.navigate(['/login']);
  }

  getUserState(): void {
    this.user$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(userState => {
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


}
