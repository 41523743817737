import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType, act } from "@ngrx/effects";
import { Router } from "@angular/router";
import {
    userLogin,
    userLoginSuccess,
    userLoginFailure,
    userSendVerification,
    userSendVerificationSuccess,
    userSendVerificationFailure,
    userResetPassword,
    userResetPasswordSuccess,
    userResetPasswordFailure,
    userSendActivation,
    userSendActivationSuccess,
    userSendActivationFailure,
    userSignup,
    userSignupSuccess,
    userSignupFailure,
    getUser,
    getUserSuccess,
    getUserFailure,
    getUserLoginHistories,
    getUserLoginHistoriesSuccess,
    getUserLoginHistoriesFailure,
    addFilter,
    addFilterFailure,
    addFilterSuccess,
    getFilters,
    getFiltersSuccess,
    getFiltersFailure,
    getFilter,
    getFilterSuccess,
    getFilterFailure,
    deleteFilter,
    deleteFilterSuccess,
    deleteFilterFailure,
    updateUserProfile,
    updateUserProfileSuccess,
    updateUserProfileFailure,
    updateFilter,
    updateFilterSuccess,
    updateFilterFailure
} from "./user.actions";
import { of } from "rxjs";
import { map, mergeMap, catchError, tap } from "rxjs/operators";
import { UserService } from "@services/user/user.service";
import { SharedService } from '@services/shared/shared.service';
import {UserRole} from "@enums/EUserRole";

@Injectable()
export class UserEffects {

  loginUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userLogin),
      mergeMap(action =>
        this.userService.login(action.email, action.password).pipe(
          map(res => {

            if (res['user'] && res['user']['roles'][0].role_id === UserRole.AFFILIATE) {
              return userLoginFailure({error: true});
            }

            return userLoginSuccess({user: res});
          }),
          tap(res => {
            if (!res['error']) {
              localStorage.setItem('authToken', res['user']['access_token']);
              this.router.navigateByUrl('/');
            }
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(userLoginFailure({ error }));
          })
        )
      )
    )
  );


  userSendVerification$ = createEffect(() =>
  this.actions$.pipe(
    ofType(userSendVerification),
    mergeMap(action =>
      this.userService.sendVerification(action.email).pipe(
        map(res => {
          return userSendVerificationSuccess({ user: res });
        }),
        catchError(error => {
          return of(userSendVerificationFailure({ error }));
        })
      )
    )
  )
);


resetUserPassword$ = createEffect(() =>
this.actions$.pipe(
  ofType(userResetPassword),
  mergeMap(action =>
    this.userService.resetPassword(action.password, action.hash).pipe(
      map(res => {
        return userResetPasswordSuccess({ user: res });
      }),
      catchError(error => {
        return of(userResetPasswordFailure({ error }));
      })
    )
  )
)
);


userSendActivation$ = createEffect(() =>
this.actions$.pipe(
  ofType(userSendActivation),
  mergeMap(action =>
    this.userService.sendActivation(action.code, action.email).pipe(
      map(res => {
        return userSendActivationSuccess({ user: res });
      }),
      catchError(error => {
        return of(userSendActivationFailure({ error }));
      })
    )
  )
)
);


  signupUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userSignup),
      mergeMap(action =>
        this.userService.signup(action.user).pipe(
          map(res => {
            return userSignupSuccess({ user: res });
          }),
          tap(() => {
            this.sharedService.onLoaderToggle(false);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(userSignupFailure({ error }));
          })
        )
      )
    )
  );

  updateUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserProfile),
      mergeMap(() => this.userService.updateProfile().pipe(
          map(res => {
            if (res['roles'].map(r => r.role_id).includes(UserRole.AFFILIATE)) {
              this.userService.logout();
              return updateUserProfileFailure({error: {}});
            }

            return updateUserProfileSuccess({user: res});
          }),
          catchError(error => {
            return of(updateUserProfileFailure({ error }));
          })
        )
      )
    )
  );

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUser),
      mergeMap(action =>
        this.userService.getUser(action.id).pipe(
          map(res => {
            return getUserSuccess({ user: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(getUserFailure({ error }));
          })
        )
      )
    )
  );

  getUserLoginHistories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserLoginHistories),
      mergeMap(action =>
        this.userService.getUserLoginHistories(action.userID, action.page, action.filterOptions).pipe(
          map(res => {
            return getUserLoginHistoriesSuccess({ loginHistories: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(getUserLoginHistoriesFailure({ error }));
          })
        )
      )
    )
  );

  getFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFilters),
      mergeMap((action) =>
        this.userService.getFilters(action.filterType).pipe(
          map(res => {
            return getFiltersSuccess({ filters: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(getFiltersFailure({ error }));
          })
        )
      )
    )
  );

  getFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFilter),
      mergeMap(action =>
        this.userService.getFilter(action.id).pipe(
          map(res => {
            return getFilterSuccess({ filter: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(getFilterFailure({ error }));
          })
        )
      )
    )
  );

  addFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addFilter),
      mergeMap(action =>
        this.userService.insertFilter(action.filter).pipe(
          map(filter => {
            return addFilterSuccess({ filter });
          }),
          tap(res => {
            this.sharedService.onSaveToggle(true);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(addFilterFailure({ error }));
          })
        )
      )
    )
  );

  updateFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateFilter),
      mergeMap(action =>
        this.userService.renewFilter(action.filter).pipe(
          map(filter => {
            return updateFilterSuccess({ filter });
          }),
          tap(res => {
            this.sharedService.onSaveToggle(true);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(updateFilterFailure({ error }));
          })
        )
      )
    )
  );

  deleteAffiliate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteFilter),
      mergeMap(action =>
        this.userService.removeFilter(action.id).pipe(
          map(() => {
            return deleteFilterSuccess({ id: action.id });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(deleteFilterFailure({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private sharedService: SharedService,
    private router: Router
  ) {}
}
