import { Component , Input } from '@angular/core';

/**
 * Child Navigation for Main Navigation Component
 */
@Component({
  selector: 'aff-sub-navigation',
  templateUrl: './sub-navigation.component.html',
  styleUrls: ['./sub-navigation.component.scss'],
})
export class SubNavigationComponent {
  @Input() showNavigation: boolean = true;
  @Input() activePageSlug: string;
  @Input() parentPageSlug: string;

  /**
   * Getting List Item from parent
   */
  @Input() item: any;

  /**
   * Toggle Method
   */
  public showItem: boolean = false;

  /**
   * @ignore
   */
  ngOnInit() {
  }

  /**
   * Toggle Functionality
   */
  public toggleSubNavigation() {
    this.showItem = !this.showItem;
  }

}
