import { createReducer, on } from '@ngrx/store';
import { 
  userLogin,
  userLoginSuccess,
  userLoginFailure,
  userSendVerification,
  userSendVerificationSuccess,
  userSendVerificationFailure,
  userResetPassword,
  userResetPasswordSuccess,
  userResetPasswordFailure,
  userSendActivation,
  userSendActivationSuccess,
  userSendActivationFailure,
  userSignup,
  userSignupSuccess,
  userSignupFailure,
  userLogout,
  updateUserProfile,
  updateUserProfileSuccess,
  updateUserProfileFailure,
  updateUserToken,
  getUser,
  getUserSuccess,
  getUserFailure,
  getUserLoginHistories,
  getUserLoginHistoriesSuccess,
  getUserLoginHistoriesFailure,
  addFilter,
  addFilterFailure,
  addFilterSuccess,
  getFilters,
  getFiltersSuccess,
  getFiltersFailure,
  getFilter,
  getFilterSuccess,
  getFilterFailure,
  deleteFilter,
  deleteFilterSuccess,
  deleteFilterFailure,
  clearSelectedFilter,
  updateFilter,
  updateFilterSuccess,
  updateFilterFailure
 } from './user.actions';
import { UserState } from '@models/index';

const initialState: UserState = {
  authToken: '',
  isLoaded: false,
  error: null,
  user: {},
  isRegistrated: false,
  loginHistories: {
    isLoaded: false,
    error: null,
    data: [],
    selectedItem: {},
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  },
  filters: {
    isLoaded: false,
    error: null,
    data: [],
    selectedItem: {},
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  }
};

const _userReducer = createReducer(
  initialState,
  on(userLogin, (state, { email, password }) => {
    
    return state;
  }),
  on(userLoginSuccess, (state, { user }) => {
    const payload = {
      isLoaded: true,
      authToken: user.access_token,
      user: user.user,
      error: null
    };

    return { ...state, ...payload };
  }),
  on(userLoginFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),

  on(userSendVerification, (state, { email }) => {
    return state;
  }),
  on(userSendVerificationSuccess, (state, { user }) => {
    const payload = {
      isLoaded: true,
      error: null
    };
    return { ...state, ...payload };
  }),
  on(userSendVerificationFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),


  on(userResetPassword, (state, { password, hash }) => {
    return state;
  }),
  on(userResetPasswordSuccess, (state, { user }) => {
    const payload = {
      isLoaded: true,
      error: null
    };
    return { ...state, ...payload };
  }),
  on(userResetPasswordFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(userSendActivation, (state, { code, email }) => {
    return state;
  }),
  on(userSendActivationSuccess, (state, { user }) => {
    const payload = {
      isLoaded: true,
      error: null
    };
    return { ...state, ...payload };
  }),
  on(userSendActivationFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(userSignup, (state, { user }) => {
    const payload = {
      isRegistrated: false,
    };
    return { ...state, ...payload };
  }),
  on(userSignupSuccess, (state, { user }) => {
    const payload = {
      isRegistrated: true,
      error: null
    };

    return { ...state, ...payload };
  }),
  on(userSignupFailure, (state, { error }) => {
    const payload = {
      isRegistrated: false,
      error
    };
    return { ...state, ...payload };
  }),
  on(updateUserToken , (state , { authToken }) => { 
    return { ...state, authToken: authToken } 
  }),
  on(updateUserProfile, (state) => {
    
    return state;
  }),
  on(updateUserProfileSuccess, (state, { user }) => {
    const payload = {
      isLoaded: true,
      user: user
    };

    return { ...state, ...payload };
  }),
  on(updateUserProfileFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(userLogout, (state) => {
    const payload = {
      authToken: '',
      isLoaded: false,
      error: null,
      user: {},
      isRegistrated: false,
      loginHistories: {
        isLoaded: false,
        error: null,
        data: [],
        selectedItem: {},
        total: 0,
        maxPages: 0,
        perPage: 0,
        currentPage: 0
      },
      filters: {
        isLoaded: false,
        error: null,
        data: [],
        selectedItem: {},
        total: 0,
        maxPages: 0,
        perPage: 0,
        currentPage: 0
      }
    };
    return { ...state, ...payload };
  }),
  on(getUser, (state, { id }) => {
    const payload = {
      isLoaded: false
    };
    return { ...state, ...payload };
  }),
  on(getUserSuccess, (state, { user }) => {
    const payload = {
      isLoaded: true,
      user
    };
    return { ...state, ...payload };
  }),
  on(getUserFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(getUserLoginHistories, (state, { userID, page, filterOptions }) => {
    const payload = {
      loginHistories: {
        isLoaded: false
      }
    };

    return { ...state, ...payload };
  }),
  on(getUserLoginHistoriesSuccess, (state, { loginHistories }) => {
    const payload = {
      loginHistories: {
        isLoaded: true,
        data: loginHistories.data,
        total: loginHistories.total,
        maxPages: loginHistories.maxPages,
        perPage: loginHistories.perPage,
        currentPage: loginHistories.currentPage,
        selectedItem: {}
      }
    };
    return { ...state, ...payload };
  }),
  on(getUserLoginHistoriesFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(addFilter, (state, { filter }) => {
    const payload = {
      filters: { ...state.filters, isLoaded: false }
    };
    return { ...state, ...payload };
  }),
  on(addFilterSuccess, (state, { filter }) => {
    const total = state.filters.total < 30 ? state.filters.total + 1 : state.filters.total;
    const payload = {
      filters: { ...state.filters, isLoaded: true, total, data: [ {...filter} , ...state.filters['data']] }
    };
    return { ...state, ...payload };
  }),
  on(addFilterFailure, (state, { error }) => {
    const payload = {
      filters: { ...state.filters, isLoaded: true, error }
    };
    return { ...state, ...payload };
  }),
  on(updateFilter, (state, { filter }) => {
    const payload = {
      filters: { ...state.filters, error: null }
    };
    return { ...state, ...payload };
  }),
  on(updateFilterSuccess, (state, { filter }) => {
    const index = state.filters.data.findIndex(filt => filt.id === filter.id);
    state.filters.data[index] = filter;
    const payload = {
      filters: {
        ...state.filters,
        selectedItem: filter
      }
    };
    return { ...state, ...payload };
  }),
  on(updateFilterFailure, (state, { error }) => {
    const payload = {
      filters: { ...state.filters, error }
    };
    return { ...state, ...payload };
  }),
  on(getFilters, (state, { filterType }) => {

    return state;
  }),
  on(getFiltersSuccess, (state, { filters }) => {
    const payload = {
      filters: { ...state.filters, isLoaded: true, ...filters }
    };
    return { ...state, ...payload };
  }),
  on(getFiltersFailure, (state, { error }) => {
    const payload = {
      filters: { ...state.filters, isLoaded: true, error }
    };
    return { ...state, ...payload };
  }),
  on(getFilter, (state, { id }) => {
    const payload = {
      filters: { ...state.filters, isLoaded: false }
    };
    return { ...state, ...payload };
  }),
  on(getFilterSuccess, (state, { filter }) => {
    const payload = {
      filters: { ...state.filters, isLoaded: true, selectedItem: filter }
    };
    return { ...state, ...payload };
  }),
  on(getFilterFailure, (state, { error }) => {
    const payload = {
      filters: { ...state.filters, isLoaded: true, error }
    };
    return { ...state, ...payload };
  }),
  on(deleteFilter, (state, { id }) => {
    const payload = {
      filters: { ...state.filters, isLoaded: false }
    };
    return { ...state, ...payload };
  }),
  on(deleteFilterSuccess, (state, { id }) => {
    const payload = {
      filters: { 
        ...state.filters, 
        isLoaded: true, 
        data: [...state.filters.data.filter(item => item.id != id)]
      }
    };
    return { ...state, ...payload };
  }),
  on(deleteFilterFailure, (state, { error }) => {
    const payload = {
      filters: { ...state.filters, isLoaded: true, error }
    };
    return { ...state, ...payload };
  }),
  on(clearSelectedFilter, (state) => {
    const payload = {
      filters: { selectedItem: {} }
    };
    return { ...state, ...payload };
  }),
);

export function userReducer(state, action) {
  return _userReducer(state, action);
}
