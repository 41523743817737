import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup
} from "@angular/forms";

import * as ENUMS from "@enums/index";
import { IState, UserState } from '@models/index';

import { 
  CampaignAffiliatesService, AffiliateService, 
  HelperService, CommissionGroupService, UserService } from '@services/index';
import { UserRole } from '@enums/index';

@Component({
  selector: 'aff-add-affiliate-popup',
  templateUrl: './add-affiliate-popup.component.html',
  styleUrls: ['./add-affiliate-popup.component.scss']
})
export class AddAffiliatePopupComponent implements OnInit, OnDestroy {
  @Input() set campaignID(campaignId) {
    this.addAffiliateForm.patchValue({ campaign_id: parseInt(campaignId) })
  };
  @Input() affiliateID: string = '';

  @Output() closePopup = new EventEmitter<boolean>();

  addAffiliateForm: FormGroup;
  errorObj: any = {};
  editMode: boolean = false;
  affiliateStatus: any;
  disabledAffiliateStatus: boolean = true;

  affiliatesList: any = [];
  selectedAffiliate: any;
  managersSearch = new FormControl();
  currentUserRole: any;
  disable: boolean = false;

  commissionGroupsList: any = [];
  selectedCommissionGroup: any;
  commissionGroupsSearch = new FormControl();

  commissionGroups$: Observable<IState>
  campaignAffiliate$: Observable<IState>;
  affiliates$: Observable<IState>;
  currentUser$: Observable<UserState>;

  private unsubscribe$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder,
              private affiliateService: AffiliateService,
              private commissionGroupService: CommissionGroupService,
              private campaignAffiliatesService: CampaignAffiliatesService, 
              private userService: UserService,
              ) {
    this.addAffiliateForm = this.formBuilder.group({
      user_id: new FormControl(null, [Validators.required]),
      campaign_id: new FormControl(null, [Validators.required]),
      group_id: new FormControl(null, [Validators.required]),
      affiliate_note: new FormControl(null, [Validators.maxLength(100)]),
      manager_note: new FormControl(null, [Validators.maxLength(100)]),
      status: new FormControl(ENUMS.CampaignAffiliateStatus.INVITED, [Validators.required]),
   });

   this.commissionGroups$ = this.commissionGroupService.getCommissionGroupsSelector()
   this.affiliates$ = this.affiliateService.getAffiliatesSelector();
   this.campaignAffiliate$ = this.campaignAffiliatesService.getCampaignAffiliateSelector();
   this.currentUser$ = this.userService.getUserSelector();
  }

  ngOnInit(): void {
    // get affiliates
    this.affiliateService.fetchAffiliates(1, {
      status: ENUMS.StatusConditions.APPROVED, 
      not_in_campaign: this.addAffiliateForm.value.campaign_id
    });

    // get commission groups
    this.commissionGroupService.fetchCommissionGroups(1, this.addAffiliateForm.value.campaign_id, {});

    this.commissionGroups$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {        
        if (state.isLoaded) {
          this.commissionGroupsList = state.data;
        } 
      });

    // check mode
    if (this.affiliateID) {
      this.editMode = true;
      this.campaignAffiliatesService.fetchCampaignAffiliate(this.affiliateID);
      this.campaignAffiliate$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(affiliate => {
          if (!HelperService.isObjectEmpty(affiliate)) {
            HelperService.fillFormValues(affiliate, this.addAffiliateForm);
            this.selectedAffiliate = affiliate['user'];
            this.selectedCommissionGroup = affiliate['commission_group'];
            this.addAffiliateForm.patchValue({ group_id: this.selectedCommissionGroup.id });
          }
        });
    };

    // listen affiliates list
    this.affiliates$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {        
        if (state.isLoaded) {
          this.affiliatesList = state.data;
        } 
      });

    // affiliates search
    this.managersSearch.valueChanges
    .pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
      distinctUntilChanged()
    )
    .subscribe(keyword => this.affiliateService.fetchAffiliates(1, {
      keyword,
      status: ENUMS.StatusConditions.APPROVED, 
      not_in_campaign: this.addAffiliateForm.value.campaign_id
    }));

    // commission groups search
    this.commissionGroupsSearch.valueChanges
    .pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
      distinctUntilChanged()
    )
    .subscribe(keyword => this.commissionGroupService.fetchCommissionGroups(1, this.addAffiliateForm.value.campaign_id, { keyword }));

    // get current user role
    this.currentUser$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(userState => {
      if(userState.isLoaded){
        this.currentUserRole = userState.user.roles[0].role_id;

        if(this.currentUserRole === UserRole.SUPERADMIN) {
          console.log( HelperService.enumToObject(ENUMS.CampaignAffiliateStatus));
          this.affiliateStatus =  HelperService.enumToObject(ENUMS.CampaignAffiliateStatus)
          this.disable= false;
        } else {

          this.affiliateStatus = ENUMS.CampaignAffiliateStatus.INVITED;
          this.disable= true;
        }
        console.log(this.affiliateStatus);
      }
    })
  }

  getSearchValue(value: string): void {
    this.managersSearch.patchValue(value['term']);
  }

  getSelectedAffiliate(selectedAffiliate): void {
    this.selectedAffiliate = selectedAffiliate;
    this.addAffiliateForm.patchValue({ user_id: selectedAffiliate ? selectedAffiliate.id : null });
  }

  getComGroupsSearchValue(value: string): void {
    this.commissionGroupsSearch.patchValue(value['term']);
  }

  getSelectedComGroups(selectedCommissionGroup): void {
    this.selectedCommissionGroup = selectedCommissionGroup;
    this.addAffiliateForm.patchValue({ group_id: selectedCommissionGroup ? selectedCommissionGroup.id : null });
  }

  saveAffiliates(): void {
    // check validations
    this.errorObj = HelperService.checkValidation(this.addAffiliateForm);

    // check role
    // this.addAffiliateForm.controls.status.disable();
    
    // check validations
    if (this.addAffiliateForm.valid) {
      // add or update affiliate to campaign
      this.editMode ? this.campaignAffiliatesService.updateCampaignAffiliate({ 
                          id: this.affiliateID, ...this.addAffiliateForm.value 
                        })
                    : this.campaignAffiliatesService.addCampaignAffiliate(this.addAffiliateForm.value);
      // close popup
      this.closePopup.emit(false);
    }
  }

  onChangeFormValue(options): void {
    switch (options.name) {
      case "invite":
        this.saveAffiliates();
        break;

      default:
        this.addAffiliateForm.patchValue({ [options.name]: options.value });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
