import {Component, HostListener, ViewChild} from '@angular/core';
import {UserService, SharedService} from '@services/index';

/**
 * Header Of the Application
 */
@Component({
  selector: 'aff-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @ViewChild('insideElement', {static: true}) insideElement;


  constructor(private userService: UserService,
              private sharedService: SharedService) {
  }

  /**
   * @ignore
   */
  ngOnInit() {
  }

  onLogout(): void {
    this.userService.logout();
  }

  onMenuToggle(): void {
    this.sharedService.toggleSidebar();
  }

  onDropOpen(e) {
    console.log('fetch NOTIFICATIONS');
  }


}
