import { createReducer, on } from "@ngrx/store";
import {
    fetchTreshold,
    fetchTresholdSuccess,
    fetchTresholdFailure,
    updateTreshold,
    updateTresholdSuccess,
    updateTresholdFailure
} from "./config.action";

import { IState } from "@models/index";

const initialState: IState = {
  isLoaded: false,
  data: [],
  selectedItem: {},
  error: null,
  total: 0,
  maxPages: 0,
  perPage: 0,
  currentPage: 0
};

const _configReducer = createReducer(
  initialState,
  on(fetchTreshold, (state) => {
    return state;
}),
  on(fetchTresholdSuccess, (state, { treshold }) => {
    console.log(treshold);
    
  const payload = {
    isLoaded: true,
    data: treshold,
    selectedItem: {}
  };    
  return { ...state, ...payload };
  }),
  on(fetchTresholdFailure, (state, { error }) => {
  const payload = {
    isLoaded: true,
    error
  };
return { ...state, ...payload };
}),
on(updateTreshold, (state, { treshold }) => {
  const payload = {
    isLoaded: false,
  }
  return { ...state, ...payload };
}),
on(updateTresholdSuccess, (state, { treshold }) => {
  const index = state.data.findIndex(tresh => tresh.id === treshold.id);
  state[index] = treshold;
  const payload = {
    isLoaded: true,
    selectedItem: treshold
  }
  return { ...state, ...payload };
}),
on(updateTresholdFailure, (state, { error }) => {
  const payload = {
    isLoaded: true,
    error
  };
  return { ...state, ...payload };
}),

);

export function configReducer(state, action) {
  return _configReducer(state, action);
}
