import { createAction, props } from "@ngrx/store";

export const fetchLanguage = createAction(
  "[Language] Fetch Languages"
);

export const fetchLanguageSuccess = createAction(
  "[Language] Fetch Languages Success",
  props<{ language }>()
);

export const fetchLanguageFailure = createAction(
  "[Language] Fetch Languages Faild",
  props<{ error }>()
);
