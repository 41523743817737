import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IFormOptions } from '@interfaces/index';

@Component({
  selector: 'aff-resources-item',
  templateUrl: './resources-item.component.html',
  styleUrls: ['./resources-item.component.scss']
})
export class ResourcesItemComponent implements OnInit {
  @Input() resources: any = [];
  @Output() changedPermission = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onShowChild(resource: any): void {
    resource.show = !resource.show
  }

  changedPermissions(resource: any): void {
    this.changedPermission.emit(resource);
  }

  onChangeFormValue(option: IFormOptions, resource: any = null): void {
    if (!resource) return;
    
    // check option by name
    resource[option.name] = option.checked;

    // check parent
    resource.check = resource.read || resource.create || resource.delete || resource.update;

    this.changedPermission.emit({ optionName: option.name, ...resource });
  }

}
