import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from "../../../environments/environment";

import { Store } from '@ngrx/store';
import { 
  getAuthToken, 
  isUserAuthenticated,
  getUserSelector, 
  getFiltersSelector,
  getSelectedFilterSelector,
  getUserLoginHistory,
  getProfileSelector
  } from '@store/user/user.selectors';
import { 
  userLogin,
  userSendVerification,
  userSendActivation,
  userResetPassword,
  userSignup,
  updateUserProfile,
  updateUserToken,
  userLogout,
  getUser, 
  getUserLoginHistories,
  addFilter,
  getFilters,
  getFilter,
  deleteFilter,
  clearSelectedFilter,
  updateFilter
} from '@store/user/user.actions';
import { UserState } from '@models/index';

import { HelperService } from '@services/helper/helper.service';
import { SharedService } from '@services/shared/shared.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  backendUrl = environment.backendUrl;

  constructor(
    private router: Router,
    private http: HttpClient,
    private sharedService: SharedService,
    private store: Store<{user: UserState}>
  ) {
    if(localStorage.getItem('authToken')) {
      this.updateUserAuthToken(localStorage.getItem('authToken'));
    }
  }

  public getUserSelector() {
    return this.store.select(getUserSelector);
  }

  public getProfileSelector() {
    return this.store.select(getProfileSelector);
  }

  public filtersSelector(){
    return this.store.select(getFiltersSelector);
  }

  public filterSelector() {
    return this.store.select(getSelectedFilterSelector);
  }

  public getUserLoginHistoriesSelector() {
    return this.store.select(getUserLoginHistory);
  }

  public dispatchLogin(email : string , password : string) {
    this.store.dispatch(userLogin({ email, password }));
  }

  public dispatchSignup(user: any) {
    this.store.dispatch(userSignup({ user }));
  }

  public dispatchUpdateProfile() {
    this.store.dispatch(updateUserProfile());
  }

  public updateUserAuthToken(authToken: string) {
    this.store.dispatch(updateUserToken({ authToken }));
  }

  public login(email : string , password : string) {
    const reqBody = { email , password };
    return this.http.post(`${this.backendUrl}/auth/login`, reqBody);
  }

  public dispatchSendVerification(email : string) {
    this.store.dispatch(userSendVerification({ email }));
  }

  public sendVerification(email : string) {
    const reqBody = { email };
    return this.http.post(`${this.backendUrl}/users/user/send-verification`, reqBody);
  }

  public dispatchResetPassword(password, hash) {
    this.store.dispatch(userResetPassword({ password, hash }));
  }

  public resetPassword(password : string, hash ) {
    const reqBody = { password, hash };
    return this.http.post(`${this.backendUrl}/users/user/reset-password/?hash=${hash}`, reqBody);
  }

  public dispatchSendActivation(code:string, email: string) {
    this.store.dispatch(userSendActivation({ code, email }));
  }

  public sendActivation(code: string, email : string) {
    const reqBody = { code, email };
    return this.http.post(`${this.backendUrl}/users/activation`, reqBody);
  }


  public signup(user: any) {
    return this.http.post(`${this.backendUrl}/users/signup`, user);
  }

  public updateProfile() {
    return this.http.get(`${this.backendUrl}/auth/profile`)
  }

  public logout() {
    localStorage.removeItem('authToken');
    this.store.dispatch(userLogout());
    this.router.navigateByUrl('/login');
  }

  public clearUSerState() {
    this.store.dispatch(userLogout());
  }

  public getUserAuthToken() {
    return this.store.select(getAuthToken);
  }

  public isAuthorized() {
    return this.store.select(isUserAuthenticated);
  }

  public fetchUser(id: string) {
    this.store.dispatch(getUser({ id }))
  }

  public getUser(id: string) {
    return this.http.get(`${this.backendUrl}/users/user/${id}`);
  }

  public fetchUserLoginHistories(userID: string, page: number, filterOptions: any = {}) {
    this.store.dispatch(getUserLoginHistories({ userID, page, filterOptions }))
  }

  public getUserLoginHistories(userID: string, page: number, filterOptions: any = {}) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);

    return this.http.get(`${this.backendUrl}/users/user/${userID}/login-history?${queryString}`);
  }

  public addFilter(filter: any) {
    this.store.dispatch(addFilter({ filter }));
  }
  
  public updateFilter(filter: any) {
    this.store.dispatch(updateFilter({ filter }));
  }

  public insertFilter(filter: any) {
    return this.http.post(`${this.backendUrl}/user-filters/filter`, filter);
  }

  public renewFilter(filter: any) {
    return this.http.patch(`${this.backendUrl}/user-filters/filter/${filter.id}`, filter);
  }

  public fetchFilters(filterType: string) {
    this.store.dispatch(getFilters({filterType}));
  }

  public getFilters(type) {
    return this.http.get(`${this.backendUrl}/user-filters?type=${type}`);
  }

  public fetchFilter(id: string | number) {
    this.store.dispatch(getFilter({ id }));
  }

  public getFilter(id: string | number) {
    return this.http.get(`${this.backendUrl}/user-filters/filter/${id}`);
  }

  public deleteFilter(id) {
    this.store.dispatch(deleteFilter({ id }));
  }

  public removeFilter(id) {
    return this.http.delete(`${this.backendUrl}/user-filters/filter/${id}`);
  }

  public clearSelectedFilter() {
    this.store.dispatch(clearSelectedFilter());
  }

  public checkFilterName(name: string | number, filters: any, editFilterId: number): boolean {
    if (!name) {
      this.sharedService.onSaveToggle(true, true, 'Name cannot be empty!');
      return false;
    }
    
    const findFilterKey = Object.keys(filters).find(filter => filters[filter].toLowerCase() === name.toString().toLowerCase());
    
    if (findFilterKey && (+findFilterKey !== editFilterId)) {
      this.sharedService.onSaveToggle(true, true, 'Filter With this Name Already Exists!');
      return false;
    }
    return true;
  }

}
