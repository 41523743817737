import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { FormControl } from '@angular/forms';

import { CommissionGroupService, CampaignService, HelperService } from "@services/index";
import { IState } from '@models/index';
import { IPage, IFormOptions } from '@interfaces/index';
import { ShowCampaignTypeValue } from '@enums/index';

@Component({
  selector: 'aff-commission-groups-page',
  templateUrl: './commission-groups-page.component.html',
  styleUrls: ['./commission-groups-page.component.scss']
})
export class CommissionGroupsPage implements OnInit, OnDestroy {
  affiliateID: string = '';

  rows = [];
  loadingIndicator: boolean = false;
  selectedRows = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  editMode: boolean = false

  filterOptions: any = {
    user_id: ''
  };

  searchValue = new FormControl();

  groups: Observable<IState>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private commissionGroupService: CommissionGroupService,
    private route: ActivatedRoute,
    private campaingService: CampaignService) {

    this.groups = this.commissionGroupService.getCommissionGroupsSelector();
  }

  ngOnInit(): void {
    this.searchValue.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => {
        this.commissionGroupService.fetchCommissionGroupsByAffiliate(1, this.affiliateID, { keyword });
      });

    // get affiliate id
    this.affiliateID = this.route.snapshot.params.id;

    if (this.affiliateID) {
      this.commissionGroupService.fetchCommissionGroupsByAffiliate(1, this.affiliateID);
    }

    this.groups
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.rows = [];
          for (const data of state.data) {
            const row = {
              campaignName: data.campaign_name,
              campaignType: ShowCampaignTypeValue[data.campaign_type],
              affiliateManager: data.affiliate_manager_first_name + ' ' + data.affiliate_manager_last_name,
              dateJoined: HelperService.dateFormater(data.join_date),
              commissionGroupName: data.com_group_name,
              commission: data.commissionOverview,
            };
            this.rows.push(row);
          }

          this.rows = [...this.rows];

          // get pagination options
          this.page.currentPage = state.currentPage - 1;
          this.page.perPage = state.perPage;
          this.page.maxPages = state.maxPages;
          this.page.total = state.total;
        }

        // close loader
        this.loadingIndicator = false;
      });
  }

  onSearch(options: IFormOptions): void {
    this.searchValue.patchValue(options.value);
  }

  setPage(e): void {
    this.selectedRows = [];
    this.campaingService.fetchCampaignsByAffiliate(e.offset + 1, this.affiliateID);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
