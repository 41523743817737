import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import {
  fetchTreshold,
  updateTreshold
} from "../../store/config/config.action";
import { getconfigsSelector, getconfigSelector } from '@store/config/config.selectors';

import { HelperService } from '../helper/helper.service';
import { SharedService } from '@services/shared/shared.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class ConfigService {
  backendUrl = environment.backendUrl;

  private _clickSaved = new Subject<boolean>();
  clickSaved$ = this._clickSaved.asObservable();

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private store: Store<{ authToken: string }>
  ) {}


  onClickSavedToggle(clicked: boolean) {
    this._clickSaved.next(clicked);
  }

  getconfigSelector() {
    return this.store.select(getconfigSelector); 
  }

  getconfigsSelector() {
    return this.store.select(getconfigsSelector); 
  }

  fetchTreshold() {
    this.store.dispatch(fetchTreshold());
  }

  updateTreshold(treshold) {
    this.store.dispatch(updateTreshold({treshold}));
  }

  getTreshold() {
    return this.http.get(`${this.backendUrl}/configs`);
  }

  renewTreshold(treshold) {
    return this.http.patch(`${this.backendUrl}/configs/config/${treshold.id}`, treshold)
  }

}
