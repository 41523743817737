import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { IModalvalue } from '@shared-models/index';

@Component({
  selector: 'aff-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements OnInit, OnDestroy {
  @Input() set listObj(listObj) {
    this.options = listObj;
    this.keys = Object.keys(listObj);
  }
  @Input() isDeletable: boolean = false;
  @Input() apiSerch: boolean = false;
  @Input() selectedValue: string | number = '';
  @Input() class: string = '';
  @Input() placeholder: string = 'Please Select';

  @Output() getSelectedValue = new EventEmitter<string | number>();
  @Output() getDeletedValue = new EventEmitter<string | number>();
  @Output() getSearchValue = new EventEmitter<string>();

  dropDownOpen: boolean = false;
  confirmationModal: boolean = false;
  deleteKey: string | number = '';
  keys: any[] = [];
  options: any = {};
  findValues: any = {};
  
  private selectModelChanged$: Subject<string> = new Subject<string>();
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private eRef: ElementRef) { }

  ngOnInit() {
    // listen input value change
    this.selectModelChanged$
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.dropDownOpen = true;
        if (this.apiSerch) {
          this.getSearchValue.emit(value);
        } else {
          this.findValues = {};
          // find options by input value
          let re = RegExp(value, 'gi');
          Object.keys(this.options).forEach(key => {
            if (this.options[key].match(re)) {
              this.findValues = {
                ...this.findValues,
                [key]: this.options[key]
              }
            }
          });
          // renew keys array by found options
          this.keys = Object.keys(this.findValues);
        }
      });
  }

  // close dropdown by outside click
  @HostListener('document:click', ['$event'])
  clickout(event): void {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.dropDownOpen = false;
    }
  }

  onSelect(key): void {
    this.selectedValue = this.options[key];
    this.getSelectedValue.emit(key);
    this.dropDownOpen = false;
  }

  onDelete(e: Event, key): void {
    e.stopPropagation();
    this.deleteKey = key;
    this.confirmationModal = true;
  }

  onDeleteConfirm(options: IModalvalue): void {
    if (options.confirm) {
      this.getDeletedValue.emit(this.deleteKey);
    }
    
    this.confirmationModal = false;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
