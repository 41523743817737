import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { CookieLifetime } from "@enums/index";
import { IFormOptions } from '@interfaces/index';
import { HelperService } from '@services/index';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'aff-group-setting-cookie',
  templateUrl: './group-setting-cookie.component.html',
  styleUrls: ['./group-setting-cookie.component.scss']
})
export class GroupSettingCookieComponent implements OnInit {
  @Output() getObjValues = new EventEmitter<any>();
  // set values from parent component
  @Input() set commissionSettingsForm(options) {
    this.cookieLifetimesForm.reset();
    HelperService.fillFormValues(options, this.cookieLifetimesForm)
  }
  @Input() campaignEditMode: boolean = false;
  @Input() clickSaved$: Observable<boolean>;
  public cookieLifetimesForm: FormGroup;

  errorObj: any = {};

  // enums
  cookieLifetime = CookieLifetime;

  private unsubscribe$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder) { 
    this.cookieLifetimesForm = this.formBuilder.group({
      // cookie_lifetime: new FormControl(this.cookieLifetime.SAME, [Validators.required]),
      // cookie_validity: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      com_group_name: new FormControl(null, [Validators.required])
    });
  }

  ngOnInit() {
    
    this.clickSaved$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(() => {
        // if (!this.campaignEditMode) {
          this.errorObj = HelperService.checkValidation(this.cookieLifetimesForm);
          // this.checkDisableValueValidation();
        // }
      });
  }

  // checkDisableValueValidation(): void {
  //   const formValues = this.cookieLifetimesForm.value;
  //   if (formValues.cookie_lifetime === this.cookieLifetime.SAME) {
  //     delete this.errorObj['cookie_validity'];
  //   }
  // }

  onChangeFormValue(options: IFormOptions): void {
    this.cookieLifetimesForm.patchValue({ [options.name]: options.value });
    this.getObjValues.emit({ [options.name]: options.value });
  }

}
