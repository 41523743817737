import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {
  fetchNotifications,
  fetchNotificationsEndPoll,
  fetchNotificationsStartPoll, setNotificationAsRead
} from "@store/notifications/notifications.actions";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {notificationsDataSelector, notificationsIsLoaded} from "@store/notifications/notifications.selectors";

@Injectable({
  providedIn: "root"
})
export class NotificationsService {
  constructor(
    private http: HttpClient,
    private store: Store<{ notifications: any }>,
  ) {
  }

  public selectNotifications(params?): Observable<any> {
    return this.store.select(notificationsDataSelector);
  }

  public selectNotificationsLoadState(params?): Observable<any> {
    return this.store.select(notificationsIsLoaded);
  }

  public fetchNotifications(params = {}) {
    return this.store.dispatch(fetchNotifications({params}));
  }

  public setNotificationAsRead(id: any) {
    return this.store.dispatch(setNotificationAsRead({id}));
  }

  public startPolling(params = {}) {
    return this.store.dispatch(fetchNotificationsStartPoll({params}));
  }

  public stopPolling(params = {}) {
    return this.store.dispatch(fetchNotificationsEndPoll({params}));
  }


  public getNotifications(params?) {
    return this.http.get(environment.backendUrl + '/notifications');
  }

  public setNotificationAsSeen(id) {
    return this.http.patch(environment.backendUrl + '/notifications/notification/'+id, {});
  }
}
