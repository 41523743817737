import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Store } from "@ngrx/store";
import { environment } from "../../../environments/environment";
import { HelperService } from '../helper/helper.service';
import {
  fetchCommissionGroup,
  fetchFilteredCommissionGroups,
  deleteCommissionGroup,
  addCommissionGroup,
  updateCommissionGroup,
  getCommissionGroup,
  fetchCommissionGroupsByAffiliate,
  clearCommissionGroup,
  makeDefaultComGroup
} from "../../store/commission-group/commission-groups.actions";
import { 
  getCommissionGroupsSelector,
  getFilteredCommissionGroupsSelector, 
  getCommissionGroupSelector } from '@store/commission-group/commission-groups.selectors';
import { IState } from '@models/index';

import { SharedService } from '@services/shared/shared.service';

@Injectable({
  providedIn: "root"
})
export class CommissionGroupService {
  backendUrl = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private store: Store<{ commissionGroup: IState }>
  ) {}

  getCommissionGroupsSelector(): Observable<IState> {
    return this.store.select(getCommissionGroupsSelector);
  }

  getCommissionGroupSelector(): Observable<any> {
    return this.store.select(getCommissionGroupSelector);
  }

  getFilteredCommissionGroupsSelector(): Observable<any[]> {
    return this.store.select(getFilteredCommissionGroupsSelector);
  }

  fetchCommissionGroups(page: number, campaignID: string, filterOptions: any) {
    this.store.dispatch(fetchCommissionGroup({ page, campaignID, filterOptions }));
  }

  fetchFilteredCommissionGroups(page: number, campaignID: string, filterOptions: any) {
    this.store.dispatch(fetchFilteredCommissionGroups({ page, campaignID, filterOptions }));
  }

  fetchCommissionGroup(id: string) {
    this.store.dispatch(getCommissionGroup({ id }));
  }

  deleteCommissionGroup(id: string, moveAffToGroupId: number = 0, affCount: number = 0) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(deleteCommissionGroup({ id, moveAffToGroupId, affCount }));
  }

  addCommissionGroup(commissionGroup: any) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(addCommissionGroup({ commissionGroup }));
  }

  updateCommissionGroup(commissionGroup: any) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(updateCommissionGroup({ commissionGroup })); 
  }

  fetchCommissionGroupsByAffiliate(page: number, affiliateID: string, filterOptions: any = {}) {
    this.store.dispatch(fetchCommissionGroupsByAffiliate({ page, affiliateID, filterOptions }));
  }

  fetchMakeDefaultComGroup(id: number, campaignId: number): void {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(makeDefaultComGroup({ id, campaignId }));
  }

  renewDefaultComGroup(id: number, campaignId: number) {
    return this.http.patch(`${this.backendUrl}/commission-groups/group/${id}/default`, { campaignId });
  }

  getCommissionGroups(page: number, campaignID: string, filterOptions: any = { }) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);

    return this.http.get(`${this.backendUrl}/campaigns/campaign/${campaignID}/commission-groups?${queryString}`);
  }

  getCommissionGroupsByAffiliate(page: number, ID: string, filterOptions: any) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(`${this.backendUrl}/commission-groups/user/${ID}/commission-group?${queryString}`); 
  }

  getCommissionGroup(id: string) {
    return this.http.get(`${this.backendUrl}/commission-groups/group/${id}`);
  }

  removeCommissionGroup(id: string, moveAffToGroupId: number) {
    return this.http.delete(`${this.backendUrl}/commission-groups/group/${id}?moveAffToGroupId=${moveAffToGroupId}`);
  }

  insertCommissionGroup(commissionGroup: any) {
    return this.http.post(`${this.backendUrl}/commission-groups/group`, commissionGroup);
  }

  renewCommissionGroup(commissionGroup: any) {
    return this.http.patch(`${this.backendUrl}/commission-groups/group/${commissionGroup.id}`, commissionGroup)
  }

  clearCommissionGroup() {
    this.store.dispatch(clearCommissionGroup());
  }

}
