import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup
} from "@angular/forms";
import { Router } from '@angular/router';
import { Observable, Subject } from "rxjs";
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { 
  AffiliateService, 
  HelperService, 
  SharedService, 
  LanguageService, 
  CountryService, 
  PageService} from "@services/index";
import { IState } from '@models/index';
import { StatusConditions, UserRole } from "@enums/index";

@Component({
  selector: 'aff-affiliates-details-page',
  templateUrl: './affiliates-details-page.component.html',
  styleUrls: ['./affiliates-details-page.component.scss']
})
export class AffiliatesDetailsPage implements OnInit, OnDestroy {
  public affiliateDetailsForm: FormGroup;
  
  statusValue = HelperService.numberEnumToObject(StatusConditions);

  affiliates$: Observable<IState>;
  affiliate$: Observable<any>;
  language$: Observable<any>;
  country$: Observable<any>;
  affTypes$: Observable<any[]>;
  status$: Observable<any[]>;

  selectedValues: any = {
    country: null,
    language: null,
    affType: null,
    status: null,
    parent_affiliate: null
  };

  lists: any = {
    country: [],
    parentAffiliates: [],
    language: [],
  };

  searchValues: any = {
    parentAffiliates: new FormControl(),
  };

  affiliate: any = {};
  editMode: boolean = false;

  errorObj: any = {};

  imageURL: string = '';
  private unsubscribe$ = new Subject<void>();

  constructor( 
    private formBuilder: FormBuilder,
    private affiliateService: AffiliateService,
    private languageService: LanguageService,
    private sharedService: SharedService,
    private router: Router,
    private pageService: PageService,
    private countryService: CountryService) {

    this.affiliateDetailsForm = this.formBuilder.group({
      password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      file_id: new FormControl(null),
      confirm_password: new FormControl(null, [Validators.required]),
      first_name: new FormControl(null, [Validators.required]),
      last_name: new FormControl(null, [Validators.required]),
      referral_id: new FormControl(null),
      referral_code: new FormControl(null),
      status_id: new FormControl(StatusConditions.PENDING, [Validators.required]),
      type_id: new FormControl(null), 
      parent_affiliate: new FormControl(null),
      language_id: new FormControl(null),
      email: new FormControl(null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$')]),
      site_url: new FormControl(null),
      company_name: new FormControl(null),
      street: new FormControl(null),
      city: new FormControl(null),
      state: new FormControl(null),
      country_id: new FormControl(null),
      zip_code: new FormControl(null, [Validators.pattern("^[0-9]*$")]),
      phone: new FormControl(null, [Validators.pattern("^[0-9]*$")]),
      fax: new FormControl(null, [Validators.pattern("^[0-9]*$")]),
      callback_url: new FormControl(null),
      note: new FormControl(null),
      send_notification: new FormControl(false),
      role: new FormControl(3, [Validators.required])
    },
    {
      validator: [
        HelperService.passwordMatchValidator, 
        HelperService.urlValidator('site_url')
      ],
    });

    // select statuses
    this.status$ = this.pageService.getUserStatusSelector();
    this.pageService.fetchUserStatuses({ roleId: UserRole.AFFILIATE });

    this.affiliates$ = this.affiliateService.getAffiliatesSelector();
    this.affiliate$ = this.affiliateService.getAffiliateSelector();
    this.affiliateService.fetchFilteredAffiliates( 1, {})

    this.language$ = this.languageService.getLanguagesSelector();
    this.languageService.fetchLanguages();

    this.country$ = this.countryService.getCountriesSelector();
    this.countryService.fetchCountries();

    this.affTypes$ = this.pageService.getUserTypeListSelector();
    this.pageService.fetchUserTypes();
  }

  ngOnInit() {    
    this.affiliates$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (!state.isLoaded) return;
        if (state.error === 'duplicate email address') {
          this.errorObj['email'] = 'This email already exist!';
        } else {
          delete this.errorObj['email'];
        }

        this.lists.parentAffiliates = state.filteredDate;
        
      });

    this.affiliate$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(affiliate => {
        this.affiliate = affiliate;
        // check mode
        if (HelperService.isObjectEmpty(this.affiliate)) {
          return this.editMode = false;
        }

        this.editMode = true;
    
        // fill form values
        HelperService.fillFormValues(this.affiliate, this.affiliateDetailsForm);
        
        this.affiliateDetailsForm.patchValue({ 
          language_id: this.affiliate.language ? this.affiliate.language.id : null, 
          country_id: this.affiliate.country ? this.affiliate.country.id : null, 
          parent_affiliate: this.affiliate.parent ? this.affiliate.parent.id : null, 
          password: null,
          confirm_password: null
        });
        
        this.imageURL = this.affiliate.image_url;
        this.selectedValues = {
          country: this.affiliate.country,
          language: this.affiliate.language,
          parentAffiliates: this.affiliate.parent,
          status: this.affiliate.status,
          affType: this.affiliate.affType
        } 
      });

      // get language list
      this.getLanguages();

      // get countries list
      this.getCountries();

      // parent affiliates search
      this.searchValues.parentAffiliates.valueChanges
         .pipe(
           takeUntil(this.unsubscribe$),
           debounceTime(500),
           distinctUntilChanged()
         )
         .subscribe(keyword => this.affiliateService.fetchFilteredAffiliates( 1, { keyword } ));
   
  }

  getSearchValue(options, name: string): void {
    // search value in managers
    this.searchValues[name].patchValue(options.term);
  }

  onSelectedValue(value: any, name: string): void {  
    this.affiliateDetailsForm.patchValue({ [name]: value ? value.id : null });

    if (name === 'parent_affiliate') {
      this.affiliateDetailsForm.patchValue(
        { referral_id: value ? value.id : null, referral_code: value ? value.referral_code : null }
      );
    }
  }

  getLanguages() {
    this.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.lists.language = state.data;
      });
  }

  getCountries(): void {
    // listen country
    this.country$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      this.lists.country = state.data;
     })
  }

  onChangeFormValue(options) {
    switch(options.name) {
      case 'first_name':
      case 'last_name':
        let val = options.value.replace(/\s\s+/g, ' ');
        this.affiliateDetailsForm.patchValue({ [options.name]: val.trim() });
        break;
      case 'send_notification':
        this.affiliateDetailsForm.patchValue({ [options.name]: options.checked });
        break;

      case 'file_id':
        if(options.value.length) {
          this.uploadImage(options.value);
        }
        break;

      case "back":
        this.router.navigate(['/affiliates'])
        break;

      case "save":
        this.saveDetails();
        break;

      default :
        this.affiliateDetailsForm.patchValue({ [options.name]: options.value.trim() });
    }
  } 

  saveDetails(): void {
    // if(this.editMode) {
    //   this.affiliate.parent.id=this.selectedValues.parentAffiliates.id
    // }

    console.log(this.affiliateDetailsForm.value);
    
    
    if (this.editMode && !this.affiliateDetailsForm.value.password) {
      this.affiliateDetailsForm.controls['password'].disable();
      this.affiliateDetailsForm.controls['confirm_password'].disable();
    }

    this.errorObj = HelperService.checkValidation(this.affiliateDetailsForm);
    
    if (this.affiliateDetailsForm.valid) {
      this.sharedService.onLoaderToggle(true);
      this.editMode ? this.affiliateService.updateAffiliate({ id: this.affiliate.id, ...this.affiliateDetailsForm.value }) 
                    : this.affiliateService.addAffiliate(this.affiliateDetailsForm.value);
    }
    
  }

  uploadImage(files): void {
    this.sharedService.onLoaderToggle(true);
    let imageFile = HelperService.createFormData(files); 
 
    this.sharedService.uploadImage(imageFile, 'user')
      .then(res => {
        this.imageURL = res['url'];
        this.sharedService.onLoaderToggle(false);
        this.affiliateDetailsForm.patchValue({ file_id: res['id'] });
      })
      .catch(err => {
        this.sharedService.onLoaderToggle(false);
      })
  }

  deleteImage(): void {
    this.affiliateDetailsForm.patchValue({ file_id: null });
    this.imageURL = '';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
