import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PermissionGuard } from '@guards/index'; 
import { 
    PayAffiliatesPage, PayoutHistoryPage } from './index';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'pay-affiliates',
        pathMatch: 'full'
    },
    {
        path: 'pay-affiliates',
        component: PayAffiliatesPage,
        canActivate: [PermissionGuard],
        data: { breadcrumb: 'Pay Affiliates' },
    },
   {    path: 'payout-history', 
        component: PayoutHistoryPage, 
        canActivate: [PermissionGuard],
        data: { breadcrumb: 'Payouts History' } 
    },
];

@NgModule({
  imports: [
    RouterModule.forChild(
        routes
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AffiliatePaymentsPageRoutingModule {}
