import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { 
  LoginPage,
  UserForgotPasswordPage,
  UserResetPasswordPage,
  ActivationPage } from './index';
  
const routes: Routes = [
    {
      path: '',
      component: LoginPage,
      data: { breadcrumb: 'Login' }
    },
    {
      path: 'forgot',
      component: UserForgotPasswordPage,
      data: { breadcrumb: 'Forgot Password' }
    },
    {
      path: 'user',
      data: { breadcrumb: 'Reset Password' },
      children: [
        { path: 'reset-password',  component: UserResetPasswordPage }
      ]
    },
    {
      path: 'user',
      data: { breadcrumb: 'activation' },
      children: [
        { path: 'activation',  component: ActivationPage }
      ]
    }
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginPageRoutingModule { }