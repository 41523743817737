import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { PaymentService } from '@services/index';
import { IFormOptions } from '@interfaces/index';
import { PaymentType } from '@enums/index';

@Component({
  selector: 'aff-payment-approve-popup',
  templateUrl: './payment-approve-popup.component.html',
  styleUrls: ['./payment-approve-popup.component.scss']
})
export class PaymentApprovePopup implements OnInit, OnDestroy {
  @Input() viewMode: boolean = false;
  @Input() affiliate: any = {};

  paymentType = PaymentType;

  threshold: number = 1;
  warning: string = '';
  payment: any = {
    send_notification: false,
    note_for_manager: null,
    note_for_affiliate: null,
    amount: 0,
    payment_type: PaymentType.STANDART,
    extra_bonus_amount: 0,
    extraBonusErr: '',
    amountErr: '',
    currentBalance: 0,
    user_id: 0
  };
  genCommission$: Observable<any>;
  payment$: Observable<any>;
  private unsubscribe$ = new Subject<void>();

  constructor(private paymentService: PaymentService) { 
  }

  ngOnInit(): void {
    this.payment.extra_bonus = !!this.payment.extra_bonus_amount;
    if (this.viewMode) {
      let extraAmount = null;
      let standartAmount = null;
      
      if(this.affiliate.payoutType === PaymentType.EXTRA){
        extraAmount = this.affiliate.paidAmount.replace('$','');
        standartAmount = 0;
      }else{
        extraAmount = 0;
        standartAmount = this.affiliate.paidAmount.replace('$','');
      }
      this.payment = {
        send_notification: this.affiliate.send_notification,
        note_for_manager: this.affiliate.note_for_manager,
        note_for_affiliate: this.affiliate.note_for_affiliate,
        amount: standartAmount,
        extra_bonus_amount: extraAmount,
        payment_type: this.affiliate.payoutType,
      };
      console.log('logging', this.payment.payment_type);
      
      this.affiliate.paymentMethod = this.affiliate.payoutMethod;
      this.affiliate.id = this.affiliate.payoutId;
    } else {
      this.payment = {
        ...this.payment,
        amount: +this.affiliate.toPay.replace('$',''),
        currentBalance: +this.affiliate.toPay.replace('$',''),
        user_id: this.affiliate.id
      }
    }
  }

  goToLink(id: number): void {
    window.open(`/affiliates/affiliate/${id}`, "_blank");
  }

  payPayment(): void | string {
    if (this.payment.payment_type === PaymentType.STANDART) {
      if (!this.payment.amount) {
        return this.payment.amountErr = 'Field is Required!';
      } else if (+this.payment.amount > this.payment.currentBalance) {
        this.payment.amountErr = '';
        return this.warning = 'Indicated amount to pay is more than current balance amount';
      }
    } else if (!this.payment.extra_bonus_amount) {
      return this.payment.extraBonusErr = 'Field is Required!';
    }
    this.payment.extra_bonus_amount = this.payment.extra_bonus_amount ? +this.payment.extra_bonus_amount * 100
                                    : 0;
    this.payment.amount = +this.payment.amount * 100;
    this.paymentService.addPayment(this.payment);
  }

  onChangeFormValue(options: IFormOptions): void {
    switch (options.name) {
      case "pay":
        this.payPayment();
        break;
      
      case 'send_notification':
      case 'extra_bonus':
        this.payment[options.name] = options.checked;
        break;

      case 'amount':
        if (options.value && +options.value < this.threshold) {
          this.warning = 'Indicated amount to pay is less than threshold amount';
        } else {
          this.warning = '';
        }
      
      default:
        this.payment[options.name] = options.value.trim();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete();
  }

}
