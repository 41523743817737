export enum ChartLabelTypes {
  sales = 'Sales',
  leads = 'Leads',
  sectier = '2nd Tier',
}

export enum ChartTheming {
  sales = 'rgba(255, 107, 65, 1)',
  leads = 'rgb(47,158,219)',
  sectier = '#dbb62f',
}

export const ChartThemingColors = {
  sales: ChartTheming.sales,
  leads: ChartTheming.leads,
  sectier: ChartTheming.sectier,
};
