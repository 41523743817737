import { createReducer, on } from "@ngrx/store";
import {
  fetchCampaignAffiliates,
  fetchCampaignAffiliatesSuccess,
  fetchCampaignAffiliatesFailure,
  getCampaignAffiliate,
  getCampaignAffiliateFailure,
  getCampaignAffiliateSuccess,
  deleteCampaignAffiliate,
  deleteCampaignAffiliateSuccess,
  deleteCampaignAffiliateFailure,
  addCampaignAffiliate,
  addCampaignAffiliateSuccess,
  addCampaignAffiliateFailure,
  updateCampaignAffiliate,
  updateCampaignAffiliateSuccess,
  updateCampaignAffiliateFailure,
  fetchCampaignAffiliatesByComGroup,
  fetchCampaignAffiliatesByComGroupSuccess,
  fetchCampaignAffiliatesByComGroupFailure,
  fetchAffiliatesInCampaign,
  fetchAffiliatesInCampaignSuccess,
  fetchAffiliatesInCampaignFailure,
  fetchAffiliateInCampaignById,
  fetchAffiliateInCampaignByIdSuccess,
  fetchAffiliateInCampaignByIdFailure,
  updateAffiliateInCampaign,
  updateAffiliateInCampaignSuccess,
  updateAffiliateInCampaignFailure,
  updateCamAffiliatesComGroup,
  updateCamAffiliatesComGroupSuccess,
  changeMassStatus,
  changeMassStatusSuccess,
  changeMassStatusFailure
} from "./campaign-affiliates.action";

import { IState } from "@models/index";

const initialState: IState = {
  isLoaded: false,
  data: [],
  isSaved: false,
  selectedItem: {},
  error: null,
  total: 0,
  maxPages: 0,
  perPage: 0,
  currentPage: 0
};

const _campaignAffiliatesReducer = createReducer(
  initialState,
  on(fetchCampaignAffiliates, (state, { page, campaignID, filterOptions }) => {
    const payload = {
      isSaved: false
    };

    return { ...state, ...payload };
  }),
  on(fetchCampaignAffiliatesSuccess, (state, { campaignAffiliates }) => {
    const payload = {
      isLoaded: true,
      data: campaignAffiliates.data,
      total: campaignAffiliates.total,
      maxPages: campaignAffiliates.maxPages,
      perPage: campaignAffiliates.perPage,
      currentPage: campaignAffiliates.currentPage,
      selectedItem: {}
    };

    return { ...state, ...payload };
  }),
  on(fetchCampaignAffiliatesFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(fetchCampaignAffiliatesByComGroup, (state, { page, campaignID, commissionGroupID }) => {
    const payload = {
      isLoaded: false,
      isSaved: false
    };
    return { ...state, ...payload };
  }),
  on(fetchCampaignAffiliatesByComGroupSuccess, (state, { campaignAffiliates }) => {
    const payload = {
      data: campaignAffiliates.data,
      total: campaignAffiliates.total,
      maxPages: campaignAffiliates.maxPages,
      perPage: campaignAffiliates.perPage,
      currentPage: campaignAffiliates.currentPage,
      selectedItem: {},
      isLoaded: true
    };

    return { ...state, ...payload };
  }),
  on(fetchCampaignAffiliatesByComGroupFailure, (state, { error }) => {
    const payload = {
      error,
      isLoaded: true
    };
    return { ...state, ...payload };
  }),
  on(getCampaignAffiliate, (state, { id }) => {
    const payload = {
      isSaved: false,
    };
    
    return { ...state, ...payload };
  }),
  on(getCampaignAffiliateSuccess, (state, { campaignAffiliate }) => {
    const payload = {
      selectedItem: campaignAffiliate
    };
    return { ...state, ...payload };
  }),
  on(getCampaignAffiliateFailure, (state, { error }) => {
    const payload = {
      error
    };
    return { ...state, ...payload };
  }),
  on(deleteCampaignAffiliate, (state, { id }) => {
    const payload = {
      isSaved: false,
    };
    
    return { ...state, ...payload };
  }),
  on(deleteCampaignAffiliateSuccess, (state, { id }) => {
    let total: number = state.total - 1;
    const payload = {
      isLoaded: true,
      total,
      data: state.data.filter(item => item.id !== id)
    };
    return { ...state, ...payload };
  }),
  on(deleteCampaignAffiliateFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(addCampaignAffiliate, (state, { campaignAffiliate }) => {
    const payload = {
      isSaved: false,
    };
    
    return { ...state, ...payload };
  }),
  on(addCampaignAffiliateSuccess, (state, { campaignAffiliate }) => {
    let total: number = state.total;

    if (state.total < state.perPage) {
      total += 1;
    } else {
      state.data.splice(state.data.length - 1, 1);
    }
    
    const payload = {
      total,
      isSaved: true,
      data: [{ ...campaignAffiliate }, ...state.data]
    };
    return { ...state, ...payload };
  }),
  on(addCampaignAffiliateFailure, (state, { error }) => {
    const payload = {
      isSaved: false,
      error
    };
    return { ...state, ...payload };
  }),
  on(updateCampaignAffiliate, (state, { campaignAffiliate }) => {
    const payload = {
      isSaved: false,
    };

    return { ...state, ...payload };
  }),
  on(updateCampaignAffiliateSuccess, (state, { campaignAffiliate }) => {
    const index = state.data.findIndex(aff => aff.id === campaignAffiliate.id);
    state.data[index] = campaignAffiliate;
    
    const payload = {
      isSaved: true,
      selectedItem: campaignAffiliate
    }
    
    return { ...state, ...payload };
  }),
  on(updateCampaignAffiliateFailure, (state, { error }) => {
    const payload = {
      isSaved: false,
      error
    };
    return { ...state, ...payload };
  }),

  on(fetchAffiliatesInCampaign, (state, { page, filterOptions }) => {
    return state;
  }),
  on(fetchAffiliatesInCampaignSuccess, (state, { affiliatesInCampaign }) => {
    const payload = {
      isLoaded: true,
      data: affiliatesInCampaign.data,
      total: affiliatesInCampaign.total,
      maxPages: affiliatesInCampaign.maxPages,
      perPage: affiliatesInCampaign.perPage,
      currentPage: affiliatesInCampaign.currentPage,
      selectedItem: {},
      error: null
    };

    return { ...state, ...payload };
  }),
  on(fetchAffiliatesInCampaignFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(fetchAffiliateInCampaignById, (state, { id }) => {
    const payload = {
      isSaved: false,
    };
    
    return { ...state, ...payload };
  }),
  on(fetchAffiliateInCampaignByIdSuccess, (state, { affiliatesInCampaign }) => {
    const payload = {
      selectedItem: affiliatesInCampaign
    };
    return { ...state, ...payload };
  }),
  on(fetchAffiliateInCampaignByIdFailure, (state, { error }) => {
    const payload = {
      error
    };
    return { ...state, ...payload };
  }),

  on(updateAffiliateInCampaign, (state, { affStatus }) => {
    const payload = {
      isSaved: false
    }
    return state;
  }),

  on(updateAffiliateInCampaignSuccess, (state, { affStatus }) => {
    const index = state.data.findIndex(aff => aff.id === affStatus.id);

    state.data[index].status = affStatus.status;
  
    const payload = {
      isSaved: true
    }
    return {...state, ...payload};
  }),

  on(updateAffiliateInCampaignFailure, (state, { error }) => {
    const payload = {
      error
    };
    return { ...state, ...payload };
  }),
  on(updateCamAffiliatesComGroup, (state, { comGroup, camAffiliatesIds }) => {
    const payload = {
      error: null,
      isSaved: false
    }
    return { ...state, ...payload };
  }),
  on(updateCamAffiliatesComGroupSuccess, (state, { comGroup, camAffiliatesIds }) => {
    for (const id of camAffiliatesIds) {
      state.data.find(data => data.id === id).commission_group = comGroup;
    }
    const payload = {
      isSaved: true
    }
    return {...state, ...payload};
  }),

  on(changeMassStatus, (state, { massStatus }) => {
    const payload = {
      isSaved: false
    }
    return state;
  }),

  on(changeMassStatusSuccess, (state, { massStatus }) => {
    massStatus.ids.forEach(id => {
      const index = state.data.findIndex(aff => aff.id === id);
      state.data[index].status = massStatus.status;
      // let aff = state.data.find(aff => aff.id == id);
      // aff.status = massStatus.status;
    });
  
    const payload = {
      isSaved: true
    }
    return {...state, ...payload};
  }),

  on(changeMassStatusFailure, (state, { error }) => {
    const payload = {
      error
    };
    return { ...state, ...payload };
  })

);

export function campaignAffiliatesReducer(state, action) {
  return _campaignAffiliatesReducer(state, action);
}
