import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aff-manage-sources-page',
  templateUrl: './manage-sources-page.component.html',
  styleUrls: ['./manage-sources-page.component.scss']
})
export class ManageSourcesPage implements OnInit {
  
  constructor() { }

  ngOnInit() {
  }

}
