import {createReducer, on, Store} from '@ngrx/store';
import {
  fetchTopBanners,
  fetchTopBannersSuccess,
  fetchTopBannersFailure,
  fetchGroupDetails,
  fetchGroupDetailsSuccess,
  fetchGroupDetailsFailure,
  fetchTopAffiliate,
  fetchTopAffiliateSuccess,
  fetchTopAffiliateFailure,
  fetchCommissions,
  fetchCommissionsSuccess,
  fetchCommissionsFailure
} from './dashboard.actions';

import {IDashboardState} from '@models/index';

const initialState: IDashboardState = {
  data: {},
};

const _dashboardReducer = createReducer(
  initialState,
  on(fetchTopBanners, (state, {payload: {field, params}}) => {
    const returnPayload = {
      data: Object.assign(state.data, {
        topBanners: {
          data: state.data.topBanners ? Object.assign(state.data.topBanners.data, {
            [field]: {
              data: [],
              isLoaded: false,
              isLoading: true,
              error: null
            }
          }) : {},
          isLoaded: false,
          isLoading: true,
          error: null
        }
      }),
    };
    return {...state, ...returnPayload};
  }),
  on(fetchTopBannersSuccess, (state, {payload, field}) => {
    const returnPayload = {
      data: Object.assign(state.data, {
        topBanners: {
          ...state.data.topBanners,
          isLoaded: true,
          data: Object.assign(state.data.topBanners.data, {
            [field]: {
              data: payload,
              isLoaded: true,
              isLoading: false,
              error: null
            }
          }),
          isLoading: false,
          error: null
        },
      }),
    };

    return {...state, ...returnPayload};
  }),
  on(fetchTopBannersFailure, (state, {error, field}) => {
    const payload = {
      data: Object.assign(state.data, {
        topBanners: {
          ...state.data.topBanners,
          data: Object.assign(state.data.topBanners.data, {
            [field]: {
              data: [],
              isLoaded: true,
              isLoading: false,
              error
            }
          }),
          isLoaded: true,
          isLoading: false,
          error
        }
      }),
      error
    };
    return {...state, ...payload};
  }),

  on(fetchTopAffiliate, (state, {payload: {field, params}}) => {
    const returnPayload = {
      data: Object.assign(state.data, {
        topAffiliates: {
          data: state.data.topAffiliates ? Object.assign(state.data.topAffiliates.data, {
            [field]: {
              data: [],
              isLoaded: false,
              isLoading: true,
              error: null
            }
          }) : {},
          isLoaded: false,
          isLoading: true,
          error: null
        }
      }),
    };

    return {...state, ...returnPayload};
  }),
  on(fetchTopAffiliateSuccess, (state, {payload, field}) => {
    const returnPayload = {
      data: Object.assign(state.data, {
        topAffiliates: {
          ...state.data.topAffiliates,
          isLoaded: true,
          data: Object.assign(state.data.topAffiliates.data, {
            [field]: {
              data: payload,
              isLoaded: true,
              isLoading: false,
              error: null
            }
          }),
          isLoading: false,
          error: null
        }
      }),
    };

    return {...state, ...returnPayload};
  }),
  on(fetchTopAffiliateFailure, (state, {error, field}) => {
    const payload = {
      data: Object.assign(state.data, {
        topAffiliates: {
          ...state.data.topAffiliates,
          data: Object.assign(state.data.topAffiliates.data, {
            [field]: {
              data: [],
              isLoaded: true,
              isLoading: false,
              error
            }
          }),
          isLoaded: true,
          isLoading: false,
          error
        }
      }),
      error
    };
    return {...state, ...payload};
  }),

  on(fetchGroupDetails, (state) => {
    const returnState = {
      data: Object.assign(state.data, {
        groupDetails: {
          isLoaded: false,
          isLoading: true,
          data: [],
          error: null
        }
      }),
    };

    return {...state, ...returnState};
  }),
  on(fetchGroupDetailsSuccess, (state, {payload}) => {
    const returnState = {
      data: Object.assign(state.data, {
        groupDetails: {
          isLoaded: true,
          isLoading: false,
          data: payload,
          error: null
        }
      }),
    };

    return {...state, ...returnState};
  }),
  on(fetchGroupDetailsFailure, (state, {error}) => {
    const payload = {
      error,
      data: Object.assign(state.data, {
        groupDetails: {
          isLoaded: true,
          isLoading: false,
          data: [],
          error
        }
      })
    };
    return {...state, ...payload};
  }),

  on(fetchCommissions, (state) => {
    const returnState = {
      data: Object.assign(state.data, {
        commissions: {
          isLoaded: state.data.commissions ? true : false,
          isLoading: true,
          data: state.data.commissions ? state.data.commissions.data : [],
          error: null
        }
      }),
    };

    return {...state, ...returnState};
  }),
  on(fetchCommissionsSuccess, (state, {payload}) => {
    const returnState = {
      data: Object.assign(state.data, {
        commissions: {
          isLoaded: true,
          isLoading: false,
          data: payload,
          error: null
        }
      }),
    };

    return {...state, ...returnState};
  }),
  on(fetchCommissionsFailure, (state, {error}) => {
    const payload = {
      data: Object.assign(state.data, {
        commissions: {
          isLoaded: true,
          isLoading: false,
          data: [],
          error
        }
      })
    };
    return {...state, ...payload};
  }),
);

export function dashboardReducer(state, action) {
  return _dashboardReducer(state, action);
}
