import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'aff-affiliates-add-tabs',
  templateUrl: './affiliates-add-tabs.component.html',
  styleUrls: ['./affiliates-add-tabs.component.scss']
})
export class AffiliatesAddTabsComponent implements OnInit {
  @Output() tab = new EventEmitter<string>()
  @Input() editMode: boolean = false;
  
  public tabNames: string[] = ['payoutsTab', 'downlinesTab', 'trackingTab','mailsTab', 'loginsHistoryTab', 'campaignsTabs', 'commissionGroupsTab'];
  
  public tabName: string = 'detailsTab';

  constructor() { }

  ngOnInit() {
  }

  onChangeTab(tabName: string) {
  if(this.tabNames.includes(tabName) && !this.editMode) return;
  this.tabName = tabName;
  this.tab.emit(tabName);
  }
}
