import { createAction, props } from "@ngrx/store";


export const fetchTreshold = createAction(
  "[Config] Fetch Treshold"
);

export const fetchTresholdSuccess = createAction(
  "[Config] Fetch Treshold Success",
  props<{ treshold }>()
);

export const fetchTresholdFailure = createAction(
  "[Config] Fetch Treshold Faild",
  props<{ error }>()
);
export const updateTreshold = createAction(
  "[Config] Update Treshold",
  props<{ treshold }>()
);

export const updateTresholdSuccess = createAction(
  "[Config] Update Treshold Success",
  props<{ treshold }>()
);

export const updateTresholdFailure = createAction(
  "[Config] Update Treshold Failure",
  props<{ error }>()
);


