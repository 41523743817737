import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";

import { Store } from "@ngrx/store";
import {
  fetchAffiliateManagers,
  fetchFilteredAffiliateManagers,
  deleteAffiliateManager,
  addAffiliateManager,
  updateAffiliateManager,
  getAffiliateManager,
  clearSelectedManager
} from "@store/affiliate-managers/affiliate-managers.action";
import { 
  getAffiliateManagersSelector, 
  getAffiliateManagerSelector } from '@store/affiliate-managers/affiliate-managers.selectors';
import { IState } from '@models/index';

import { HelperService } from '@services/helper/helper.service';
import { SharedService } from '@services/shared/shared.service';

@Injectable({
  providedIn: "root"
})
export class AffiliateManagersService {
  backendUrl = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private store: Store<{ affiliateManagers: IState }>
  ) {}

  getAffiliateManagersSelector(): Observable<IState> {
    return this.store.select(getAffiliateManagersSelector);
  }

  getAffiliateManagerSelector(): Observable<IState> {
    return this.store.select(getAffiliateManagerSelector);
  }
  
  fetchAffiliateManagers(page: number, filterOptions: any) {
    this.store.dispatch(fetchAffiliateManagers({ page, filterOptions }));
  }

  fetchFilteredAffiliateManagers(page: number, filterOptions: any) {
    this.store.dispatch(fetchFilteredAffiliateManagers({ page, filterOptions }));
  }

  fetchAffiliateManager(id: string) {
    this.store.dispatch(getAffiliateManager({ id }))
  }

  deleteAffiliateManager(id: string) {
    this.store.dispatch(deleteAffiliateManager({ id }));
  }

  clearaffiliateManager() {
    this.store.dispatch(clearSelectedManager());
  }

  addAffiliateManager(affiliateManager: any) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(addAffiliateManager({ affiliateManager }));
  }

  updateAffiliateManager(affiliateManager: any) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(updateAffiliateManager({ affiliateManager }));
  }

  getAffiliateManagers(page: number, filterOptions: any = { }) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);
    
    return this.http.get(`${this.backendUrl}/users/affiliate-managers?${queryString}`);
  }

  getAffiliateManager(id: string) {
    return this.http.get(`${this.backendUrl}/users/user/${id}`);
  }

  removeAffiliateManager(id: string) {
    return this.http.delete(`${this.backendUrl}/users/user/${id}`);
  }

  insertAffiliateManager(AffiliateManager: any) { 
    return this.http.post(`${this.backendUrl}/users/user/`, AffiliateManager);
  }

  renewAffiliateManager(AffiliateManager: any) {
    return this.http.patch(`${this.backendUrl}/users/user/${AffiliateManager.id}`, AffiliateManager)
  }
}
