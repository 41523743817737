import { createSelector, createFeatureSelector } from '@ngrx/store';
import { PageState } from '@models/index';

export const pageSelector = createFeatureSelector<PageState>('page');
export const getPageSelector = createSelector(pageSelector, (state: PageState) => state);
export const getTitleSelector = createSelector(pageSelector, (state: PageState) => state.breadcrum[state.breadcrum.length - 1]);
export const getBreadcrumSelector = createSelector(pageSelector, (state: PageState) => state.breadcrum);
export const getPageURLSelector = createSelector(pageSelector, (state: PageState) => state.pageURL);
export const getDatePickerOptionsSelector = createSelector(pageSelector, (state: PageState) => state.datePickerOptions);
export const getResourcesSelector = createSelector(pageSelector, (state: PageState) => state.resources);
export const getRolesSelector = createSelector(pageSelector, (state: PageState) => state.roles);
export const getRoleSelector = createSelector(pageSelector, (state: PageState) => state.roles.selectedItem);
export const getPermissionsSelector = createSelector(pageSelector, (state: PageState) => state.permissions);
export const getUserStatusSelector = createSelector(pageSelector, (state: PageState) => state.userStatus.data);
export const getUserTypesSelector = createSelector(pageSelector, (state: PageState) => state.userTypes);
export const getUserTypeSelector = createSelector(pageSelector, (state: PageState) => state.userTypes.selectedItem);
export const getUserTypeListSelector = createSelector(pageSelector, (state: PageState) => state.userTypes.data);
export const getEnumsSelector = createSelector(pageSelector, (state: PageState) => state.enums);
export const getHelpSelector = createSelector(pageSelector, (state: PageState) => state.help);
