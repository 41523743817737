import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageService } from '@services/page/page.service';
import { IPage, IFormOptions } from '@interfaces/index';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HelperService } from '@services/helper/helper.service';
import { IModalvalue } from '@shared-models/index';

@Component({
  selector: 'aff-affiliate-types-page',
  templateUrl: './affiliate-types-page.component.html',
  styleUrls: ['./affiliate-types-page.component.scss']
})
export class AffiliateTypesPage implements OnInit, OnDestroy {

  addAffTypeForm: FormGroup;
  editMode: boolean = false;

  maxOrder: number;
  typeId: string;
  userType;
  confirmationModal: boolean = false;
  deleteValues: string = '';

  showFiterComponent: boolean = false;
  popupForm: boolean = false;
  loadingIndicator: boolean = true;

  selectedRows = [];
  rows: any[] = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  errorObj: any = {};

  affTypes$: Observable<any>;
  affType$: Observable<any>;
  unsubscribe$ = new Subject<void>();

  constructor(
    private pageService: PageService,
    private formBuilder: FormBuilder) {
    this.addAffTypeForm = this.formBuilder.group({
      name: new FormControl(null, [Validators.required]),
      display_order: new FormControl(null),
      is_default: new FormControl(false)
    });
    this.pageService.changePageInfo({ breadcrum: ['Configuration', 'Affiliate Types'] });

    this.affTypes$ = this.pageService.getUserTypesSelector();

    this.affType$ = this.pageService.getUserTypeSelector();
  
    this.pageService.fetchUserTypes();

  }

  ngOnInit(): void {
    this.affTypes$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        // if(state.isSaved) {
        //   this.popupForm = false;
        // }

        if(!HelperService.isObjectEmpty(state.selectedItem)) {
            HelperService.fillFormValues(state.selectedItem, this.addAffTypeForm);
        }
        if (!state.isLoaded) return;
        this.maxOrder = state.data.length + 1;
        this.rows = [];
        for (const type of state.data) {
          const row = {
            affiliateTypeId: type.id,
            affiliateTypeName: type.name,
            default: type.is_default,
            displayOrder: type.display_order
          };
          this.rows.push(row);
        }

        this.rows = [...this.rows];
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
      });

  }

  onAddaffTypes(): void {
    this.addAffTypeForm.reset();
    this.editMode = false;
    this.popupForm = true;
  }

  closePopup(value: boolean): void {
    this.popupForm = value;
  }

  onAction(action: string, row: any): void {
     switch (action) {
      case "delete":
        this.deleteValues = `Affiliate Type: <br><br> ${row.affiliateTypeName}`;
        this.selectedRows= [{ id: row.affiliateTypeId }];
        this.confirmationModal = true;
        break;

      case "edit":
        this.popupForm = true;
        this.editMode = true;
        this.typeId = row.affiliateTypeId;
        this.pageService.fetchUserType(row.affiliateTypeId);
        break;
    }
  }

  onDeleteConfirm(options: IModalvalue): void {
    if (options.confirm) {
      for (const selectedRow of this.selectedRows) {
        this.pageService.deleteUserType(selectedRow.id);
      }
      this.selectedRows = [];
    }
    this.confirmationModal = false;
  }

  onSelect(e): void {
    this.selectedRows = e.selected;
  }

  saveUserType(): void {
    this.errorObj = HelperService.checkValidation(this.addAffTypeForm);
    if (HelperService.isObjectEmpty(this.errorObj)) {
      if(!this.addAffTypeForm.value.is_default) {
        this.addAffTypeForm.value.is_default = false;
      }
      this.pageService.addUserType(this.addAffTypeForm.value);
    }
  }

  updateUserType() {
    this.errorObj = HelperService.checkValidation(this.addAffTypeForm);
    if(this.addAffTypeForm.value.type_name != '') {
      delete this.errorObj['type_name'];
    }
    if (HelperService.isObjectEmpty(this.errorObj)) {
      this.pageService.updateUserType({ id: this.typeId, ...this.addAffTypeForm.value });
    }
  }

  onChangeFormValue(options: IFormOptions): void {
    switch(options.name) {
      case 'submit' :
        if(this.editMode) {
          this.addAffTypeForm.value.display_order = this.addAffTypeForm.value.display_order 
          ? this.addAffTypeForm.value.display_order : this.userType.display_order;
        } else {
          this.addAffTypeForm.value.display_order = this.addAffTypeForm.value.display_order 
          ? this.addAffTypeForm.value.display_order : this.maxOrder;
        }
        this.editMode ? this.updateUserType() : this.saveUserType();
        if (HelperService.isObjectEmpty(this.errorObj)) {
        this.popupForm = false;
        }
        break;
      
      case 'is_default': 
        this.addAffTypeForm.patchValue({ [options.name]: options.checked });
        break;

      default :
        this.addAffTypeForm.patchValue({ [options.name]: options.value });
        break;
    }
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
