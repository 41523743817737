import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { 
  UserService, 
  PageService, 
  HelperService, 
  SharedService 
} from '@services/index';

import { UserState } from '@models/index';
import { environment } from 'environments/environment';

@Component({
  selector: 'aff-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.scss']
})
export class RegistrationPage implements OnInit, OnDestroy {
  @ViewChild('recaptcha', { static: true }) recaptcha;

  siteKey = environment.site_key;
  regForm: FormGroup;

  notification: any = {
    errorMessage: '',
    successfully: ''
  };

  passwordShowOptions: any = {
    password: { 
      show: false,
      icon: '/assets/images/icons/password-show.svg'
    },
    confirm: {
      show: false,
      icon: '/assets/images/icons/password-show.svg'
    }
  }

  user$: Observable<UserState>;
  unsubscribe$ = new Subject<void>();

  constructor(private userService: UserService,
              private pageService: PageService,
              private sharedService: SharedService,
              private formBuilder: FormBuilder) { 

    //set title
    pageService.changePageInfo({ breadcrum: ['Sign up']});

    this.user$ = userService.getUserSelector();
    
    this.regForm = this.formBuilder.group({
      first_name: new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z]*$')]),
      last_name: new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z]*$')]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      confirm_password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      captcha: new FormControl(null, [Validators.required]),
      role: new FormControl(2, [Validators.required]),
      terms: new FormControl(false, [Validators.required])
    }, {
      validator: HelperService.passwordMatchValidator
    });
  }

  ngOnInit() {
    this.user$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(userState => {
      
        if (userState.isRegistrated) {
          this.notification.successfully = 'You successfully registered! Account activation link is sent to your email address.'
          this.regForm.reset();
          this.recaptcha.reset();
        }

        if (userState.error) {
          this.recaptcha.reset();

          switch (userState.error) {
            case 'duplicate email address':
              this.notification.email = 'This email already exist!';
              break;
  
            case 'Verification expired':
              this.notification.errorMessage = 'Verification expired. Check the checkbox again!';
              break;
          
            default:
              this.notification.errorMessage = 'Something went wrong!';
              break;
          }
        }
      });
  }

  onShowPassword(option: string): void {
    this.passwordShowOptions[option].show = !this.passwordShowOptions[option].show;
    this.passwordShowOptions[option].show 
      ? this.passwordShowOptions[option].icon = '/assets/images/icons/password-hide.svg' 
      : this.passwordShowOptions[option].icon = '/assets/images/icons/password-show.svg'
  }

  onSignup(): string | void {
    this.notification = HelperService.checkValidation(this.regForm);

    if (!this.regForm.value.terms) {
      return this.notification.errorMessage = 'You have to agree to the User Agreement, Privacy Policy and Cookie Policy!';
    } else if (this.regForm.get('captcha').errors) {
      return this.notification.errorMessage = 'Captcha cannot be empty!';
    } else {
      this.notification.errorMessage = '';
    }
    
    if (this.regForm.valid) {
      this.sharedService.onLoaderToggle(true);
      this.userService.clearUSerState();
      this.userService.dispatchSignup(this.regForm.value);
    }
  }

  onChangeFormValue(e: any, name: string) {
    let val = e.target.value;

    switch(name) {
      case 'first_name':
      case 'last_name':
        val = val.replace(/\s\s+/g, ' ');

      default :
        this.regForm.patchValue({ [name]: val.trim() });
    }
  }

  resolved(captchaResponse: string): void {
    this.regForm.patchValue({ captcha: captchaResponse })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
