import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetchNavigation , fetchNavigationSuccess, fetchNavigationFailure } from './navigation.actions';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { SharedService } from '@services/shared/shared.service';

@Injectable()
export class NavigationEffects {

  loadNavigation$ = createEffect(() => this.actions$.pipe(
    ofType(fetchNavigation),
    mergeMap(() => this.sharedService.getNavigation()
      .pipe(
        map(navigation => {
            return fetchNavigationSuccess({navigation});
        }),
        catchError((error) => {
            return of(fetchNavigationFailure({error}))
        })
      ))
    )
  );

  constructor(
    private actions$: Actions,
    private sharedService: SharedService
  ) {}
}