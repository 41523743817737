import {Directive, EventEmitter, HostBinding, HostListener} from '@angular/core';

@Directive({
  selector: '[appDropDown]',
  exportAs: 'appDropDown'
})
export class DropDownDirective {

  @HostBinding('class.open') isOpen = false;
  private target: any;
  private dropTarget: Element;
  public events: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {

    if (!!this.dropTarget) {
      const clickedInside = this.dropTarget.contains(targetElement);
      const clickTarget = this.target.contains(targetElement);

      if (!clickedInside) {
        if (this.isOpen && !clickTarget) {
          this.isOpen = false;
          this.dropTarget = null;
          this.events.emit({isOpen: this.isOpen});
        }
      }
    }
  }

  @HostListener('click', ['$event'])
  public onClickE(e) {
    this.target = e.target.parentNode;
    this.dropTarget = e.target.parentNode.nextElementSibling;
    this.isOpen = !this.isOpen;
    this.events.emit({isOpen: this.isOpen});
  }

}
