import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

/**
 * Dynamic Checkbox Component
 */
@Component({
  selector: 'aff-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  /**
   * Checkbox name for form
   */
  @Input() name: string = '';

  /**
   * Checkbox ID for form
   */
  @Input() id: string = '';

  /**
   * Checkbox value for form
   */
  @Input() checked: boolean = false;

  /**
   * Checkbox value for form
   */
  @Input() value: string = '';

  /**
   * Checkbox label for form
   */
  @Input() label: string = '';

  /**
   * Checkbox disabled for form
   */
  @Input() disabled: boolean = false;

  /**
   * Checkbox change handler for parent component
   */
  @Output() onCheckValue = new EventEmitter;

  constructor() { }

  /**
   * @ignore
   */
  ngOnInit() {
  }

  /**
   * Checkbox change detect functionality
   * @param e
   */
  onCheckValueChange(e) {
    const options = {
      name: this.name,
      checked: e.target.checked,
      value: this.value
    };
    this.onCheckValue.emit(options);
  }

}
