import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { FormControl } from '@angular/forms';

import { AffiliateService, HelperService, MessageService } from "@services/index";
import { IState } from '@models/index';
import { IPage, IFormOptions } from '@interfaces/index';

@Component({
  selector: 'aff-affiliates-mails-page',
  templateUrl: './affiliates-mails-page.component.html',
  styleUrls: ['./affiliates-mails-page.component.scss']
})
export class AffiliatesMailsPage implements OnInit, OnDestroy{

  affiliateID: string = '';

  rows = [];
  loadingIndicator: boolean = false;
  selectedRows = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  editMode: boolean = false

  filterOptions: any = {
    user_id: ''
  };

  searchValue = new FormControl();

  messages$: Observable<IState>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private affiliateService: AffiliateService,
    private messageService: MessageService,
    private route: ActivatedRoute ) {

    this.messages$ = this.messageService.getMessagesSelector();
  }

  ngOnInit(): void {

      // get affiliate id
      this.affiliateID = this.route.snapshot.params.id;

      if (this.affiliateID) {
        this.messageService.fetchMessages(1, {}, { user_id: this.affiliateID } );
      }
   

    this.searchValue.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => {
        this.messageService.fetchMessages(1, {}, { user_id: this.affiliateID } );
      });

    this.messages$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.rows = [];
          for (const data of state.data) {
            console.log(data)
            const row = {
              id: data.id,
              subject: data.subject,
              from: data.sent.email,
              to: data.received.email, 
              message: data.text,
              date: data.created_at
            };
            this.rows.push(row);
          }

          this.rows = [...this.rows];

          // get pagination options
          this.page.currentPage = state.currentPage - 1;
          this.page.perPage = state.perPage;
          this.page.maxPages = state.maxPages;
          this.page.total = state.total;
        }

        // close loader
        this.loadingIndicator = false;
      });
  }

  onSearch(options: IFormOptions): void {
    this.searchValue.patchValue(options.value);
  }

  setPage(e): void {
    this.selectedRows = [];
    this.messageService.fetchMessages(1, {}, { user_id: this.affiliateID } );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
