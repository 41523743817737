import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AffiliatePaymentsPageRoutingModule } from './affiliate-payments-page-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '@shared/shared.module';
import { SharedPipesModule } from '@pipes/index';
import { NgSelectModule } from '@ng-select/ng-select';
import { MyDatePickerModule } from 'mydatepicker';
import { SharedDirectivesModule } from '@directives/index';

import { 
  PayAffiliatesPage, 
  PayoutHistoryPage, 
  PaymentApprovePopup,
  PayoutHistoryFilterComponent,
  PayAffiliatesFilterComponent } from './index';

@NgModule({
  declarations: [
    PayAffiliatesPage,
    PayoutHistoryPage,
    PayAffiliatesFilterComponent,
    PayoutHistoryFilterComponent,
    PaymentApprovePopup
  ],
  imports: [
    CommonModule,
    AffiliatePaymentsPageRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    SharedModule,
    NgSelectModule,
    MyDatePickerModule,
    SharedPipesModule,
    SharedDirectivesModule
  ]
})
export class AffiliatePaymentsPageModule { }
