import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import {
    fetchTreshold,
    fetchTresholdSuccess,
    fetchTresholdFailure,
    updateTreshold,
    updateTresholdSuccess,
    updateTresholdFailure
} from "./config.action";
import { EMPTY, of } from "rxjs";
import { map, mergeMap, catchError, tap } from "rxjs/operators";
import { SharedService } from '@services/shared/shared.service';
import { ConfigService } from '@services/config/config.service';


@Injectable()
export class ConfigEffects {

loadTreshold$ = createEffect(() =>
this.actions$.pipe(
  ofType(fetchTreshold),
  mergeMap(action =>
    this.configService.getTreshold().pipe( 
      map(res => {
        return fetchTresholdSuccess({ treshold: res });
      }),
      catchError(error => {
        this.sharedService.onLoaderToggle(false);
        return of(fetchTresholdFailure({ error }));
      })
    )
  )
)
);

updatTreshold$ = createEffect(() =>
this.actions$.pipe(
  ofType(updateTreshold),
  mergeMap(action =>
    this.configService.renewTreshold(action.treshold).pipe(
      map(treshold => {
        return updateTresholdSuccess({ treshold });
      }),
      tap(res => {
        this.configService.fetchTreshold(); 
      }),
      catchError(error => {
        return of(updateTresholdFailure({ error }));
      })
    )
  )
)
);
  
  constructor(
    private actions$: Actions,
    private configService: ConfigService,
    private sharedService: SharedService,
    private router: Router
  ) {}
}
