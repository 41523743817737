import { Directive, OnInit, OnDestroy, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil, pluck } from 'rxjs/operators';

import { UserService, HelperService } from '@services/index';
import { UserRole } from '@enums/index';

@Directive({
    selector: '[appHasRole]'
  })
export class HasRoleDirective implements OnInit, OnDestroy {
  // the role the user must have 
  @Input() appHasRole: string;

  profile$: Observable<any>;
  unsubscribe$ = new Subject();

  isVisible = false;

  /**
   * @param {ViewContainerRef} viewContainerRef 
   * 	-- the location where we need to render the templateRef
   * @param {TemplateRef<any>} templateRef 
   *   -- the templateRef to be potentially rendered
   */
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private userService: UserService
  ) {
      this.profile$ = userService.getProfileSelector();
  }

  ngOnInit() {
    this.profile$
      .pipe(takeUntil(this.unsubscribe$), pluck('roles'))
      .subscribe(roles => {
        // If he doesn't have any roles, we clear the viewContainerRef
        if (!roles || !roles.length) {
          return this.viewContainerRef.clear();
        }
        // If the user has the role needed to 
        // render this component we can add it
        if (roles[0].role_id === UserRole[this.appHasRole]) {
          // If it is already visible (which can happen if
          // his roles changed) we do not need to add it a second time
          if (!this.isVisible) {
            // We update the `isVisible` property and add the 
            // templateRef to the view using the 
            // 'createEmbeddedView' method of the viewContainerRef
            this.isVisible = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        } else {
          // If the user does not have the role, 
          // we update the `isVisible` property and clear
          // the contents of the viewContainerRef
          this.isVisible = false;
          this.viewContainerRef.clear();
        }
      });
  }
  
  // Clear the subscription on destroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}