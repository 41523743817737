import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, FormControl} from '@angular/forms';
import {DashboardService} from "@services/dashboard/dashboard.service";
import {Observable, Subject} from "rxjs";
import {TopBannersModel, TopBannersDataState} from "@models/iDashboardState";
import {StoreModel} from "@interfaces/IStoreModel";
import {takeUntil} from "rxjs/operators";
import {AffiliateService} from "@services/affiliate/affiliate.service";

@Component({
  selector: 'aff-dashboard-tables',
  templateUrl: './dashboard-tables.component.html',
  styleUrls: ['./dashboard-tables.component.scss']
})
export class DashboardTablesComponent implements OnInit, OnDestroy {

  topForm: FormGroup;

  private topAffiliates$: Observable<StoreModel<any>>;
  public affListState: StoreModel<any>;
  public affActiveListKey: string = 'all';
  public affActiveList: StoreModel<any[]>;

  private topBanners$: Observable<StoreModel<TopBannersDataState>>;
  public topBannersState: StoreModel<TopBannersDataState>;
  public bannersActiveListKey: string = 'all';
  public bannersActiveList: StoreModel<TopBannersModel[]>;

  private subs$ = new Subject();
  public affiliateManager: any;
  public affPopupState: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private affiliateService: AffiliateService,
  ) {
    this.topForm = this.formBuilder.group({
      top_aff: new FormControl('all'),
      top_banner: new FormControl('all')
    });
    this.topAffiliates$ = this.dashboardService.selectTopAffiliates();
    this.topBanners$ = this.dashboardService.selectTopBanners();
  }


  ngOnInit() {
    this.fetchTopBanners();
    this.fetchTopAffiliates();
  }

  ngOnDestroy(): void {
    this.subs$.next(null);
    this.subs$.unsubscribe();
  }

  fetchTopBanners() {
    this.dashboardService.fetchTopBanners('all', {})

    this.topBanners$
      .pipe(takeUntil(this.subs$))
      .subscribe(resp => {
        this.topBannersState = resp;

        if (resp.isLoaded) {
          this.bannersActiveList = resp.data[this.bannersActiveListKey];
        }
      });
  }

  fetchTopAffiliates() {
    this.dashboardService.fetchTopAffiliates('all', {})

    this.topAffiliates$
      .pipe(takeUntil(this.subs$))
      .subscribe(resp => {
        this.affListState = resp;

        if (resp.isLoaded) {
          this.affActiveList = resp.data[this.affActiveListKey];
        }
      });
  }

  onChangeFormValue(e: any) {
    if (e.name === 'top_aff') {
      this.dashboardService.fetchTopAffiliates(e.value, e.value !== 'all' ? {type: e.value} : {});
      this.affActiveListKey = e.value;
    } else {
      this.dashboardService.fetchTopBanners(e.value, e.value !== 'all' ? {type: e.value} : {});
      this.bannersActiveListKey = e.value;

    }
  }

  affPopupToggle($event: boolean) {
    this.affPopupState = $event;
  }

  openAffiliate(id: any) {
    this.affPopupToggle(true);
    this.affiliateService.fetchAffiliate(id);
    const selectedAff = this.affActiveList.data.find(i => i.id === id);
    this.affiliateManager = selectedAff;
  }
}
