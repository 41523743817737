import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import {
  fetchCountry,
  fetchCountrySuccess,
  fetchCountryFailure
} from "./country.actions";
import { EMPTY, of } from "rxjs";
import { map, mergeMap, catchError, tap } from "rxjs/operators";
import { CountryService } from '../../services/country/country.service';


@Injectable()
export class CountryEffects {

loadCountries$ = createEffect(() =>
this.actions$.pipe(
  ofType(fetchCountry),
  mergeMap(action =>
    this.countryService.getCountry().pipe(
      map(res => {
        return fetchCountrySuccess({ country: res });
      }),
      catchError(error => {
        return of(fetchCountryFailure({ error }));
      })
    )
  )
)
);


  constructor(
    private actions$: Actions,
    private countryService: CountryService
  ) {}
}
