import { createAction, props } from "@ngrx/store";

export const fetchCommissionGroup = createAction(
  "[Commission Group] Fetch",
  props<{ page, campaignID, filterOptions }>()
);

export const fetchCommissionGroupSuccess = createAction(
  "[Commission Group] Fetch Success",
  props<{ commissionGroup }>()
);

export const fetchCommissionGroupFailure = createAction(
  "[Commission Group] Fetch Faild",
  props<{ error }>()
);

export const fetchFilteredCommissionGroups = createAction(
  "[Commission Group] Fetch Filtered",
  props<{ page, campaignID, filterOptions }>()
);

export const fetchFilteredCommissionGroupsSuccess = createAction(
  "[Commission Group] Fetch Filtered Success",
  props<{ commissionGroups }>()
);

export const fetchFilteredCommissionGroupsFailure = createAction(
  "[Commission Group] Fetch Filtered Faild",
  props<{ error }>()
);

export const getCommissionGroup = createAction(
  "[Commission Group] Get",
  props<{ id }>()
);

export const getCommissionGroupSuccess = createAction(
  "[Commission Group] Get Success",
  props<{ commissionGroup }>()
);

export const getCommissionGroupFailure = createAction(
  "[Commission Group] Get Failure",
  props<{ error }>()
);

export const deleteCommissionGroup = createAction(
  "[Commission Group] Delete",
  props<{ id, moveAffToGroupId, affCount }>()
);

export const deleteCommissionGroupSuccess = createAction(
  "[Commission Group] Delete Success",
  props<{ id, moveAffToGroupId, affCount }>()
);

export const deleteCommissionGroupFailure = createAction(
  "[Commission Group] Delete Failure",
  props<{ error }>()
);

export const addCommissionGroup = createAction(
  "[Commission Group] Add",
  props<{ commissionGroup }>()
);

export const addCommissionGroupSuccess = createAction(
  "[Commission Group] Add Success",
  props<{ commissionGroup }>()
);

export const addCommissionGroupFailure = createAction(
  "[Commission Group] Add Failure",
  props<{ error }>()
);

export const updateCommissionGroup = createAction(
  "[Commission Group] Update",
  props<{ commissionGroup }>()
);

export const updateCommissionGroupSuccess = createAction(
  "[Commission Group] Update Success",
  props<{ commissionGroup }>()
);

export const updateCommissionGroupFailure = createAction(
  "[Commission Group] Update Failure",
  props<{ error }>()
);

export const fetchCommissionGroupsByAffiliate = createAction(
  "[Commission Group] Fetch Groups By Affiliate",
  props<{ page, affiliateID, filterOptions }>()
);

export const fetchCommissionGroupsByAffiliateSuccess = createAction(
  "[Commission Group] Fetch Groups By Affiliate Success",
  props<{ commissionGroup }>()
);

export const fetchCommissionGroupsByAffiliateFailure = createAction(
  "[Commission Group] Fetch Groups By Affiliate Faild",
  props<{ error }>()
);

export const clearCommissionGroup = createAction(
  "[Commission Group] Clear Selected"
);

export const makeDefaultComGroup = createAction(
  "[Commission Group] Make Default",
  props<{ id, campaignId }>()
);

export const makeDefaultComGroupSuccess = createAction(
  "[Commission Group] Make Default Success",
  props<{ id, comGroup }>()
);

export const makeDefaultComGroupFailure = createAction(
  "[Commission Group] Make Default Faild",
  props<{ error }>()
);
