import { Component, OnInit, Inject, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'aff-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit, OnDestroy {
  @Output() closePopup = new EventEmitter<boolean>();
  @Input() showClose: boolean = true;
  @Input() scrollable: boolean = true;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.document.body.classList.add('modal-open');
  }

  ngOnInit() {
  }

  closePopupBox(value: boolean, e: Event): void {
    // e.stopPropagation();
    if (e.target['className'] === 'popup_container' || e.target['className'] === 'close') {
      this.closePopup.emit(value);
    }
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove('modal-open');
  }
}
