import {createReducer, on} from "@ngrx/store";

import {
  addCampaignUrl, addCampaignUrlFailure, addCampaignUrlSuccess,
  deleteCampaignUrl,
  deleteCampaignUrlFailure,
  deleteCampaignUrlSuccess,
  editCampaignUrl,
  editCampaignUrlFailure,
  editCampaignUrlSuccess,
  fetchCampaignURLs,
  fetchCampaignURLsFailure,
  fetchCampaignURLsSuccess
} from "@store/campaign-urls/campaign-urls.actions";
import {StoreModel} from "@interfaces/IStoreModel";
import {ICampaignUrlsState} from "@models/iCampaignUrlsState";

const initialState: StoreModel<ICampaignUrlsState> | any = {
  data: null,
  error: null,
  isLoading: false,
  isLoaded: false,
  action: null,
  selectedItem: null
};

const _campaignUrlsReducers = createReducer(
  initialState,
  on(fetchCampaignURLs, (state, {payload}) => {
    const returnPayload = Object.assign(state, {
      isLoading: true,
      isLoaded: false
    });

    return {...state, ...returnPayload}
  }),
  on(fetchCampaignURLsSuccess, (state, {payload}) => {
    const returnPayload = Object.assign(state, {
      ...payload,
      isLoaded: true,
      isLoading: false,
      error: null
    })
    return {...state, ...returnPayload}
  }),
  on(fetchCampaignURLsFailure, (state, {error}) => {
    const returnPayload = Object.assign(state, {
      data: [],
      isLoaded: true,
      isLoading: false,
      error: error
    })
    return {...state, ...returnPayload}
  }),
  on(addCampaignUrl, (state, {params}) => {
    const returnPayload = Object.assign(state, {
      action: true,
      isLoading: true,
      isLoaded: false
    });

    return {...state, ...returnPayload}
  }),
  on(addCampaignUrlSuccess, (state, {payload}) => {
    const returnPayload = Object.assign(state, {
      isLoaded: true,
      isLoading: false,
      error: null,
      selectedItem: payload
    })
    return {...state, ...returnPayload}
  }),
  on(addCampaignUrlFailure, (state, {error}) => {
    const returnPayload = Object.assign(state, {
      isLoaded: true,
      isLoading: false,
      error: error
    })
    return {...state, ...returnPayload}
  }),
  on(deleteCampaignUrl, (state, {id}) => {
    const returnPayload = Object.assign(state, {
      action: true,
      isLoading: true,
      isLoaded: false
    });

    return {...state, ...returnPayload}
  }),
  on(deleteCampaignUrlSuccess, (state, {id}) => {
    const returnPayload = Object.assign(state, {
      isLoaded: true,
      isLoading: false,
      error: null
    })
    return {...state, ...returnPayload}
  }),
  on(deleteCampaignUrlFailure, (state, {error}) => {
    const returnPayload = Object.assign(state, {
      isLoaded: true,
      isLoading: false,
      error: error
    })
    return {...state, ...returnPayload}
  }),
  on(editCampaignUrl, (state, {id, params}) => {
    const returnPayload = Object.assign(state, {
      action: true,
      isLoading: true,
      isLoaded: false
    })
    return {...state, ...returnPayload}
  }),
  on(editCampaignUrlSuccess, (state, {payload}) => {
    const returnPayload = Object.assign(state, {
      isLoaded: true,
      isLoading: false,
      error: null,
      selectedItem: payload
    })
    return {...state, ...returnPayload}
  }),
  on(editCampaignUrlFailure, (state, {error}) => {
    const returnPayload = Object.assign(state, {
      isLoaded: true,
      isLoading: false,
      error: error
    })
    return {...state, ...returnPayload}
  })
);

export function campaignUrlsReducers(state, action) {
  return _campaignUrlsReducers(state, action);
}
