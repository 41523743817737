import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {EMPTY, of} from "rxjs";
import {map, mergeMap, catchError, tap} from "rxjs/operators";
import {CampaignService} from "@services/campaign/campaign.service";
import {SharedService} from '@services/shared/shared.service';
import {
  addCampaignUrl, addCampaignUrlFailure, addCampaignUrlSuccess,
  deleteCampaignUrl,
  deleteCampaignUrlFailure,
  deleteCampaignUrlSuccess,
  editCampaignUrl,
  editCampaignUrlFailure,
  editCampaignUrlSuccess,
  fetchCampaignURLs,
  fetchCampaignURLsFailure,
  fetchCampaignURLsSuccess
} from "@store/campaign-urls/campaign-urls.actions";
import {Store} from "@ngrx/store";

@Injectable()
export class CampaignUrlsEffects {
  getCampaignUrls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCampaignURLs),
      mergeMap(action => this.campaignService.getCampaignUrls(action.page, action.payload).pipe(
        map(e => {
          return fetchCampaignURLsSuccess({payload: e});
        }),
        catchError(error => {
          return of(fetchCampaignURLsFailure({error}));
        })
        )
      )
    )
  );

  addCampaignUrls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCampaignUrl),
      mergeMap(action => this.campaignService.addCampaignUrls(action.params).pipe(
        tap((e) => {
          this.store.dispatch(fetchCampaignURLs({page: 1, payload: action.params.campaign_id}))
        }),
        map(e => {
          this.sharedService.onSaveToggle(true, false);
          return addCampaignUrlSuccess({payload: e});
        }),
        catchError(error => {
          this.sharedService.onSaveToggle(true, true, 'Error');
          return of(addCampaignUrlFailure({error}));
        }),
        )
      )
    )
  );

  deleteCampaignUrls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCampaignUrl),
      mergeMap(action => this.campaignService.deleteCampaignUrls(action.id)
        .pipe(
          tap(() => {
            this.store.dispatch(fetchCampaignURLs({page: 1, payload: action.params.campaign_id}))
          }),
          map(e => {
            this.sharedService.onSaveToggle(true, false, 'Successfully deleted');
            return deleteCampaignUrlSuccess({id: action.id});
          }),
          catchError(error => {
            this.sharedService.onSaveToggle(true, true, 'Error');
            return of(deleteCampaignUrlFailure({error}));
          })
        )
      )
    )
  );

  editCampaignUrls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editCampaignUrl),
      mergeMap(action => this.campaignService.editCampaignUrls(action.id, action.params).pipe(
        tap(() => {
          this.store.dispatch(fetchCampaignURLs({page: 1, payload: action.params.campaign_id}))
        }),
        map(e => {
          this.sharedService.onSaveToggle(true, false);
          return editCampaignUrlSuccess({payload: e});
        }),
        catchError(error => {
          this.sharedService.onSaveToggle(true, true, 'Error');
          return of(editCampaignUrlFailure({error}));
        })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private campaignService: CampaignService,
    private sharedService: SharedService,
    private store: Store<{campaignUrls: any}>,
  ) {
  }
}
