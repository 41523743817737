import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import * as ENUMS from '@enums/index';
import {
  AffiliateService,
  AffiliateManagersService,
  UserService,
  HelperService,
  PageService,
  PaymentMethodsService
} from '@services/index'
import { IState } from "@models/index";
import { IFormOptions } from '@interfaces/index';
import { IModalvalue } from '@shared-models/index';

@Component({
  selector: 'aff-affiliates-list-filter',
  templateUrl: './affiliates-list-filter.component.html',
  styleUrls: ['./affiliates-list-filter.component.scss']
})
export class AffiliatesListFilterComponent implements OnInit, OnDestroy {
  showFiterComponent: boolean = false;

  affiliateManagers$: Observable<IState>;
  paymentMethods$: Observable<IState>;
  affTypes$: Observable<any[]>;

  selectedValues: any = {
    managers: [],
    paymentMethods: [],
    affTypes: [],
    parent_affiliates: []
  };

  lists: any = {
    paymentMethods: [],
    managers: [],
    affTypes: [],
    parent_affiliates: []
  };

  searchValues: any = {
    managers: new FormControl(),
    keyword: new FormControl()
  };

  status = ENUMS.StatusConditions;

  datePickerOptions$: Observable<any>;
  dateOption: any = {
    created_from: '',
    created_to: ''
  }

  filters$: Observable<any>;
  selectedFilter$: Observable<any>;
  filterNamePopup: boolean = false;
  isFilterEmpty: boolean = true;

  affiliates$: Observable<any>;
  affiliatesList: any = [];
  affiliatesSearch = new FormControl();

  savedFilterList: any = {};
  filterSelectedValue: string | number = '';
  filterSelectedId: number;

  filterOptions = {
    keyword: '',
    status: [],
    paymentMethods: [],
    affTypes: [],
    created_from: '',
    created_to: '',
    managers: [],
    parent_affiliates: []
  };

  private unsubscribe$ = new Subject<void>();

  constructor(
    private affiliateService: AffiliateService,
    private affiliateManagersService: AffiliateManagersService,
    private userService: UserService,
    private pageService: PageService,
    private paymentMethodsService: PaymentMethodsService) {
      // get datepicker options
      this.datePickerOptions$ = pageService.getDatePickerOptionsSelector();

      this.filters$ = this.userService.filtersSelector();
      this.selectedFilter$ = this.userService.filterSelector();

      this.affiliateManagers$ = this.affiliateManagersService.getAffiliateManagersSelector();
      this.paymentMethods$ = this.paymentMethodsService.getPaymentMethodsSelector();
      this.affTypes$ = this.pageService.getUserTypeListSelector();

      this.pageService.fetchUserTypes();
      this.affiliateManagersService.fetchAffiliateManagers(1, {});
      this.paymentMethodsService.fetchPaymentMethods(1, {});

      // get affiliates
      this.affiliates$ = this.affiliateService.getAffiliatesSelector();
      this.affiliateService.fetchAffiliates(1, {});
  }

  ngOnInit() {
    // listen value change of keyword
    this.searchValues.keyword.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(newValue => {
        this.filterOptions['keyword'] = newValue;
        this.affiliateService.fetchAffiliates(1, this.filterOptions);
      });

    // owner search
    this.searchValues.managers.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.affiliateManagersService.fetchAffiliateManagers(1, { keyword }));

    // Get filters
    this.userService.fetchFilters('affiliate');
    this.filters$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded && state.data && state.data.length) {
          this.savedFilterList = {};
          state.data.forEach(filter => {
            this.savedFilterList = {
              ...this.savedFilterList,
              [filter.id]: filter.name
            }
          });
        }
      });

    // Get selected filter
    this.selectedFilter$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(filter => {
        if (filter.type !== 'affiliate') return;
        const filterOpt = filter.filter_fields;
        if (filterOpt) {
          this.onClearFilter();
          HelperService.fillFilterOption(filterOpt, this.filterOptions);
          this.filterSelectedId = filter.id;

          Object.keys(this.dateOption).forEach(key => {
            this.dateOption[key] = filterOpt.find(opt => opt.key === key)
              ? { formatted: filterOpt.find(opt => opt.key === key)['value'] } : ''
          });
          this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
          this.affiliateService.fetchAffiliates(1, this.filterOptions);
          this.showFiterComponent = true;
        }
      });

    // affiliates search
    this.affiliatesSearch.valueChanges
    .pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
      distinctUntilChanged()
    )
    .subscribe(keyword => this.affiliateService.fetchAffiliates(1, { keyword }));

  // get affiliates
  this.getAffiliates();

    // get affiliate managers
    this.getAffiliateManagers();

    //get payment methods
    this.getPaymentMethods();


  }

  getAffiliates(): void {
    this.affiliates$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.affiliatesList = state.data;
        }
    });
  }

  getAffiliateManagers(): void {
    this.affiliateManagers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.lists.managers = state.data;
        }
      });
  }

  getPaymentMethods() {
    this.paymentMethods$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.lists.paymentMethods = state.data;
        }
      });
  }

  getAffiliatesSearchValue(options): void {
    // search value in affiliates
    this.affiliatesSearch.patchValue(options.term);
  }

  onChangeAffiliatesSelection(affiliates: any[]): void {
    let affiliatesIds: number[] = [];
    for (const affiliate of affiliates) {
      affiliatesIds.push(affiliate.id);      
    }
    this.filterOptions['parent_affiliates'] = affiliatesIds;
    this.affiliateService.fetchAffiliates(1, this.filterOptions);
  }

  getSearchValue(options, name: string): void {
    // search value in managers
    this.searchValues[name].patchValue(options.term);
  }

  onSelectedValue(values: any[], name: string): void {
    let selectedValues: number[] = [];
    for (const value of values) {
      selectedValues.push(value.id);
    }
    this.filterOptions[name] = selectedValues;
    this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
    this.affiliateService.fetchAffiliates(1, this.filterOptions);
  }

  onDateChanged(event, name: string): void {
    this.filterOptions[name] = event.formatted;
    this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
    this.affiliateService.fetchAffiliates(1, this.filterOptions);
  }

  onCalendarToggle(e: Event, name: string): void {
    this.pageService.disableDate({
      name,
      date: name === 'created_from' ? this.filterOptions.created_to : this.filterOptions.created_from
    });
  }

  onToggleFilter(): void {
    this.showFiterComponent = !this.showFiterComponent;
  }

  getSelectedFilter(id): void {
    this.filterSelectedValue = this.savedFilterList[id];
    this.userService.fetchFilter(id);
  }

  onDeleteFilter(id): void {
    this.userService.deleteFilter(id);
  }

  setFilterNamePopup(option: IModalvalue): void {
    if (option.confirm) {
      if (!this.userService.checkFilterName(option.inputValue, this.savedFilterList, this.filterSelectedId)) return;
      // delete keyword property
      delete this.filterOptions.keyword;

      this.filterSelectedId
        ? this.userService.updateFilter({
          id: this.filterSelectedId,
          name: option.inputValue,
          type: 'affiliate',
          additional_fields: this.filterOptions
        })
        : this.userService.addFilter({
          name: option.inputValue,
          type: 'affiliate',
          additional_fields: this.filterOptions
        });

    }
    this.filterNamePopup = false;
  }

  onClearFilter(): void {
    
    this.dateOption = {
      created_from: '',
      created_to: '',
    };

    this.lists = {
      paymentMethods: [],
      managers: [],
      affTypes: [],
      parent_affiliates: []
    };

    this.selectedValues = {
      managers: [],
      paymentMethods: [],
      affTypes: [],
      parent_affiliates: []
    };

    this.filterOptions = {
      keyword: '',
      status: [],
      paymentMethods: this.selectedValues.paymentMethods,
      affTypes: this.selectedValues.affTypes,
      created_from: '',
      created_to: '',
      managers: this.selectedValues.managers,
      parent_affiliates: this.selectedValues.parent_affiliates
    };

  }

  onChangeFormValue(options: IFormOptions): void {
    switch (options.name) {
      case 'clearFilter':
        this.onClearFilter();
        this.filterSelectedValue = '';
        this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
        this.affiliateService.fetchAffiliates(1, this.filterOptions);
        break;

      case 'saveFilter':
        if (this.isFilterEmpty) return;
        this.filterNamePopup = true;
        break;

      case 'keyword':
        this.searchValues.keyword.patchValue(options.value);
        break;

      case 'status':
        if (options.checked) {
          this.filterOptions[options.name].push(options.value);
        } else {
          // find unchecked value and remove from bannerSize array
          const findIndex = this.filterOptions[options.name].findIndex(val => val === options.value);
          this.filterOptions[options.name].splice(findIndex, 1);
        }
        this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
        this.affiliateService.fetchAffiliates(1, this.filterOptions);
        break;

      default:
        this.isFilterEmpty = HelperService.checkEmptyFilter(this.filterOptions);
        this.filterOptions[options.name] = options.value;
        this.affiliateService.fetchAffiliates(1, this.filterOptions);
        break;
    }
  }

  ngOnDestroy(): void {
    this.userService.clearSelectedFilter();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
