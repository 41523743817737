import { Component , Input , Output , EventEmitter } from '@angular/core';
import { IFilterSetting } from '@shared-models/index';

/**
 * Dynamic Filter Component
 */
@Component({
  selector: 'aff-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})

export class FilterComponent {

  @Input() type: string;

  /**
   * Getting dynamic settings from parent;
   */
  @Input() settings: Array<IFilterSetting>;

  /**
   * Handle Submit and send to parent;
   */
  @Output() onHandle = new EventEmitter();

  /**
   * @ignore
   */
  ngOnInit() {
  }

  /**
   * Handle information from filter component and send it to parent component
   *
   * @param {string} e The target to process see
   */
  onChangeFormValue(e: any) {
  }
}
