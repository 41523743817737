import { createReducer, on } from '@ngrx/store';
import { openSidebar , closeSidebar, toggleSidebar } from './sidebar.actions';

export const initialState = true;

const _sidebarReducer = createReducer(initialState,
  on(openSidebar, state => true),
  on(closeSidebar, state => false),
  on(toggleSidebar, state => !state)
);

export function sidebarReducer(state, action) {
  return _sidebarReducer(state, action);
}
