import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'aff-campaign-add-tabs',
  templateUrl: './campaign-add-tabs.component.html',
  styleUrls: ['./campaign-add-tabs.component.scss']
})
export class CampaignAddTabsComponent implements OnInit {

  @Output() tab =  new EventEmitter<string>()
  @Input() editMode: boolean = false;

  public tabName: string = 'commissionsSettingTab';

  constructor() { }

  ngOnInit() {
  }

  onChangeTab(tabName: string) {
    if ((tabName === 'bannersTab' || tabName === 'affiliatesInCampaignTab' || tabName === 'campaignURLs') && !this.editMode) return;
      this.tabName = tabName;
      this.tab.emit(tabName);
  }

}
