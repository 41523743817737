import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PermissionGuard } from '@guards/index'; 
import { 
    AffiliatesInCampaignPage } from './index';

const routes: Routes = [
    {
        path: '',
        component: AffiliatesInCampaignPage,
        canActivate: [PermissionGuard],
        data: {
            breadcrumb: 'Affiliates in Campaign'
        },
    }
];

@NgModule({
  imports: [
    RouterModule.forChild(
        routes
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AffiliatesInCampaignPageRoutingModule {}
