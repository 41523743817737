import { Component , Input , Output , EventEmitter } from '@angular/core';

/**
 * Dynamic Block Component where you can add dynamic components
 */
@Component({
  selector: 'aff-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss'],
})

export class BlockComponent {

  /**
   * Getting Title from parent component
   */
  @Input() title: string;

  /**
   * @ignore
   */
  ngOnInit() {
  }
}
