import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'aff-select-single',
  templateUrl: './select-single.component.html',
  styleUrls: ['./select-single.component.scss']
})
export class SelectSingleComponent implements OnInit {
  @Input() placeHolder: string = 'Please Select';
  @Input() name: string = ''; // for output
  @Input() options: any;
  @Input() value: any = null;
  @Input() class = '';
  @Input() error: string = '';
  @Output() onSelectedOption = new EventEmitter();
  @Input() disabled: boolean = false;
  
  greenBorder: boolean = false;

  keys: any[] = [];
  isObject: boolean = false;
  constructor() { }

  ngOnInit() {
    if(!Array.isArray(this.options)){
      this.isObject = true;
      this.keys = Object.keys(this.options);
    }    
  }

  selectOptions(e) {
    const options = {
      value: e.target.value,
      name: this.name
    }

    this.onSelectedOption.emit(options);

    if(e.target.value.length) {
      this.greenBorder = true;
    } else {
      this.greenBorder = false;
    }
  }

}
