import {createReducer, on} from "@ngrx/store";
import {
  fetchAffiliate,
  fetchAffiliateSuccess,
  fetchAffiliateFailure,
  fetchFilteredAffiliates,
  fetchFilteredAffiliatesSuccess,
  fetchFilteredAffiliatesFailure,
  getAffiliate,
  getAffiliateSuccess,
  getAffiliateFailure,
  deleteAffiliate,
  deleteAffiliateSuccess,
  deleteAffiliateFailure,
  addAffiliate,
  addAffiliateSuccess,
  addAffiliateFailure,
  updateAffiliate,
  updateAffiliateSuccess,
  updateAffiliateFailure,
  fetchAffiliateByAffManager,
  fetchAffiliateByAffManagerSuccess,
  fetchAffiliateByAffManagerFailure,
  fetchAffiliateDownlines,
  fetchAffiliateDownlinesSuccess,
  fetchAffiliateDownlinesFailure, clearAffFilter
} from "./affiliate.actions";

import {IState} from "@models/index";
import {Action} from "@ngrx/store/src/models";
import {On} from "@ngrx/store/src/reducer_creator";

const initialState: IState | any = {
  isLoaded: false,
  data: [],
  filteredDate: [],
  assignedAffiliates: [],
  selectedItem: {},
  isSaved: false,
  error: null,
  total: 0,
  maxPages: 0,
  perPage: 0,
  currentPage: 0,
  _filterOptions: null,
  _reducerOptions: null,
};

const sharedReducer = (): On<any>[] => {
  return [
    on(clearAffFilter, (state, {any}) => {
      const payload = {
        _filterOptions: {},
      };

      return {...state, ...payload};
    })
  ]
}

const _affiliateReducer = createReducer(
  initialState,
  on(fetchAffiliate, (state, {page, filterOptions, reducerOptions}) => {
    // console.log('reducerOptions', reducerOptions);
    // console.log('filterOptions', filterOptions);
    // console.log('state', state);

    if (!!reducerOptions) {
      if (reducerOptions.merge) {
        filterOptions = {...state._filterOptions, ...filterOptions};
      }
    }

    const returnPayload = {
      _filterOptions: {...state._filterOptions, ...filterOptions},
      _reducerOptions: !!reducerOptions ? reducerOptions : state._reducerOptions,
    }

    return {...state, ...returnPayload};
  }),
  on(fetchAffiliateSuccess, (state, {affiliate}) => {
    const payload = {
      isLoaded: true,
      data: affiliate.data,
      total: affiliate.total,
      maxPages: affiliate.maxPages,
      perPage: affiliate.perPage,
      currentPage: affiliate.currentPage,
      selectedItem: {},
      error: null
    };

    return {...state, ...payload};
  }),
  on(fetchAffiliateFailure, (state, {error}) => {
    const payload = {
      isLoaded: true,
      error
    };
    return {...state, ...payload};
  }),
  on(fetchFilteredAffiliates, (state, {page, filterOptions}) => {
    const payload = {
      isLoaded: false
    };
    return {...state, ...payload};
  }),
  on(fetchFilteredAffiliatesSuccess, (state, {affiliates}) => {
    const payload = {
      isLoaded: true,
      filteredDate: affiliates.data
    };

    return {...state, ...payload};
  }),
  on(fetchFilteredAffiliatesFailure, (state, {error}) => {
    const payload = {
      isLoaded: true,
      error
    };
    return {...state, ...payload};
  }),
  on(fetchAffiliateByAffManager, (state, {affManagerId, page, filterOptions}) => {
    return state;
  }),
  on(fetchAffiliateByAffManagerSuccess, (state, {affiliates}) => {
    const payload = {
      isLoaded: true,
      assignedAffiliates: affiliates.data,
      total: affiliates.total,
      maxPages: affiliates.maxPages,
      perPage: affiliates.perPage,
      currentPage: affiliates.currentPage
    };

    return {...state, ...payload};
  }),
  on(fetchAffiliateByAffManagerFailure, (state, {error}) => {
    const payload = {
      isLoaded: true,
      error
    };
    return {...state, ...payload};
  }),
  on(getAffiliate, (state, {id}) => {
    const payload = {
      isLoaded: false
    };
    return {...state, ...payload};
  }),
  on(getAffiliateSuccess, (state, {affiliate}) => {
    const payload = {
      isLoaded: true,
      selectedItem: affiliate
    };
    return {...state, ...payload};
  }),
  on(getAffiliateFailure, (state, {error}) => {
    const payload = {
      isLoaded: true,
      error
    };
    return {...state, ...payload};
  }),
  on(deleteAffiliate, (state, {id}) => {
    const payload = {
      isLoaded: false
    };
    return {...state, ...payload};
  }),
  on(deleteAffiliateSuccess, (state, {id}) => {
    const payload = {
      isLoaded: true,
      data: state.data.filter(item => item.id !== id)
    };
    return {...state, ...payload};
  }),
  on(deleteAffiliateFailure, (state, {error}) => {
    const payload = {
      isLoaded: true,
      error
    };
    return {...state, ...payload};
  }),
  on(addAffiliate, (state, {affiliate}) => {
    const payload = {
      isSaved: false
    };
    return {...state, ...payload};
  }),
  on(addAffiliateSuccess, (state, {affiliate}) => {
    const payload = {
      isSaved: true,
      error: null
    };
    return {...state, ...payload};
  }),
  on(addAffiliateFailure, (state, {error}) => {
    const payload = {
      error
    };
    return {...state, ...payload};
  }),
  on(updateAffiliate, (state, {affiliate}) => {
    const payload = {
      isLoaded: false,
    }
    return {...state, ...payload};
  }),
  on(updateAffiliateSuccess, (state, {affiliate}) => {
    const index = state.data.findIndex(aff => aff.id === affiliate.id);
    state[index] = affiliate;
    const payload = {
      isLoaded: true,
      selectedItem: affiliate
    }
    return {...state, ...payload};
  }),
  on(updateAffiliateFailure, (state, {error}) => {
    const payload = {
      isLoaded: true,
      error
    };
    return {...state, ...payload};
  }),
  on(fetchAffiliateDownlines, (state, {page, affiliateID, filterOptions}) => {
    const payload = {
      isLoaded: false
    };
    return {...state, ...payload};
  }),
  on(fetchAffiliateDownlinesSuccess, (state, {downlines}) => {
    const payload = {
      isLoaded: true,
      data: downlines.data,
      total: downlines.total,
      maxPages: downlines.maxPages,
      perPage: downlines.perPage,
      currentPage: downlines.currentPage,
      selectedItem: {}
    };
    return {...state, ...payload};
  }),
  on(fetchAffiliateDownlinesFailure, (state, {error}) => {
    const payload = {
      isLoaded: true,
      error
    };
    return {...state, ...payload};
  }),
  ...sharedReducer()
);

export function affiliateReducer(state, action) {
  return _affiliateReducer(state, action);
}
