import { createAction, props } from "@ngrx/store";

export const fetchCampaignAffiliates = createAction(
  "[Campaign Affiliates] Fetch",
  props<{ page, campaignID, filterOptions }>()
);

export const fetchCampaignAffiliatesSuccess = createAction(
  "[Campaign Affiliates] Fetch Success",
  props<{ campaignAffiliates }>()
);

export const fetchCampaignAffiliatesFailure = createAction(
  "[Campaign Affiliates] Fetch Faild",
  props<{ error }>()
);

export const fetchCampaignAffiliatesByComGroup = createAction(
  "[Campaign Affiliates] Fetch By Com Group",
  props<{ page, campaignID, commissionGroupID }>()
);

export const fetchCampaignAffiliatesByComGroupSuccess = createAction(
  "[Campaign Affiliates] Fetch By Com Group Success",
  props<{ campaignAffiliates }>()
);

export const fetchCampaignAffiliatesByComGroupFailure = createAction(
  "[Campaign Affiliates] Fetch By Com Group Faild",
  props<{ error }>()
);

export const getCampaignAffiliate = createAction(
  "[Campaign Affiliates] Get",
  props<{ id }>()
);

export const getCampaignAffiliateSuccess = createAction(
  "[Campaign Affiliates] Get Success",
  props<{ campaignAffiliate }>()
);

export const getCampaignAffiliateFailure = createAction(
  "[Campaign Affiliates] Get Failure",
  props<{ error }>()
);

export const deleteCampaignAffiliate = createAction(
  "[Campaign Affiliates] Delete",
  props<{ id }>()
);

export const deleteCampaignAffiliateSuccess = createAction(
  "[Campaign Affiliates] Delete Success",
  props<{ id }>()
);

export const deleteCampaignAffiliateFailure = createAction(
  "[Campaign Affiliates] Delete Failure",
  props<{ error }>()
);

export const addCampaignAffiliate = createAction(
  "[Campaign Affiliates] Add",
  props<{ campaignAffiliate }>()
);

export const addCampaignAffiliateSuccess = createAction(
  "[Campaign Affiliates] Add Success",
  props<{ campaignAffiliate }>()
);

export const addCampaignAffiliateFailure = createAction(
  "[Campaign Affiliates] Add Failure",
  props<{ error }>()
);

export const updateCampaignAffiliate = createAction(
  "[Campaign Affiliates] Update",
  props<{ campaignAffiliate }>()
);

export const updateCampaignAffiliateSuccess = createAction(
  "[Campaign Affiliates] Update Success",
  props<{ campaignAffiliate }>()
);

export const updateCampaignAffiliateFailure = createAction(
  "[Campaign Affiliates] Update Failure",
  props<{ error }>()
);


export const fetchAffiliatesInCampaign = createAction(
  "[Campaign Affiliates] Fetch Affiliates in Campaign",
  props<{ page, filterOptions }>()
);

export const fetchAffiliatesInCampaignSuccess = createAction(
  "[Campaign Affiliates] Fetch Affiliates in Campaign Success",
  props<{ affiliatesInCampaign }>()
);

export const fetchAffiliatesInCampaignFailure = createAction(
  "[Campaign Affiliates] Fetch Affiliates in Campaign Faild",
  props<{ error }>()
);


export const fetchAffiliateInCampaignById = createAction(
  "[Campaign Affiliates] Get Affiliate in Campaign",
  props<{ id }>()
);

export const fetchAffiliateInCampaignByIdSuccess = createAction(
  "[Campaign Affiliates] Get Affiliate in Campaign Success",
  props<{ affiliatesInCampaign }>()
);

export const fetchAffiliateInCampaignByIdFailure = createAction(
  "[Campaign Affiliates] Get Affiliate in Campaign Failure",
  props<{ error }>()
);


export const updateAffiliateInCampaign = createAction(
  "[Campaign Affiliates] Update Affiliate in Campaign",
  props<{ affStatus }>()
);

export const updateAffiliateInCampaignSuccess = createAction(
  "[Campaign Affiliates] Update Affiliate in Campaign Success",
  props<{ affStatus }>()
);

export const updateAffiliateInCampaignFailure = createAction(
  "[Campaign Affiliates] Update Affiliate in Campaign Failure",
  props<{ error }>()
);

export const updateCamAffiliatesComGroup = createAction(
  "[Campaign Affiliates] Update Camp Affiliates Com Group",
  props<{ comGroup: any, camAffiliatesIds: number[] }>()
);

export const updateCamAffiliatesComGroupSuccess = createAction(
  "[Campaign Affiliates] Update Camp Affiliates Com GroupSuccess",
  props<{ comGroup, camAffiliatesIds }>()
);

export const updateCamAffiliatesComGroupFailure = createAction(
  "[Campaign Affiliates] Update Camp Affiliates Com GroupFailure",
  props<{ error: any }>()
);

export const changeMassStatus = createAction(
  "[Campaign Affiliates] Update Mass Status",
  props<{ massStatus }>()
);

export const changeMassStatusSuccess = createAction(
  "[Campaign Affiliates] Update Mass Status Success",
  props<{ massStatus }>()
);

export const changeMassStatusFailure = createAction(
  "[Campaign Affiliates] Update Mass Status Failure",
  props<{ error }>()
);
