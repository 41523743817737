import { createAction, props } from "@ngrx/store";

export const fetchCountry = createAction(
  "[Country] Fetch Countries"
);

export const fetchCountrySuccess = createAction(
  "[Country] Fetch Countries Success",
  props<{ country }>()
);

export const fetchCountryFailure = createAction(
  "[Country] Fetch Countries Faild",
  props<{ error }>()
);
