import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import {
    fetchBanner,
    fetchBannerSuccess,
    fetchBannerFailure,
    getBanner,
    getBannerSuccess,
    getBannerFailure,
    deleteBanner,
    deleteBannerSuccess,
    deleteBannerFailure,
    addBanner,
    addBannerSuccess,
    addBannerFailure,
    updateBanner,
    updateBannerSuccess,
    updateBannerFailure
} from "./banner.actions";
import { EMPTY, of } from "rxjs";
import { map, mergeMap, catchError, tap } from "rxjs/operators";
import { BannerService } from "../../services/banner/banner.service";
import { SharedService } from '@services/shared/shared.service';

@Injectable()
export class BannerEffects {
  loadBanners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchBanner),
      mergeMap(action =>
        this.bannerService.getBanners(action.page, action.filterOptions).pipe(
          map(res => {
            return fetchBannerSuccess({ banner: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchBannerFailure({ error }));
          })
        )
      )
    )
  );

  getBanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBanner),
      mergeMap(action => 
        this.bannerService.getBanner(action.id).pipe(
          map(res => {
            return getBannerSuccess({ banner: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(getBannerFailure({ error }));
          })
        )
      )
    )
  );

  deleteBanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteBanner),
      mergeMap(action =>
        this.bannerService.removeBanner(action.id).pipe(
          map(() => {
            return deleteBannerSuccess({ id: action.id });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(deleteBannerFailure({ error }));
          })
        )
      )
    )
  );

  addBanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addBanner),
      mergeMap(action =>
        this.bannerService.insertBanner(action.banner).pipe(
          map(banner => {
            return addBannerSuccess({ banner });
          }),
          tap(res => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
            setTimeout(() => {
              this.router.navigate([`/promotion-tools/promotion-tool/${res.banner["id"]}`]); 
            }, 2000);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(addBannerFailure({ error }));
          })
        )
      )
    )
  );

  updateBanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBanner),
      mergeMap(action =>
        this.bannerService.renewBanner(action.banner).pipe(
          map(banner => {
            return updateBannerSuccess({ banner });
          }),
          tap(res => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
            setTimeout(() => {
              this.router.navigate([`/promotion-tools/promotion-tool/${res.banner["id"]}`]);
            }, 2000);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(updateBannerFailure({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private bannerService: BannerService,
    private sharedService: SharedService,
    private router: Router
  ) {}
}
