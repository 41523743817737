import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import {
    fetchEventCategories,
    fetchEventCategoriesSuccess,
    fetchEventCategoriesFailure,
    getEventCategory,
    getEventCategorySuccess,
    getEventCategoryFailure,
    addEventCategory,
    addEventCategorySuccess,
    addEventCategoryFailure,
    updateEventCategory,
    updateEventCategorySuccess,
    updateEventCategoryFailure,
    deleteEventCategory,
    deleteEventCategorySuccess,
    deleteEventCategoryFailure
} from "./event-category.action";
import { EMPTY, of } from "rxjs";
import { map, mergeMap, catchError, tap } from "rxjs/operators";
import { EventCategoryService } from '@services/index'
import { SharedService } from '@services/shared/shared.service';


@Injectable()
export class EventCategoryEffects {
  loadEventCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchEventCategories),
      mergeMap(action =>
        this.eventCategoryService.getEventCategories(action.page, action.filterOptions).pipe( 
          map(res => {
            return fetchEventCategoriesSuccess({ eventCategory: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(fetchEventCategoriesFailure({ error }));
          })
        )
      )
    )
  );

  getEventCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEventCategory),
      mergeMap(action => 
        this.eventCategoryService.getEventCategory(action.id).pipe(
          map(res => {
            return getEventCategorySuccess({ eventCategory: res });
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(getEventCategoryFailure({ error }));
          })
        )
      )
    )
  );

  addEventCategory$ = createEffect(() =>
    this.actions$.pipe(
        ofType(addEventCategory),
        mergeMap(action =>
        this.eventCategoryService.insertEventCategory(action.eventCategory).pipe(
            map(eventCategory => {
            return addEventCategorySuccess({ eventCategory });
            }),
            tap(res => {
            this.eventCategoryService.fetchEventCategories(1, {}); 
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
            }),
            catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(addEventCategoryFailure({ error }));
            })
        )
        )
    )
);

  updateEventCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEventCategory),
      mergeMap(action =>
        this.eventCategoryService.renewEventCategory(action.eventCategory).pipe(
          map(eventCategory => {
            return updateEventCategorySuccess({ eventCategory });
          }),
          tap(res => {
            this.eventCategoryService.fetchEventCategories(1, {});  
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
          }),
          catchError(error => {
            this.sharedService.onLoaderToggle(false);
            return of(updateEventCategoryFailure({ error }));
          })
        )
      )
    )
  );

  deleteEventCategory$ = createEffect(() =>
  this.actions$.pipe(
    ofType(deleteEventCategory),
    mergeMap(action =>
      this.eventCategoryService.removeEventCategory(action.id).pipe(
        map(() => {
          return deleteEventCategorySuccess({ id: action.id });
        }),
        tap(res => {
            this.eventCategoryService.fetchEventCategories(1, {}); 
        }),
        catchError(error => {
          this.sharedService.onLoaderToggle(false);
          return of(deleteEventCategoryFailure({ error }));
        })
      )
    )
  )
);


  
  constructor(
    private actions$: Actions,
    private eventCategoryService: EventCategoryService,
    private sharedService: SharedService,
    private router: Router
  ) {}
}
