import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from './find-value/findValueInArray.pipe';
import { SafePipe } from './safe/safe.pipe';

@NgModule({
  declarations: [FilterPipe, SafePipe],
  exports: [
    FilterPipe,
    SafePipe
  ],
  imports: [
    CommonModule
  ]
})
export class SharedPipesModule { }
