import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { UserService, AffiliateService, HelperService, MessageService } from '@services/index';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'aff-message-add-popup',
  templateUrl: './message-add-popup.component.html',
  styleUrls: ['./message-add-popup.component.scss']
})
export class MessageAddPopup implements OnInit, OnDestroy {

  addMessageForm: FormGroup;

  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

  keyword = new FormControl();
  sendTo = null;
  messageFrom;

  profile$: Observable<any>;

  affiliates$: Observable<any>;
  selectedAffiliates: any = [];
  affiliatesList: any = [];
  affiliatesSearch = new FormControl();

  errorObj: any = {};

  private unsubscribe$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private affiliateService: AffiliateService,
    private userService: UserService,
    private messageService:  MessageService
  ) {

    this.addMessageForm = this.formBuilder.group({
      affiliate_id: new FormControl(null, [Validators.required]),
      subject: new FormControl(null),
      text: new FormControl(null, [Validators.required]),
    });

    this.profile$ = this.userService.getProfileSelector();
    this.affiliates$ = this.affiliateService.getAffiliatesSelector();
    this.affiliateService.fetchAffiliates( 1 ,{});
   }

  ngOnInit() {
    this.profile$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        if (HelperService.isObjectEmpty(user)) return;
        this.messageFrom = user.userId;
      })


        // affiliates search
        this.affiliatesSearch.valueChanges
        .pipe(
          takeUntil(this.unsubscribe$),
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe(keyword => this.affiliateService.fetchAffiliates(1, { keyword }));

    this.getAffiliates();
  }

  
  getAffiliates(): void {
    this.affiliates$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {        
        if (state.isLoaded) {
          this.affiliatesList = state.data;
        }
    });
  }

  getAffiliatesSearchValue(options): void {
    // search value in affiliates
    this.affiliatesSearch.patchValue(options.term);
  }

  onChangeAffiliatesSelection(affiliates: any[]): void {
    if(!affiliates.length) {
      this.sendTo = null;
    } else {
      let affiliatesIds: number[] = [];
      for (const affiliate of affiliates) {
        this.sendTo = affiliate ? affiliate.id : null;
        affiliatesIds.push(affiliate.id);
      }
    }
    this.addMessageForm.patchValue({ affiliate_id: this.sendTo });
   
  }

  onChangeFormValue(options) {
     switch(options.name) {
      case 'submit':

        this.errorObj = HelperService.checkValidation(this.addMessageForm);

        if(HelperService.isObjectEmpty(this.errorObj)) {
          let messageObj = {from: this.messageFrom, to: this.sendTo, subject: this.addMessageForm.value.subject, text: this.addMessageForm.value.text};
          console.log(messageObj);
          this.messageService.addMessage(messageObj);
          this.clicked.emit(false);
        }
        break;

      default:
        this.addMessageForm.patchValue({ [options.name]: options.value });
        break;
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
