export enum UserStatus{
  WAITINGFOREMAILCONFIRMATION = 1,
  PENDING = 2,
  ACTIVE = 3,
  PAUSED = 4,
  APPROVED = 5,
  SUSPENDED = 6,
  FRAUD = 7,
  DECLINED =8
}
