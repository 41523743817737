import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SharedService, UserService } from '@services/index';
import { INotification } from '@models/index';
import {DashboardService} from "@services/dashboard/dashboard.service";

@Component({
  selector: 'app-authorized-pages',
  templateUrl: './authorized-pages.component.html',
  styleUrls: ['./authorized-pages.component.scss']
})
export class AuthorizedPages implements OnInit {
  notification: INotification = {
    isShow: false,
    isError: false,
    message: ''
  };

  private unsubscribe$ = new Subject<void>();

  constructor(private sharedService: SharedService,
              private userService: UserService) {
    // get user date
    userService.dispatchUpdateProfile();
   }

  ngOnInit() {
    // notification toggle
    this.sharedService.getNotificationSelector()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((val => {
        this.notification = val;
        if (val.isShow) {
          setTimeout(() => {
            this.sharedService.onSaveToggle(false);
          }, 2000)
        }
    }));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
