import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'aff-manage-products-discounts-list-page',
  templateUrl: './manage-products-discounts-list-page.component.html',
  styleUrls: ['./manage-products-discounts-list-page.component.scss']
})
export class ManageProductsDiscountsListPage implements OnInit {
  public data = {
    rows :[
      { date: 'gabi test comm', promotionCode: 'Daniel Brown', validOn: '28%', affiliates: '9/9/2009' , active: '9/9/2009' , Edit: '9/12/2009'},
      { date: 'gabi test comm', promotionCode: 'Daniel Brown', validOn: '28%', affiliates: '9/9/2009' , active: '9/9/2009' , Edit: '9/12/2009'},
      { date: 'gabi test comm', promotionCode: 'Daniel Brown', validOn: '28%', affiliates: '9/9/2009' , active: '9/9/2009' , Edit: '9/12/2009'},
    ],
    columns : [
      { name: 'Date' },
      { name: 'Promotion Code' },
      { name: 'Valid On' },
      { name: 'Affiliates' },
      { name: 'Active' },
      { name: 'Edit' }
    ]
  }

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onAddPromotion() {
    this.router.navigate(['manage_affiliates/manage_products_discounts/add']);
  }

}
