import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';

import { SharedModule } from '@shared/shared.module';

import { LoginPage, RegistrationPage, UserForgotPasswordPage, UserResetPasswordPage, ActivationPage } from './index';
import { LoginPageRoutingModule } from './user-page-routing.module';
import { SharedDirectivesModule } from '@directives/index';

@NgModule({
  declarations: [
    LoginPage,
    RegistrationPage,
    UserForgotPasswordPage,
    UserResetPasswordPage,
    ActivationPage
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    LoginPageRoutingModule,
    RecaptchaModule,
    SharedDirectivesModule
  ]
})
export class UserPageModule { }
