import { createReducer, on } from '@ngrx/store';
import { 
    fetchNavigation, fetchNavigationSuccess, 
    fetchNavigationFailure, setActivePage } from './navigation.actions';

import { NavigationState } from '@models/index';

const initialState: NavigationState = {
    isLoaded: false,
    data: [],
    error: null,
    activePage: '/dashboard'
};

const _navigationReducer = createReducer(
    initialState,
    on(fetchNavigation, state => {
        return state;
    }),
    on(fetchNavigationSuccess, ( state , { navigation } ) => {
        const payload = {
            isLoaded: true,
            data: navigation
        }

        return {...state, ...payload}
     }),
    on(fetchNavigationFailure, ( state, { error } ) => {
        const payload = {
            isLoaded: true,
            error
        }
        return {...state, ...payload }
    }),
    on(setActivePage, (state, { pageSlug } ) => {
        const payload = {
            activePage: pageSlug
        };

        return {...state, ...payload };
    })
);

export function navigationReducer(state, action) {
    return _navigationReducer(state, action);
}