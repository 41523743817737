import { createAction , props } from '@ngrx/store';


export const userLogin = createAction(
  '[USER] Login',
  props<{ email, password }>()
);

export const userLoginSuccess = createAction(
  '[USER] Login Success',
  props<{ user }>()
);

export const userLoginFailure = createAction(
  '[USER] Login Failure',
  props<{ error }>()
);



export const userSendVerification = createAction(
  '[USER] Send Verification',
  props<{ email }>()
);

export const userSendVerificationSuccess = createAction(
  '[USER]  Send Verification Success',
  props<{ user }>()
);

export const userSendVerificationFailure = createAction(
  '[USER]  Send Verification Failure',
  props<{ error }>()
);


export const userResetPassword = createAction(
  '[USER] Reset Password',
  props<{ password, hash }>()
);

export const userResetPasswordSuccess = createAction(
  '[USER]  Reset Password Success',
  props<{ user }>()
);

export const userResetPasswordFailure = createAction(
  '[USER]  Reset Password Failure',
  props<{ error }>()
);


export const userSendActivation = createAction(
  '[USER] Send Activation',
  props<{ code, email }>()
);

export const userSendActivationSuccess = createAction(
  '[USER]  Send Activation Success',
  props<{ user }>()
);

export const userSendActivationFailure = createAction(
  '[USER]  Send Activation Failure',
  props<{ error }>()
);

export const userSignup = createAction(
  '[USER] Signup',
  props<{ user }>()
);

export const userSignupSuccess = createAction(
  '[USER] Signup Success',
  props<{ user }>()
);

export const userSignupFailure = createAction(
  '[USER] Signup Failure',
  props<{ error }>()
);

export const userLogout = createAction(
  '[USER] Logout'
);

export const updateUserToken = createAction(
  '[USER] Update Token',
    props<{ authToken }>()
);

export const updateUserProfile = createAction(
  '[USER] Update Profile'
);

export const updateUserProfileSuccess = createAction(
  '[USER] Update Profile Success',
  props<{ user }>()
);

export const updateUserProfileFailure = createAction(
  '[USER] Update Profile Failure',
  props<{ error }>()
);

export const getUser = createAction(
  "[USER] Get",
  props<{ id }>()
);

export const getUserSuccess = createAction(
  "[USER] Get Success",
  props<{ user }>()
);

export const getUserFailure = createAction(
  "[USER] Get Failure",
  props<{ error }>()
);

export const getUserLoginHistories = createAction(
  "[USER] Get Login History",
  props<{ userID, page, filterOptions }>()
);

export const getUserLoginHistoriesSuccess = createAction(
  "[USER] Get Login History Success",
  props<{ loginHistories }>()
);

export const getUserLoginHistoriesFailure = createAction(
  "[USER] Get Login History Failure",
  props<{ error }>()
);

export const addFilter = createAction(
  "[USER] Add Filter",
  props<{ filter }>()
);

export const addFilterSuccess = createAction(
  "[USER] Add Filter Success",
  props<{ filter }>()
);

export const addFilterFailure = createAction(
  "[USER] Add Filter Failure",
  props<{ error }>()
);

export const updateFilter = createAction(
  "[USER] Update Filter",
  props<{ filter }>()
);

export const updateFilterSuccess = createAction(
  "[USER] Update Filter Success",
  props<{ filter }>()
);

export const updateFilterFailure = createAction(
  "[USER] Update Filter Failure",
  props<{ error }>()
);

export const getFilters = createAction(
  "[USER] Get Filters",
  props<{ filterType }>()
);

export const getFiltersSuccess = createAction(
  "[USER] Get Filters Success",
  props<{ filters }>()
);

export const getFiltersFailure = createAction(
  "[USER] Get Filters Failure",
  props<{ error }>()
);

export const getFilter = createAction(
  "[USER] Get Filter",
  props<{ id }>()
);

export const getFilterSuccess = createAction(
  "[USER] Get Filter Success",
  props<{ filter }>()
);

export const getFilterFailure = createAction(
  "[USER] Get Filter Failure",
  props<{ error }>()
);

export const deleteFilter = createAction(
  "[USER] Delete Filter",
  props<{ id }>()
);

export const deleteFilterSuccess = createAction(
  "[USER] Delete Filter Success",
  props<{ id }>()
);

export const deleteFilterFailure = createAction(
  "[USER] Delete Filter Failure",
  props<{ error }>()
);

export const clearSelectedFilter = createAction(
  "[USER] Delete Filter Failure"
);
