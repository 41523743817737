import { Injectable, OnDestroy } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { UserService } from '@services/index';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, OnDestroy {
    isUserAuthenticated$: Observable<boolean>;
    private unsubscribe$ = new Subject<void>();

    constructor(
        private router: Router,
        private userService: UserService
    ) {
      this.isUserAuthenticated$ = userService.isAuthorized();
     }

    canActivate(): Observable<boolean> {
        return this.isUserAuthenticated$
          .pipe(
            takeUntil(this.unsubscribe$),
            map(isAuthorized => {
              if (isAuthorized) {
                  // logged in so return true
                  return true;
              }
              this.router.navigateByUrl('/login')
              return false;
          })
        );
    }

    ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }
}