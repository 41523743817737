import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageService, HelperService } from '@services/index';
import { IPage } from '@interfaces/index';
import { CampaignAffiliatesService } from '../../services/campaign-affiliates/campaign-affiliates.service';
import { from, Observable, Subject } from 'rxjs';
import { IState } from "@models/index";
import { takeUntil } from 'rxjs/operators';
import { Status } from '@enums/index';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'aff-affiliates-in-campaign-page',
  templateUrl: './affiliates-in-campaign-page.component.html',
  styleUrls: ['./affiliates-in-campaign-page.component.scss']
})
export class AffiliatesInCampaignPage implements OnInit, OnDestroy {

  affiliatesInCampaign$: Observable<IState>;
  affiliatesInCampaign: any= {};
  showSelection: boolean = false;

  selectedAffStatus: any = {
    status: null
  };
  affStatus: string;
  id;
  affType;
  status_drop = ['involved', 'pending', 'suspended', 'declined', 'invited'];
  mass_status: string = '';
  selectedIds: any = [];

  popup: boolean = false;

  rows = [];
  loadingIndicator: boolean = true;
  selectedRows = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  private unsubscribe$ = new Subject<void>();

  constructor(
    private pageService: PageService,
    private campaignAffiliatesService: CampaignAffiliatesService ,
    private helpService: HelperService,
    private activeRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.pageService.changePageInfo({ breadcrum: ['Affiliates in Campaign'] });

    this.affiliatesInCampaign$ = this.campaignAffiliatesService.getCampaignAffiliatesSelector();
  }

  ngOnInit() {
    this.activeRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
      if (!resp['page']) {
        this.router.navigate([], {
          relativeTo: this.activeRoute,
          queryParams: {page: 1},
          queryParamsHandling: 'merge'
        });
        return;
      }


    })

    this.affiliatesInCampaign$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.selectedRows = [];
        if(!HelperService.isObjectEmpty(state.selectedItem)) {
          this.affiliatesInCampaign = state.selectedItem;
          this.affiliatesInCampaign.campaignStatus = HelperService.getEnumValue(Status, this.affiliatesInCampaign.campaign.status)
          this.selectedAffStatus.status = state.selectedItem.status;
        }
        if(state.isSaved) {
          this.popup = false;
        }

        if (state.isLoaded) {
          this.rows = [];
          for (const campaignAffiliate of state.data) {
            this.affStatus = campaignAffiliate.status;
            const row = {
              campaignId: campaignAffiliate.id,
              campaignName: campaignAffiliate.campaign ? campaignAffiliate.campaign.name : '',
              campaignStatus: campaignAffiliate.campaign ?
                            HelperService.getEnumValue(Status, campaignAffiliate.campaign.status) : '',
              campaignType: campaignAffiliate.campaign ? campaignAffiliate.campaign.campaign_type : '',
              campaignValidFrom: campaignAffiliate.campaign ? campaignAffiliate.campaign.active_from : '',
              campaignValidTo: campaignAffiliate.campaign ? campaignAffiliate.campaign.active_to : '',
              affiliateManager: campaignAffiliate.campaign ? campaignAffiliate.campaign.user ?
                      campaignAffiliate.campaign.user.email : '' : '',
              affiliateName: campaignAffiliate.user.profile.first_name + ' ' + campaignAffiliate.user.profile.last_name,
              affiliateEmail: campaignAffiliate.user.email,
              affiliateType: campaignAffiliate.user.affType ? campaignAffiliate.user.affType.name : '',
              affiliateJoinedDate: campaignAffiliate.created_at,
              affiliateInvolvementStatus: campaignAffiliate.status
            };
            this.rows.push(row);
          }
        }
        this.rows = [...this.rows];
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
      });
  }

  onAction(row) {
    this.id = row.campaignId;
    this.popup = true;
    this.campaignAffiliatesService.fetchAffiliateInCampaignById(row.campaignId);
  }

  setPage(e): void {
    // this.campaignAffiliatesService.fetchAffiliatesInCampaign(e.offset + 1, {});
    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams: {page: e.offset + 1},
      queryParamsHandling: 'merge'
    });
  }

  onSelect(e): void {
    this.selectedRows = e.selected;
  }

  onChangeAffStatus(affStatus): void {
    this.affStatus = affStatus;
  }

  changeMassStatus() {
    this.selectedIds = [];
    this.selectedRows.forEach( data => {
      this.selectedIds.push(data.campaignId);
    })
    this.campaignAffiliatesService.changeMassStatus({ status: this.mass_status , ids: this.selectedIds})
    this.selectedRows = [];
  }

  getSelectionOptions(show: boolean): boolean {
    return this.showSelection = show;
  }

  onChangeFormValue(options) {
    switch (options.name) {
      case 'submit':
        let status = this.affStatus;
        let affStatus = { id: parseInt(this.id), status };
        this.campaignAffiliatesService.updateAffiliateInCampaign(affStatus);
        break;
      case 'change_status':
        this.mass_status = options.value;
        break;
      default:
        break;
    }

  }

  closePopup(value) {
    this.popup = value;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
