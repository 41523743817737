import { Component, OnInit } from '@angular/core';
import { PageService } from '@services/index';

/**
 * Dashboard Page
 */
@Component({
  selector: 'aff-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPage implements OnInit {
  

  constructor(private pageService: PageService) {
    // set page breadcrum
    pageService.changePageInfo({ breadcrum: ['Dashboard'] });
   }

  /**
   * FAKE DATA
   */
  public WELCOME_DATA = {
    'title' : 'Welcome Ida!',
    'text' : 'Last login:',
    'information' : '10/16/2019 2:09:12 PM'
  };

  /**
   * FAKE DATA
   */
  public AFFILIATE_LIST = {
    title: 'Affiliates',
    list : [
      {
        name: 'Pending',
        count: 26,
        url: '/'
      },
      {
        name: 'New',
        count: 541,
        url: '/'
      },
      {
        name: 'Cancelled',
        count: 17426,
        url: '/'
      },
      {
        name: 'Fraud',
        count: 92,
        url: '/'
      },
      {
        name: 'Active',
        count: 538,
        url: '/'
      },{
        name: 'Approved',
        count: 4000,
        url: '/'
      }
    ]
  };

  /**
   * FAKE DATA
   */
  public ATTENTION_LIST = {
    title: 'Attention',
    list : [
      {
        name: 'Messages',
        count: 308,
        url: '/'
      },
      {
        name: 'Special Comissions',
        count: 0,
        url: '/'
      },
      {
        name: 'Product Discounts',
        count: 0,
        url: '/'
      },
      {
        name: 'nactive affiliates',
        count: 948,
        url: '/'
      }
    ]
  };

  /**
   * @ignore
   */
  ngOnInit() {}

}
