import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Store } from "@ngrx/store";
import {
  fetchLanguage
} from "../../store/language/language.actions";
import { getLanguageSelector, getLanguageListSelector } from '@store/language/language.selectors';
import { LanguageState } from '@models/index';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class LanguageService {
  backendUrl = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private store: Store<{ language: LanguageState }>
  ) {}

  getLanguagesSelector(): Observable<LanguageState> {
    return this.store.select(getLanguageSelector);
  }

  getLanguageListSelector(): Observable<any> {
    return this.store.select(getLanguageListSelector);
  }

  fetchLanguages() {
    this.store.dispatch(fetchLanguage());
  }

  getLanguage() {
    return this.http.get(`${this.backendUrl}/statics/languages`);
  }

}
