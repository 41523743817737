import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';

import { AffiliateService, PageService, HelperService } from "@services/index";

@Component({
  selector: 'aff-affiliates-add-page',
  templateUrl: './affiliates-add-page.component.html',
  styleUrls: ['./affiliates-add-page.component.scss']
})

export class AffiliatesAddPage implements OnInit, OnDestroy {
  affiliateID: string = '';
  editMode: boolean = false;

  tabs: any = {
    detailsTab: true,
    payoutsTab: false,
    downlinesTab: false,
    trackingTab: false,
    mailsTab: false,
    loginHistoryTab: false,
    campaignsTab: false,
    commissionGroupsTab: false
  };

  affiliate$: Observable<any>;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private affiliateService: AffiliateService,
    private route: ActivatedRoute,
    private pageService: PageService) {
      //set breadcrum
      pageService.changePageInfo({ breadcrum: ['Affiliates', 'Add New Affiliate']});
      
      this.affiliate$ = this.affiliateService.getAffiliateSelector();
     }

    ngOnInit() {
      // get affiliate id
      this.affiliateID = this.route.snapshot.params.id;      
  
      if (this.affiliateID) {
        this.affiliateService.fetchAffiliate(this.affiliateID);
        return this.editMode = true;
      }
  
      this.affiliate$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(affiliate => {
          // check mode
          if (HelperService.isObjectEmpty(affiliate)) {
            return this.editMode = false;
          }

          this.editMode = true;
          this.pageService.changePageInfo({ breadcrum: ['Affiliates', affiliate.first_name]});
        });
    }

  onChangeTab(selectedTab: string): void {
    for(let tab in this.tabs) {
      this.tabs[tab] = false;
    }
    this.tabs[selectedTab] = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
