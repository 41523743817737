import { createAction, props } from "@ngrx/store";


export const fetchTopBanners = createAction(
  "[Top Banner] Fetch Top Banners",
  props<{ payload: {field: any, params: any} }>()
);

export const fetchTopBannersSuccess = createAction(
  "[Top Banner] Fetch Top Banners Success",
  props<{ payload, field }>()
);

export const fetchTopBannersFailure = createAction(
  "[Top Banner] Fetch Top Banners Failed",
  props<{ error, field? }>()
);

export const fetchTopAffiliate = createAction(
  "[Top Affiliates] Fetch Top Affiliates",
  props<{ payload: {field: any, params: any} }>()
);

export const fetchTopAffiliateSuccess = createAction(
  "[Top Affiliates] Fetch Top Affiliates Success",
  props<{ payload, field }>()
);

export const fetchTopAffiliateFailure = createAction(
  "[Top Affiliates] Fetch Top Affiliates Failed",
  props<{ error, field? }>()
);

export const fetchGroupDetails = createAction(
  "[Group Details] Fetch Group Details",
);

export const fetchGroupDetailsSuccess = createAction(
  "[Group Details] Fetch Group Details Success",
  props<{ payload }>()
);

export const fetchGroupDetailsFailure = createAction(
  "[Group Details] Fetch Group Details Failed",
  props<{ error }>()
);

export const fetchCommissions = createAction(
  "[Commissions] Fetch Commissions",
  props<{ payload }>()
);

export const fetchCommissionsSuccess = createAction(
  "[Commissions] Fetch Commissions Success",
  props<{ payload }>()
);

export const fetchCommissionsFailure = createAction(
  "[Commissions] Fetch Commissions Failed",
  props<{ error }>()
);
