import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { HelperService } from '@services/index';
import { CommissionModel, AutoCurrency, CommissionGroupClickApproval } from '@enums/index';
import { IFormOptions } from '@interfaces/index';

@Component({
  selector: 'aff-group-setting-sale',
  templateUrl: './group-setting-sale.component.html',
  styleUrls: ['./group-setting-sale.component.scss']
})
export class GroupSettingSaleComponent implements OnInit {
  @Output() getObjValues = new EventEmitter<any>();
  @Input() set commissionSettingsForm(options) {
    if (options.sale_commission_active) {
      HelperService.fillFormValues(options, this.saleCommissionForm)
    }
  }
  @Input() campaignEditMode: boolean = false;
  @Input() clickSaved$: Observable<boolean>;

  saleCommissionForm: FormGroup;

  // enums
  commissionGroupClickApproval = CommissionGroupClickApproval;
  commissionModel = CommissionModel;
  autoCurrency = AutoCurrency;

  errorObj: any = {};
  revenue: boolean = false;

  private unsubscribe$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder) { 
    this.saleCommissionForm = this.formBuilder.group({
      sale_commission_active: new FormControl(false, [Validators.required]),
      sale_commission_model: new FormControl(this.commissionModel.CPA, [Validators.required]),
      sale_extra_bonus: new FormControl(false, [Validators.required]),
      sale_extra_bonus_value: new FormControl(null),
      sale_approval: new FormControl(this.commissionGroupClickApproval.MANUAL, [Validators.required]),
      sale_commission: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      sale_auto_currency: new FormControl(this.autoCurrency.PERCENT, [Validators.required]),
      sale_second_tier: new FormControl(false, [Validators.required]),
      sale_tier_commission: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      sale_tier_commission_currency: new FormControl(this.autoCurrency.PERCENT, [Validators.required]),
      sale_is_zero_orders: new FormControl(false, [Validators.required]),
      sale_is_zero_commission: new FormControl(false, [Validators.required]),
      sale_is_fixed_cost: new FormControl(false, [Validators.required]),
      sale_fixed_cost_comm: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      sale_fixed_commission_currency: new FormControl(this.autoCurrency.MONEY, [Validators.required]),
      first_sale_comm: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      first_second_tier_comm: new FormControl(null, [Validators.required])
    });
  }

  ngOnInit() {
    this.clickSaved$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(() => {
      if (this.saleCommissionForm.value.sale_commission_active) {
        // check validations
        this.errorObj = HelperService.checkValidation(this.saleCommissionForm);
        console.log(this.errorObj);
        console.log(this.saleCommissionForm.value);
        
        
        this.checkDisableValueValidation();
      }
    });

    if(this.saleCommissionForm.value.sale_commission_model === 3) {
      this.revenue = true;
    }
  }

  checkDisableValueValidation(): void {
    const formValues = this.saleCommissionForm.value;
    if (!formValues.sale_second_tier) {
      delete this.errorObj['sale_tier_commission'];
      delete this.errorObj['first_second_tier_comm'];
    }
    if (!formValues.sale_is_fixed_cost) {
      delete this.errorObj['sale_fixed_cost_comm'];
    }

    if (formValues.sale_commission_model !== 3) {
      delete this.errorObj['first_sale_comm'];
      delete this.errorObj['first_second_tier_comm'];
    }

    if(formValues.sale_commission_model !== 3 && !formValues.sale_second_tier) {
      delete this.errorObj['first_second_tier_comm'];
    }
  }

  onChangeFormValue(options: IFormOptions): void {
    console.log(options);
    
    switch(options.name) {
      case 'sale_commission_active':
      case 'sale_second_tier':
      case 'sale_is_zero_orders':
      case 'sale_is_zero_commission':
      case 'sale_is_fixed_cost':
      case 'sale_extra_bonus':
        this.saleCommissionForm.patchValue({ [options.name]: options.checked });
        this.getObjValues.emit({ [options.name]: options.checked });
        break;

      case 'sale_commission' : 
      case 'sale_tier_commission' : 
      case 'sale_fixed_cost_comm' : 
      case 'sale_commission_model': 
      case 'first_sale_comm' : 
      case 'first_second_tier_comm': 
        this.saleCommissionForm.patchValue({ [options.name]: parseInt(options.value)});
        this.getObjValues.emit({ [options.name]: parseInt(options.value) });
        if(this.saleCommissionForm.value.sale_commission_model !== 3) {
          this.revenue = false;
          delete this.errorObj['first_second_tier_comm'];
          delete this.errorObj['first_sale_comm'];
        } else {
          this.revenue = true;
        }
        break;

      default :
      this.saleCommissionForm.patchValue({ [options.name]: options.value });
      this.getObjValues.emit({ [options.name]: options.value });
    }
  
  }

}
