import { createReducer, on } from "@ngrx/store";
import {
  fetchAffiliateManagers,
  fetchAffiliateManagersSuccess,
  fetchAffiliateManagersFailure,
  fetchFilteredAffiliateManagers,
  fetchFilteredAffiliateManagersSuccess,
  fetchFilteredAffiliateManagersFailure,
  getAffiliateManager,
  getAffiliateManagerFailure,
  getAffiliateManagerSuccess,
  deleteAffiliateManager,
  deleteAffiliateManagerSuccess,
  deleteAffiliateManagerFailure,
  addAffiliateManager,
  addAffiliateManagerSuccess,
  addAffiliateManagerFailure,
  updateAffiliateManager,
  updateAffiliateManagerSuccess,
  updateAffiliateManagerFailure,
  clearSelectedManager
} from "./affiliate-managers.action";

import { IState } from "@models/index";

const initialState: IState = {
  isLoaded: false,
  data: [],
  filteredDate: [],
  selectedItem: {},
  error: null,
  total: 0,
  maxPages: 0,
  perPage: 0,
  currentPage: 0
};

const _affiliateManagersReducer = createReducer(
  initialState,
  on(fetchAffiliateManagers, (state, { page, filterOptions }) => {
    return state;
  }),
  on(fetchAffiliateManagersSuccess, (state, { affiliateManagers }) => {
    const payload = {
      isLoaded: true,
      data: affiliateManagers.data,
      total: affiliateManagers.total,
      maxPages: affiliateManagers.maxPages,
      perPage: affiliateManagers.perPage,
      currentPage: affiliateManagers.currentPage,
      error: null
    };

    return { ...state, ...payload };
  }),
  on(fetchAffiliateManagersFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(fetchFilteredAffiliateManagers, (state, { page, filterOptions }) => {
    const payload = {
      isLoaded: false
    };
    return { ...state, ...payload };
  }),
  on(fetchFilteredAffiliateManagersSuccess, (state, { affiliateManagers }) => {
    const payload = {
      isLoaded: true,
      filteredDate: affiliateManagers.data
    };

    return { ...state, ...payload };
  }),
  on(fetchFilteredAffiliateManagersFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(getAffiliateManager, (state, { id }) => {
    const payload = {
      isLoaded: false,
    };
    return { ...state, ...payload };
  }),
  on(getAffiliateManagerSuccess, (state, { affiliateManager }) => {
    const payload = {
      selectedItem: affiliateManager,
      isLoaded: true
    };
    return { ...state, ...payload };
  }),
  on(getAffiliateManagerFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(clearSelectedManager, state => {
    const payload = {
      selectedItem: {},
    };
    return { ...state, ...payload };
  }),
  on(deleteAffiliateManager, (state, { id }) => {
    return state;
  }),
  on(deleteAffiliateManagerSuccess, (state, { id }) => {
    let total: number = state.total - 1;
    const payload = {
      total,
      data: state.data.filter(item => item.id !== id)
    };
    return { ...state, ...payload };
  }),
  on(deleteAffiliateManagerFailure, (state, { error }) => {
    const payload = {
      error
    };
    return { ...state, ...payload };
  }),
  on(addAffiliateManager, (state, { affiliateManager }) => {
    const payload = {
      isLoaded: false,
    };
    return { ...state, ...payload };
  }),
  on(addAffiliateManagerSuccess, (state, { affiliateManager }) => {
    let total: number = state.total;

    if (state.total < state.perPage) {
      total += 1;
    } else {
      state.data.splice(state.data.length - 1, 1);
    }
    
    const payload = {
      isLoaded: true,
      total,
      data: [{ ...affiliateManager }, ...state.data],
      error: null
    };
    return { ...state, ...payload };
  }),
  on(addAffiliateManagerFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(updateAffiliateManager, (state, { affiliateManager }) => {
    return state;
  }),
  on(updateAffiliateManagerSuccess, (state, { affiliateManager }) => {
    const index = state.data.findIndex(aff => aff.id === affiliateManager.id);
    state.data[index] = affiliateManager;
    
    const payload = {
      selectedItem: affiliateManager
    }
    
    return { ...state, ...payload };
  }),
  on(updateAffiliateManagerFailure, (state, { error }) => {
    const payload = {
      error
    };
    return { ...state, ...payload };
  }),
);

export function affiliateManagersReducer(state, action) {
  return _affiliateManagersReducer(state, action);
}
