import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

import { IPage, IFormOptions } from '@interfaces/index';
import { UserService, HelperService } from '@services/index';

@Component({
  selector: 'aff-logins-history-page',
  templateUrl: './logins-history-page.component.html',
  styleUrls: ['./logins-history-page.component.scss']
})
export class LoginsHistoryPage implements OnInit, OnDestroy {
  userID: string = '';
  rows = [];
  loadingIndicator: boolean = true;
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  searchValue = new FormControl();
  
  loginHistores$: Observable<any>;
  private unsubscribe$ = new Subject<void>();

  constructor(private userService: UserService,
              private route: ActivatedRoute,) { 
    this.loginHistores$ = userService.getUserLoginHistoriesSelector();
  }

  ngOnInit(): void {
    this.searchValue.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => {
        this.userService.fetchUserLoginHistories(this.userID, 1, { keyword });
      });
      
    // get affiliate id
    this.userID = this.route.snapshot.params.id;
  
    if (this.userID) {
      this.userService.fetchUserLoginHistories(this.userID, 1);
    }

    this.loginHistores$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.rows = [];
          for (const loginHistory of state.data) {
              const row = { 
                loginDate: HelperService.dateFormater(loginHistory.created_at),
                ipAddress: loginHistory.ip,
                countryName: 'Georgia'
              };
              this.rows.push(row);
          }

          this.rows = [...this.rows];

          // get pagination options
          this.page.currentPage = state.currentPage - 1;
          this.page.perPage = state.perPage;
          this.page.maxPages = state.maxPages;
          this.page.total = state.total;
        }

      // close loader
      this.loadingIndicator = false;
    });
  }

  onSearch(options: IFormOptions): void {
    this.searchValue.patchValue(options.value);
  }

  setPage(e): void {
    this.userService.fetchUserLoginHistories(this.userID, e.offset + 1);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
