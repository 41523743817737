import { Component, OnInit } from '@angular/core';

/**
 * Dynamic Pages Container
 */
@Component({
  selector: 'aff-pages-container',
  templateUrl: './pages-container.component.html',
  styleUrls: ['./pages-container.component.scss']
})
export class PagesContainer implements OnInit {
  /**
   * Dynamic Pages Constructor
   */
  constructor() { }

  /**
   * @ignore
   */
  ngOnInit() {
  }

}
