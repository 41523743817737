import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { 
    changePageInfo, 
    changeDateOption,
    resetOption,
    fetchRoles,
    fetchResources, 
    fetchRole, 
    updatePermissions,
    fetchUserStatus,
    fetchUserType,
    fetchUserTypes,
    addUserType, 
    updateUserType, 
    fetchRoleByToken,
    deleteUserType,
    getEnums, 
    getHelp
  } from '@store/page/page.actions';
import {
  getPageSelector, 
  getTitleSelector,
  getPageURLSelector,
  getBreadcrumSelector,
  getDatePickerOptionsSelector,
  getRolesSelector,
  getRoleSelector,
  getResourcesSelector, 
  getPermissionsSelector, 
  getUserStatusSelector,
  getUserTypeSelector,
  getUserTypeListSelector,
  getUserTypesSelector, 
  getEnumsSelector, 
  getHelpSelector,
} from '@store/page/page.selectors';

import { Store } from '@ngrx/store';

import { SharedService } from '@services/shared/shared.service';

import { PageState } from '@models/index';
import { IDisableDateOption } from '@interfaces/index';
import { Resources, Roles, Permissions, Enums } from '@models/iPage';
import { HelperService } from '@services/helper/helper.service';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  backendUrl = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private store: Store<{page: PageState}>,
  ) { }

  public getPageInfoSelector(): Observable<PageState> {
    return this.store.select(getPageSelector);
  }

  public getPageTitleSelector(): Observable<string> {
    return this.store.select(getTitleSelector);
  }

  public getPageBreadcrumSelector(): Observable<string[]> {
    return this.store.select(getBreadcrumSelector);
  }

  public getPageURLSelector(): Observable<string> {
    return this.store.select(getPageURLSelector);
  }

  public getDatePickerOptionsSelector(): Observable<any> {
    return this.store.select(getDatePickerOptionsSelector);
  }

  public getRecoursesSelector(): Observable<Resources> {
    return this.store.select(getResourcesSelector);
  }

  public getRolesSelector(): Observable<Roles> {
    return this.store.select(getRolesSelector);
  }

  public getPermissionsSelector(): Observable<Permissions> {
    return this.store.select(getPermissionsSelector);
  }

  public getRoleSelector(): Observable<any> {
    return this.store.select(getRoleSelector);
  }

  public getUserStatusSelector(): Observable<any> {
    return this.store.select(getUserStatusSelector);
  }

  public getUserTypeSelector(): Observable<any> {
    return this.store.select(getUserTypeSelector);
  }

  public getUserTypesSelector(): Observable<any> {
    return this.store.select(getUserTypesSelector);
  }

  public getUserTypeListSelector(): Observable<any[]> {
    return this.store.select(getUserTypeListSelector);
  }

  public getEnumsSelector(): Observable<Enums> {
    return this.store.select(getEnumsSelector);
  }

  public fetchEnums(types: any): void {
    this.store.dispatch(getEnums({ types }))
  }

  public fetchRoles(): void {
    this.store.dispatch(fetchRoles());
  }

  public fetchRole(id: string): void {
    this.store.dispatch(fetchRole({ id }));
  }

  public fetchRoleByToken(): void {
    this.store.dispatch(fetchRoleByToken());
  }

  public fetchUserStatuses(filterOptions: any): void {
    this.store.dispatch(fetchUserStatus({ filterOptions }));
  }

  public fetchUserType(id): void {
    this.store.dispatch(fetchUserType({ id }));
  }

  public fetchUserTypes(): void {
    this.store.dispatch(fetchUserTypes());
  }

  public addUserType(userType) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(addUserType({ userType }));
  }

  public updateUserType(userType) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(updateUserType({ userType }));
  }

  public deleteUserType(id) {
    this.store.dispatch(deleteUserType({ id }));
  }

  public fetchResources(): void {
    this.store.dispatch(fetchResources());
  }

  public updatePermissions(permissions: any[]): void {
    this.store.dispatch(updatePermissions({ permissions }));
  }

  public insertPermissions(permissions: any[]) {
    return this.http.patch(`${this.backendUrl}/front-rbac/permissions/update`, permissions);
  }

  public getRoles() {
    return this.http.get(`${this.backendUrl}/rbac/roles`);
  }

  public getEnums(queryparams: any) {
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(`${this.backendUrl}/statics/enums?${queryString}`);
  }

  public getHelpSelector(): Observable<any> {
    return this.store.select(getHelpSelector);
  }

  public dispatchHelp(key: string): void {
    this.store.dispatch(getHelp({ key }));
  }

  public getHelp(key: string): Observable<any> {
    return this.http.get(`${this.backendUrl}/helps/help/${key}`);
  }

  public getRole(id: string) {
    return this.http.get(`${this.backendUrl}/rbac/roles/role/${id}`);
  }

  public getRoleByToken() {
    return this.http.get(`${this.backendUrl}/rbac/roles/user`);
  }

  public getUserStatuses(queryparams: any) {
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(`${this.backendUrl}/users/statuses?${queryString}`);
  }

  public getUserTypes() {
    return this.http.get(`${this.backendUrl}/users/user-types`);
  }

  public getUserType(id) {
    return this.http.get(`${this.backendUrl}/users/user-type/${id}`);
  }

  public insertUserType(userType) {  
    return this.http.post(`${this.backendUrl}/users/user-type`, userType);
  }
  
  public renewUserType(userType) {
    return this.http.patch(`${this.backendUrl}/users/user-type/${userType.id}`, userType);
  }

  public removeUserType(id) {
    return this.http.delete(`${this.backendUrl}/users/user-type/${id}`);
  }

  public getResources() {
    return this.http.get(`${this.backendUrl}/front-rbac`);
  }

  public changePageInfo(page): void {
    this.store.dispatch(changePageInfo({ page }));
  }

  public changeDateOption(option: any) {
    this.store.dispatch(changeDateOption({ option }))
  }

  public resetOption(optionName: string) {
    this.store.dispatch(resetOption({ optionName }))
  }

  public disableDate(options: IDisableDateOption) {
    this.resetOption('disableUntil');
    this.resetOption('disableSince');

    if (options.date) {
      const disableOption = options.name.includes('from') ? 'disableSince' : 'disableUntil';
      const dateOptions: Date = new Date(options.date);
      
      this.changeDateOption({ 
        [disableOption]: {
            year: dateOptions.getFullYear(), 
            month: dateOptions.getMonth() + 1, 
            day: dateOptions.getDate()
          }
      });
    };
  }
}
