export enum AffManagersStatus {
    Approved = 5,
    Suspended = 6,
    Pending = 2
};

export enum AffManagersPayment {
    Paypal = 'paypal',
    MoneyBookers = 'money_bookers',
    Cheque = 'cheque',
    Bank = 'bank_wire_transfer',
    NoMethod = ' no_method'
}

export enum SuspendedManager{
    SUSPENDED = 1
}