import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { HelperService } from '@services/index';

@Component({
  selector: 'aff-img-popup',
  templateUrl: './img-popup.component.html',
  styleUrls: ['./img-popup.component.scss']
})
export class ImgPopupComponent implements OnInit {

  @Input() generatorValues;
  @Output() close = new EventEmitter<any>();


  constructor() { }

  closeImgPopup(value) {
    this.close.emit(value);
  }

  copyImgData(value) {
    HelperService.copyImgData(value);
  }


  ngOnInit() { }

}
