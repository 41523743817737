import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthorizedPages} from '@pages/authorized-pages/index'

import { AuthGuard, SecureInnerPagesGuard } from '@guards/index';

const routes: Routes = [
  {
    path: '',
    component: AuthorizedPages,
    canActivate: [AuthGuard],
    loadChildren: () => import('@pages/authorized-pages/authorized-pages.module').then(m => m.AuthorizedPagesModule)
  },
  {
    path: 'login',
    canActivate: [SecureInnerPagesGuard],
    loadChildren: () => import('@pages/user-page/user-page.module').then(m => m.UserPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
