import {createReducer, on} from "@ngrx/store";

import {
  fetchNotifications,
  fetchNotificationsFailed,
  fetchNotificationsPoll, fetchNotificationsPollFailed,
  fetchNotificationsPollSuccess,
  fetchNotificationsStartPoll,
  fetchNotificationsSuccess,
  setNotificationAsRead,
  setNotificationAsReadFailed,
  setNotificationAsReadSuccess
} from "@store/notifications/notifications.actions";

const initialState: any = {
  isLoading: false,
  isLoaded: false,
  error: null,
  data: [],
  selectedItem: {},
  isRefreshing: null,
  isPooling: null
};

const _notificationsReducer = createReducer(
  initialState,
  on(fetchNotifications, (state, {  }) => {
    Object.assign(state, {
      isLoading: true,
      isLoaded: false
    })
    return {...state};
  }),
  on(fetchNotificationsSuccess, (state, { payload }) => {
    Object.assign(state, {
      isLoading: false,
      isLoaded: true,
      data: payload
    })
    return {...state};
  }),
  on(fetchNotificationsFailed, (state, { error }) => {
    Object.assign(state, {
      isLoading: false,
      isLoaded: true,
      error
    })
    return {...state};
  }),
  on(setNotificationAsRead, (state, { id }) => {
    Object.assign(state, {
      selectedItem: {
        isLoading: true,
        isLoaded: false
      },
      error: null
    })
    return {...state};
  }),
  on(setNotificationAsReadSuccess, (state, { payload }) => {
    const targetEl = state.data.find(i => i.id === payload.id);
    const data = state.data.map((i) => {
      if (i.id === payload.id) {
        return {
          ...payload
        }
      } else {
        return i
      }
    });

    Object.assign(state, {
      data,
      selectedItem: {
        ...payload,
        isLoading: false,
        isLoaded: true
      },
      error: null
    })
    return {...state};
  }),
  on(setNotificationAsReadFailed, (state, { error }) => {
    Object.assign(state, {
      selectedItem: {
        isLoading: false,
        isLoaded: true
      },
      error
    })
    return {...state};
  }),
  on(fetchNotificationsStartPoll, (state, { params }) => {
    Object.assign(state, {
      isPooling: true
    })
    return {...state};
  }),
  on(fetchNotificationsPoll, (state, {  }) => {
    Object.assign(state, {
      isRefreshing: true
    })
    return {...state};
  }),
  on(fetchNotificationsPollSuccess, (state, { payload }) => {
    Object.assign(state, {
      data: payload,
      isRefreshing: false
    })
    return {...state};
  }),
  on(fetchNotificationsPollFailed, (state, { error }) => {
    Object.assign(state, {
      error,
      isRefreshing: false
    })
    return {...state};
  })
);

export function notificationsReducer(state, action) {
  return _notificationsReducer(state, action);
}
