import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GeneratedCommissionsRoutingModule } from './generated-commissions-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MyDatePickerModule } from 'mydatepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedPipesModule } from '@pipes/index';
import { SharedModule } from '@shared/shared.module';

import { 
  GeneratedCommissionsApprovePopup,
  GeneratedCommissionsFilterPage,
  GeneratedCommissionsListPage,
  CommissionsHistoryFilterPage,
  CommissionsHistoryListPage
} from './index';

@NgModule({
  declarations: [
    GeneratedCommissionsApprovePopup,
    GeneratedCommissionsFilterPage,
    GeneratedCommissionsListPage,
    CommissionsHistoryFilterPage,
    CommissionsHistoryListPage
],
  imports: [
    CommonModule,
    GeneratedCommissionsRoutingModule,
    NgxDatatableModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SharedPipesModule,
    MyDatePickerModule,
    NgSelectModule
  ]
})
export class GeneratedCommissionsModule { }
