import { createReducer, on } from "@ngrx/store";
import {
  fetchGeneratedCommissions,
  fetchGeneratedCommissionsSuccess,
  fetchGeneratedCommissionsFailure,
  getGeneratedCommission,
  getGeneratedCommissionSuccess,
  getGeneratedCommissionFailure,
  updateGeneratedCommissions,
  clearSelectedGenCommision
} from "./generated-commission.action";

import { IState } from "@models/index";

const initialState: IState = {
  isLoaded: false,
  data: [],
  filteredDate: [],
  selectedItem: {},
  error: null,
  total: 0,
  maxPages: 0,
  perPage: 0,
  currentPage: 0
};

const _generatedCommissionReducer = createReducer(
  initialState,
  on(fetchGeneratedCommissions, (state, { page, filterOptions }) => {
    return state;
  }),
  on(fetchGeneratedCommissionsSuccess, (state, { generatedCommissions }) => {
    const payload = {
      isLoaded: true,
      data: generatedCommissions.data,
      total: generatedCommissions.total,
      maxPages: generatedCommissions.maxPages,
      perPage: generatedCommissions.perPage,
      currentPage: generatedCommissions.currentPage,
      error: null,
      selectedItem: {}
    };

    return { ...state, ...payload };
  }),
  on(fetchGeneratedCommissionsFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };

    return { ...state, ...payload };
  }),
  on(getGeneratedCommission, (state, { id }) => {
    return state;
  }),
  on(getGeneratedCommissionSuccess, (state, { generatedCommission }) => {
    const payload = {
      error: null,
      selectedItem: generatedCommission
    };

    return { ...state, ...payload };
  }),
  on(getGeneratedCommissionFailure, (state, { error }) => {
    const payload = {
      error
    };

    return { ...state, ...payload };
  }),
  on(updateGeneratedCommissions, (state, { generatedCommissions }) => {
    for (const genComm of generatedCommissions) {
      state.data = state.data.filter(data => data.id !== genComm.id)
    }

    const payload = {
      total: state.total - generatedCommissions.length
    };

    return { ...state, ...payload };
  }),
  on(clearSelectedGenCommision, (state) => {
    const payload = {
      selectedItem: {}
    };

    return { ...state, ...payload };
  })
);

export function generatedCommissionReducer(state, action) {
  return _generatedCommissionReducer(state, action);
}
