import { createAction, props } from "@ngrx/store";

export const fetchBanner = createAction(
  "[Banner] Fetch",
  props<{ page, filterOptions }>()
);

export const fetchBannerSuccess = createAction(
  "[Banner] Fetch Success",
  props<{ banner }>()
);

export const fetchBannerFailure = createAction(
  "[Banner] Fetch Faild",
  props<{ error }>()
);

export const getBanner = createAction(
  "[Banner] Get",
  props<{ id }>()
);

export const getBannerSuccess = createAction(
  "[Banner] Get Success",
  props<{ banner }>()
);

export const getBannerFailure = createAction(
  "[Banner] Get Failure",
  props<{ error }>()
)

export const deleteBanner = createAction(
  "[Banner] Delete",
  props<{ id }>()
);

export const deleteBannerSuccess = createAction(
  "[Banner] Delete Success",
  props<{ id }>()
);

export const deleteBannerFailure = createAction(
  "[Banner] Delete Failure",
  props<{ error }>()
);

export const addBanner = createAction(
  "[Banner] Add",
  props<{ banner }>()
);

export const addBannerSuccess = createAction(
  "[Banner] Add Success",
  props<{ banner }>()
);

export const addBannerFailure = createAction(
  "[Banner] Add Failure",
  props<{ error }>()
);

export const updateBanner = createAction(
  "[Banner] Update",
  props<{ banner }>()
);

export const updateBannerSuccess = createAction(
  "[Banner] Update Success",
  props<{ banner }>()
);

export const updateBannerFailure = createAction(
  "[Banner] Update Failure",
  props<{ error }>()
);
