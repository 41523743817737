import {createAction, props} from "@ngrx/store";

export const fetchNotifications = createAction(
  '[Notifications] Fetch',
  props<{ params? }>()
);

export const fetchNotificationsSuccess = createAction(
  '[Notifications] Fetch Success',
  props<{ payload }>()
);

export const fetchNotificationsFailed = createAction(
  '[Notifications] Fetch Failure',
  props<{ error }>()
);

export const setNotificationAsRead = createAction(
  '[Notifications] Mark as read',
  props<{ id }>()
);

export const setNotificationAsReadSuccess = createAction(
  '[Notifications] Mark as read Success',
  props<{ payload }>()
);

export const setNotificationAsReadFailed = createAction(
  '[Notifications] Mark as read Failure',
  props<{ error }>()
);

export const fetchNotificationsStartPoll = createAction(
  '[Notifications] Start Poll',
  props<{ params? }>()
);

export const fetchNotificationsEndPoll = createAction(
  '[Notifications] End Poll',
  props<{ params? }>()
);

export const fetchNotificationsPoll = createAction(
  '[Notifications] Poll Fetch',
  props<{ params? }>()
);

export const fetchNotificationsPollSuccess = createAction(
  '[Notifications] Poll Fetch Success',
  props<{ payload }>()
);

export const fetchNotificationsPollFailed = createAction(
  '[Notifications] Poll Fetch Failure',
  props<{ error }>()
);
