import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Store } from "@ngrx/store";
import { environment } from "../../../environments/environment";
import { HelperService } from '../helper/helper.service';
import {
  fetchOrders,
  getOrder,
  updateOrder,
  createOrder,
  clearOrderState
} from "../../store/order/order.action";
import { 
  getOrderSelector,
  getOrdersSelector } from '@store/order/order.selectors';
import { IState } from '@models/index';

import { SharedService } from '@services/shared/shared.service';

@Injectable({
  providedIn: "root"
})
export class OrderService {
  backendUrl = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private store: Store<{ order: IState }>
  ) {}

  getOrdersSelector(): Observable<IState> {
    return this.store.select(getOrdersSelector);
  }

  getOrderSelector(): Observable<any> {
    return this.store.select(getOrderSelector);
  }

  fetchOrders(page: number, filterOptions: any) {
    this.store.dispatch(fetchOrders({ page, filterOptions }));
  }

  fetchOrder(id: string) {
    this.store.dispatch(getOrder({ id }))
  }

  updateOrder(order: any, option: string) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(updateOrder({ order, option }));
  }

  clearOrderState() {
    this.store.dispatch(clearOrderState())
  }

  createOrder(order: any) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(createOrder({ order }));
  }

  insertOrder(order: any) {
    return this.http.post(`${this.backendUrl}/orders/order`, order);
  }

  getOrders(page: number, filterOptions: any = { }) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);

    return this.http.get(`${this.backendUrl}/commissions?${queryString}`);
  }

  getOrder(id: string) {
    return this.http.get(`${this.backendUrl}/orders/order/${id}/generated-commission`);
  }

  renewOrder(order: any, option: string) {
    return this.http.patch(`${this.backendUrl}/orders/order/${order.orderId}/${option}`, order);
  }

}
