import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

import { BannerService, HelperService } from '@services/index';

import { IState } from "@models/index";
import { IPage, IFormOptions } from '@interfaces/index';
import { BannerType } from '@enums/index';

@Component({
  selector: 'aff-campaign-banners-tab',
  templateUrl: './campaign-banners-tab.component.html',
  styleUrls: ['./campaign-banners-tab.component.scss']
})
export class CampaignBannersTabComponent implements OnInit, OnDestroy {
  @Input() campaignID: string = '';
  loadingIndicator: boolean = true;
  columns: any = [
    { name: "ID", width: 10 },
    { name: "Name" },
    { name: "Promotion Tool Type" },
    { name: "Destination URL" }
  ];
  rows: any[] = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  keyword = new FormControl();

  banners$: Observable<IState>;
  private unsubscribe$ = new Subject<void>();

  constructor(private bannerService: BannerService) { 
                this.banners$ = this.bannerService.getBannersSelector(); 
              }
  
  ngOnInit() {
    // listen value change of keyword
    this.keyword.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
          debounceTime(500),
          distinctUntilChanged()
      )
      .subscribe(keyword => {
        this.bannerService.fetchBanners(1, { campaigns: this.campaignID, keyword });
      });

    // check campaign ID
    if (!this.campaignID) return;
    // dispatch banners
    this.bannerService.fetchBanners(1, { campaigns: this.campaignID });

    // listen campaign list
    this.banners$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.rows = [];
          for (const banner of state.data) {
            const row = {
              id: banner.id,
              name: banner.name,
              promotionToolType: HelperService.getEnumValue(BannerType, banner.type),
              destinationUrl: banner.destination_url.url,
            };
            this.rows.push(row);
          }
          this.rows = [...this.rows];
        }
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
    });
  }

  setPage(e): void {
    this.bannerService.fetchBanners(e.offset + 1, { campaign_id: this.campaignID });
  }

  onChangeSearch(options: IFormOptions): void {
    this.keyword.patchValue(options.value);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
