import { Component , Input } from '@angular/core';

/**
 * Component for creating lists in application
 */
@Component({
  selector: 'aff-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})

export class ListComponent {

  /**
   * @ignore
   */
  @Input() data: any;

  /**
   * @ignore
   */
  ngOnInit() {
  }

}
