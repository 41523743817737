import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';

import { PageService } from '@services/index';
import { Roles } from '@models/iPage';

@Component({
  selector: 'app-permission-listing-page',
  templateUrl: './permission-listing-page.component.html',
  styleUrls: ['./permission-listing-page.component.scss']
})
export class PermissionListingPage implements OnInit, OnDestroy {
  rows = [];
  loadingIndicator: boolean = true;

  roles$: Observable<Roles>;
  private unsubscribe$ = new Subject<void>();

  constructor(private pageService: PageService,
              private router: Router) {
    // set page breadcrum
    pageService.changePageInfo({ breadcrum: ['Configuration', 'Permissions'] });
    this.roles$ = pageService.getRolesSelector();
    pageService.fetchRoles();
  }

  ngOnInit() {
    this.roles$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.rows = [];
          for (const role of state.data) {
            const row = {
              id: role.id,
              name: role.name,
            };
            this.rows.push(row);
          }
        }
        this.rows = [...this.rows];
        this.loadingIndicator = false;
      })
  }

  onEdit(row: any): void {
    this.router.navigate(['configuration', 'permissions', row.id]);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
