import { createAction, props } from "@ngrx/store";


export const fetchPaymentMethod = createAction(
  "[Payment Method] Fetch Payment Method",
  props<{ page, filterOptions }>()
);

export const fetchPaymentMethodSuccess = createAction(
  "[Payment Method] Fetch Payment Method Success",
  props<{ method }>()
);

export const fetchPaymentMethodFailure = createAction(
  "[Payment Method] Fetch Payment Method Faild",
  props<{ error }>()
);

export const getPaymentMethod = createAction(
  "[Payment Method] Get Payment Method",
  props<{ id }>()
);

export const getPaymentMethodSuccess = createAction(
  "[Payment Method] Get Payment Method Success",
  props<{ method }>()
);

export const getPaymentMethodFailure = createAction(
  "[Payment Method] Get Payment Method Failure",
  props<{ error }>()
)
export const addPaymentMethod = createAction(
  "[Payment Method] Add Payment Method",
  props<{ method }>()
);

export const addPaymentMethodSuccess = createAction(
  "[Payment Method] Add Payment Method Success",
  props<{ method }>()
);

export const addPaymentMethodFailure = createAction(
  "[Payment Method] Add Payment Method Failure",
  props<{ error }>()
);

export const updatePaymentMethod = createAction(
  "[Payment Method] Update Payment Method",
  props<{ method }>()
);

export const updatePaymentMethodSuccess = createAction(
  "[Payment Method] Update Payment Method Success",
  props<{ method }>()
);

export const updatePaymentMethodFailure = createAction(
  "[Payment Method] Update Payment Method Failure",
  props<{ error }>()
);

export const deletePaymentMethod = createAction(
  "[Payment Method] Delete Payment Method",
  props<{ id }>()
);

export const deletePaymentMethodSuccess = createAction(
  "[Payment Method] Delete Payment Method Success",
  props<{ id }>()
);

export const deletePaymentMethodFailure = createAction(
  "[Payment Method] Delete Payment Method Failure",
  props<{ error }>()
);

export const updateUserPayout = createAction(
  "[Payment Method] Update User Payment Method",
  props<{ payMethod }>()
);

export const updateUserPayoutSuccess = createAction(
  "[Payment Method] Update User Payment Method Success",
  props<{ payMethod }>()
);

export const updateUserPayoutFailure = createAction(
  "[Payment Method] Update User Payment Method Failure",
  props<{ error }>()
);




