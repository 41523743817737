import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";
import { Store } from "@ngrx/store";
import {
  fetchMessages,
  getMessage,
  addMessage,
  deleteMessage
} from "../../store/message/message.actions";
import { getMessagesSelector, getMessageSelector } from '@store/message/message.selectors';
import { IState } from '@models/index';

import { HelperService } from '../helper/helper.service';
import { SharedService } from '@services/shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  backendUrl = environment.backendUrl;

  constructor(
    private sharedService: SharedService,
    private http: HttpClient,
    private store: Store<{ campaign: string }>
  ) {}

  getMessagesSelector(): Observable<IState> {
    return this.store.select(getMessagesSelector);
  }

  getMessageSelector(): Observable<any> {
    return this.store.select(getMessageSelector)
  }

  fetchMessages(page, filterOptions, user_id) {
    this.store.dispatch(fetchMessages({ page, filterOptions, user_id }));
  }

  fetchMessage(id: string) {
    this.store.dispatch(getMessage({ id }))
  }

  addMessage(message) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(addMessage({ message }));
  }

  deleteMessage(id) {
    this.store.dispatch(deleteMessage({ id }));
  }

  getMessages(page: number, filterOptions: any = { }, user_id) {
    const queryparams = { ...{ page }, ...filterOptions,  ...user_id };
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(`${this.backendUrl}/messages?${queryString}`); 
  } 

  getMessage(id) {
    return this.http.get(`${this.backendUrl}/messages/message/${id}`);
  }

  insertMessage(message) {
    return this.http.post(`${this.backendUrl}/messages/message`, message);
  }
 
  removeMessage(id) {
    return this.http.delete(`${this.backendUrl}/messages/message/${id}`);
  }

 
}
