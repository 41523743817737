import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import {
  fetchLanguage,
  fetchLanguageSuccess,
  fetchLanguageFailure
} from "./language.actions";
import { EMPTY, of } from "rxjs";
import { map, mergeMap, catchError, tap } from "rxjs/operators";
import { LanguageService } from '../../services/language/language.service';


@Injectable()
export class LanguageEffects {

loadLanguages$ = createEffect(() =>
this.actions$.pipe(
  ofType(fetchLanguage),
  mergeMap(action =>
    this.languageService.getLanguage().pipe(
      map(res => {
        return fetchLanguageSuccess({ language: res });
      }),
      catchError(error => {
        return of(fetchLanguageFailure({ error }));
      })
    )
  )
)
);


  constructor(
    private actions$: Actions,
    private languageService: LanguageService
  ) {}
}
