import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { PageService } from '@services/index';

@Component({
  selector: 'aff-messages-replay-page',
  templateUrl: './messages-replay-page.component.html',
  styleUrls: ['./messages-replay-page.component.scss']
})
export class MessagesReplayPage implements OnInit {
  public sendMessageForm: FormGroup;
  messagesTo = ['5025 - Hello', '5024 - Good Morning']

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private pageService: PageService) {
      this.sendMessageForm = this.formBuilder.group({
      messagesTo: new FormControl(null, [Validators.required]),
      subject: new FormControl(null, [Validators.required]),
      message: new FormControl(null, [Validators.required])
      });


      pageService.changePageInfo({ breadcrum: ['Messages', 'Add New Message']})
    }

  ngOnInit() {
  }

  onChangeFormValue(options) {
    switch(options.name) {
      case 'cancel' :
        this.router.navigate(['/messages']);
        break;

      case 'submit' :
        this.sendMessage();
        break;

      default :
        this.sendMessageForm.patchValue({ [options.name]: options.value });
    }
  }

  sendMessage() {
  }

}
