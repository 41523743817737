import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PermissionGuard } from '@guards/index';
import { 
  GeneratedCommissionsListPage,
  CommissionsHistoryListPage } from './index';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'income-commissions',
        pathMatch: 'full'
    },
    {
        path: 'income-commissions',
        canActivate: [PermissionGuard],
        component: GeneratedCommissionsListPage,
        data: {
            breadcrumb: 'Income Commissions'
        },
    },
    {
        path: 'commissions-history',
        canActivate: [PermissionGuard],
        component: CommissionsHistoryListPage,
        data: {
            breadcrumb: 'Commissions History'
        },
    },
];

@NgModule({
  imports: [
    RouterModule.forChild(
        routes
    )
  ],
  exports: [
    RouterModule
  ]
})
export class GeneratedCommissionsRoutingModule {}
