export enum CookieLifetime {
  SAME = 'same',
  CUSTOM = 'custom'
}
  
export enum ClickApproval {
  Auto = 'auto',
  Manual = 'manual'
}

export enum SalesLeadsApproval {
  Auto = 'auto',
  Manual = 'manual'
}

export enum AutoCurrencyForCG {
  DOLLAR = 'dollar',
  PERCENT = 'percent',
}

export enum ZeroOrdersCurrency {
  DOLLAR = 'dollar',
  PERCENT = 'percent',
}

export enum CommissionModel {
  CPA = 1,
  CPM = 2,
  REVENUE = 3
}
