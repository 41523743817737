import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

import { 
  AffiliateManagersService, 
  HelperService, 
  AffiliateService,
  CampaignService, 
  PageService} from "@services/index";

import * as ENUMS from '@enums/index';

@Component({
  selector: 'aff-affiliate-managers-list-filter',
  templateUrl: './affiliate-managers-list-filter.component.html',
  styleUrls: ['./affiliate-managers-list-filter.component.scss']
})
export class AffiliatesManagerListFilterComponent implements OnInit, OnDestroy {
  // enums
  status = ENUMS.AffManagersStatus

  helperService = HelperService;

  showFiterComponent: boolean = false;

  keyword = new FormControl();
  filterOptions = {
    keyword: '',
    status: [],
    affiliates: [],
    // campaigns: [],
    created_from: '',
    created_to: ''
  };

  dateOption: any = {
    create_from: '',
    create_to: ''
  }

  datePickerOptions$: Observable<any>

  affiliates$: Observable<any>;
  selectedAffiliates: any = [];
  affiliatesList: any = [];
  affiliatesSearch = new FormControl();

  // campaigns$: Observable<any>;
  // selectedCampaigns: any = [];
  // campaignsList: any = [];
  // campaignsSearch = new FormControl();

  private unsubscribe$ = new Subject<void>();

  constructor(private affiliateManagersService: AffiliateManagersService,
              private pageService: PageService,
              private affiliateService: AffiliateService,
              private campaignService: CampaignService) { 
    // get datepicker options
    this.datePickerOptions$ = pageService.getDatePickerOptionsSelector();
    // get affiliate managers
    this.affiliateManagersService.fetchFilteredAffiliateManagers(1, {});
    // get affiliates
    this.affiliates$ = this.affiliateService.getAffiliatesSelector();
    this.affiliateService.fetchAffiliates(1, {});
    // get campaigns
    // this.campaigns$ = this.campaignService.getCampaignsSelector();
    this.campaignService.fetchCampaigns(1, {});
  }

  ngOnInit() {
    // listen value change of keyword
    this.keyword.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
          debounceTime(500),
          distinctUntilChanged()
      )
      .subscribe(newValue => {
        this.filterOptions['keyword'] = newValue;
        this.affiliateManagersService.fetchAffiliateManagers(1, this.filterOptions);
      });

    // affiliates search
    this.affiliatesSearch.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.affiliateService.fetchAffiliates(1, { keyword }));

    // get affiliates
    this.getAffiliates();

    // affiliates search
    // this.campaignsSearch.valueChanges
    //   .pipe(
    //     takeUntil(this.unsubscribe$),
    //     debounceTime(500),
    //     distinctUntilChanged()
    //   )
    //   .subscribe(keyword => this.campaignService.fetchCampaigns(1, { keyword }));

    // get affiliate managers
    // this.getCampaigns();

  }

  getAffiliates(): void {
    this.affiliates$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.affiliatesList = state.data;
        }
    });
  }

  // getCampaigns(): void {
  //   this.campaigns$
  //     .pipe(takeUntil(this.unsubscribe$))
  //     .subscribe(state => {
  //       if (state.isLoaded) {
  //         this.campaignsList = state.data;
  //       }
  //   });
  // }

  getAffiliatesSearchValue(options): void {
    // search value in affiliates
    this.affiliatesSearch.patchValue(options.term);
  }

  getCampaignsSearchValue(options): void {
    // search value in campaigns
    // this.campaignsSearch.patchValue(options.term);
  }

  onChangeAffiliatesSelection(affiliates: any[]): void {
    let affiliatesIds: number[] = [];
    for (const affiliate of affiliates) {
      affiliatesIds.push(affiliate.id);
    }
    this.filterOptions['affiliates'] = affiliatesIds;

    this.affiliateManagersService.fetchAffiliateManagers(1, this.filterOptions);
  }

  onChangeCampaignsSelection(campaigns: any[]): void {
    let campaignsIds: number[] = [];
    for (const affiliate of campaigns) {
      campaignsIds.push(affiliate.id);
    }
    this.filterOptions['campaigns'] = campaignsIds;

    this.affiliateManagersService.fetchAffiliateManagers(1, this.filterOptions);
  }

  onCalendarToggle(e: Event, name: string): void {
    this.pageService.disableDate({
      name, 
      date: name === 'create_from' ? this.filterOptions.created_to : this.filterOptions.created_from 
    });
  }

  onDateChanged(event, name: string) {
    this.filterOptions[name] = event.formatted;
    this.affiliateManagersService.fetchAffiliateManagers(1, this.filterOptions);
  }

  onToggleFilter() {
    this.showFiterComponent = !this.showFiterComponent;
  }

  onClearFilter() {
    this.selectedAffiliates = [];
    // this.selectedCampaigns = [];
    this.filterOptions = {
      keyword: '',
      status: [],
      affiliates: this.selectedAffiliates,
      // campaigns: this.selectedCampaigns,
      created_from: '',
      created_to: ''
    };

    this.dateOption = {
      create_from: '',
      create_to: ''
    };
  }

  onChangeFormValue(options) {    
    switch(options.name) {
      case 'clearFilter' :
        this.onClearFilter();
        // reset selected value
        this.affiliateManagersService.fetchAffiliateManagers(1, {});
        break;

      case 'status' :
        if (options.checked) {
          this.filterOptions['status'].push(options.value);
        } else {
          // find unchecked value and remove from status array
          const findIndex = this.filterOptions['status'].findIndex(val => val === options.value);
          this.filterOptions['status'].splice(findIndex, 1);
        }
        this.affiliateManagersService.fetchAffiliateManagers(1, this.filterOptions);
        break;

      case 'keyword':
        this.keyword.patchValue(options.value)
        break;

      default :
      this.filterOptions[options.name] = options.value;
      this.affiliateManagersService.fetchAffiliateManagers(1, this.filterOptions);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
