import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";

import { Store } from "@ngrx/store";
import {
  fetchCountry
} from "../../store/country/country.actions";
import { getCountrySelector } from '@store/country/country.selectors';
import { CountryState } from '@models/index';

@Injectable({
  providedIn: "root"
})
export class CountryService {
  backendUrl = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private store: Store<{ country: CountryState }>
  ) {}

  getCountriesSelector(): Observable<CountryState> {
    return this.store.select(getCountrySelector)
  }

  fetchCountries() {
    this.store.dispatch(fetchCountry());
  }

  getCountry() {
    return this.http.get(`${this.backendUrl}/statics/countries`);
  }

}
