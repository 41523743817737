import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Output,
  EventEmitter
} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { MessageService } from '@services/index';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'aff-message-view-popup',
  templateUrl: './message-view-popup.component.html',
  styleUrls: ['./message-view-popup.component.scss']
})
export class MessageViewPopup implements OnInit, OnDestroy, OnChanges {

  @Input() messageID: string = '';
  @Output('clicked') onClick: EventEmitter<any> = new EventEmitter<any>();

  message = {};
  message$: Observable<any>;

  private unsubscribe$ = new Subject<void>();

  constructor(private messageService: MessageService) {
    this.message$ = this.messageService.getMessageSelector();
  }

  ngOnChanges(changes: SimpleChanges) {
    const {messageID} = changes;

    if (messageID.firstChange && messageID.currentValue.length || !messageID.firstChange && messageID.currentValue !== messageID.previousValue) {
      this.messageService.fetchMessage(messageID.currentValue);
    }
  }

  ngOnInit() {
    if (this.messageID) {
      // this.messageService.fetchMessage(this.messageID);
    }

    this.message$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(message => {
        this.message = message;
      });

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete();
  }

}
