import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PermissionGuard } from '@guards/index';
import { 
  AffiliatesListPage, AffiliatesAddPage } from './index';

const routes: Routes = [
    {
        path: '',
        component: AffiliatesListPage,
        canActivate: [PermissionGuard],
        data: {
            breadcrumb: 'Affiliates'
        },
    },
    { 
        path: 'affiliate', 
        canActivate: [PermissionGuard],
        children: [
            { path: '', component: AffiliatesAddPage, data: { breadcrumb: 'Add Affiliate' } },
            { path: ':id', component: AffiliatesAddPage, data: { breadcrumb: 'Edit Affiliate' } }
        ] 
    }
];

@NgModule({
  imports: [
    RouterModule.forChild(
        routes
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AffiliatesRoutingModule {}
