import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';

@Component({
  selector: 'aff-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {
  @ViewChild('radioElement', { static: true }) radioElement: ElementRef;

  // set attributes
  @Input() set options(att) {
    Object.keys(att).forEach(key => {
      this.radioElement.nativeElement.setAttribute(key, att[key]);
    });
  }
  
  @Input() radioValue = '';
  @Input() class = '';
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;

  @Output() onOutPutValue = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onChange(inputValue) {
    const options = {
      value: this.radioElement.nativeElement.value,
      name: this.radioElement.nativeElement.name
    }
    this.onOutPutValue.emit(options);
  }
}
