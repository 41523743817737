import { Component, OnInit } from '@angular/core';

/**
 * Main Page Container
 */
@Component({
  selector: 'aff-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss']
})
export class MainContainer implements OnInit {

  /**
   * @ignore
   */
  constructor() { }

  /**
   * @ignore
   */
  ngOnInit() {
  }

}
