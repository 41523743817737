import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

import { 
  CampaignAffiliatesService, 
  HelperService, 
  CommissionGroupService } from '@services/index';
import { IState } from '@models/index';
import { IModalvalue } from '@shared-models/index';
  
@Component({
  selector: 'aff-change-affiliates-com-group-popup',
  templateUrl: './change-affiliates-com-group-popup.component.html',
  styleUrls: ['./change-affiliates-com-group-popup.component.scss']
})
export class ChangeAffiliatesComGroupPopupComponent implements OnInit, OnDestroy {
  @Output() outPutOtpions = new EventEmitter<IModalvalue>();
  @Input() campaignID: string = '';
  @Input() affiliates: any[] = [];
  @Input() comGroupIDs: any[] = [];

  commissionGroupsList: any = [];
  selectedCommissionGroup: any;
  commissionGroupsSearch = new FormControl();
  commissionGroups$: Observable<IState>
  error: boolean = false;

  private unsubscribe$ = new Subject<void>();

  constructor(private commissionGroupService: CommissionGroupService,
              private campaignAffiliateService: CampaignAffiliatesService) {
    this.commissionGroups$ = this.commissionGroupService.getCommissionGroupsSelector();
  }

  ngOnInit(): void { 
    // get commission groups
    this.commissionGroupService.fetchCommissionGroups(1, this.campaignID, {});

    this.commissionGroups$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {        
        if (state.isLoaded) {
          this.commissionGroupsList = state.data;
          for (const comGroup of this.comGroupIDs) {
            this.commissionGroupsList = this.commissionGroupsList.filter(group => group.id !== comGroup.id)
          }
        } 
      });

    // commission groups search
    this.commissionGroupsSearch.valueChanges
    .pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
      distinctUntilChanged()
    )
    .subscribe(keyword => this.commissionGroupService.fetchCommissionGroups(1, this.campaignID, { keyword }));
  }

  getComGroupsSearchValue(value: any): void {
    this.commissionGroupsSearch.patchValue(value.term);
  }

  getSelectedComGroups(selectedCommissionGroup: any): void {
    this.error = false;
    this.selectedCommissionGroup = selectedCommissionGroup;
  }
    
  onChangeGroup(): void | boolean {
    if (!this.selectedCommissionGroup || 
        HelperService.isObjectEmpty(this.selectedCommissionGroup ||
        !this.affiliates.length)) return this.error = true;

    if (this.affiliates.length) {
      const affiliatesIds = this.affiliates.map((item) => item.id);
      this.campaignAffiliateService.dispatchupdateCamAffiliatesComGroup(this.selectedCommissionGroup, affiliatesIds);
    } else if (this.comGroupIDs) {
      this.outPutOtpions.emit({ cancel: false, confirm: true, inputValue: this.selectedCommissionGroup.id });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
