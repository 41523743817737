import { createReducer, on } from "@ngrx/store";
import {
  fetchCommissionGroup,
  fetchCommissionGroupSuccess,
  fetchCommissionGroupFailure,
  fetchFilteredCommissionGroups,
  fetchFilteredCommissionGroupsSuccess,
  fetchFilteredCommissionGroupsFailure,
  getCommissionGroup,
  getCommissionGroupFailure,
  getCommissionGroupSuccess,
  deleteCommissionGroup,
  deleteCommissionGroupSuccess,
  deleteCommissionGroupFailure,
  addCommissionGroup,
  addCommissionGroupSuccess,
  addCommissionGroupFailure,
  updateCommissionGroup,
  updateCommissionGroupSuccess,
  updateCommissionGroupFailure,
  fetchCommissionGroupsByAffiliate,
  fetchCommissionGroupsByAffiliateSuccess,
  fetchCommissionGroupsByAffiliateFailure,
  clearCommissionGroup,
  makeDefaultComGroup,
  makeDefaultComGroupSuccess,
  makeDefaultComGroupFailure
} from "./commission-groups.actions";

import { IState } from "@models/index";

const initialState: IState = {
  isLoaded: false,
  data: [],
  filteredDate: [],
  isSaved: false,
  selectedItem: {},
  error: null,
  total: 0,
  maxPages: 0,
  perPage: 0,
  currentPage: 0
};

const _commissionGroupReducer = createReducer(
  initialState,
  on(fetchCommissionGroup, (state, { page, campaignID, filterOptions }) => {
    return state;
  }),
  on(fetchCommissionGroupSuccess, (state, { commissionGroup }) => {
    const payload = {
      isLoaded: true,
      data: commissionGroup.data,
      total: commissionGroup.total,
      maxPages: commissionGroup.maxPages,
      perPage: commissionGroup.perPage,
      currentPage: commissionGroup.currentPage,
      selectedItem: {}
    };

    return { ...state, ...payload };
  }),
  on(fetchCommissionGroupFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(fetchFilteredCommissionGroups, (state, { page, campaignID, filterOptions }) => {
    const payload = {
      isLoaded: false
    }

    return { ...state, ...payload };
  }),
  on(fetchFilteredCommissionGroupsSuccess, (state, { commissionGroups }) => {
    const payload = {
      isLoaded: true,
      filteredDate: commissionGroups.data,
    };

    return { ...state, ...payload };
  }),
  on(fetchFilteredCommissionGroupsFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(fetchCommissionGroupsByAffiliate, (state, { page, affiliateID, filterOptions }) => {
    const payload = {
      isLoaded: false
    };
    return { ...state, ...payload };
  }),
  on(fetchCommissionGroupsByAffiliateSuccess, (state, { commissionGroup }) => {
    const payload = {
      isLoaded: true,
      data: commissionGroup.data,
      total: commissionGroup.total,
      maxPages: commissionGroup.maxPages,
      perPage: commissionGroup.perPage,
      currentPage: commissionGroup.currentPage,
      selectedItem: {}
    };
    return { ...state, ...payload };
  }),
  on(fetchCommissionGroupsByAffiliateFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),

  on(getCommissionGroup, (state, { id }) => {
    const payload = {
      isLoaded: false,
      isSaved: false
    };
    return { ...state, ...payload };
  }),
  on(getCommissionGroupSuccess, (state, { commissionGroup }) => {
    const payload = {
      isLoaded: true,
      selectedItem: commissionGroup
    };
    return { ...state, ...payload };
  }),
  on(getCommissionGroupFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(deleteCommissionGroup, (state, { id, moveAffToGroupId }) => {
    const payload = {
      isLoaded: false,
      isSaved: false
    };
    return { ...state, ...payload };
  }),
  on(deleteCommissionGroupSuccess, (state, { id, moveAffToGroupId, affCount }) => {
    if (moveAffToGroupId) {
      const movedGroup = state.data.find(com => com.id === moveAffToGroupId);
      movedGroup.affCount = +movedGroup.affCount + affCount;
    }

    const payload = {
      isLoaded: true,
      data: state.data.filter(item => item.id !== id),
      total: state.total - 1
    };
    return { ...state, ...payload };
  }),
  on(deleteCommissionGroupFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error
    };
    return { ...state, ...payload };
  }),
  on(addCommissionGroup, (state, { commissionGroup }) => {
    const payload = {
      isSaved: false
    };
    return { ...state, ...payload };
  }),
  on(addCommissionGroupSuccess, (state, { commissionGroup }) => {
    let total: number = state.total;
    
    if (state.total < state.perPage) {
      total += 1;
    } else {
      state.data.splice(state.data.length - 1, 1);
    }

    commissionGroup.affCount = 0;

    const payload = {
      isSaved: true,
      total,
      selectedItem: {},
      data: [{ ...commissionGroup }, ...state.data]
    };
    return { ...state, ...payload };
  }),
  on(addCommissionGroupFailure, (state, { error }) => {
    const payload = {
      isSaved: false,
      error
    };
    return { ...state, ...payload };
  }),
  on(updateCommissionGroup, (state, { commissionGroup }) => {
    const payload = {
      isSaved: false
    }
    return { ...state, ...payload };
  }),
  on(updateCommissionGroupSuccess, (state, { commissionGroup }) => {
    const index = state.data.findIndex(com => com.id === commissionGroup.id);
    state.data[index] = { ...state.data[index], commissionGroup };
    
    const payload = {
      isSaved: true,
      selectedItem: {}
    }
    return { ...state, ...payload };
  }),
  on(updateCommissionGroupFailure, (state, { error }) => {
    const payload = {
      isSaved: false,
      error
    };
    return { ...state, ...payload };
  }),
  on(makeDefaultComGroup, (state, { id, campaignId }) => {
    const payload = {
      error: null
    };
    return { ...state, ...payload };
  }),
  on(makeDefaultComGroupSuccess, (state, { id, comGroup }) => {
    state.data.forEach(com => {
      id === com.id ? com.is_default = true : com.is_default = false;
    });
    
    return { ...state };
  }),
  on(makeDefaultComGroupFailure, (state, { error }) => {
    const payload = {
      error
    };
    return { ...state, ...payload };
  }),
  on(clearCommissionGroup, state => {
    const payload = {
      selectedItem: {},
    };
    return { ...state, ...payload };
  }),
);

export function commissionGroupReducer(state, action) {
  return _commissionGroupReducer(state, action);
}
